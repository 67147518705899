import { createSlice } from '@reduxjs/toolkit'
import { getConfigurationRoute, getDesignsRoute, routes } from '../routes/Routes'

const initialState = {
  navigate: {
    enabled: false,
    to: ''
  }
}

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setNavigate: (state, action) => {
      state.navigate = {
        enabled: true,
        to: action.payload
      }
    },
    unsetNavigate: state => {
      state.navigate = initialState.navigate
    }
  }
})

export const {
  setNavigate,
  unsetNavigate
} = navigationSlice.actions

export const toConfigurationPage = (productId, designId) => async (dispatch, getState) => {
  const productDesignConfigurationRoute = getConfigurationRoute(productId, designId)
  dispatch(setNavigate(productDesignConfigurationRoute))
}

export const toDesignsPage = productId => async (dispatch, getState) => {
  const productDesignsRoute = getDesignsRoute(productId)
  dispatch(setNavigate(productDesignsRoute))
}

export const toHomePage = () => async (dispatch, getState) => {
  dispatch(setNavigate(routes.home))
}

export default navigationSlice.reducer
