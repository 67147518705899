const Viewer = window.Viewer3D

let viewerInstance
const destroyViewerInstance = () => {
  if (viewerInstance) {
    viewerInstance.destroy()
    viewerInstance = null
  }
}

// let viewerContainer
// const setViewerContainer = element => viewerContainer = element


const startViewerInstance = () => {
  viewerInstance = new Viewer({
    // the id of the container where the component will live
    containerID: "Viewer3D",
    assetsPath: process.env.PUBLIC_URL + '/assets/viewer3d-static'
  })
  window.viewer3d = viewerInstance;
  //viewerInstance.updateRenderAspect()
}

const setViewerContainer = (containerId) => {
  viewerInstance.setContainer(containerId)
}


const moveCameraOnPartSelection = (part) => {

  const frontParts = [
    "FRONTE",
    "INSERTI_FIANCHI",
    "COLLO",
    "INSERTO_COLLO",
    "POLSI",
    "INSERTO_POLSI",
    "ELEMENTO_1",
    "ELEMENTO_2",
    "INSERTO_FIANCHI",
    "ELEMENTO_3",
    "ELEMENTO_4",
    "INSERTO_FIANCO",
    "INSERTO_RETRO",
    "TASCHE",
    "LIVELLO_1",
    "CAVALLOTTO",
    "PATTINA",
    "CAPPUCCIO",
    "FONDO_GRESPO"
  ];
  const leftParts = [
    "FIANCO_SX",
    "GIROMANICA_SX",
    "GAMBA_SX",
    "GAMBA_SX_RETRO",
    "MANICA_SX",
    "GAMBA_RETRO_SX",
    "GAMBA_FRONTE_SX",
    "GAMBA_SX_FRONTE"
  ];
  const rightParts = [
    "MANICA_DX",
    "FIANCO_DX",
    "GIROMANICA_DX",
    "GAMBA_DX",
    "GAMBA_FRONTE_DX",
    "GAMBA_RETRO_DX",
    "GAMBA_DX_RETRO",
    "GAMBA_DX_FRONTE"
  ];

  const backParts = [
    "RETRO"
  ];


  if (frontParts.includes(part)) {
    viewerInstance?.setCameraToFront();
  } else if (leftParts.includes(part)) {
    viewerInstance?.setCameraToLeft();
  } else if (rightParts.includes(part)) {
    viewerInstance?.setCameraToRight();
  } else if (backParts.includes(part)) {
    viewerInstance?.setCameraToBack();
  } else {
    console.log("No matching side found");
  }

}

export {
  Viewer,
  viewerInstance,
  // viewerContainer,
  setViewerContainer,
  startViewerInstance,
  destroyViewerInstance,
  moveCameraOnPartSelection
}
