import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { initApp } from "../../slices/initSlice"
import ErrorPage from "../../pages/ErrorPage"

export default function InitManager(props) {
  const { children } = props
  const dispatch = useDispatch()
  const initState = useSelector(state => state.init)

  useEffect(() => {
    dispatch(initApp())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (initState.initFulfilled) {
    if (initState.initError)
      return <ErrorPage message={initState.initErrorMessage} />
    else
      return children
  }


}
