export const getPositions = () => {
  return [
    {
      id: "front-right",
      src: "/imgs/front-right.png",
      label: "option_position_label_front_right",
      supported: [
        "image", "free", "number", "initials",
      ],
      mesh_name: "front",
      side: "front",
      positionX: 0.43922,
      positionY: 0.27725,
      width: 50,
    },
    {
      id: "front-center-center",
      src: "/imgs/front-center-center.png",
      label: "option_position_label_front_center_center",
      supported: [
        "image", "free", "number",
      ],
      mesh_name: "front",
      side: "front",
      positionX: 0.49644,
      positionY: 0.27725,
      width: 50,
    },
    {
      id: "front-left",
      src: "/imgs/front-left.png",
      label: "option_position_label_front_left",
      supported: [
        "image", "free", "number", "initials",
      ],
      mesh_name: "front",
      side: "front",
      positionX: 0.55587,
      positionY: 0.27725,
      width: 50,
    },
    {
      id: "front-center-top",
      src: "/imgs/front-center-top.png",
      label: "option_position_label_front_center_top",
      supported: [
        "image", "free",
      ],
      mesh_name: "front",
      side: "front",
      positionX: 0.49644,
      positionY: 0.27725,
      width: 300,
    },
    {
      id: "front-center",
      src: "/imgs/front-center.png",
      label: "option_position_label_front_center",
      supported: [
        "image", "free",
      ],
      mesh_name: "front",
      side: "front",
      positionX: 0.49644,
      positionY: 0.49997,
      width: 150,
    },
    {
      id: "front-center-bottom",
      src: "/imgs/front-center-bottom.png",
      label: "option_position_label_front_center_bottom",
      supported: [
        "image", "free",
      ],
      mesh_name: "front",
      side: "front",
      positionX: 0.49644,
      positionY: 0.72269,
      width: 300,
    },
    {
      id: "back-top",
      src: "/imgs/back-top.png",
      label: "option_position_label_back_top",
      supported: [
        "image", "free", "name"
      ],
      mesh_name: "back",
      side: "back",
      positionX: 0.16499,
      positionY: 0.21044,
      width: 300,
    },
    {
      id: "back-center",
      src: "/imgs/back-center.png",
      label: "option_position_label_back_center",
      supported: [
        "image", "free", "number",
      ],
      mesh_name: "back",
      side: "back",
      positionX: 0.16499,
      positionY: 0.40031,
      width: 220,
    },
    {
      id: "back-bottom",
      src: "/imgs/back-bottom.png",
      label: "option_position_label_back_bottom",
      supported: [
        "image", "free", "number", "name"
      ],
      mesh_name: "back",
      side: "back",
      positionX: 0.16499,
      positionY: 0.72587,
      width: 300,
    }
  ]
}

export const getPositionsLogo = () => {
  return [
    {
      id: "front-right",
      src: "/imgs/front-right.png",
      label: "option_position_label_front_right",
      mesh_name: "Fronte",
      side: "front",
      positionX: 0.43922,
      positionY: 0.27725,
      width: 50,
    },
    {
      id: "front-center-center",
      src: "/imgs/front-center-center.png",
      label: "option_position_label_front_center_center",
      mesh_name: "Fronte",
      side: "front",
      positionX: 0.49644,
      positionY: 0.27725,
      width: 50,
    },
    {
      id: "front-left",
      src: "/imgs/front-left.png",
      label: "option_position_label_front_left",
      mesh_name: "Fronte",
      side: "front",
      positionX: 0.55587,
      positionY: 0.27725,
      width: 50,
    },

  ]
}



