import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconUploadCustom = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className}>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 -960 960 960" width="20"
      fill="currentColor"
      >
      <path d="M480-192q-120 0-205.5-85T192-486l-57 57-51-51 144-144 144 144-51 51-57-57q-2 94 62 158t154 64q22 0 42-4t39-12l53 53q-30 16-63.5 25.5T480-192Zm252-144L588-480l51-51 57 57q2-94-62-158t-154-64q-21 0-41.5 4T399-680l-53-53q30-16 64-25.5t70-9.5q121 0 206 85t82 209l57-57 51 51-144 144Z"/>
      </svg>
    </SvgIcon>
  );
};
export default IconUploadCustom;
