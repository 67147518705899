import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { unsetNavigate } from "../../slices/navigationSlice"
import { useNavigate, useLocation } from "react-router"
import { loadConfiguredProduct, setConfiguredProductId } from "../../slices/productSlice"

export default function NavigateTo() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const navigateState = useSelector(state => state.navigation.navigate)
  const location = useLocation()

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const configuredProductId = urlParams.get('configuredProductId');
    // IF ID IS NOT NULL, LOAD CONFIGURED PRODUCT
    if (configuredProductId) {
      dispatch(loadConfiguredProduct(configuredProductId))
    }
    else {
      // OTHERWISE, RESET CONFIGURED PRODUCT ID BEFORE INITIALIZING
      dispatch(setConfiguredProductId(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, dispatch]);

  useEffect(() => {
    if (navigateState.enabled) {
      dispatch(unsetNavigate())
      navigate(navigateState.to)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateState])

}
