export const getFonts = () => {
  return [
    {
      label: "Font_A",
      value: "Aku & Kamu",
      src: "/imgs/font_akukamu.png"
    },
    {
      label: "Font_B",
      value: "Aurora BdCn BT",
      src: "/imgs/font_aurora.png"
    },
    // {
    //   label: "sans-serif",
    //   value: "sans-serif",
    //   src: "/imgs/F001.png",
    // },
    {
      label: "Font_C",
      value: "Cubadak",
      src: "/imgs/font_cubadak.png"
    },
    {
      label: "Font_D",
      value: "Farsan",
      src: "/imgs/font_farsan.png"
    },
    {
      label: "Font_E",
      value: "Alegre Sans",
      src: "/imgs/font_alegre.png"
    },
    {
      label: "Font_F",
      value: "Typey McTypeface",
      src: "/imgs/font_typeymctypeface.png"
    },
    {
      label: "Font_G",
      value: "Bullpen Hv",
      src: "/imgs/font_bullpenhv.png"
    },
    {
      label: "Font_H",
      value: "Averil",
      src: "/imgs/font_averil.png"
    },
    {
      label: "Font_I",
      value: "Liquid Crystal",
      src: "/imgs/font_liquidcrystal.png"
    },
    {
      label: "Font_L",
      value: "Horizon BT",
      src: "/imgs/font_horizonbt.png"
    },
    {
      label: "Font_M",
      value: "Kelson Sans RU",
      src: "/imgs/font_kelson.png"
    },
    {
      label: "Font_N",
      value: "Tertre Med",
      src: "/imgs/font_tertremed.png"
    },
    {
      label: "Font_O",
      value: "Kenyan Coffee Stencil Sb",
      src: "/imgs/font_kenyancoffeestencilsb.png"
    },
    {
      label: "Font_P",
      value: "ArtBrush",
      src: "/imgs/font_artbrush.png"
    },
    // {
    //   label: "Broken Glass",
    //   value: "Broken Glass",
    //   src: "/imgs/font_brokenglass.png"
    // },
    // {
    //   label: "Dyphusion BRK",
    //   value: "Dyphusion BRK",
    //   src: "/imgs/font_dyphusion.png"
    // },
    // {
    //   label: "LaTribuneCP",
    //   value: "LaTribuneCP",
    //   src: "/imgs/font_latribune.png"
    // },
    // {
    //   label: "MadisonSquare Incised",
    //   value: "MadisonSquare Incised",
    //   src: "/imgs/font_madisonsquare.png",
    // },
    // ---
  ]
}
