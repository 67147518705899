import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconOffsetX = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 21"
        width="20"
      >
        <path d="M8.33342 15.5V5.5H18.3334V15.5H8.33342ZM10.0001 13.8333H16.6667V7.16667H10.0001V13.8333ZM5.00008 15.5V13.8333H6.66675V15.5H5.00008ZM5.00008 7.16667V5.5H6.66675V7.16667H5.00008ZM1.66675 15.5V13.8333H3.33341V15.5H1.66675ZM1.66675 11.3333V9.66667H3.33341V11.3333H1.66675ZM1.66675 7.16667V5.5H3.33341V7.16667H1.66675Z" fill="currentColor"/>
      </svg>
    </SvgIcon>
  );
};
export default IconOffsetX;
