import { Box, Button, IconButton, List, Typography, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import Divider from "./Divider";
import PartsListItem from "./layout/PartsListItem";
import { useDispatch, useSelector } from "react-redux";
import { setProductParts } from "../slices/appSlice";
import AccordionMessage from "./AccordionMessage";
// import ScrollDownSignal from "./ScrollDownSignal";

export default function PanelClonePart(props) {

  const {
    productParts,
    handleClose,
    cloneDestination,
    setCloneDestination,
    updatePart,
    // isScrollable,
    // isScrolledToEnd
  } = props

  const { t } = useTranslation();

  const theme = useTheme();

  const appState = useSelector(state => state.app)
  const dispatch = useDispatch()


  const onClick = (item) => {
    const partList = [];

    appState.productParts.forEach(el => {

      let element = { ...el };

      if (element.id === cloneDestination.id) {
        element.color = item.color
        element.colorPosition = item.colorPosition
        element.color2 = item.color2
        element.color2Position = item.color2Position
        element.colorsAngle = item.colorsAngle
        element.pattern = !element.supportPattern ? undefined : item.pattern;
        element.patternUrl = !element.supportPattern ? element.patternUrl : item.patternUrl
        element.patternAngle = !element.supportPattern ? element.patternAngle : item.patternAngle
        element.patternSize = !element.supportPattern ? element.patternSize : item.patternSize
        element.patternOffsetX = !element.supportPattern ? element.patternOffsetX : item.patternOffsetX
        element.patternOffsetY = !element.supportPattern ? element.patternOffsetY : item.patternOffsetY
        element.patternColor = !element.supportPattern ? element.patternColor : item.patternColor
        // console.log(element)
        setCloneDestination({ ...element });
      }
      partList.push({ ...element });
      updatePart({ ...element });
    });

    dispatch(setProductParts(partList));
  }

  return (
    <>
      <Box
        className="panel-box"
        sx={{
          img: {
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain"
          }
        }}
      >

        <Box className="panel-header" sx={{
          display: "flex",
          gap: "10px",
          py: "10px",
          alignItems: "center"
        }}>
          <Typography variant="h4" sx={{ marginRight: "auto" }}>
            {t("panel_title_copy_property")}
          </Typography>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box >
          {/* SELECTED PART ON WICH YOU WANT DUPLICATE THE PROPERTIES */}
          <Box className="panel-content">
            <AccordionMessage
              className=""
              title={t("info_title_clone_part")}
              text={t("info_description_clone_part")}
            />
            <Typography variant="body2" sx={{
              color: theme.palette.white.o07,
              mt: "20px"
            }}>
              {t("panel_title_text_you_are_editing")}
            </Typography>
          </Box>
          <PartsListItem
            item={cloneDestination}
            index={0}
            onClick={() => { }}
            notClickable={true}
            clone={false}
          />
          <Box className="panel-content">
            <Divider bgcolor={theme.palette.white.o015}></Divider>
          </Box>

          {/* ALL THE OTHER PARTS WHERE YOU CAN COPY */}
          <Box className="panel-content">

            <Typography variant="body2" sx={{
              color: theme.palette.white.o07,
            }}>
              {t("panel_title_text_select_the_part_from_which_you_want_to_copy")}
            </Typography>
          </Box>

          <List>
            {productParts.map((item, index) => (
              item.id !== cloneDestination.id ?
                <PartsListItem
                  key={"list-item-" + index}
                  item={item}
                  index={index}
                  onClick={onClick}
                  copy={true}
                  copyFunction={onClick}
                  patternWarning={!cloneDestination.supportPattern && item.pattern}
                /> : ""
            ))}
          </List>
        </Box>
      </Box>
      <Box sx={{
        position: "sticky",
        bottom: "10px",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
        width: "100%"
      }}>
        <Button
          variant="contained"
          className="btn-white"
          onClick={() => { handleClose(); }}>
          {t("panel_button_label_confirm")}
        </Button>
      </Box>
    </>
  )
}
