import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconScrollDown = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 21 20"
        width="21"
      >
        <path d="M10.5 18L5 12.5L6.0625 11.4375L9.75 15.125V9H11.25V15.125L14.9375 11.4375L16 12.5L10.5 18ZM9.75 7.5V5H11.25V7.5H9.75ZM9.75 3.5V2H11.25V3.5H9.75Z"/>

      </svg>
    </SvgIcon>
  );
};
export default IconScrollDown;
