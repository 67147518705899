import Button from "@mui/material/Button";
import { forwardRef } from "react";
import { Typography, useTheme } from "@mui/material";
const ButtonRound = forwardRef((props, ref) => {
  const theme = useTheme();
  const {
    //ref,
    id,
    variant,
    className,
    state,
    onClick,
  } = props;
  return (
    <Button
      disableRipple
      ref={ref}
      id={id}
      variant={variant}
      className={className}
      state={state}
      onClick={onClick}
      sx={{
        bgcolor: theme.palette.black.o05,
        webkitBackdropFilter: theme.palette.filter.blur50,
        backdropFilter: theme.palette.filter.blur50,
        color: theme.palette.white.main,
        py: "15px",
        px: "30px",
        borderRadius: "50px",
        boxShadow: 0,
        ":hover": {
          bgcolor: theme.palette.grey.o05,
          boxShadow: 0,
        },
        '&[state="active"]': {
          bgcolor: theme.palette.white.main,
          boxShadow: 0,
        },
        '&[state="active"] span': {
          color: theme.palette.black.main,
        },
        "&.btn-white": {
          bgcolor: theme.palette.white.main,
        },
        "&.btn-white span": {
          color: theme.palette.black.main,
        },
        "&.btn-red": {
          bgcolor: theme.palette.primary.blur,
        },
        "&.btn-red:hover": {
          bgcolor: theme.palette.primary.blurHover,
        },
        "&.btn-solid-red": {
          bgcolor: theme.palette.primary.solidRed,
        },
        "&.btn-solid-red:hover": {
          bgcolor: theme.palette.primary.main,
        },
        "&.mx-auto": {
          marginX: "auto",
        }
      }}
    >
      <Typography variant="button">{props.text}</Typography>
    </Button>
  );
});
export default ButtonRound;
