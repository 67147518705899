import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";

const CardPlacement = (props) => {
  const { id, text, className, imgsrc, onClick } = props;
  const theme = useTheme();
  return (
    <Box
      id={id}
      className={className}
      onClick={onClick}
      sx={{
        overflow: "hidden",
        bgcolor: "transparent",
        p: "10px",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        boxShadow: 0,
        flexGrow: 1,
        maxWidth: "calc(calc(100% - 10px) / 3)",
        "&.active": {
          outline: `1px solid ${theme.palette.white.o05}`,
          ":hover": {
            bgcolor: "transparent",
            boxShadow: 0,
          },
        },
        ":hover": {
          bgcolor: theme.palette.white.o01,
          boxShadow: 0,
        },
        img: {
          display: "block",
          width: "100px",
          height: "100px",
          margin: "0 auto",
        }
      }}
    >
      <img src={imgsrc} alt={text} />
      <Typography
        variant="caption"
        sx={{
          textAlign: "center",
          maxWidth: "100px",
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
export default CardPlacement;
