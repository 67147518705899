import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";

export default function BuyButton(props) {
  const theme = useTheme()
  const {
    id,
    variant,
    className,
    onClick,
    text,
  } = props

  return (
    <Button
      disableRipple
      id={id}
      variant={variant}
      className={className}
      onClick={onClick}
      sx={{
        marginLeft: "10px",
        marginRight: "0px",
        bgcolor: theme.palette.primary.hover,
        color: theme.palette.white.main,
        fontSize: 14,
        lineHeight: 1.2,
        fontWeight: 600,
        py: "11px",
        px: "26px",
        borderRadius: "50px",
        boxShadow: 0,
        ":hover": {
          bgcolor: theme.palette.primary.main,
          boxShadow: 0,
        },
        "&.disabled": {
          bgcolor: theme.palette.dark.main,
          cursor: "default",
          "&:hover": {
            bgcolor: theme.palette.dark.main,
            cursor: "default"
          },
        },
        "&.card-customize-btn": {
          marginLeft: "0px",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "10px",
        },
        "&.mx-auto": {
          marginX: "auto",
        }
      }}
    >
      {text}
    </Button>
  )
}
