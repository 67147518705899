import { Drawer, Box, Typography, IconButton, useTheme, Menu, MenuItem } from "@mui/material";
import Divider from "./Divider";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import PanelCustomTexts from "./PanelCustomTexts";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as IconNumber } from "../icons/icon-text-number.svg";
import { ReactComponent as IconText } from "../icons/icon-text-text.svg";
import { ReactComponent as IconInitial } from "../icons/icon-text-initial.svg";
import { ReactComponent as IconName } from "../icons/icon-text-name.svg";
import PanelCustomizeText from "./PanelCustomizeText";
import { viewerInstance } from "../helpers/Viewer";
import { appBarHeight } from "../helpers/layout";
import ModalConfirmation from "./ModalConfirmation";
import { ReactComponent as IconTrash } from "../icons/icon-trash.svg";

export default function DrawerText(props) {
  const {
    colorsList,
    colorsInUse,
    textsList,
    setTextsList,
    positionsList,
    fontList,
    currentSelectedItem,
    setCurrentSelectedItem,
    textSelected,
    setTextSelected,
    textEdit,
    setTextEdit,
    indexTextSelected,
    setIndexTextSelected,
    defaultText
  } = props;

  const theme = useTheme();

  const { t } = useTranslation()

  const refAdd = useRef();

  const [colorsInUseText, setColorsInUseText] = useState(colorsInUse);

  const [waitingForPreset, setWaitingForPreset] = useState(false);
  const [presetSide, setPresetSide] = useState("front");

  const [modalDeleteTextOpen, setModalDeleteTextOpen] = useState(false);

  const handleTextSelection = (item, index) => {
    setTextSelected({ ...item });
    setIndexTextSelected(index);
    setTextEdit(true)
    viewerInstance.selectItemName(item.id);
  }

  const handleAddNewTextEdit = (type) => {
    // console.log("handleAddNewTextEdit");
    closeMenuNewText(textsList);

    const typeFilterede = textsList.filter((e) => e.type === type);
    const textDefault = {
      ...defaultText, ...{
        type: type,
        text:
          type === "number" ? typeFilterede.length > 0 ? typeFilterede[0].text : "00" :
            type === "name" ? typeFilterede.length > 0 ? typeFilterede[0].text : "Name Surname" :
              type === "initials" ? "NC" :
                type === "free" ? "Text" : ""
      }
    };

    setTextSelected(textDefault)
    // const tempTextsList = [...textsList];
    // let length = tempTextsList.push({ ...textDefault });
    // setTextsList([...tempTextsList]);
    setIndexTextSelected(null);
    setTextEdit(true);
  }

  const handleTextEditCancel = () => {
    if (indexTextSelected === null) {
      viewerInstance.deleteItem();
    } else {
      if (textSelected.id === "") {
        const tempTextsList = [...textsList];
        if (indexTextSelected !== null) {
          tempTextsList.splice(indexTextSelected, 1);
        }
        setTextsList([...tempTextsList]);
      }
    }
    setTextSelected(null);
    setIndexTextSelected(null);
    setTextEdit(false)
    viewerInstance.cancel();
    viewerInstance.unselectItem();
    setCurrentSelectedItem(null);
  }

  const handleTextEditConfirm = () => {
    if (textSelected) {
      const tempTextsList = [...textsList];

      if (indexTextSelected !== null && textSelected.id !== "") {
        tempTextsList[indexTextSelected] = { ...textSelected };
      }
      else {
        if (textSelected.id === "") {
          if (indexTextSelected !== null) {
            tempTextsList.splice(indexTextSelected, 1);
          }
        }
        else {
          if (tempTextsList.filter((text) => text.id === textSelected.id).length === 0) {
            tempTextsList.push({ ...textSelected });
          }
        }
      }
      setTextsList([...tempTextsList]);
    }

    viewerInstance.cancel();
    viewerInstance.unselectItem();
    handleUnselect();
  }

  const handleUnselect = () => {
    setTextEdit(false)
    setTextSelected(null);
    setIndexTextSelected(null);
  }


  const handleTextDuplicate = () => {
    const tempTextsList = [...textsList];
    // console.log(indexTextSelected, tempTextsList)

    if (indexTextSelected !== null) {
      tempTextsList[indexTextSelected] = { ...textSelected };
    } else {
      tempTextsList.push({ ...textSelected });
    }

    const clonedResponse = viewerInstance.cloneItem(textSelected.id);

    const newIndex = tempTextsList.push({ ...textSelected, ...{ id: clonedResponse.name } });
    // console.log(tempTextsList);
    handleTextSelection({ ...textSelected, ...{ id: clonedResponse.name } }, newIndex - 1)
    setTextsList([...tempTextsList]);
    // handleTextEditCancel();
  }

  const handleDelete = () => {
    const tempTextsList = [...textsList];
    if (indexTextSelected !== null) {
      tempTextsList.splice(indexTextSelected, 1);
    }
    setTextsList([...tempTextsList]);
    viewerInstance.deleteItem();
    handleTextEditCancel()
    setCurrentSelectedItem(null);
  }

  const handleTextDuplicateFromList = (id) => {
    const tempTextsList = [...textsList];

    const filtered = tempTextsList.filter((e) => e.id === id);

    const clonedResponse = viewerInstance.cloneItem(id);

    const newIndex = tempTextsList.push({ ...filtered[0], ...{ id: clonedResponse.name, hasLink: null } });
    // console.log(tempTextsList);
    handleTextSelection({ ...filtered[0], ...{ id: clonedResponse.name, hasLink: null } }, newIndex - 1)
    setTextsList([...tempTextsList]);
    // handleTextEditCancel();
  }

  const handleDeleteFromList = (id) => {
    const tempTextsList = [...textsList];

    const filtered = tempTextsList.filter((e) => e.id === id);
    if (filtered.length > 0) {
      const index = tempTextsList.indexOf(filtered[0]);
      tempTextsList.splice(index, 1);
      setTextsList([...tempTextsList]);
      viewerInstance.deleteItem(id);
      handleTextEditCancel()
      setCurrentSelectedItem(null);
    }
  }

  const [menuTextOpen, setMenuTextOpen] = useState(false);

  const openMenuNewText = () => {
    setMenuTextOpen(true);
  }

  const closeMenuNewText = () => {
    setMenuTextOpen(false);
  }

  const handleMirror = () => {
    if (textSelected && textSelected.id && textSelected.id !== "") {
      const tempTextSelected = { ...textSelected };
      const tempTextsList = [...textsList];

      if (textSelected.hasLink) {
        viewerInstance.unmirrorItem(textSelected.id);
        viewerInstance.deleteItem(tempTextSelected.hasLink);
        tempTextSelected.hasLink = null;
        handleUpdateTextSelected({ ...tempTextSelected }, currentSelectedItem, [...tempTextsList]);
        // console.log(unmirrorResponse);
        viewerInstance.selectItemName(tempTextSelected.id);
      } else {
        const mirrorResponse = viewerInstance.mirrorItem(textSelected.id);
        tempTextsList.push({
          ...tempTextSelected, ...{
            id: mirrorResponse.name,
            size_x: mirrorResponse.size.width.toFixed(2).toString(),
            size_y: mirrorResponse.size.height.toFixed(2).toString(),
            angle: mirrorResponse.rotation,
            hasLink: tempTextSelected.id,
          }
        });
        tempTextSelected.hasLink = mirrorResponse.name;
        // console.log("handleMirror setTextsList:",tempTextSelected, [...tempTextsList]);
        handleUpdateTextSelected({ ...tempTextSelected }, currentSelectedItem, [...tempTextsList]);
      }
    }
  }

  const handleUnlink = () => {
    // console.log("handleUnlink")
    if (textSelected && textSelected.id !== "") {
      const tempTextSelected = { ...textSelected };
      const tempTextsList = [...textsList];

      if (textSelected.hasLink) {
        const unmirrorResponse = viewerInstance.unmirrorItem(textSelected.id);
        if (unmirrorResponse) {

          const filtered = tempTextsList.filter((e) => e.id === tempTextSelected.hasLink);

          if (filtered.length > 0) {
            const index = tempTextsList.indexOf(filtered[0]);
            if (index >= 0) {
              let tempTextFromList = { ...tempTextsList[index] };
              tempTextFromList.hasLink = null;
              tempTextsList[index] = tempTextFromList;
            }
          }

          tempTextSelected.hasLink = null;
          setTextSelected({ ...tempTextSelected });

          const filteredCurrent = tempTextsList.filter((e) => e.id === tempTextSelected.id);

          if (filteredCurrent.length > 0) {
            const index = tempTextsList.indexOf(filteredCurrent[0]);
            if (index >= 0) {
              tempTextsList[index] = { ...tempTextSelected };
            }
          }

          setTextsList([...tempTextsList]);
        }
      }
    }
  }

  const handleUpdateTextSelected = (tempTextSelected, itemInfo, tempTextsList) => {
    // const tempTextSelected = { ...textSelected };
    // console.log("updatetempTextSelected", { ...tempTextSelected }, itemInfo)
    if (tempTextSelected.id === itemInfo.name) {
      const textSelectedUpdated = {
        ...tempTextSelected, ...{
          // id: itemInfo.name,
          text: itemInfo.text,
          font: itemInfo.font,
          text_color: itemInfo.color,
          angle: itemInfo.rotation >= 0 ? itemInfo.rotation % 360 : 360 + (itemInfo.rotation % 360),
          size_x: itemInfo.size.width.toFixed(0).toString(),
          size_y: itemInfo.size.height.toFixed(0).toString(),
          size_ratio: (itemInfo.size.width / itemInfo.size.height),
          isMirrorable: itemInfo.isMirrorable,
          isLocked: itemInfo.isLocked,
          background: itemInfo.backgroundColor ? true : false,
          // background_color: itemInfo.backgroundColor,
          background_size: itemInfo.backgroundBorderWidth,
          background_angle: itemInfo.backgroundBorderJoin,
          border: itemInfo.outlineColor ? true : false,
          // border_color: itemInfo.outlineColor,
          border_weight: itemInfo.outlineWidth,
          border_angle: itemInfo.outlineJoin,
          border_dotted: itemInfo.outlineStyle,
        }
      }

      setTextSelected({ ...textSelectedUpdated });
      //UPDATE TEXTS LIST - IF HASLINK

      if (textSelectedUpdated.hasLink) {
        // console.log("ENTRO", textSelectedUpdated.hasLink, tempTextsList)
        const filteredHasLink = tempTextsList.filter((e) => e.id === textSelectedUpdated.hasLink);
        // console.log("filteredHasLink", filteredHasLink)
        if (filteredHasLink.length > 0) {
          const indexHasLink = tempTextsList.indexOf(filteredHasLink[0]);
          if (indexHasLink >= 0) {
            const infoToUpdate = viewerInstance.getItemInfo(textSelectedUpdated.hasLink);
            if (infoToUpdate) {
              const tempLink = {
                ...tempTextsList[indexHasLink], ...{
                  // id: itemInfo.name,
                  text: infoToUpdate.text,
                  font: infoToUpdate.font,
                  text_color: infoToUpdate.color,
                  angle: infoToUpdate.rotation >= 0 ? infoToUpdate.rotation % 360 : 360 + (infoToUpdate.rotation % 360),
                  size_x: infoToUpdate.size.width.toFixed(0).toString(),
                  size_y: infoToUpdate.size.height.toFixed(0).toString(),
                  size_ratio: (infoToUpdate.size.width / infoToUpdate.size.height),
                  isMirrorable: infoToUpdate.isMirrorable,
                  isLocked: infoToUpdate.isLocked,
                  background: infoToUpdate.backgroundColor ? true : false,
                  // background_color: infoToUpdate.backgroundColor,
                  background_size: infoToUpdate.backgroundBorderWidth,
                  background_angle: infoToUpdate.backgroundBorderJoin,
                  border: infoToUpdate.outlineColor ? true : false,
                  // border_color: infoToUpdate.outlineColor,
                  border_weight: infoToUpdate.outlineWidth,
                  border_angle: infoToUpdate.outlineJoin,
                  border_dotted: infoToUpdate.outlineStyle,
                }
              }
              tempTextsList[indexHasLink] = { ...tempLink };
            }
          }
        }
      }
      //UPDATE TEXTS LIST
      const filtered = tempTextsList.filter((e) => e.id === itemInfo.name);
      let oldTextSelected = null;
      if (filtered.length > 0) {
        const index = tempTextsList.indexOf(filtered[0]);
        if (index >= 0) {
          setIndexTextSelected(index);
          oldTextSelected = structuredClone(tempTextsList[index]);
          tempTextsList[index] = { ...textSelectedUpdated };
        }
        else {
          setIndexTextSelected(tempTextsList.push({ ...textSelectedUpdated }) - 1);
        }
      } else {
        setIndexTextSelected(tempTextsList.push({ ...textSelectedUpdated }) - 1);
      }

      // UPDATE TEXTS WITH SAME TYPE IF NUMBER OR NAME

      if ((oldTextSelected !== null && oldTextSelected.text !== itemInfo.text) && (tempTextSelected.type === "number" || tempTextSelected.type === "name")) {
        // GET ALL TEXTS WITH SAME TYPE BUT DIFFERENT ID
        const filteredByType = tempTextsList.filter((e) => (e.id !== tempTextSelected.id && tempTextSelected.type === e.type));

        if (filteredByType.length > 0) {
          filteredByType.forEach((filteredItem) => {

            const infoToUpdate = viewerInstance.getItemInfo(filteredItem.id);
            const updateItem = {
              ...infoToUpdate, ...{
                text: textSelectedUpdated.text.length > 0 ? textSelectedUpdated.text : " "
              }
            }

            const tempItem = JSON.stringify(updateItem);
            const itemToUpdate = JSON.parse(tempItem);

            // console.log(viewerInstance, itemToUpdate);

            const responseUpdate = viewerInstance.updateItem(itemToUpdate);

            const filteredItemTemp = {
              ...filteredItem, ...{
                // id: itemInfo.name,
                text: responseUpdate.text,
                font: responseUpdate.font,
                text_color: responseUpdate.color,
                angle: responseUpdate.rotation >= 0 ? responseUpdate.rotation % 360 : 360 + (responseUpdate.rotation % 360),
                size_x: responseUpdate.size.width.toFixed(0).toString(),
                size_y: responseUpdate.size.height.toFixed(0).toString(),
                size_ratio: (responseUpdate.size.width / responseUpdate.size.height),
                isMirrorable: responseUpdate.isMirrorable,
                isLocked: responseUpdate.isLocked,
                background: responseUpdate.backgroundColor ? true : false,
                // background_color: responseUpdate.backgroundColor,
                background_size: responseUpdate.backgroundBorderWidth,
                background_angle: responseUpdate.backgroundBorderJoin,
                border: responseUpdate.outlineColor ? true : false,
                // border_color: responseUpdate.outlineColor,
                border_weight: responseUpdate.outlineWidth,
                border_angle: responseUpdate.outlineJoin,
                border_dotted: responseUpdate.outlineStyle,
              }
            }

            const filteredTemp = tempTextsList.filter((e) => e.id === itemToUpdate.name);

            if (filteredTemp.length > 0) {
              const indexTemp = tempTextsList.indexOf(filteredTemp[0]);
              if (indexTemp >= 0) {
                tempTextsList[indexTemp] = { ...filteredItemTemp };
              }
            }

          });
        }
      }

      setTextsList([...tempTextsList])
    }
  }

  const handleBringOnTop = () => {
    viewerInstance.bringCurrentItemOnTop();
  }

  const handleCenterItem = () => {
    viewerInstance.centerItem();
  }

  useEffect(() => {
    // console.log("currentSelectedItem change", currentSelectedItem, textSelected);
    // Check item currentItem is empty and textSelected is not we have to unset textSelected
    if (
      (currentSelectedItem === null && textSelected) ||
      (currentSelectedItem && currentSelectedItem.type === "LogoInfo")
    ) {
      // (currentSelectedItem && textSelected && currentSelectedItem.type === "TextInfo" && textSelected.id !== "" && textSelected.id !== currentSelectedItem.name)
      handleUnselect()
    }
    if (currentSelectedItem && currentSelectedItem.isText && textSelected && currentSelectedItem.name === textSelected.id) {
      handleUpdateTextSelected({ ...textSelected }, { ...currentSelectedItem }, [...textsList]);
    }

    // UPDATE CAMERA ON PRESET LOADING
    if (waitingForPreset) {
      setWaitingForPreset(false);
      setTimeout(() => {
        switch (presetSide) {
          case "front":
            viewerInstance.setCameraToFront();
            break;
          case "back":
            viewerInstance.setCameraToBack();
            break;
          case "left":
            viewerInstance.setCameraToLeft();
            break;
          case "right":
            viewerInstance.setCameraToRight();
            break;
          default:
            break;
        }
      }, (0));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedItem]);

  useEffect(() => {

    const filtered = colorsList.filter((color) => {

      for (let i = 0; i < textsList.length; i++) {
        if ((textsList[i].background_color === color.color ||
          textsList[i].text_color === color.color ||
          textsList[i].border_color === color.color) &&
          !colorsInUse.includes(color)) {
          return true;
        }
      }
      return false;
    });

    const tempColorInUseText = [...colorsInUse, ...filtered];
    setColorsInUseText(tempColorInUseText);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorsInUse, textsList]);

  const handleDeleteAll = () => {
    setTextsList([]);
    textsList.forEach((text) => {
      viewerInstance.deleteItem(text.id);
    });

  }

  return (
    <>
      <Drawer
        className="panel-right"
        sx={{
          //width: 442,
          "& .MuiDrawer-paper": {
            width: 442,
            maxHeight: `calc(100vh - ${appBarHeight + 30}px)`,
            gap: "10px",
            borderRadius: "20px",
          },
        }}
        variant="permanent"
        anchor="right"
        elevation={0}
      >

        {/* PANNEL LIST TEXTS */}

        <Box id="texts">
          <Box id="text-init" sx={{ display: textEdit ? "none" : "block" }}>
            <Box className="panel-box">
              <Box
                className="panel-header"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4" sx={{ marginRight: "auto" }}>
                  {t("panel_title_entered_texts")}
                </Typography>

                <IconButton
                  aria-label="delete-all"
                  size="small"
                  onClick={() => { setModalDeleteTextOpen(true) }}
                  sx={{
                    display: textsList.length === 0 ? "none" : "flex",
                    mr: "5px",
                    svg: {
                      width: "20px",
                      height: "20px",
                      fill: "currentcolor"
                    }
                  }}>
                  <IconTrash />
                </IconButton>

                <IconButton ref={refAdd} aria-label="add" size="small" onClick={openMenuNewText}>
                  <AddIcon fontSize="small" />
                </IconButton>
                <Menu
                  open={menuTextOpen}
                  onClose={closeMenuNewText}
                  anchorEl={refAdd.current}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      // border: `1px solid ${theme.palette.white.o015}`,
                      borderRadius: "8px",
                      backgroundColor: "rgba(0,0,0,0.8)",
                      backdropFilter: theme.palette.filter.blur50,
                      outline: `1px solid ${theme.palette.white.o015}`,
                    },
                    "& .MuiList-root": {
                      padding: "0px",
                      display: "flex",
                      flexDirection: "column"
                    },
                    "& .MuiMenuItem-root": {
                      padding: "8px",
                      gap: "10px"
                    },
                    "& svg": {
                      width: "20px",
                      fill: theme.palette.text.primary
                    }
                  }}
                >
                  <MenuItem onClick={() => { handleAddNewTextEdit("number") }}><IconNumber />{t("menu_text_type_number")}</MenuItem>
                  <Divider bgcolor={theme.palette.white.o015}></Divider>
                  <MenuItem onClick={() => { handleAddNewTextEdit("name") }}><IconName />{t("menu_text_type_name")}</MenuItem>
                  <Divider bgcolor={theme.palette.white.o015}></Divider>
                  <MenuItem onClick={() => { handleAddNewTextEdit("initials") }}><IconInitial />{t("menu_text_type_initials")}</MenuItem>
                  <Divider bgcolor={theme.palette.white.o015}></Divider>
                  <MenuItem onClick={() => { handleAddNewTextEdit("free") }}><IconText />{t("menu_text_type_simple_text")}</MenuItem>
                </Menu>
              </Box>

              <Box className="panel-content">
                {
                  textsList.length <= 0 ?
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.white.o07,
                      }}
                    >
                      {t("panel_description_entered_texts")}
                    </Typography>
                    :
                    <PanelCustomTexts
                      textsList={textsList}
                      selectText={handleTextSelection}
                      handleDuplicate={handleTextDuplicateFromList}
                      handleDelete={handleDeleteFromList}
                    />
                }
              </Box>
            </Box>
          </Box>
        </Box>

        {
          textEdit && textSelected ?
            <PanelCustomizeText
              textSelected={textSelected}
              handleCancel={handleTextEditCancel}
              handleSave={handleTextEditConfirm}
              handleDuplicate={handleTextDuplicate}
              positionsList={positionsList}
              fontList={fontList}
              colorsList={colorsList}
              colorsInUse={colorsInUseText}
              setTextSelected={setTextSelected}
              handleDelete={handleDelete}
              currentSelectedItem={currentSelectedItem}
              handleMirror={handleMirror}
              handleUnlink={handleUnlink}
              handleUpdateTextSelected={handleUpdateTextSelected}
              textsList={textsList}
              handleBringOnTop={handleBringOnTop}
              handleCenterItem={handleCenterItem}
              setWaitingForPreset={setWaitingForPreset}
              setPresetSide={setPresetSide}
            /> : ""
        }

      </Drawer>

      <ModalConfirmation
        isOpen={modalDeleteTextOpen}
        title={"modal_title_reset_texts"}
        description={"modal_description_reset_texts"}
        handleClose={() => { setModalDeleteTextOpen(false) }}
        handleContinue={() => { handleDeleteAll(); setModalDeleteTextOpen(false) }}
      />
    </>
  );
}
