import { Box, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material";

const CardSquared = (props) => {
  const {
    id,
    imgsrc,
    className,
    name,
    onClick
  } = props;
  const theme = useTheme();
  return (
    <Tooltip title={name} disableInteractive>
      <Box
        id={id}
        className={className}
        onClick={onClick}
        sx={{
          display: "flex",
          bgcolor: "transparent",
          p: "5px",
          outline: `1px solid ${theme.palette.white.o01}`,
          outlineOffset: "-1px",
          borderRadius: "15px",
          boxShadow: 0,
          aspectRatio: "1",
          boxSizing: "border-box",
          "&.card-squared img": {
            width: "42px",
            height: "42px"
          },
          ":hover": {
            outline: `1px solid ${theme.palette.white.o02}`,
            boxShadow: 0,
            cursor: "pointer",
          },
          "&.active": {
            outline: `1px solid ${theme.palette.white.o07}`,
            boxShadow: 0,
          },
        }}
      >
        <img src={imgsrc} alt={name} />
      </Box>
    </Tooltip>
  );
};
export default CardSquared;
