import { useSelector } from "react-redux"
import Loader from "./Loader"

export default function LoaderManager() {
  const appState = useSelector(state => state.app)
  return (
    <Loader
      isLoading={appState.isLoading}
    />
  )
}
