import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: ['en', 'it-IT', 'it', 'en-US'],
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      it: {
        translation: {
          tab_label_design: "Design",
          tab_label_customize: "Personalizza",
          tab_label_coming_soon: "Coming Soon",
          tab_label_texts: "Testi",
          tab_label_images: "Loghi",
          tab_tooltip_design: "Seleziona un Design per continuare",
          label_size: "Dimensione",
          scene_option_menu_title: "Impostazioni scena",
          option_label_interface_theme: "Tema dell'interfaccia",
          option_label_interface_blur: "Sfocatura interfaccia",
          option_label_interface_grid: "Visualizzazione a griglia",
          panel_label_colors_in_use: "Colori in uso",
          panel_label_product_parts: "Parti del prodotto",
          panel_description_colors_in_use: "Questa è la lista dei colori in uso. Clicca su uno di essi per modificarlo.",
          panel_label_change_global_color: "Cambia colore globale",
          panel_button_label_confirm: "Conferma",
          panel_button_label_cancel: "Annulla",
          panel_label_selected_color: "Selezionato:",
          panel_title_entered_texts: "Testi inseriti",
          panel_description_entered_texts: "Al momento non hai ancora inserito alcun testo nel tuo design. Utilizza il pulsante “+” per aggiungere il tuo primo testo.",
          menu_text_type_number: "Numero",
          menu_text_type_name: "Nome Giocatore",
          menu_text_type_initials: "Iniziali",
          menu_text_type_simple_text: "Testo libero",
          panel_label_text_type_number: "Numero",
          panel_label_text_type_name: "Nome",
          panel_label_text_type_initials: "Iniziali",
          panel_label_text_type_simple_text: "Testo",
          option_position_label_front_high: "Fronte alto",
          option_position_label_front_low: "Fronte basso",
          option_position_label_back_top: "Retro alto",
          option_position_label_front_center_bottom: "Fronte centro basso",
          option_position_label_front_center: "Fronte centro",
          option_position_label_front_center_center: "Fronte centro centro",
          option_position_label_front_center_top: "Fronte centro alto",
          option_position_label_front_left: "Fronte sinistra",
          option_position_label_front_right: "Fronte destra",
          option_position_label_back_bottom: "Retro basso",
          option_position_label_back_center: "Retro centro",
          option_position_label_back_high: "Retro alto",
          option_position_label_back_low: "Retro basso",
          option_position_label_side_left: "Lato sinistro",
          option_position_label_side_right: "Lato destro",
          option_position_label_sleeve_left: "Manica sinistra",
          option_position_label_sleeve_right: "Manica destra",
          option_position_label_back_right: "Retro destro",
          option_position_label_back_left: "Retro sinistro",
          option_position_label_back_right_high: "Retro destro alto",
          option_position_label_back_left_high: "Retro sinistro alto",
          option_position_label_back_right_low: "Retro destro basso",
          option_position_label_back_left_low: "Retro sinistro basso",
          option_position_label_front_right_high: "Fronte destro alto",
          option_position_label_front_left_high: "Fronte sinistro alto",
          option_position_label_front_right_low: "Fronte destro basso",
          option_position_label_front_left_low: "Fronte sinistro basso",
          option_position_label_back_detail: "Dettaglio retro",
          panel_title_positioning: "Posizionamento",
          panel_description_positioning_text: "Puoi aggiungere un testo scegliendo un posizionamento standard, oppure cliccando direttamente sul modello.",
          panel_description_positioning_image: "Puoi aggiungere un'immagine scegliendo un posizionamento standard, oppure cliccando direttamente sul modello.",
          panel_title_image_dimensions: "Dimensioni",
          panel_title_image_background: "Sfondo dell'immagine",
          panel_title_image_angle_shape: "Forma dell’angolo",
          panel_title_image_background_size: "Abbondanza sfondo",
          panel_title_image_align_object: "Centra oggetto",
          panel_title_fill_color: "Colore di riempimento",
          panel_title_text_angle_shape: "Forma dell’angolo",
          panel_title_text_border: "Bordo del testo",
          panel_title_text_align_object: "Centra oggetto",
          panel_title_text_dimensions: "Dimensioni",
          panel_title_text_direction: "Direzione testo",
          panel_title_text_border_weight: "Spessore bordo",
          panel_title_text_border_dotted: "Bordo tratteggiato",
          panel_title_text_background: "Sfondo del testo",
          panel_title_text_background_size: "Abbondanza sfondo",
          panel_title_entered_images: "Loghi inserite",
          panel_description_entered_images: "Al momento non hai ancora inserito alcuna immagine nel tuo design. Utilizza il pulsante “+” per aggiungere la prima immagine.",
          panel_title_upload_image: "Carica la tua immagine",
          error_file_not_compliant: "File non conforme",
          error_file_description_size: "Il caricamento del file non è andato a buon fine. Il file deve essere di uno dei formati supportati: jpg, png o svg e non deve superare il limite massimo di peso (Max 10 MB).",
          table_prices_title_compelte_order: "Completa l'ordine",
          button_add_player: "Aggiungi giocatore",
          table_tot_order:"Totale ordine",
          panel_title_copy_property: "Copia/Incolla proprietà",
          panel_desciption_copy_parts: "Copia le configurazioni tra le parti",
          panel_description_text_copy_parts: "Cliccando su una delle parti qui sotto, potrai facilmente trasferirne tutte le proprietà (color, gradienti e pattern) sulla parte che hai scelto di modificare.",
          panel_title_text_you_are_editing: "Stai modificando",
          panel_title_text_select_the_part_from_which_you_want_to_copy: "Seleziona la parte da cui ereditare le proprietà",
          error_message_pattern: "La parte che stai configurando non è compatibile con i pattern. Ciò significa che verranno riportati tutti i parametri escluso il pattern.",
          info_title_image_connected: "Grafica collegata",
          info_description_image_connected: "Questo elemento grafico è collegato al suo originale posizionato nell'altra spalla. Puoi scollegarlo cliccando il pulsante qui sotto.",
          info_btn_image_connected: "Scollega istanza",
          info_title_clone_part: "Copia le configurazioni tra le parti",
          info_description_clone_part: "Cliccando su una delle parti qui sotto, potrai facilmente trasferirne tutte le proprietà (color, gradienti e pattern) sulla parte che hai scelto di modificare.",
          panel_title_brand_logo: "+Adrenalina Logo",
          panel_title_text_brand_logo: "Il logo di +Adrenalina non può essere eliminato",
          panel_title_brand_logo_options: "Posizionamento e colore",
          panel_upload_file_support_notes: "Formati supportati: jpg, png, svg | Peso max: 10 MB",
          panel_upload_file_description:"Trascina e rilascia qui il tuo file oppure utilizza il pulsante qui sotto",
          info_title_name_player: "Suggerimento per Nome Giocatore",
          info_description_name_player: "“Nome Giocatore” è un tipo di testo che può essere inserito più volte (ad es. fronte/retro) e il suo contenuto sarà uguale per tutti i testi di tipo “Nome Giocatore”.Per impostrare correttamente tutti i nomi dei giocatori della tua squadra, vai alla sezione  “Acquista” o premi il pulsante qui sotto.",
          info_title_number_player: "Suggerimento per Numero",
          info_description_number_player: "“Numero” è un tipo di testo che può essere inserito più volte (ad es. fronte/retro) e il suo contenuto sarà uguale per tutti i testi di tipo “Numero”.Per impostrare correttamente tutti i numeri dei giocatori della tua squadra, vai alla sezione  “Acquista” o premi il pulsante qui sotto.",
          panel_description_design: "Puoi cambiare il design di base del prodotto. Colori, sfumature e pattern verranno mantenuti invariati, se compatibili.",
          label_abort: "Annulla",
          label_confirm: "Conferma",
          modal_title_reset_design: "Ripristina il design",
          modal_description_reset_design: "Eseguendo questa azione riporterai il design al suo stato di partenza. Verranno re-impostati tutti i colori, gradienti e pattern di default. Testi e loghi resteranno correttamente impostati. Sei certo di voler procedere?",
          modal_title_reset_images: "Elimina tutti i loghi",
          modal_description_reset_images: "Eseguendo questa azione eliminerai tutti i loghi che hai inserito nella fase di personalizzazione. Se elimini tutti i loghi non li potrai più ripristinare e/o modificare. Questa azione è irreversibile. Sei certo di voler procedere?",
          modal_title_reset_texts: "Elimina tutti i test",
          modal_description_reset_texts: "Eseguendo questa azione eliminerai tutti i testi che hai inserito nella fase di personalizzazione. Se elimini tutti i testi non li potrai più ripristinare e/o modificare. Questa azione è irreversibile. Sei certo di voler procedere?",
          panel_login_title: "Accedi",
          panel_login_description: "Effettua l’accesso con la tua email e password per ottenere tutte le funzionalità del configuratore.",
          panel_login_email: "Email",
          panel_login_password: "Password",
          panel_login_button: "Accedi",
          panel_logout_button: "Esci",
          panel_login_abort: "Annulla",
          viewer_error_position_invalid: "Area non compatibile",
          modal_title_change_product: "Cambia prodotto",
          modal_description_change_product: "Stai andando alla scelta di un nuovo prodotto da configurare. Eseguendo questa azione perderai tutte le personalizzazioni che hai realizzato finora. Sei certo di voler continuare?",
          page_products_title: "Scegli un prodotto",
          page_designs_title: "Scegli un design",
          modal_title_exit_configurator: "Esci dal configuratore",
          modal_description_exit_configurator: "Stai uscendo dal configuratore. Verrai re-indirizzato al e-commerce di +Adrenalina. La configurazione di prodotto che hai realizzato finora andrà persa. Sei certo di voler continuare?",
          label_user_not_logged: "Effettua la login per visualizzare prezzi e prodotti a te dedicati",
          label_do_you_have_an_account: "Non possiedi un account?",
          label_button_request_credentials: "Richiedi le tue credenziali",
          label_set_language: "Imposta la lingua",
          label_user_name: "Nome utente",
          FRONTE: "Fronte",
          RETRO: "Retro",
          MANICA_DX: "Manica Destra",
          MANICA_SX: "Manica Sinistra",
          FIANCO_DX: "Fianco Destro",
          FIANCO_SX: "Fianco Sinistro",
          INSERTI_FIANCHI: "Inserto Fianchi",
          COLLO: "Collo",
          INSERTO_COLLO: "Inserto Collo",
          POLSI: "Polsi",
          INSERTO_POLSI: "Inserto Polsi",
          ELEMENTO_1: "Elemento 1",
          ELEMENTO_2: "Elemento 2",
          INSERTO_FIANCHI: "Inserto Fianchi",
          ELEMENTO_3: "Elemento 3",
          ELEMENTO_4: "Elemento 4",
          FINANCO_SX: "Fianco Sinistro",
          GIROMANICA_DX: "Giromanica Destra",
          GIROMANICA_SX: "Giromanica Sinistra",
          INSERTO_FIANCO: "Inserto Fianchi",
          GAMBA_DX: "Gamba Destra",
          GAMBA_SX: "Gamba Sinistra",
          INSERTO_RETRO: "Inserto Retro",
          GAMBA_DX_FRONTE: "Gamba Fronte Destra",
          GAMBA_SX_FRONTE: "Gamba Fronte Sinistra",
          GAMBA_DX_RETRO: "Gamba Retro Destra",
          GAMBA_SX_RETRO: "Gamba Retro Sinistra",
          GAMBA_FRONTE_DX: "Gamba Fronte Destra",
          GAMBA_FRONTE_SX: "Gamba Fronte Sinistra",
          GAMBA_RETRO_DX: "Gamba Retro Destra",
          GAMBA_RETRO_SX: "Gamba Retro Sinistra",
          TASCHE: "Tasche",
          LIVELLO_1: "Livello 1",
          CAVALLOTTO: "Cavallotto",
          PATTINA: "Pattina",
          CAPPUCCIO: "Cappuccio",
          FONDO_GRESPO: "Fondo Grespo",
          label_add_pattern: "Aggiungi pattern",
          label_button_upload_img: "Carica",
          zip: "Zip",
          borders_detail: "Dettaglio Bordi",
          leg_border: "Bordo Gamba",
          panel_logout_title: "Effettuare il logout?",
          panel_logout_description: "Il processo di configurazione verrà interrotto.",
          label_the_product: "Il prodotto",
          label_doesnt_exist: "non esiste",
          label_scroll_down: "Scroll down",
          label_scroll_top: "Scroll top",
          label_no_products: "Nessun prodotto",
          label_no_configured_product: "Nessun prodotto configurato",
          label_configured_products: "Prodotti configurati",
          label_unit_price_in_euro: "Prezzo unitario: €",
          modal_order_creation_load_description: "Stiamo creando il tuo ordine",
          modal_order_creation_success_title: "Ordine creato con successo",
          modal_order_creation_success_description: "Il tuo ordine è stato creato con successo e aggiunto al carrello nella tua area riservata",
          modal_order_creation_success_button_gotocart: "Vai al carrello",
          modal_order_creation_success_label_or: "Oppure",
          modal_order_creation_success_button_configurenewproduct: "Configura nuovo prodotto",
          modal_order_creation_fail_title: "Ordine non creato",
          modal_order_creation_fail_description: "A causa di un problema tecnico, non è stato possibile creare correttamente il tuo ordine",
          modal_order_creation_fail_button_cancel: "Annulla",
          modal_order_creation_fail_button_retry: "Riprova",
          label_error_letters_and_symbols_not_supported: "Lettere e simboli non supportati",
          label_chose: "Scegli",
          label_price_for: "Prezzo per",
          label_piece_short: "pz:",
          label_button_add_to_cart: "Aggiungi al carrello",
          loader_page_text: "Caricamento",
          offline_notifier_snack_message: "Nessuna connessione internet",
          buy_button_text: "Acquista",
          players_table_head_row: 'Riga',
          players_table_head_name: 'Nome',
          players_table_head_initials: 'Iniziali',
          players_table_head_number: 'Numero',
          players_table_head_size: 'Taglia',
          players_table_head_quantity: 'Quantità',
          label_mirroring_title: "Specchia",
          label_mirroring_description: "Applica la stessa grafica, con gli stessi parametri di configurazione ad entrambe le spalle.",
          expired_session_snack_message: "La sessione è scaduta",
          cart_invalid_data_snack_message: "Dati non validi",
          cart_empty_table_snack_message: "Aggiungere almeno un giocatore alla lista",
          label_price_visibility: "Visibilità prezzi",
        }
      },
      en: {
        translation: {
          tab_label_design: "Design",
          tab_label_customize: "Customize",
          tab_label_coming_soon: "Coming Soon",
          tab_label_texts: "Texts",
          tab_label_images: "Logos",
          tab_tooltip_design: "Select a Design to continue",
          label_size: "Size",
          scene_option_menu_title: "Scene settings",
          option_label_interface_theme: "Interface Theme",
          option_label_interface_blur: "Interface blur",
          option_label_interface_grid: "Grid view",
          panel_label_colors_in_use: "Colors in use",
          panel_label_product_parts: "Product parts",
          panel_description_colors_in_use: "This is the list of the colors in use. Click on one of them to modify it.",
          panel_label_change_global_color: "Change global color",
          panel_button_label_confirm: "Confirm",
          panel_button_label_cancel: "Cancel",
          panel_label_selected_color: "Selected:",
          panel_title_entered_texts: "Entered texts",
          panel_description_entered_texts: "At the moment, you haven't entered any text into your design. Use the “+” button to add your first text.",
          menu_text_type_number: "Number",
          menu_text_type_name: "Player Name",
          menu_text_type_initials: "Initials",
          menu_text_type_simple_text: "Simple text",
          panel_label_text_type_number: "Number",
          panel_label_text_type_name: "Name",
          panel_label_text_type_initials: "Initials",
          panel_label_text_type_simple_text: "Text",
          option_position_label_front_high: "Front high",
          option_position_label_front_low: "Front low",
          option_position_label_back_top: "Back top",
          option_position_label_front_center_bottom: "Front center bottom",
          option_position_label_front_center: "Front center",
          option_position_label_front_center_center: "Front center center",
          option_position_label_front_center_top: "Front center top",
          option_position_label_front_left: "Front left",
          option_position_label_front_right: "Front right",
          option_position_label_back_bottom: "Back bottom",
          option_position_label_back_center: "Back center",
          option_position_label_back_high: "Back high",
          option_position_label_back_low: "Back low",
          option_position_label_side_left: "Side left",
          option_position_label_side_right: "Side right",
          option_position_label_sleeve_left: "Sleeve left",
          option_position_label_sleeve_right: "Sleeve right",
          option_position_label_back_right: "Back right",
          option_position_label_back_left: "Back left",
          option_position_label_back_right_high: "Back right high",
          option_position_label_back_left_high: "Back left high",
          option_position_label_back_right_low: "Back right low",
          option_position_label_back_left_low: "Back left low",
          option_position_label_front_right_high: "Front right high",
          option_position_label_front_left_high: "Front left high",
          option_position_label_front_right_low: "Front right low",
          option_position_label_front_left_low: "Front left low",
          option_position_label_back_detail: "Back detail",
          panel_title_positioning: "Positioning",
          panel_description_positioning_text: "You can add text by choosing a standard placement or by clicking directly on the template.",
          panel_description_positioning_image: "You can add the image by choosing a standard placement or by clicking directly on the template.",
          panel_title_image_dimensions: "Size",
          panel_title_image_background: "Background of the image",
          panel_title_image_angle_shape: "Angle shape",
          panel_title_image_background_size: "Background size",
          panel_title_image_align_object: "Center object",
          panel_title_fill_color: "Fill color",
          panel_title_text_angle_shape: "Angle shape",
          panel_title_text_border: "Text border",
          panel_title_text_align_object: "Center object",
          panel_title_text_dimensions: "Size",
          panel_title_text_direction: "Text direction",
          panel_title_text_border_weight: "Border weight",
          panel_title_text_border_dotted: "Dotted border",
          panel_title_text_background: "Text background",
          panel_title_text_background_size: "Background size",
          panel_title_entered_images: "Entered logos",
          panel_description_entered_images: "At the moment, you haven't uploaded any images into your design. Use the “+” button to add your first image.",
          panel_title_upload_image: "Upload your image",
          error_file_not_compliant: "Not compliant file",
          error_file_description_size: "The file upload was unsuccessful. The file exceeds the maximum weight limit (Max 10 MB).",
          table_prices_title_compelte_order: "Complete the order",
          button_add_player: "Add player",
          table_tot_order:"Order total",
          panel_title_copy_property: "Copy/Paste property",
          panel_desciption_copy_parts: "Copy configurations between parts",
          panel_description_text_copy_parts: "By clicking on one of the parts below, you can easily transfer all the properties (colors, gradients, and patterns) to the part you have chosen to edit.",
          panel_title_text_you_are_editing: "You are editing",
          panel_title_text_select_the_part_from_which_you_want_to_copy: "Select the part from which you want to inherit the properties",
          error_message_pattern: "The part you are configuring is not compatible with patterns. This means that all parameters will be reported except the pattern.",
          info_title_image_connected: "Connected graphic",
          info_description_image_connected: "This graphic element is connected to its original placed on the other shoulder. You can disconnect it by clicking the button below.",
          info_btn_image_connected: "Disconnect instance",
          info_title_clone_part: "Copy configurations between parts",
          info_description_clone_part: "By clicking on one of the parts below, you can easily transfer all the properties (colors, gradients, and patterns) to the part you have chosen to edit.",
          panel_title_brand_logo: "+Adrenalina Logo",
          panel_title_text_brand_logo: "+Adrenalina logo cannot be deleted",
          panel_title_brand_logo_options: "Position and color",
          panel_upload_file_support_notes: "Supported formats: jpg, png, svg | Max weight: 10 MB",
          panel_upload_file_description: "Drag and drop your file here or use the button below",
          info_title_name_player: "Suggestions for Player Name",
          info_description_name_player: "“Player Name” is a type of text that can be inserted multiple times (e.g. front/back) and its content will be the same for all texts of type “Player Name”. To correctly set all the names of the players of your team, go to the “Buy” section or press the button below.",
          info_title_number_player: "Suggestions for Number",
          info_description_number_player: "“Number” is a type of text that can be inserted multiple times (e.g. front/back) and its content will be the same for all texts of type “Number”. To correctly set all the numbers of the players of your team, go to the “Buy” section or press the button below.",
          panel_description_design: "You can change the basic design of the product. Colors, gradients, and patterns will be kept unchanged if compatible.",
          label_abort: "Cancel",
          label_confirm: "Confirm",
          modal_title_reset_design: "Reset design",
          modal_description_reset_design: "By performing this action, you will restore the design to its initial state. All default colors, gradients, and patterns will be reset. Texts and logos will remain correctly set. Are you sure you want to proceed?",
          modal_title_reset_images: "Delete all logos",
          modal_description_reset_images: "By performing this action, you will delete all the logos you have inserted in the customization phase. If you delete all the logos, you will not be able to restore and/or modify them. This action is irreversible. Are you sure you want to proceed?",
          modal_title_reset_texts: "Delete all texts",
          modal_description_reset_texts: "By performing this action, you will delete all the texts you have inserted in the customization phase. If you delete all the texts, you will not be able to restore and/or modify them. This action is irreversible. Are you sure you want to proceed?",
          panel_login_title: "Login",
          panel_login_description: "Log in with your email and password to get all the configurator features.",
          panel_login_email: "Email",
          panel_login_password: "Password",
          panel_login_button: "Login",
          panel_logout_button: "Logout",
          panel_login_abort: "Cancel",
          viewer_error_position_invalid: "Area not compatible",
          modal_title_change_product: "Change product",
          modal_description_change_product: "You are going to the choice of a new product to configure. By performing this action, you will lose all the customizations you have made so far. Are you sure you want to continue?",
          page_products_title: "Choose a product",
          page_designs_title: "Choose a design",
          modal_title_exit_configurator: "Exit the configurator",
          modal_description_exit_configurator: "You are exiting the configurator. You will be redirected to the +Adrenalina e-commerce. The product configuration you have made so far will be lost. Are you sure you want to continue?",
          label_user_not_logged: "Log in to view prices and products dedicated to you",
          label_do_you_have_an_account: "Don't have an account?",
          label_button_request_credentials: "Request your credentials",
          label_set_language: "Set language",
          label_user_name: "User name",
          FRONTE: "Front",
          RETRO: "Back",
          MANICA_DX: "Sleeve Right",
          MANICA_SX: "Sleeve Left",
          FIANCO_DX: "Side Right",
          FIANCO_SX: "Side Left",
          INSERTI_FIANCHI: "Sides Insert",
          COLLO: "Neck",
          INSERTO_COLLO: "Neck Insert",
          POLSI: "Wrists",
          INSERTO_POLSI: "Wrists Insert",
          ELEMENTO_1: "Element 1",
          ELEMENTO_2: "Element 2",
          INSERTO_FIANCHI: "Sides Insert",
          ELEMENTO_3: "Element 3",
          ELEMENTO_4: "Element 4",
          FINANCO_SX: "Side Left",
          GIROMANICA_DX: "Armhole Right",
          GIROMANICA_SX: "Armhole Left",
          INSERTO_FIANCO: "Sides Insert",
          GAMBA_DX: "Leg Right",
          GAMBA_SX: "Leg Left",
          INSERTO_RETRO: "Back Insert",
          GAMBA_DX_FRONTE: "Leg Front Right",
          GAMBA_SX_FRONTE: "Leg Front Left",
          GAMBA_DX_RETRO: "Leg Back Right",
          GAMBA_SX_RETRO: "Leg Back Left",
          GAMBA_FRONTE_DX: "Leg Front Right",
          GAMBA_FRONTE_SX: "Leg Front Left",
          GAMBA_RETRO_DX: "Leg Back Right",
          GAMBA_RETRO_SX: "Leg Back Left",
          TASCHE: "Pockets",
          LIVELLO_1: "Level 1",
          CAVALLOTTO: "Inseam",
          PATTINA: "Flap",
          CAPPUCCIO: "Hood",
          FONDO_GRESPO: "Bottom Detail",
          label_add_pattern: "Add pattern",
          label_button_upload_img: "Upload",
          zip: "Zip",
          borders_detail: "Borders Detail",
          leg_border: "Leg Border",
          panel_logout_title: "Do you want to log out?",
          panel_logout_description: "The configuration process will be interrupted.",
          label_the_product: "The product",
          label_doesnt_exist: "doesn't exist",
          label_scroll_down: "Scroll down",
          label_scroll_top: "Scroll top",
          label_no_products: "No products",
          label_no_configured_product: "No configured product",
          label_configured_products: "Configured products",
          label_unit_price_in_euro: "Unit price: €",
          modal_order_creation_load_description: "We are creating your order",
          modal_order_creation_success_title: "Order created successfully",
          modal_order_creation_success_description: "Your order has been successfully created and added to the cart in your reserved area",
          modal_order_creation_success_button_gotocart: "Go to cart",
          modal_order_creation_success_label_or: "Or",
          modal_order_creation_success_button_configurenewproduct: "Configure new product",
          modal_order_creation_fail_title: "Order not created",
          modal_order_creation_fail_description: "Due to a technical problem, your order could not be created correctly",
          modal_order_creation_fail_button_cancel: "Cancel",
          modal_order_creation_fail_button_retry: "Try again",
          label_error_letters_and_symbols_not_supported: "Letters and symbols not supported",
          label_chose: "Choose",
          label_price_for: "Price for",
          label_piece_short: "pcs:",
          label_button_add_to_cart: "Add to cart",
          loader_page_text: "Loading",
          offline_notifier_snack_message: "No internet connection",
          buy_button_text: "Buy",
          players_table_head_row: 'Row',
          players_table_head_name: 'Name',
          players_table_head_initials: 'Initials',
          players_table_head_number: 'Number',
          players_table_head_size: 'Size',
          players_table_head_quantity: 'Quantity',
          label_mirroring_title: "Mirror",
          label_mirroring_description: "Apply the same graphic, with the same configuration parameters to both shoulders.",
          expired_session_snack_message: "The session has expired",
          cart_invalid_data_snack_message: "Invalid data",
          cart_empty_table_snack_message: "Add at least one player to the list",
          label_price_visibility: "Price visibility",
        }
      }
    }
  });

export default i18n;
