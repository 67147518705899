import { createRef, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import ButtonRound from "./ButtonRound";
// import { appBarHeight } from "../helpers/layout";
import { useTranslation } from "react-i18next";
import SwitchButtonButton from "./SwitchGridButton";

export default function SwitchBtn(props) {

  const {
    className,
    designView,
    setDesignView,
    showGridSwitch,
    listActive,
    toggleFunction
    // widthMask,
    // setWidthMask,
    // transformMask,
    // setTransformMask,
  } = props;
  const refBtnSx = createRef();
  const refBtnDx = createRef();
  const theme = useTheme();

  const [transformMask, setTransformMask] = useState(0)
  const [widthMask, setWidthMask] = useState(86)

  const { t } = useTranslation();

  useEffect(() => {
    if (refBtnSx.current && refBtnDx.current) {
      const widthSx = refBtnSx.current.offsetWidth;
      const widthDx = refBtnDx.current.offsetWidth;
      setWidthMask(designView === 0 ? widthSx : widthDx);
      setTransformMask(designView === 0 ? 0 : widthSx);
    }
  }, [refBtnDx, refBtnSx, designView, setTransformMask, setWidthMask]);

  return (
    <Box
      className={`SwitchContainer ${className}`}
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        pointerEvents: "all",
        ".buttons-container": {
          p: "4px",
          backgroundColor: theme.palette.black.o05,
          borderRadius: "23px",
          //alignSelf: "center",
          //marginTop: "40px",
          pointerEvents: "all",
          alignSelf: "center",
          backdropFilter: theme.palette.filter.blur50,
        },

        "& .mask-box .mask": {
          width: "100px",
          height: "100%",
          borderRadius: "20px",
          backgroundColor: theme.palette.white.o01,
          position: "absolute",
          transition: "all 0.5s ease",
        },
        "& .MuiButtonBase-root": {
          padding: "10px 15px",
          backgroundColor: "transparent",
          borderRadius: "20px",
          backdropFilter: theme.palette.filter.blur0,
        },
        "& .mask-box": {
          position: "relative"
        },
        ".MuiButtonBase-root:hover": {
          backgroundColor: "transparent",
        },
        "&:not(.switch-not-fixed-pos)": {
          position: "fixed",
          // top: "130px",
          // top: `calc(20px + ${appBarHeight + "px"})`,
          bottom: "34px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: theme.zIndex.appBar + 200,
          "&.absolute-bottom-navigator": {
            bottom: "96px"
          }
        },
      }}
    >

      <Box className="buttons-container">
        <Box className="mask-box">
          <Box
            className="mask"
            style={{
              width: `${widthMask}px`,
              transform: `translateX(${transformMask}px)`,
            }}
          />
          <Box sx={{ display: "inline-block" }}>
            <ButtonRound
              ref={refBtnSx}
              text={t("FRONTE")}
              variant="contained"
              className=""
              state=""
              onClick={() => setDesignView(0)}
            />
          </Box>
          <Box sx={{ display: "inline-block" }}>
            <ButtonRound
              ref={refBtnDx}
              text={t("RETRO")}
              variant="contained"
              className=""
              state=""
              onClick={() => setDesignView(1)}
            />
          </Box>
        </Box>
      </Box>
      {showGridSwitch ?
        <Box className="buttons-container">
          <SwitchButtonButton
            listActive={listActive}
            toggleFunction={toggleFunction}
          />
        </Box>
        : " "
      }
    </Box>
  );
}
