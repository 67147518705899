import { Box, FilledInput, FormControl, IconButton, InputAdornment, Slider, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import HeightIcon from "@mui/icons-material/Height";
import IconWidth from "./icons/IconWidth";
// import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
// import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
// import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import IconBackgroundOffsetThin from "./icons/IconBackgroundOffsetThin";
import IconBackgroundOffsetThick from "./icons/IconBackgroundOffsetThick";
import IconBackgroundOffsetNormal from "./icons/IconBackgroundOffsetNormal";
import IconNull from "./icons/IconNull";
import IconStrokeWidthThin from "./icons/IconStrokeWidthThin";
import IconStrokeWidthMedium from "./icons/IconStrokeWidthMedium";
import IconStrokeWidthThick from "./icons/IconStrokeWidthThick";
import IconStrokeJoinMiter from "./icons/IconStrokeJoinMiter";
import IconStrokeJoinRound from "./icons/IconStrokeJoinRound";
import IconStrokeJoinBevel from "./icons/IconStrokeJoinBevel";
import IconStrokeDashLine from "./icons/IconStrokeDashLine";
import IconStrokeDashPointer from "./icons/IconStrokeDashPointer";
import IconStrokeDashDashed from "./icons/IconStrokeDashDashed";
import CardPlacement from "./CardPlacement";
import Divider from "./Divider";
import SwitchCheck from "./SwitchCheck";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ButtonRound from "./ButtonRound";
import { ReactComponent as IconTextBig } from "../icons/icon-text.svg";
import { ReactComponent as IconTextBox } from "../icons/icon-textbox.svg";
import { ReactComponent as IconTextBorder } from "../icons/icon-textborder.svg";
import { ReactComponent as IconClone } from "../icons/icon-clone.svg";
import { ReactComponent as IconLocketOpen } from "../icons/icon-locket-open.svg";
import { ReactComponent as IconLocketClose } from "../icons/icon-locket-close.svg";
import { ReactComponent as IconTrash } from "../icons/icon-trash.svg";
import { ReactComponent as IconLayers } from "../icons/icon-bring-up.svg";
import CardSquared from "./CardSquared";
import ColorsListSelector from "./ColorsListSelector";
import { viewerInstance } from "../helpers/Viewer";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import AccordionMessage from "./AccordionMessage";
// import { debounce } from "lodash"

let timer;

export default function PanelCustomizeText(props) {

  const {
    textSelected,
    setTextSelected,
    handleCancel,
    handleSave,
    positionsList,
    fontList,
    colorsList,
    colorsInUse,
    handleDuplicate,
    handleDelete,
    currentSelectedItem,
    handleMirror,
    handleUnlink,
    handleUpdateTextSelected,
    textsList,
    handleBringOnTop,
    handleCenterItem,
    setWaitingForPreset,
    setPresetSide
  } = props

  const theme = useTheme();

  const { t } = useTranslation();

  const [initialPosition, setInitialPosition] = useState(textSelected.position_standard === null ? true : false);

  const [editStatus, setEditStatus] = useState(textSelected.position_standard === null ? null : 0);

  const testTypeKey = {
    "brandLogo": "  LOGO_+A_POSITION_NAME",
    "image": "  USER_LOGO/TEXT_POSITION_NAME",
    "free": "USER_LOGO/TEXT_POSITION_NAME",
    "number": "USER_NUMBER_POSITION_NAME",
    "initials": "USER_LETTERS_POSITION_NAME",
    "name": "USER_NAME_POSITION_NAME"
  }

  const handleUpdateAttributeOnText = (attribute, value) => {
    // console.log("attribute:", attribute, "  | value:", value)
    const tempTextSelected = { ...textSelected };
    let timeToWaitMillisecond = 250;
    if (tempTextSelected.hasOwnProperty(attribute)) {
      // CHANGE SIZE WITH SIZE_RATIO
      if (attribute === "size_x" || attribute === "size_y") {
        let size_x = tempTextSelected.size_x;
        let size_y = tempTextSelected.size_y;

        timeToWaitMillisecond = 1000;

        if (attribute === "size_x") {
          size_x = value;
          size_y = parseFloat(value) / tempTextSelected.size_ratio;
        }
        if (attribute === "size_y") {
          size_y = value;
          size_x = parseFloat(value) * tempTextSelected.size_ratio;
        }
        tempTextSelected["size_x"] = size_x.toString();
        tempTextSelected["size_y"] = size_y.toString();
      } else {
        tempTextSelected[attribute] = value;
      }
    }


    // console.log(currentSelectedItem, updateViewer)
    if (currentSelectedItem && currentSelectedItem.name === tempTextSelected.id) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        handleUpdateList({ ...tempTextSelected }, { ...currentSelectedItem })
      },
        timeToWaitMillisecond);
    } else {
      // console.log("handleUpdateAttributeOnText", currentSelectedItem)
      setTextSelected(tempTextSelected);
    }
    // console.log("NOT DEBOUNCE");
    setTextSelected(tempTextSelected);
  }

  const handleUpdateList = (tempTextSelected, currentSelectedItem) => {
    // console.log("DEBOUNCE")
    // console.log("tempTextSelected",{...tempTextSelected});
    const updateItem = {
      ...currentSelectedItem, ...{
        text: tempTextSelected.text.length > 0 ? tempTextSelected.text : " ",
        font: tempTextSelected.font,
        color: tempTextSelected.text_color,
        rotation: tempTextSelected.angle,
        size: { width: parseFloat(tempTextSelected.size_x), height: parseFloat(tempTextSelected.size_y) },
        isLocked: tempTextSelected.isLocked,
        backgroundColor: tempTextSelected.background ? tempTextSelected.background_color : null,
        backgroundBorderJoin: tempTextSelected.background_angle,
        backgroundBorderWidth: tempTextSelected.background_size,
        // backgroundExtraSize: tempTextSelected.background_size,
        outlineColor: tempTextSelected.border ? tempTextSelected.border_color : null,
        outlineWidth: tempTextSelected.border_weight,
        outlineJoin: tempTextSelected.border_angle,
        outlineStyle: tempTextSelected.border_dotted,
      }
    }
    const tempItem = JSON.stringify(updateItem);
    const itemToUpdate = JSON.parse(tempItem);

    // console.log(viewerInstance, itemToUpdate);

    const responseUpdate = viewerInstance.updateItem(itemToUpdate);

    // console.log("updateItem",{...updateItem});
    // console.log(responseUpdate);
    handleUpdateTextSelected({ ...tempTextSelected }, responseUpdate, [...textsList]);
  }

  const handleSelectInitialPosition = (el, name) => {
    handleUpdateAttributeOnText("position_standard", name)
    handleAddText({ ...textSelected });
    setInitialPosition(false);
    setEditStatus(0);

    viewerInstance.setPresetPosition(
      el.unwrapCenter.u,
      el.unwrapCenter.v,
      el.height * 10 //cm to mm
    );

    setWaitingForPreset(true)
    setPresetSide(el.side)
  }

  // const handleSetTextSelected = (props) => {
  //   setTextSelected(props);
  //   handleAddText(props)
  // }

  const handleAddText = async (props) => {
    if (props.text) {
      let response = await viewerInstance.addText(props.text, textSelected.type);
      // console.log(response);
      if (response) {
        const updatedInfo = {
          id: response.name,
          position_standard: 0,
          // size_x: response.size.width.toFixed(2).toString(),
          // size_y: response.size.height.toFixed(2).toString(),
          // size_ratio: response.size.width/response.size.height,
        };
        setInitialPosition(false);
        setEditStatus(0);
        // console.log(response);
        handleUpdateTextSelected({ ...props, ...updatedInfo }, { ...response }, [...textsList]);
      }
    }
  }

  return (
    <Box sx={{
      position: "relative",
    }}>
      <Tooltip title={!textSelected.isLocked ? "" : "The item is locked, unlock to interact"} followCursor>
        {/*TEXT PLACEMENT*/}
        <Box
          id="text-placement"
          sx={{
            "&.panel-disabled .panel-content, & .panel-disabled.panel-content": {
              opacity: 0.3,
              cursor: "default",
              pointerEvents: "none",
              "& .active": {
                background: "transparent"
              }
            }
          }

          }
          className={textSelected.isLocked ? "panel-disabled" : ""}
        >
          <Box className="panel-box">

            {/* HEADER WITH SHORTCUT */}

            <Box
              className="panel-header"
              sx={{
                display: "flex",
                alignItems: "center",
                ".fab-transparent": {
                  "svg": {
                    width: "20px",
                    height: "20px",
                    fill: theme.palette.text.primary
                  }
                }
              }}
            >
              <Typography variant="h4" sx={{ marginRight: "auto" }}>
                {
                  textSelected.type === "number" ? t("menu_text_type_number") :
                    textSelected.type === "name" ? t("menu_text_type_name") :
                      textSelected.type === "initials" ? t("menu_text_type_initials") :
                        textSelected.type === "free" ? t("menu_text_type_simple_text") : ""
                }
              </Typography>
              <IconButton
                aria-label="add"
                size="small"
                onClick={handleCancel}
                sx={{ display: initialPosition ? "flex" : "none" }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>

              <IconButton
                sx={{
                  display: initialPosition ? "none" : "flex",
                  marginRight: "5px",
                }}
                className="fab-transparent"
                aria-label="add"
                size="small"
                onClick={() => { handleBringOnTop() }}
              >
                <IconLayers />
              </IconButton>

              <IconButton
                sx={{
                  display: initialPosition ? "none" : "flex",
                  marginRight: "5px",
                }}
                className="fab-transparent"
                aria-label="add"
                size="small"
                onClick={() => { handleUpdateAttributeOnText("isLocked", !textSelected.isLocked) }}
              >
                {textSelected.isLocked ? <IconLocketClose /> : <IconLocketOpen />}
              </IconButton>
              <IconButton
                sx={{
                  display: initialPosition ? "none" : "flex",
                  marginRight: "5px",
                }}
                className="fab-transparent"
                aria-label="add"
                size="small"
                onClick={handleDuplicate}
              >
                <IconClone />
              </IconButton>
              <IconButton
                sx={{ display: initialPosition ? "none" : "flex" }}
                className="fab-transparent"
                aria-label="add"
                size="small"
                onClick={handleDelete}
              >
                <IconTrash />
              </IconButton>
            </Box>

            {/*  TEXT */}

            <Box className="panel-content">
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  slotProps={{ inputLabel: { shrink: true } }}
                  // InputLabelProps={{ shrink: true }}
                  id="filled-basic"
                  sx={{
                    svg: {
                      width: "24px",
                      height: "24px",
                      fill: "currentcolor"
                    }
                  }}
                  label={
                    textSelected.type === "number" ? t("panel_label_text_type_number") :
                      textSelected.type === "name" ? t("panel_label_text_type_name") :
                        textSelected.type === "initials" ? t("panel_label_text_type_initials") :
                          textSelected.type === "free" ? t("panel_label_text_type_simple_text") : ""
                  }
                  variant="filled"
                  placeholder="Field content"
                  value={textSelected.text}
                  disabled={textSelected.isLocked || (initialPosition && (textSelected.type === "number" || textSelected.type === "name") && textsList.filter((e) => e.type === textSelected.type).length > 0)}
                  InputProps={{
                    endAdornment: (initialPosition && (textSelected.type === "number" || textSelected.type === "name") && textsList.filter((e) => e.type === textSelected.type).length > 0) ? <InputAdornment position="start"><IconLocketClose /></InputAdornment> : ""
                  }}
                  onChange={(e) => { handleUpdateAttributeOnText("text", e.target.value) }}
                />
              </FormControl>
            </Box>

            {textSelected.type === "number" || textSelected.type === "name" ?
              <Box className="panel-content">
                <AccordionMessage

                  className=""
                  title={textSelected.type === "name" ? t("info_title_name_player") : t("info_title_number_player")}
                  text={textSelected.type === "name" ? t("info_description_name_player") : t("info_description_number_player")}
                // btnText={t("info_btn_image_connected")}
                // onClick={handleUnlink}
                />
              </Box>
              : ""}
            {/* <Box className="panel-divider">
              <Divider bgcolor={theme.palette.white.o015}></Divider>
            </Box> */}
            {initialPosition ? "" : <>
              <Box className="panel-content">
                <Box className="list-item">
                  <RotateRightIcon
                    sx={{ color: theme.palette.white.main, marginRight: "5px" }}
                  />
                  <Slider
                    className="custom-slider"
                    value={Number(textSelected.angle)}
                    onChange={(e) => {
                      handleUpdateAttributeOnText("angle", e.target.value);
                    }}
                    aria-labelledby="input-slider"
                    sx={{ maxWidth: "calc(100% - 149px)" }}
                    step={1}
                    min={0}
                    max={360}
                  />
                  <FormControl variant="filled">
                    <FilledInput
                      className="MuiInput-number"
                      id="filled-adornment-deg"
                      size="small"
                      placeholder="000.00"
                      value={Number(textSelected.angle)}
                      onChange={(e) => {
                        handleUpdateAttributeOnText("angle", e.target.value);
                      }}
                      inputProps={{
                        step: 1,
                        min: 0,
                        max: 360,
                        type: "number",
                        "aria-label": "deg",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              {textSelected.isMirrorable ?
                <>
                  <Box className="panel-content">
                    <Box className="list-item">
                      <Box sx={{ marginRight: "auto" }}>
                        <Typography variant="body1">
                          Specchia
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: theme.palette.white.o07 }}
                        >
                          Applica la stessa grafica, con gli stessi parametri di
                          configurazione ad entrambe le spalle.
                        </Typography>
                      </Box>
                      <SwitchCheck toggleFunction={handleMirror} value={textSelected.hasLink ? true : false}></SwitchCheck>
                    </Box>
                  </Box>
                  {textSelected.hasLink ?
                    <Box className="panel-content">
                      <AccordionMessage
                        className=""
                        title={t("info_title_image_connected")}
                        text={t("info_description_image_connected")}
                        btnText={t("info_btn_image_connected")}
                        onClick={handleUnlink}
                      />
                    </Box> : ""}
                </> : ""}


              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              <Box className="panel-content">
                <Box className="list-item" sx={{ gap: "10px" }}>
                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_dimensions")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <IconWidth></IconWidth>
                    <FormControl variant="filled">
                      {/* Scrive solo numeri e "." Vedi useEffect usato qui sopra */}
                      <FilledInput
                        value={textSelected.size_x}
                        className="MuiInput-number"
                        id="filled-adornment-mm"
                        size="small"
                        placeholder="000.00"
                        onChange={(e) => {
                          handleUpdateAttributeOnText("size_x", e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">mm</InputAdornment>
                        }
                        //aria-describedby="filled-mm-helper-text"
                        inputProps={{
                          step: 1,
                          min: 1,
                          max: 300,
                          type: "number",
                          "aria-label": "mm",
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    <HeightIcon
                      sx={{ width: "20px", height: "20px" }}
                    ></HeightIcon>
                    <FormControl variant="filled">
                      {/* Scrive solo numeri e "." Vedi useEffect usato qui sopra */}
                      <FilledInput
                        value={textSelected.size_y}
                        className="MuiInput-number"
                        id="filled-adornment-mm"
                        size="small"
                        placeholder="000.00"
                        onChange={(e) => {
                          handleUpdateAttributeOnText("size_y", e.target.value);
                        }}
                        endAdornment={
                          <InputAdornment position="end">mm</InputAdornment>
                        }
                        //aria-describedby="filled-mm-helper-text"
                        inputProps={{
                          step: 1,
                          min: 1,
                          max: 300,
                          type: "number",
                          "aria-label": "mm",
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>

              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              <Box className="panel-content">
                <Box className="list-item">
                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_align_object")}
                  </Typography>
                  <IconButton
                    className="fab-small"
                    aria-label="align-object"
                    size="small"
                    onClick={handleCenterItem}
                  >
                    <AlignHorizontalCenterIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </>}
          </Box>

          {/* INITIAL POSITION */}

          <Box
            className="panel-box"
            sx={{ display: initialPosition ? "block" : "none", marginTop: "10px" }}
          >
            <Box
              className="panel-header"
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ marginRight: "auto" }}>
                {t("panel_title_positioning")}
              </Typography>
            </Box>
            <Box className="panel-content">
              <Typography
                variant="body2"
                sx={{
                  color: theme.palette.white.o07,
                }}
              >
                {t("panel_description_positioning_text")}
              </Typography>
            </Box>
            <Box className="panel-content container-card-placement">
              {
                textSelected.type ?
                  Object.keys(positionsList[testTypeKey[textSelected.type]]).map((name, index) => {
                    const el = positionsList[testTypeKey[textSelected.type]][name];
                    return (
                      <CardPlacement key={"initialPosition_" + index}
                        onClick={() => { handleSelectInitialPosition(el, name) }}
                        className="card-placement"
                        imgsrc={process.env.REACT_APP_FRONTEND_ASSETS + "placer-icon/" + positionsList["PRODUCT_TYPE"] + "_" + name + ".svg"}
                        text={t("option_position_label_" + name)}
                      ></CardPlacement>
                    )
                  }) : ""

              }
            </Box>
          </Box>

          {/*TEXT STYLE*/}

          < Box
            id="text-style"
            className="panel-dx three-elements"
            sx={{
              marginTop: "10px",
              display: initialPosition ? "none" : "block",
            }}
          >
            <Box className="panel-actions">
              <IconButton
                //className="parts-icons active"
                className={`parts-icons ${editStatus === 0 ? "active" : ""}`}
                aria-label="text"
                size="large"
                onClick={() => { setEditStatus(0) }}
              >
                <IconTextBig width="32" height="32" />
              </IconButton>
              <IconButton
                //className="parts-icons"
                className={`parts-icons ${editStatus === 1 ? "active" : ""}`}
                aria-label="text-borders"
                size="large"
                onClick={() => { setEditStatus(1) }}
              >
                <IconTextBorder width="32" height="32" />
              </IconButton>
              <IconButton
                //className="parts-icons"
                className={`parts-icons ${editStatus === 2 ? "active" : ""}`}
                aria-label="text-box"
                size="large"
                onClick={() => { setEditStatus(2) }}
              >
                <IconTextBox width="32" height="32" />
              </IconButton>
            </Box>

            {/*  FONT TEXT */}

            <Box
              id="panel-text"
              sx={{
                display: editStatus === 0 ? "block" : "none",
                p: "10px 8px"
              }}
            >
              <Box className="panel-content" sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "4px"
              }}>
                {
                  fontList.map((el, index) => {
                    return (
                      <CardSquared
                        key={"Font_" + index}
                        id={el.value}
                        className={"card-squared " + (el.value === textSelected.font ? "active" : "")}
                        imgsrc={process.env.REACT_APP_FRONTEND_ASSETS + el.src}
                        name={el.label}
                        onClick={() => {
                          handleUpdateAttributeOnText("font", el.value)
                        }}
                      ></CardSquared>
                    )
                  })
                }
              </Box>

              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              <Box className="panel-content">
                <Box className="list-item">
                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_fill_color")}
                  </Typography>
                  {/* <SwitchCheck
                  value={textSelected.text_color_active}
                  toggleFunction={() =>{handleUpdateAttributeOnText("text_color_active", !textSelected.text_color_active)}}
                ></SwitchCheck> */}
                </Box>
              </Box>

              {/* <Box className="panel-divider">
              <Divider bgcolor={theme.palette.white.o015}></Divider>
            </Box> */}

              <ColorsListSelector
                disable={!textSelected.text_color_active}
                colorsInUse={colorsInUse}
                colorsList={colorsList}
                color={textSelected.text_color ? textSelected.text_color.toUpperCase() : textSelected.text_color}
                color2={false}
                handleUpdateColor={(value) => { handleUpdateAttributeOnText("text_color", value) }}
                handleUpdateColors={() => { }}
                editGradient={false}
              />

              {/* FONT TEXT DIRECTION */}
              {/* DIRECTION AND ALIGNMENT COMMENTED OUT
            <Box className="panel-content">
              <Box className="list-item">
                <Typography variant="body1" sx={{ marginRight: "auto" }}>
                  {t("panel_title_text_direction")}
                </Typography>
                <Box sx={{ display: "flex", gap: "5px" }}>
                  <IconButton
                    className={"fab-small " + (textSelected.text_align === 1 ? "active" : "")}
                    onClick={() => { handleUpdateAttributeOnText("text_align", 1) }}
                    aria-label="text-align-left"
                    size="small"
                  >
                    <FormatAlignLeftIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    className={"fab-small " + (textSelected.text_align === 2 ? "active" : "")}
                    onClick={() => { handleUpdateAttributeOnText("text_align", 2) }}
                    aria-label="text-align-center"
                    size="small"
                  >
                    <FormatAlignCenterIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    className={"fab-small " + (textSelected.text_align === 3 ? "active" : "")}
                    onClick={() => { handleUpdateAttributeOnText("text_align", 3) }}
                    aria-label="text-align-right"
                    size="small"
                  >
                    <FormatAlignRightIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </Box>

          */}
            </Box>

            {/*  FONT BORDER TEXT */}

            <Box
              id="panel-text-border"
              sx={{
                display: editStatus === 1 ? "block" : "none",
                p: "10px 8px"
              }}
            >
              <Box className="panel-content">
                <Box className="list-item">
                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_border")}
                  </Typography>
                  <SwitchCheck
                    value={textSelected.border}
                    toggleFunction={() => { handleUpdateAttributeOnText("border", !textSelected.border) }}
                  ></SwitchCheck>
                </Box>
              </Box>
              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              <ColorsListSelector
                disable={!textSelected.border}
                colorsInUse={colorsInUse}
                colorsList={colorsList}
                color={textSelected.border_color}
                color2={false}
                handleUpdateColor={(value) => { handleUpdateAttributeOnText("border_color", value) }}
                handleUpdateColors={() => { }}
                editGradient={false}
              />

              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              {/* TEXT BORDER - WEIGHT */}

              <Box className={"panel-content " + (textSelected.border !== false ? "" : "panel-disabled")}>
                <Box className="list-item">

                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_border_weight")}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <IconButton
                      className={"fab-small " + (textSelected.border_weight === 1.6 ? "active" : "")}
                      aria-label="stroke-width-thin"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_weight", 1.6) }}
                    >
                      <IconStrokeWidthThin fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.border_weight === 2.5 ? "active" : "")}
                      aria-label="stroke-width-medium"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_weight", 2.5) }}
                    >
                      <IconStrokeWidthMedium fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.border_weight === 3.2 ? "active" : "")}
                      aria-label="stroke-width-thick"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_weight", 3.2) }}
                    >
                      <IconStrokeWidthThick fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              {/* TEXT BORDER - ANGLE SHAPE */}

              <Box className={"panel-content " + (textSelected.border !== false ? "" : "panel-disabled")}>
                <Box className="list-item">
                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_angle_shape")}
                  </Typography>

                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <IconButton
                      className={"fab-small " + (textSelected.border_angle === "miter" ? "active" : "")}
                      aria-label="stroke-join-miter"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_angle", "miter") }}
                    >
                      <IconStrokeJoinMiter fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.border_angle === "round" ? "active" : "")}
                      aria-label="stroke-join-round"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_angle", "round") }}
                    >
                      <IconStrokeJoinRound fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.border_angle === "bevel" ? "active" : "")}
                      aria-label="stroke-join-bevel"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_angle", "bevel") }}
                    >
                      <IconStrokeJoinBevel fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              {/* <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box> */}

              {/* TEXT BORDER - STYLE */}

              {/* COMMENTED OUT
              <Box className={"panel-content " + (textSelected.border !== false ? "" : "panel-disabled")}>
                <Box className="list-item">

                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_border_dotted")}
                  </Typography>

                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <IconButton
                      className={"fab-small " + (textSelected.border_dotted === "line" ? "active" : "")}
                      aria-label="stroke-dash-line"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_dotted", "line") }}
                    >
                      <IconStrokeDashLine fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.border_dotted === "dot" ? "active" : "")}
                      aria-label="stroke-dash-pointer"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_dotted", "dot") }}
                    >
                      <IconStrokeDashPointer fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.border_dotted === "dash" ? "active" : "")}
                      aria-label="stroke-dash-dashed"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("border_dotted", "dash") }}
                    >
                      <IconStrokeDashDashed fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Box> */}
            </Box>

            {/*  FONT BACKGROUND TEXT */}

            <Box
              id="panel-text-background"
              sx={{
                display: editStatus === 2 ? "block" : "none",
                p: "10px 8px"
              }}
            >
              <Box className="panel-content">
                <Box className="list-item">
                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_background")}
                  </Typography>
                  <SwitchCheck
                    value={textSelected.background}
                    toggleFunction={() => { handleUpdateAttributeOnText("background", !textSelected.background) }}
                  ></SwitchCheck>
                </Box>
              </Box>
              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              <ColorsListSelector
                disable={!textSelected.background}
                colorsInUse={colorsInUse}
                colorsList={colorsList}
                color={textSelected.background_color}
                color2={false}
                handleUpdateColor={(value) => { handleUpdateAttributeOnText("background_color", value) }}
                handleUpdateColors={() => { }}
                editGradient={false}
              />

              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              {/* FONT BACKGROUND TEXT - SIZE  */}

              <Box className={"panel-content " + (textSelected.background !== false ? "" : "panel-disabled")}>
                <Box className="list-item">

                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_background_size")}
                  </Typography>

                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <IconButton
                      className={"fab-small " + (textSelected.background_size === 0 ? "active" : "")}
                      aria-label="null"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("background_size", 0) }}
                    >
                      <IconNull fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.background_size === 3 ? "active" : "")}
                      aria-label="background-offset-thin"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("background_size", 3) }}
                    >
                      <IconBackgroundOffsetThin fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.background_size === 5 ? "active" : "")}
                      aria-label="background-offset-normal"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("background_size", 5) }}
                    >
                      <IconBackgroundOffsetNormal fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.background_size === 8 ? "active" : "")}
                      aria-label="background-offset-thick"
                      size="small"
                      onClick={() => { handleUpdateAttributeOnText("background_size", 8) }}
                    >
                      <IconBackgroundOffsetThick fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>

              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>

              {/* FONT BACKGROUND TEXT - ANGLE SHAPE */}

              <Box className={"panel-content " + (textSelected.background !== false ? "" : "panel-disabled")}>
                <Box className="list-item">
                  <Typography variant="body1" sx={{ marginRight: "auto" }}>
                    {t("panel_title_text_angle_shape")}
                  </Typography>
                  <Box sx={{ display: "flex", gap: "5px" }}>
                    <IconButton
                      className={"fab-small " + (textSelected.background_angle === "miter" ? "active" : "")}
                      onClick={() => { handleUpdateAttributeOnText("background_angle", "miter") }}
                      aria-label="stroke-join-miter"
                      size="small"
                    >
                      <IconStrokeJoinMiter fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.background_angle === "round" ? "active" : "")}
                      onClick={() => { handleUpdateAttributeOnText("background_angle", "round") }}
                      aria-label="stroke-join-round"
                      size="small"
                    >
                      <IconStrokeJoinRound fontSize="small" />
                    </IconButton>
                    <IconButton
                      className={"fab-small " + (textSelected.background_angle === "bevel" ? "active" : "")}
                      onClick={() => { handleUpdateAttributeOnText("background_angle", "bevel") }}
                      aria-label="stroke-join-bevel"
                      size="small"
                    >
                      <IconStrokeJoinBevel fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box >

        </Box>
      </Tooltip>
      <Box className="panel-footer">
        {!initialPosition ? "" :
          <ButtonRound
            id="btn-cancel"
            text={t("panel_button_label_cancel")}
            variant="contained"
            className={" " + (initialPosition ? "mx-auto" : "")}
            sx={{
              marginRight: initialPosition ? "auto" : "unset",
              marginLeft: initialPosition ? "auto" : "unset"
            }}
            onClick={() => { handleCancel(); }}
          />
        }
        {initialPosition ? "" : <ButtonRound
          id="btn-confirm"
          text={t("panel_button_label_confirm")}
          variant="contained"
          className="mx-auto btn-white"
          onClick={() => { handleSave(); }}
        />}
      </Box>
    </Box>
  )
}
