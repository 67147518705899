import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconBackgroundOffsetThin = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 20"
        width="20"
      >
        <path d="M13,7v6H7V7H13z M13,5H7C5.9,5,5,5.9,5,7v6c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V7C15,5.9,14.1,5,13,5z" />
      </svg>
    </SvgIcon>
  );
};
export default IconBackgroundOffsetThin;
