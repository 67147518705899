import { Box, useTheme } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ColorGlobalEdit(props) {

  const {
    color,
    size,
    newColor
  } = props

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        padding: "5px",
        gap: "15px",
        backgroundColor: theme.palette.white.o01,
        borderRadius: "50px",
      }}
    >
      <Box className={`${size} current-color-global`}>
        <Box
          className="color-inner"
          sx={{ backgroundColor: color }}
        ></Box>
      </Box>
      <ArrowForwardIcon
        sx={{ color: theme.palette.white.main }}
      ></ArrowForwardIcon>
      <Box className={`${size} active`}>
        <Box
          className="color-inner"
          sx={{ backgroundColor: newColor }}
        ></Box>
      </Box>
    </Box>
  );
}
