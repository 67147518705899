
export const getErrorMessage = error => {
  let errorMessage
  if (error) {
    errorMessage = [error.message]
    if (error.code) {
      errorMessage.unshift(error.code)
    }
    if (error.response) {
      const response = error.response
      if (response.config && response.config.url) {
        const url = '/' + response.config.url
        errorMessage.unshift(url)
      }
      if (response.data && response.data.message) {
        const message = response.data.message
        errorMessage.push(message)
      }
    }
    errorMessage = errorMessage.join(' ')
  }
  else {
    errorMessage = 'Unknown error'
  }
  return errorMessage
}
