import { createSlice } from '@reduxjs/toolkit'
import api from '../api/api'
import { jwtDecode } from 'jwt-decode'
import {
  clearAuthData, getLocalStorageAccessToken, getLocalStorageRefreshToken,
  isExpiredAccessToken, isExpiredRefreshToken, isExpiredToken, persistAuthData,
  setAnonymousId
} from '../helpers/auth'
import { AxiosError } from 'axios'
import { setIsLoading } from './appSlice'
import { initApp } from './initSlice'
import { enqueueSnackbar } from 'notistack'
import { snackbarVariants } from '../helpers/layout'
import i18n from '../helpers/i18n'

const initialState = {
  loginDialogOpened: false,
  isLoggedInUser: false,
  userData: null,
  logoutDialogOpened: false,
  checkFulfilled: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginDialogOpened: (state, action) => {
      state.loginDialogOpened = action.payload
    },
    setIsLoggedInUser: (state, action) => {
      state.isLoggedInUser = action.payload
    },
    setUserData: (state, action) => {
      state.userData = action.payload
    },
    setLogoutDialogOpened: (state, action) => {
      state.logoutDialogOpened = action.payload
    },
    setCheckFulfilled: (state, action) => {
      state.checkFulfilled = action.payload
    }
  }
})

export const {
  setLoginDialogOpened,
  setIsLoggedInUser,
  setUserData,
  setLogoutDialogOpened,
  setCheckFulfilled
} = authSlice.actions

export const login = userdata => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  try {
    const response = await api.login(userdata)
    dispatch(setUserDataAndLogin(response))
    dispatch(setLoginDialogOpened(false))
  }
  catch { }
  await dispatch(initApp())
  dispatch(setIsLoading(false))
}

export const logout = () => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  dispatch(setLogoutDialogOpened(false))
  try {
    await api.logout()
  }
  catch {}
  dispatch(clearStorageAndLogout())
  await dispatch(initApp())
  dispatch(setIsLoading(false))
}

export const refreshTokenOnAxios401Error = err => async (dispatch, getState) => {
  if (err instanceof AxiosError && err.response?.status === 401) {
    const success = await dispatch(checkLocalAuthAndRefresh())
    if (!success)
      throw new Error('Session expired, cannot refresh session')
  }
  else
    throw err
}

export const setUserDataAndLogin = response => async (dispatch, getState) => {
  let decodedAccessToken
  if (response) {
    persistAuthData(response)
    decodedAccessToken = jwtDecode(response.token)
  }
  else {
    const token = getLocalStorageAccessToken()
    decodedAccessToken = jwtDecode(token)
  }
  dispatch(setUserData(decodedAccessToken))
  dispatch(setIsLoggedInUser(true))
}

export const clearStorageAndLogout = () => async (dispatch, getState) => {
  clearAuthData()
  dispatch(setIsLoggedInUser(false))
  dispatch(setUserData(null))
}

export const checkAuth = () => async (dispatch, getState) => {
  let success = false
  try {
    const anonymousOwnerId = setAnonymousId()
    await api.createUpdateAnonymousOwner(anonymousOwnerId)
    const hashParams = document.location.hash
    const refreshTokenFromUrl = hashParams.startsWith('#') ? hashParams.substring(1) : ''
    if (refreshTokenFromUrl) {
      window.history.replaceState("", "", `${document.location.pathname}${document.location.search}`);
      const expiredRefreshToken = isExpiredToken(refreshTokenFromUrl)
      if (expiredRefreshToken)
        dispatch(clearStorageAndLogout())
      else {
        const response = await api.refreshToken(refreshTokenFromUrl)
        dispatch(setUserDataAndLogin(response))
        success = true
      }
    }
    else {
      success = await dispatch(checkLocalAuthAndRefresh())
    }
  }
  catch (err) {
    dispatch(clearStorageAndLogout())
  }
  dispatch(setCheckFulfilled(true))
  return success
}

export const checkLocalAuthAndRefresh = () => async (dispatch, getState) => {
  let success = false
  const expiredAccessToken = isExpiredAccessToken()
  const expiredRefreshToken = isExpiredRefreshToken()
  if (expiredRefreshToken) {
    dispatch(clearStorageAndLogout())
    enqueueSnackbar(i18n.t('expired_session_snack_message'), { variant: snackbarVariants.error })
  }
  else {
    let response
    if (expiredAccessToken) {
      const refreshToken = getLocalStorageRefreshToken()
      try {
        response = await api.refreshToken(refreshToken)
        dispatch(setUserDataAndLogin(response))
        success = true
      }
      catch (err) {
        dispatch(clearStorageAndLogout())
        enqueueSnackbar(i18n.t('expired_session_snack_message'), { variant: snackbarVariants.error })
      }
    }
    else {
      dispatch(setUserDataAndLogin(response))
      success = true
    }
  }
  return success
}

export default authSlice.reducer
