import { Box, useTheme } from "@mui/material"
// import { appBarHeight } from "../helpers/layout"
import SwitchBtn from "../components/SwitchBtn"
import SwiperDesign from "./SwiperDesign"
import { useState } from "react"
import DesignGridView from "./DesignGridView"
import { useDispatch, useSelector } from "react-redux"
import { setGridView } from "../slices/appSlice"

export default function ProductDesignsList(props) {
  const theme = useTheme()
  const {
    initialSlide,
    designsList,
    handleSelectDesign,
  } = props

  const [designView, setDesignView] = useState(0)

  const handleSetDesign = design => handleSelectDesign(design.id)
  const appState = useSelector(state => state.app)
  const dispatch = useDispatch()
  const handleDispatchSetGridView = gridView => dispatch(setGridView(gridView))

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "2",
        overflow: "hidden",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.appBar + 10,
        background: theme.palette.dark.main
      }}
    >
      <Box pl={2}>
      </Box>
      <SwitchBtn
        designView={designView}
        setDesignView={setDesignView}
        className={appState.gridView ? " " : "absolute-bottom-navigator"}
        showGridSwitch={true}
        listActive={!appState.gridView}
        toggleFunction={() => {handleDispatchSetGridView(!appState.gridView)}}
      />
      <SwiperDesign
        initialSlide={initialSlide}
        designView={designView}
        designSlides={designsList}
        handleFunction={handleSetDesign}
        gridView={appState.gridView}
      />
      <DesignGridView
        designView={designView}
        gridView={appState.gridView}
        designSlides={designsList}
        handleFunction={handleSetDesign}
      />
    </Box>
  )
}
