import { useEffect, useState } from "react"
import i18n from "../../helpers/i18n"

export default function LanguageManager(props) {

  const [ready, setReady] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const queryLang = urlParams.get('lang')
    // CHECK IF QUERY LANG IS PRESENT
    if (queryLang) {
      // IF QUERY LANG IS IT OR EN, CHANGE LANGUAGE
      if (queryLang === "it" || queryLang === "en") {
        i18n.changeLanguage(queryLang)
      }
    }
    setReady(true)
  }, [])

  return ready ? props.children : ''
}
