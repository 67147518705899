import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { checkAuth } from "../../slices/authSlice"

export default function AuthManager(props) {
  const { children } = props
  const dispatch = useDispatch()
  const authState = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(checkAuth())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (authState.checkFulfilled)
    return children
}
