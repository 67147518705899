import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconStrokeJoinMiter = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 20"
        width="20"
      >
        <path d="M16,16h-6v-6H4V4h12V16z" />
      </svg>
    </SvgIcon>
  );
};
export default IconStrokeJoinMiter;
