import { Box, Tooltip } from "@mui/material";

export default function PatternSelector(props) {
  const {
    className,
    imgsrc,
    onClick,
    name,
  } = props;

  return (
    <Tooltip title={name} disableInteractive>
    <Box className={className} onClick={onClick} sx= {{
      "&.pattern-selector": {
        width: "52px",
        height: "52px",
        outline: "1px solid transparent",
        outlineOffset: "-1px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        cursor:"pointer",
        "&:hover": {
          outline: "1px solid rgba(255, 255, 255, 0.2)",
          outlineOffset: "-1px",
          cursor: "pointer",
        },
        "&.active": {
          outline: "1px solid rgba(255, 255, 255, 0.7)",
          outlineOffset: "-1px"
        },
        "& .pattern-inner": {
          width: "42px",
          height: "42px",
          outline: "1px solid #ffffff",
          borderRadius: "50%",
          "img": {
            objectFit:"cover",
            width:"100%",
            height: "100%",
            borderRadius: "50%",
            filter: "brightness(0) invert(1)",
          }
        }
      }
    }}>
      <Box className="pattern-inner">
        <img src={imgsrc} alt={"preview of pattern " + name}/>
      </Box>
    </Box>
    </Tooltip>
  );
}
