import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
// import ColorizeIcon from '@mui/icons-material/Colorize';
// import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from "react-i18next";
// import { ReactComponent as IconClone } from "../../icons/icon-clone.svg";

export default function ExtraPartsListItem(props) {

  const {
    item,
    // index,
    onClick,
  } = props

  // const theme = useTheme();

  const { t } = useTranslation();

  return (
    <ListItem
      disablePadding
      onClick={() => { onClick(item) }}
    >
      <ListItemButton sx={{
        gap: "10px",
        "&:hover .icon-invisible": {
            opacity: 1,
        }
      }}>
        <Box
          sx={{
            width: "48px",
            aspectRatio: 1,
            borderRadius: "4px",
            outline: `1px solid rgba(255,255,255,0.15)`,
            outlineOffset: "-0.5px",
            background: item.color,
            overflow: "hidden",
            position: "relative"
          }}
        >
        </Box>
        <ListItemText primary={t(item.name)} />
      </ListItemButton>
    </ListItem>
  )

}
