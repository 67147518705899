import { jwtDecode } from "jwt-decode"
import { v4 as uuidv4 } from 'uuid'

const keys = {
  token: 'token',
  refreshToken: 'refreshToken'
}
const anonymousOwnerIdKey = 'anonymous_owner_id'

export const persistAuthData = response => {
  const keysArray = Object.values(keys)
  for (const key of keysArray)
    localStorage.setItem(key, response[key])
}

export const clearAuthData = () => {
  const keysArray = Object.values(keys)
  for (const key of keysArray)
    localStorage.removeItem(key)
}

export const getLocalStorageAccessToken = () => {
  return localStorage.getItem(keys.token)
}

export const getLocalStorageRefreshToken = () => {
  return localStorage.getItem(keys.refreshToken)
}

export const getLocalStorageAnonymousId = () => {
  return localStorage.getItem(anonymousOwnerIdKey)
}

export const isExpiredAccessToken = () => {
  const accessToken = getLocalStorageAccessToken()
  return isExpiredToken(accessToken)
}

export const isExpiredRefreshToken = () => {
  const refreshToken = getLocalStorageRefreshToken()
  return isExpiredToken(refreshToken)
}

export const isExpiredToken = token => {
  const decodedToken = jwtDecode(token)
  const now = Math.trunc(Date.now() / 1000)
  const expiredToken = decodedToken.exp < now
  return expiredToken
}

export const setAnonymousId = () => {
  let anonymousOwnerId = getLocalStorageAnonymousId()
  if (!anonymousOwnerId) {
    anonymousOwnerId = uuidv4()
    localStorage.setItem(anonymousOwnerIdKey, anonymousOwnerId)
  }
  return anonymousOwnerId
}
