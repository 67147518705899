import i18n from "../helpers/i18n"

export const routes = {
  home: '/',
  designs: '/:productId',
  configuration: '/:productId/:designId',
  uiComponents: '/ui-components',
  notFound: '*'
}

export const getConfigurationRoute = (productId, designId) => {
  return routes.configuration.replace(':productId', productId).replace(':designId', designId)
}

export const getDesignsRoute = productId => {
  return routes.designs.replace(':productId', productId)
}

export const getSgebSiteOrdersUrl = () => {
  return `https://ordini.sgeb.it/${i18n.resolvedLanguage}`
}
