import { Box, Grid2, IconButton, Typography, useTheme } from "@mui/material"
import ButtonRound from "./ButtonRound"
import { useTranslation } from "react-i18next"
// import { ReactComponent as IconTshirt } from "../icons/icon-tshirt.svg"
import Divider from "./Divider"
import TableSizeRow from "./TableSizeRow"
import ButtonSmall from "./ButtonSmall"
import AddIcon from "@mui/icons-material/Add"
import { useEffect, useState } from "react"
import CloseIcon from '@mui/icons-material/Close';
import { setTotalPrice } from "../slices/appSlice"
import { useDispatch, useSelector } from "react-redux"
// import { useDispatch } from "react-redux"
// import { saveOrUpdateConfiguration } from "../slices/productSlice"

export default function TableSizes(props) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // const dispatch = useDispatch()
  const {
    productId,
    designId,
    selectedDesign,
    handleCancel,
    handleSave,
    listProduct,
    totalPrice,
    setListProduct,
  } = props

  const [totQty, setTotQty] = useState(0)
  // const [totPrice, setTotPrice] = useState(0)
  const [priceForPice, setPriceForPice] = useState(0)

  const appState = useSelector(state => state.app);

  // const listProduct = [
  //   {
  //     name: "",
  //     initials: "",
  //     number: "",
  //     size: "XL",
  //     quantity: 1,
  //     price: 29.50
  //   },
  // ]

  const handleAddProduct = () => {
    const tempListProduct = [...listProduct]
    tempListProduct.push({
      name: "",
      initials: "",
      number: "",
      size: "",
      quantity: 1,
      minQuantity: Math.min(...selectedDesign.prices.map(p => p[0])),
      price: 0,
      errorSize: false,
      errorQuantity: false
    })
    setListProduct([...tempListProduct])
  }

  // useEffect(() => {
  //   dispatch(saveOrUpdateConfiguration())
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  useEffect(() => {
    let tempQty = 0
    let tempPrice = 0
    listProduct.forEach(element => {
      tempQty += element.quantity
      // tempPrice += element.quantity * element.price
    })
    let tempPriceForPice = findPrice(tempQty);
    tempPrice = tempQty * findPrice(tempQty);
    setPriceForPice(tempPriceForPice)
    setTotQty(Number(tempQty))
    dispatch(setTotalPrice(Number(tempPrice)))
    // setTotPrice(Number(tempPrice))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listProduct])

  const findPrice = (quantity) => {
    let price = 0
    for (const quantityPrice of selectedDesign.prices) {
      if (quantityPrice[0] <= quantity) {
        price = quantityPrice[1]
        break
      }
    }
    return price
  }

  const handleDeleteRow = (id) => {
    const tempListProduct = [...listProduct]
    tempListProduct.splice(id, 1)
    setListProduct([...tempListProduct])
  }

  const handleUpdateRow = (index, row) => {
    const tempListProduct = [...listProduct]
    tempListProduct[index] = { ...row }
    setListProduct([...tempListProduct])
  }

  return (
    <Box className="panel-box"
      sx={{
        background: theme.palette.black.o05,
        backdropFilter: theme.palette.filter.blur50,
        zIndex: theme.zIndex.appBar + 200,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "70px 30px",
        maxHeight: "100svh",
        overflow: "hidden",
        borderRadius: "0px",
        [theme.breakpoints.up("lg")]: {
          padding: "70px 80px",
        },
        [theme.breakpoints.up("xl")]: {
          padding: "70px 160px",
        }
      }}>
      {/* TITLE */}
      <Box sx={{
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
      }}>
        <Typography variant="h2">{t("table_prices_title_compelte_order")}</Typography>
        <IconButton
          onClick={() => handleCancel()}
          sx={{
            display: 'flex',
            flexShrink: 0,
          }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* TABLES */}

      <Box sx={{
        display: "flex",
        // flexGrow: "2",
        displa: "flex",
        flexDirection: "column",
        borderRadius: "20px 0px 0px 20px",
        overflow: "hidden",
      }}>
        {/* TABLE CONTAINER 1 */}
        <Box sx={{
          p: "20px",
          borderRadius: "20px",
          background: theme.palette.dark.light08,
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          "svg": {
            height: "32px",
          },
          overflow: "hidden",
        }}>

          {/* ICON AND TITLE */}
          <Box sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center"
          }}>
            <Typography variant="h3">{productId}</Typography>
            <Typography variant="h4" color={theme.palette.white.o05}>{designId}</Typography>
          </Box>

          {/* TABLE */}
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            gap: "10px",
            "& .tab-cell>.MuiTypography-root, & .MuiGrid2-root>.MuiTypography-root": {
              p: "10px 12px",
              display: "block",
              "& span": {
                color: theme.palette.white.o05,
                marginRight: "10px"
              },
            },
            "& .tab-cell": {
              minHeight: "59px",
              display: "flex",
              alignItems: "center"
            }
          }}>
            <Grid2 container sx={{
              overflow: "hidden",
              maxHeight: "100%",
              flexShrink: 0,
            }}
              spacing={1}
              columns={26}
            >
              <Grid2 size={1}><Typography variant="h5">{t('players_table_head_row')}</Typography></Grid2>
              <Grid2 size={6}><Typography variant="h5">{t('players_table_head_name')}</Typography></Grid2>
              <Grid2 size={6}><Typography variant="h5">{t('players_table_head_initials')}</Typography></Grid2>
              <Grid2 size={6}><Typography variant="h5">{t('players_table_head_number')}</Typography></Grid2>
              <Grid2 size={3}><Typography variant="h5">{t('players_table_head_size')}</Typography></Grid2>
              <Grid2 size={3}><Typography variant="h5">{t('players_table_head_quantity')}</Typography></Grid2>
              {/* <Grid2 xs={3}><Typography variant="h5" align="right">Prezzo</Typography></Grid2> */}
            </Grid2>
            <Divider bgcolor={theme.palette.white.o015} ></Divider>
            <Grid2
              container
              spacing={1}
              columns={26}
              sx={{
                display: "flex",
                overflowY: "auto",
                maxWidth: "100%",
                "&::-webkit-scrollbar": {
                  width: "0.3em",
                  borderRadius: "10px"
                },
                "&::-webkit-scrollbar-track": {
                  // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "darkgrey",
                  borderRadius: "10px",
                  // outline: "1px solid slategrey",
                },
              }}
            >

              {listProduct.length === 0 &&
                <Grid2 size={26}>
                  <Grid2 container>
                    <Typography variant="body2"><i>{t("label_no_configured_product")}</i></Typography>
                  </Grid2>
                </Grid2>
              }

              {
                listProduct.map((el, index) => {
                  return (
                    <Grid2
                      size={26}
                      key={"tablePricesRow_" + index}
                      sx={{ borderTop: index !== 0 ? `1px solid ${theme.palette.white.o015}` : "" }}
                    >
                      <Grid2
                        container
                        spacing={1}
                        columns={26}
                        sx={{ mt: index !== 0 ? 1 : 0 }}
                      >
                        <TableSizeRow
                          index={index}
                          row={el}
                          selectedDesign={selectedDesign}
                          // name={el.name}
                          // initials={el.initials}
                          // number={el.number}
                          // size={el.size}
                          // quantity={el.quantity}
                          // price={el.price}
                          updateRow={handleUpdateRow}
                          deleteRow={handleDeleteRow}
                        />
                      </Grid2>
                    </Grid2>
                  )
                })
              }

            </Grid2>
            <Divider bgcolor={theme.palette.white.o015} ></Divider>
            <Grid2
              container
              sx={{
                display: "flex",
                flexShrink: 0,
              }}
              spacing={1}
              columns={26}
            >
              {/* FOOTER PRICE TAB  */}
              <Grid2 offset={{ xs: 1 }} size={{ xs: 14 }} pt="10px">
                <ButtonSmall
                  onClick={handleAddProduct}
                  text={t("button_add_player")}
                  className="btn-small btn-small-icon"
                  icon={<AddIcon fontSize="14" style={{ color: theme.palette.white.main }} />}
                />

              </Grid2>
              {/* <Grid2 xs={4}><Typography variant="h4" align="right">{t("table_tot_order")}</Typography></Grid2> */}
              <Grid2 size={{ xs: 10 }} sx={{
                display: "flex",
                flexDirection: "column",
                // gap: "5px",
                alignItems: "flex-end"
              }}>

                {appState.priceVisible ?
                  <Typography variant="body2" align="right" sx={{ span: { mx: "5px" } }}>
                    <span>{t("label_price_for")}</span>{totQty} <span>{t("label_piece_short")}</span>€ {priceForPice?.toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </Typography>
                  : " "
                }

                {appState.priceVisible ?
                <Typography variant="h4" align="right">
                  <span>€</span>{
                    totalPrice?.toLocaleString('it-IT', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }
                </Typography>
                : " "
                }


              </Grid2>
            </Grid2>

          </Box>

        </Box>

      </Box>

      {/* FOOTER */}
      <Box className="panel-footer">
        <ButtonRound
          id="btn-cancel"
          text={t("panel_button_label_cancel")}
          variant="contained"
          className=""
          onClick={handleCancel}
        />
        <ButtonRound
          id="btn-confirm"
          text={t("label_button_add_to_cart")}
          variant="contained"
          className="btn-solid-red"
          onClick={handleSave}
        />
      </Box>
    </Box>
  )

}
