export const getTexts = () => {
  return [
    // {
    //   id: "",
    //   type: "free",
    //   text: "Testo placeholder",
    //   position_standard: 1,
    //   font: "F002",
    //   size_x: "100",
    //   size_y: "1000",
    //   text_align: "",
    //   actual_position_x: "",
    //   actual_position_y: "",
    //   text_color_active: false,
    //   text_color: "#FFFFFF",
    //   border: false,
    //   border_color: "#FFFFFF",
    //   border_weight: 1,
    //   border_angle: 1,
    //   border_dotted: 1,
    //   background: false,
    //   background_color: "#FFFFFF",
    //   background_size: 0,
    //   background_angle: 1,
    //   isLocked: false,
    // }
  ]
}
