import { Box, Typography } from "@mui/material"
import ColorGlobalEdit from "./ColorGlobalEdit";
import PaletteIcon from "@mui/icons-material/Palette";
import ColorSelector from "./ColorSelector";
import ButtonRound from "./ButtonRound";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function PanelGlobalColor(props) {

  const {
    colorsList,
    handleClose,
    handleSave,
    globalColorSelected,
    handleChangeGlobalColor,
  } = props;

  // const theme = useTheme();

  const {t} = useTranslation();

  const [newColor, setNewColor] = useState(globalColorSelected);

  return (
    <Box
      id="edit-global-color"
      sx={{
        display: "block"
      }}
    >
      <Box className="panel-box">
        <Box
          className="panel-header"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ marginRight: "auto" }}>
            {t("panel_label_change_global_color")}
          </Typography>
          {/* <IconButton
            aria-label="add"
            size="small"
            onClick={() =>{handleClose()}}
          >
            <CloseIcon fontSize="small" />
          </IconButton> */}
        </Box>
        <Box className="panel-content panel-global-color">
          <ColorGlobalEdit
            size={"color-selector color-selector-l"}
            color={globalColorSelected}
            newColor={newColor}
          ></ColorGlobalEdit>
        </Box>
        <Box className="panel-content">
          <Box className="color-sample-container">
            <PaletteIcon sx={{margin: "5px",width: "25px", height: "25px"}}></PaletteIcon>
            {colorsList.map((item, index) => (
              <ColorSelector
                key={index}
                className={"color-selector color-selector-m "+ (newColor === item.color ?  "active" : "")}
                sample={item.color}
                label={item.label}
                onClick={() => {
                  setNewColor(item.color);
                  handleChangeGlobalColor(item.color);
                }}
              ></ColorSelector>
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="panel-footer">
        <ButtonRound
          id="btn-cancel"
          text="Annulla"
          variant="contained"
          className=""
          onClick={() =>{handleClose()}}
        />
        <ButtonRound
          id="btn-confirm"
          text="Conferma"
          variant="contained"
          className="btn-white"
          onClick={() =>{handleSave(newColor)}}
        />
      </Box>
    </Box>)
}
