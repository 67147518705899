import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconNull = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 20"
        width="20"
      >
        <path d="M5,16c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l10-10c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-10,10C5.5,15.9,5.3,16,5,16z" />
      </svg>
    </SvgIcon>
  );
};
export default IconNull;
