import { configureStore } from '@reduxjs/toolkit'
import appReducer from '../slices/appSlice'
import authReducer from '../slices/authSlice'
import initReducer from '../slices/initSlice'
import productReducer from '../slices/productSlice'
import navigationReducer from '../slices/navigationSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    init: initReducer,
    product: productReducer,
    navigation: navigationReducer
  }
})
