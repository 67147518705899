import { useEffect, createRef } from "react";
import { viewerInstance, setViewerContainer } from "../helpers/Viewer"
import { Box } from "@mui/material";
// import { TimerOutlined } from "@mui/icons-material";
import { store } from "../store/store";
import { enqueueSnackbar } from 'notistack'
import { snackbarVariants } from '../helpers/layout'
import i18n from '../helpers/i18n'

export default function Viewer3D(props) {

  const {
    // appBarHeight,
    // index,
    imageSelected,
    setImageSelected,
    // indexImageSelected,
    setIndexImageSelected,
    // imageEdit,
    setImageEdit,
    imagesList,
    setImagesList,
    textSelected,
    setTextSelected,
    // indexTextSelected,
    setIndexTextSelected,
    // textEdit,
    setTextEdit,
    // textsList,
    setTextsList,
    // currentSelectedItem,
    setCurrentSelectedItem,
    // defaultText,
    // defaultImage,
    // isLoading,
    // setIsLoading
    dispatchSetBrandLogo
  } = props;

  const ref = createRef()

  // ITEMS SELECTION

  const handleItemSelection = (item) => {
    const state = store.getState();
    const { textsList, imagesList } = state.app;

    // const handleImageSelection = (item, index) => {
    if (item) {
      // LOGO - IMAGE case
      if (item.isLogo && (imageSelected === null || (imageSelected !== null && imageSelected.id !== item.name))) {
        const filtered = imagesList.filter((e) => e.id === item.name);
        if (filtered.length > 0) {
          const index = imagesList.indexOf(filtered[0]);
          setImageSelected(structuredClone(filtered[0]));
          setIndexImageSelected(index);
          setImageEdit(true);
          // viewerInstance.selectItemName(id);
        }
      }
      // TEXT case
      if (item.isText && (textSelected === null || (textSelected !== null && textSelected.id !== item.name))) {
        const filtered = textsList.filter((e) => e.id === item.name);
        // console.log(filtered, textsList, item);
        if (filtered.length > 0) {
          const index = textsList.indexOf(filtered[0]);
          // console.log(filtered);
          setTextSelected(structuredClone(filtered[0]));
          setIndexTextSelected(index);
          setTextEdit(true);
          // viewerInstance.selectItemName(id);
        }
      }
    }
  }

  const handleCloneItem = (item, sourceItem) => {
    const state = store.getState();
    const { textsList, imagesList } = state.app;

    setTimeout(() => {
      if (item) {
        // LOGO - IMAGE case
        if (item.isLogo && imagesList.filter((e) => e.id === item.name).length === 0) {
          const sourceLogo = imagesList.filter((e) => e.id === sourceItem.name);
          if (sourceLogo.length > 0) {

            const tempImagesList = [...imagesList];
            const cloneImage = {
              ...sourceLogo[0], ...{
                id: item.name,
                size_x: item.size.width.toFixed(2).toString(),
                size_y: item.size.height.toFixed(2).toString(),
                angle: item.rotation >= 0 ? item.rotation % 360 : 360 + (item.rotation % 360),
                hasLink: null,
              }
            };
            tempImagesList.push(cloneImage);
            setImagesList([...tempImagesList]);
            setCurrentSelectedItem(item);

            const filtered = tempImagesList.filter((e) => e.id === item.name);
            if (filtered.length > 0) {
              const index = tempImagesList.indexOf(filtered[0]);
              // console.log(filtered);
              setImageSelected(structuredClone(filtered[0]));
              setIndexImageSelected(index);
              setImageEdit(true);
              // viewerInstance.selectItemName(id);
            }
          }

        }
        // TEXT case
        if (item.isText) {
          const sourceText = textsList.filter((e) => e.id === sourceItem.name);
          if (sourceText.length > 0) {
            const tempTextsList = [...textsList];
            const clonedText = {
              ...sourceText[0], ...{
                id: item.name,
                size_x: item.size.width.toFixed(2).toString(),
                size_y: item.size.height.toFixed(2).toString(),
                angle: item.rotation >= 0 ? item.rotation % 360 : 360 + (item.rotation % 360),
                hasLink: null,
              }
            };

            tempTextsList.push(clonedText);
            setTextsList(tempTextsList);
            setCurrentSelectedItem(item);

            const filtered = tempTextsList.filter((e) => e.id === item.name);
            if (filtered.length > 0) {
              const index = tempTextsList.indexOf(filtered[0]);
              // console.log(filtered);
              setTextSelected(structuredClone(filtered[0]));
              setIndexTextSelected(index);
              setTextEdit(true);
              // viewerInstance.selectItemName(id);
            }
          }
        }
      }
    }, 200);
  }

  const handleDeleteItem = (item) => {
    const state = store.getState();
    const { textsList, imagesList, imageSelected, textSelected } = state.app;

    if (item) {
      if (item.isLogo) {
        const tempImageSelected = structuredClone(imageSelected)
        // console.log(item.name, {...tempImageSelected}, item.name === tempImageSelected.id)
        const tempImagesList = [...imagesList];
        // console.log(item.name, [...tempImagesList])
        const filtered = tempImagesList.filter((e) => e.id === item.name);
        // console.log(filtered);
        if (filtered.length > 0) {
          const index = tempImagesList.indexOf(filtered[0]);
          // console.log("index", index, tempImagesList, filtered[0])
          if (index >= 0) {
            tempImagesList.splice(index, 1);
            // console.log("tempImagesList", [...tempImagesList]);
            setImagesList([...tempImagesList]);
          }
        }
        if (tempImageSelected && item.name === tempImageSelected.hasLink) {
          tempImageSelected.hasLink = null;
          setImageSelected(structuredClone(tempImageSelected))
        }
        if (tempImageSelected && item.name === tempImageSelected.id) {
          setImageSelected(null);
          setImageEdit(false);
          setCurrentSelectedItem(null);
        }
      }
      if (item.isText) {
        const tempTextSelected = structuredClone(textSelected)
        if (tempTextSelected && item.name === tempTextSelected.id) {
          tempTextSelected.id = "";
          setTextSelected(tempTextSelected);
        } else {
          const tempTextsList = [...textsList];
          // console.log(tempTextsList)
          const filtered = tempTextsList.filter((e) => e.id === item.name);
          // console.log(filtered);
          if (filtered.length > 0) {
            if (filtered[0].hasLink && tempTextSelected && tempTextSelected.id === filtered[0].hasLink) {
              tempTextSelected.hasLink = null;
              setTextSelected(tempTextSelected);
            }
            const index = tempTextsList.indexOf(filtered[0]);
            if (index >= 0) {
              tempTextsList.splice(index, 1);
              setTextsList([...tempTextsList]);
            }
          }
        }

      }
    }
  }


  useEffect(() => {
    if (viewerInstance) {
      // console.log('not viewerInstance')
      // const viewerContainer = document.createElement('div')
      // viewerContainer.id = 'Viewer3D'
      // viewerContainer.classList.add('viewer3D')
      // viewerContainer.style.width = '100%' // context.fullscreen ? '100vw' : `calc(100vw - ${context.appDrawerWidth}px)`
      // viewerContainer.style.height = '100%'
      // viewerContainer.style.position = 'relative'
      // viewerContainer.style.outline = '0px'
      // ref.current.append(viewerContainer)
      setViewerContainer("Viewer3D")
      // setIsLoading(true);

      // EVENT MANAGER

      const eventManager = viewerInstance.getViewerEventManager();

      // viewerContainer.getElementsByTagName("canvas")[0].addEventListener(eventManager.onItemChangedEvent, (e) => {console.log(e)})

      // ON ITEM CHANGED
      eventManager.addEventListener(eventManager.onItemChangedEvent, (e) => {
        const state = store.getState();
        const { currentSelectedItem } = state.app;
        if (currentSelectedItem === null || currentSelectedItem.name === e.data.item.name) {
          const dataItem = JSON.parse(JSON.stringify(e.data.item));
          setCurrentSelectedItem(dataItem);
        }
        // console.log(e)
      })

      // ON INIT DEV SCENE COMPLETED

      eventManager.addEventListener(eventManager.onInitDevSceneCompletedEvent, (e) => {
        // console.log(e)
      })

      // ON INIT DEV SCENE STARTED

      eventManager.addEventListener(eventManager.onAddItemStartedEvent, (e) => {
        // console.log(e)
      })

      // ON ADD ITEM COMPLETED

      eventManager.addEventListener(eventManager.onAddItemCompletedEvent, (e) => {
        const state = store.getState();
        const { brandLogo, brandLogoColorDefault } = state.app;
        const dataItem = JSON.parse(JSON.stringify(e.data.item));

        if (dataItem.isBrandLogo) {
          if (!brandLogo.name) {
            // console.log("BRAND LOGO", dataItem)
            dispatchSetBrandLogo({...brandLogo,
              ...{
              // url: process.env.REACT_APP_FRONTEND_ASSETS + "svg/piu-adrenalina-logo.svg",
              position: "front",
              isLocked: true,
              color: brandLogoColorDefault,
              name: dataItem.name,
              type: dataItem.type,
              isLogo: dataItem.isLogo,
              isBrandLogo: dataItem.isBrandLogo,
            }})

            viewerInstance.setBrandLogoColor(brandLogoColorDefault);
          }
        } else {
          setCurrentSelectedItem(dataItem);
        }
        // console.log(e)
      })

      // ON ITEM SELECTED

      eventManager.addEventListener(eventManager.onItemSelectedEvent, (e) => {
        const dataItem = JSON.parse(JSON.stringify(e.data.item));
        setCurrentSelectedItem(dataItem);
        handleItemSelection(e.data.item);
        // console.log(e)
      })

      // ON ITEM DELETED

      eventManager.addEventListener(eventManager.onItemDeletedEvent, (e) => {
        handleDeleteItem(e.data.item);
        // console.log(e)
      })

      // ON ITEM CLONED

      eventManager.addEventListener(eventManager.onItemClonedEvent, (e) => {
        handleCloneItem(e.data.item, e.data.sourceItem);
        // console.log(e)
      })

      // ON ITEM UNSELECTED

      eventManager.addEventListener(eventManager.onItemUnselectedEvent, (e) => {
        setCurrentSelectedItem(null);
        // console.log(e)
      })

      // ON ITEM LOCKED

      eventManager.addEventListener(eventManager.onItemLockedEvent, (e) => {
        const dataItem = JSON.parse(JSON.stringify(e.data.item));
        setCurrentSelectedItem(dataItem);
        // console.log(e)
      })

      // ON ITEM POSITION INVALID

      eventManager.addEventListener(eventManager.onAddItemErrorEvent, (e) => {
        // console.log(e)
        enqueueSnackbar(
          i18n.t("viewer_error_position_invalid"),
          { variant: snackbarVariants.warning },
        )
      })

      // setIsLoading(false);
    }
    // else {
    //   // console.log('viewerInstance', viewerInstance)
    //   if (viewerInstance && !ref.current.hasChildNodes()) {
    //     ref.current.append(viewerContainer)
    //     //viewerInstance.updateRenderAspect()
    //   }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viewerInstance) {
      // const eventManager = viewerInstance.getViewerEventManager();
      // console.log(eventManager);
      // eventManager.removeEventListener(eventManager.onItemSelectedEvent, (e) => {handleImageSelection(e.data.item.name)});
      // eventManager.addEventListener(eventManager.onItemSelectedEvent, (e) => {handleImageSelection(e.data.item.name)});
      // console.log(eventManager);
    }
  }, [imagesList]);


  return (
    <Box
      ref={ref}
      id="Viewer3D"
      sx={{
        // height: `calc(100svh - ${appBarHeight}px)`,
        // paddingTop: appBarHeight + "px",
        display: "block",
        height: `100svh`,
        width: "100%"
      }}>

    </Box>
  )
}
