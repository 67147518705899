import { Box, ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material";
import { ReactComponent as IconLocketClose } from "../icons/icon-locket-close.svg";
import { useTranslation } from "react-i18next";

export default function BrandLogoListItem(props) {

  const {
    handleOpenBrandLogoCustomizer,
    brandLogo,
    small
  } = props

  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <ListItem
      disablePadding
      onClick={() => { handleOpenBrandLogoCustomizer(); }}
      sx={{
        "& img": {
          objectFit: "contain",
          maxWidth: "100%",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
        },
      }}
    >
      <ListItemButton sx={{
        gap: "10px",
        "& svg": {
          width: "20px",
          height: "20px",
          fill: theme.palette.text.primary
        },
        ".actions-button": {
          display: "flex",
          pointerEvents: "none",
          opacity: "0"
        },
        "&:hover": {
          ".actions-button": {
            pointerEvents: "auto",
            opacity: "1"
          }
        }
      }} className={small ? "file-preview small" : "file-preview"}>
        <Box sx={{
          position: "relative",
          borderRadius: "4px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          flexShrink: 0,
          margin: small ? "0 18px" : "0px"
        }}>
          <Box
            sx={{
              display: "flex",
              backgroundSize: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundImage: `url(${"../../assets/imgs/Thumbnail-BG-pattern_48.png"})`,
            }}
          >
            <Box
              className="img-file-preview"
              sx={{
                backgroundImage: `url("${brandLogo.url}")`,
              }}
            ></Box>
          </Box>
        </Box>
        <ListItemText sx={{
          wordBreak: "break-word"
        }} primary={t("panel_title_brand_logo")} secondary={brandLogo.id} />
        <Box sx={{
          padding: "2px",
          borderRadius: "5px",
          background: theme.palette.white.main,
          display: brandLogo.isLocked ? "flex" : "none",
          aspectRatio: 1,
          "& svg": {
            fill: theme.palette.black.main,
          }
        }}>
          <IconLocketClose />
        </Box>
      </ListItemButton>
    </ListItem>

  )
}
