export const getProductParts = () => {
  return [
    // {
    //   id: "0",
    //   label: "Fronte",
    //   color: "#E00026",
    //   colorPosition: 0,
    //   color2: "",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
    // {
    //   id: "1",
    //   label: "Retro",
    //   color: "#9C1829",
    //   colorPosition: 0,
    //   color2: "",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
    // {
    //   id: "2",
    //   label: "Manica destra",
    //   color: "#E00026",
    //   colorPosition: 0,
    //   color2: "",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
    // {
    //   id: "3",
    //   label: "Manica sinistra",
    //   color: "#E00026",
    //   colorPosition: 0,
    //   color2: "#9C1829",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
    // {
    //   id: "4",
    //   label: "Colletto",
    //   color: "#FF7B69",
    //   colorPosition: 0,
    //   color2: "",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
    // {
    //   id: "5",
    //   label: "Dettaglio design 1",
    //   color: "#9C1829",
    //   colorPosition: 0,
    //   color2: "",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
    // {
    //   id: "6",
    //   label: "Dettaglio design 2",
    //   color: "#FF7B69",
    //   colorPosition: 0,
    //   color2: "",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
    // {
    //   id: "7",
    //   label: "Dettaglio design 3",
    //   color: "#FFFFFF",
    //   colorPosition: 0,
    //   color2: "",
    //   color2Position: 100,
    //   colorsAngle: 0,
    //   pattern: "no-pattern",
    //   patternAngle: 0,
    //   patternSize: 1,
    // },
  ]
}
