import { Drawer, Box, IconButton, useTheme } from "@mui/material";
// import { ReactComponent as IconTshirt } from "../icons/icon-tshirt.svg";
// import { ReactComponent as IconShorts } from "../icons/icon-shorts.svg";
// import { ReactComponent as IconSocks } from "../icons/icon-socks.svg";
// import { ReactComponent as IconUniform } from "../icons/icon-uniform.svg";
// import { ReactComponent as IconLayers } from "../icons/icon-layers.svg";
import { ReactComponent as IconSettings } from "../icons/icon-settings.svg";
import { useEffect, useRef, useState } from "react";
// import { useTranslation } from "react-i18next";
import OptionPanel from "./OptionPanel";
import DownloadIcon from '@mui/icons-material/Download';
import { appBarHeight } from "../helpers/layout";


export default function PanelLeft(props) {
  const {
    // index,
    handleChangeGridView,
    gridView,
    themeMode,
    handleChangeThemeMode,
    blur,
    handleChangeBlur,
    // setGridView,
    // handleSetShowMode,
    // previewState,
    handleDownload,
    selectDesignOpen,
    openProducts
  } = props;

  // const { t } = useTranslation();

  const refOptionBtn = useRef();

  const theme = useTheme();

  const [optionMenuOpen, setOptionMenuOpen] = useState(false);
  const [refLeft, setRefLeft] = useState(undefined);
  const [refBottom, setRefBottom] = useState(undefined);

  const handleOpenOptionMenu = () => {
    setOptionMenuOpen(true);
  }

  const handleCloseOptionMenu = () => {
    setOptionMenuOpen(false);
  }

  const setBottomLeft = () => {
    if (refOptionBtn.current) {
      const clientRectColor = refOptionBtn.current.getBoundingClientRect();

      setRefLeft(clientRectColor.left + "px")
      setRefBottom(clientRectColor.bottom + "px")
    }
  }

  useEffect(() => {
    setBottomLeft();

    window.addEventListener("resize", setBottomLeft);

    return () => {
      window.removeEventListener("resize", setBottomLeft);
    }
  }, [refOptionBtn]);

  return (
    <>
      <Drawer
        className='panel-left'
        sx={{
          //width: 48,
          position: "fixed",
          zIndex: theme.zIndex.appBar,
          pointerEvents: "none",
          "& .MuiDrawer-paper": {
            zIndex: 1,
            maxHeight: `calc(100vh - ${appBarHeight}px)`,
            backgroundColor: "transparent",
            left: "30px",
            top: `${appBarHeight}px`,
            borderRight: "unset",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "space-between",
            pb: "30px"
          },
        }}
        variant="permanent"
        anchor="left"
        elevation={0}
      >
        <Box
          className="actions-center"
          sx={{
            display: "flex",
            //display: 'flex',
            flexDirection: "column",
            gap: "10px",
            pointerEvents: "auto",
          }}
        >
          {/* <IconButton
            className={`parts-icons ${previewState ? "" : "active"}`}
            aria-label="tshirt"
            size="large"
            sx={{ alignSelf: "flex-start" }}
            onClick={() => { handleSetShowMode(false) }}
          >
            <IconTshirt width="32" height="32" />
          </IconButton> */}
          {/* <IconButton
          className="parts-icons"
          aria-label="shorts"
          size="large"
          sx={{ alignSelf: "flex-start" }}
        >
          <IconShorts width="32" height="32" />
        </IconButton>
        <IconButton
          className="parts-icons"
          aria-label="socks"
          size="large"
          sx={{ alignSelf: "flex-start" }}
        >
          <IconSocks width="32" height="32" />
        </IconButton> */}

          {/* SHOW MODE HAS BEEN REFACTORED ON THE VIEWER */}

          {/* <IconButton
            className={`parts-icons ${previewState ? "active" : ""}`}
            aria-label="uniform"
            size="large"
            sx={{ alignSelf: "flex-start" }}
            onClick={() => { handleSetShowMode(true) }}
          >
            <IconUniform width="32" height="32" />
          </IconButton> */}
        </Box>
      </Drawer>

      <Drawer
        className='panel-left'
        sx={{
          //width: 48,
          position: "fixed",
          zIndex: theme.zIndex.appBar + 110,
          pointerEvents: "none",
          "& .MuiDrawer-paper": {
            zIndex: 1,
            maxHeight: `calc(100vh - ${appBarHeight}px)`,
            backgroundColor: "transparent",
            left: "30px",
            top: `${appBarHeight}px`,
            borderRight: "unset",
            gap: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "space-between",
            pb: "30px"
          },

        }}
        variant="permanent"
        anchor="left"
        elevation={0}
      >
        <Box
          className="actions-center"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            pointerEvents: "auto",
          }}
        >
          {process.env.REACT_APP_SHOW_SAVE_LOAD_BUTTONS === 'true' &&
          <IconButton
            sx={{
              display: selectDesignOpen ? "none" : "flex",
              alignSelf: "flex-start",
            }}
            className="parts-icons"
            aria-label="layers"
            size="large"
            onClick={handleDownload}
          >
            <DownloadIcon sx={{ width: "32px", height: "32px"}}/>
            {/* <IconLayers width="32" height="32" /> */}
          </IconButton>
          }

          {/* BUTTON OPTIONS */}

          <IconButton
            sx={{
              alignSelf: "flex-start",
              display: !openProducts && selectDesignOpen ? "flex" : "none",
             }}
            className="parts-icons open-popup-settings"
            aria-label="settings"
            size="large"
            onClick={handleOpenOptionMenu}
            ref={refOptionBtn}
          >
            <IconSettings width="32" height="32" />
          </IconButton>

        </Box>
      </Drawer>

      <OptionPanel
        refLeft={refLeft}
        refBottom={refBottom}
        optionMenuOpen={optionMenuOpen}
        handleCloseOptionMenu={handleCloseOptionMenu}
        themeMode={themeMode}
        handleChangeThemeMode={handleChangeThemeMode}
        blur={blur}
        handleChangeBlur={handleChangeBlur}
        gridView={gridView}
        handleChangeGridView={handleChangeGridView}
      ></OptionPanel>
    </>
  );
}
