import { Box, FormControl, FormHelperText, Grid2, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography, useTheme } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { ReactComponent as IconTrash } from "../icons/icon-trash.svg"
import { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'


export default function TableSizeRow(props) {
  const theme = useTheme()
  const {
    index,
    row,
    selectedDesign,
    updateRow,
    deleteRow,
  } = props

  const [numberIsValid, setNumberIsValid] = useState(true)

  const { t } = useTranslation()

  const updateAttribute = (attribute, value) => {
    // console.log('updateAttribute', attribute, value)
    const tempRow = { ...row }
    tempRow[attribute] = value
    if (attribute === 'quantity' && value !== '') {
      let numberValue = value
      if (_.isString(value))
        numberValue = _.toNumber(value)
      tempRow.quantity = numberValue < 1 ? 1 : numberValue
      // tempRow.price = findPrice(row.size, tempRow.quantity)
    }
    if (attribute === 'size') {
      // tempRow.price = findPrice(value, tempRow.quantity)
    }
    updateRow(index, {
      name: tempRow.name,
      initials: tempRow.initials,
      number: tempRow.number,
      size: tempRow.size,
      quantity: tempRow.quantity,
      minQuantity: tempRow.minQuantity,
      price: tempRow.price,
      errorSize: tempRow.errorSize,
      errorQuantity: tempRow.errorQuantity
    })
  }

  // const findPrice = (sizeToFind, quantity) => {
  //   let price = 0
  //   for (const quantityPrice of selectedDesign.prices) {
  //     if (quantityPrice[0] <= quantity) {
  //       price = quantityPrice[1]
  //       break
  //     }
  //   }
  //   return price
  // }

  const isNumeroValido = (str) => {
    // console.log("isNumeroValido", str, str !== "" ?
    // /^[0-9]+$/.test(str) && !str.includes(",") : true)
    // Verifica se la stringa è un numero valido e non contiene lettere o simboli
    setNumberIsValid(
      str !== "" ?
        /^[0-9]+$/.test(str) && !str.includes(",") : true)
  }

  return (
    <>
      <Grid2 size={1}>
        <Box className="tab-cell">
          <Typography variant="p">{index + 1}</Typography>
        </Box>
      </Grid2>
      <Grid2 size={6}>
        <FormControl className="hasHelperText" sx={{
          width: "100%",
        }}>
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                py: "9px"
              }
            }}
            slotProps={{ inputLabel: { shrink: true } }}
            // InputLabelProps={{ shrink: true }}
            id="filled-basic"
            label=" "
            variant="filled"
            placeholder="es: Mario Rossi"
            value={row.name}
            onChange={(e) => { updateAttribute("name", e.target.value) }}
          />
          {/* <FormHelperText id="filled-basic-helper-text">
            Assistive text
          </FormHelperText> */}
        </FormControl>
      </Grid2>
      <Grid2 size={6}>
        <FormControl className="hasHelperText" sx={{
          width: "100%",
        }}>
          <TextField
            sx={{
              "& .MuiInputBase-root": {
                py: "9px"
              }
            }}
            slotProps={{ inputLabel: { shrink: true } }}
            // InputLabelProps={{ shrink: true }}
            id="filled-basic"
            label=" "
            variant="filled"
            placeholder="es: MR"
            value={row.initials}
            onChange={(e) => { updateAttribute("initials", e.target.value) }}
          />
          {/* <FormHelperText id="filled-basic-helper-text">
            Assistive text
          </FormHelperText> */}
        </FormControl>
      </Grid2>
      <Grid2 size={6}>
        <FormControl className="hasHelperText" sx={{
          width: "100%",
        }}>
          <TextField
            error={!numberIsValid}
            sx={{
              "& .MuiInputBase-root": {
                py: "9px"
              }
            }}
            slotProps={{ inputLabel: { shrink: true } }}
            // InputLabelProps={{ shrink: true }}
            id="filled-basic"
            label=" "
            variant="filled"
            placeholder="es: 00"
            value={row.number}
            onChange={(e) => {
              isNumeroValido(e.target.value)
              updateAttribute("number", e.target.value)
            }}
          />
          {!numberIsValid ?
            <FormHelperText error id="filled-basic-helper-text">
              <Typography variant="caption" sx={{
                color: theme.palette.dark.main
              }}>{t("label_error_letters_and_symbols_not_supported")}</Typography>
            </FormHelperText> : ""}
        </FormControl>

      </Grid2>
      <Grid2 size={3}>
        <FormControl
          variant="filled"
          className="FormControlSelect hasHelperText"
          sx={{
            width: "100%",
            ">.MuiInputBase-root": {
              py: "7px"
            },
          }}
          error={row.errorSize}
        >
          <InputLabel id="select-label"></InputLabel>
          <Select
            labelId="select-label"
            id="select"
            value={row.size}
            label=" "
            onChange={(e) => updateAttribute("size", e.target.value)}
            sx={{
              "& .MuiSelect-select .notranslate::after": {
                //content: `"${placeholder}"`,
                content: `"Scegli"`,
                opacity: "1 !important",
                color: theme.palette.white.o07,
              },
            }}
          >
            <MenuItem value="">
              <em>{t("label_chose")}</em>
            </MenuItem>
            {selectedDesign.sizes.map(size => (
              <MenuItem
                key={size.variantName}
                value={size.variantName}
              >
                {size.variantName}
              </MenuItem>
            ))
            }
            {/* <MenuItem value={"2XS"}>2XS</MenuItem>
            <MenuItem value={"XS"}>XS</MenuItem>
            <MenuItem value={"S"}>S</MenuItem>
            <MenuItem value={"M"}>M</MenuItem>
            <MenuItem value={"L"}>L</MenuItem>
            <MenuItem value={"XL"}>XL</MenuItem>
            <MenuItem value={"2XL"}>2XL</MenuItem>
            <MenuItem value={"3XL"}>3XL</MenuItem> */}
          </Select>
        </FormControl>
      </Grid2>
      <Grid2 size={3}>
        <Tooltip title={'Quantità minima: ' + row.minQuantity} placement="top">
          <Box sx={{
            position: "relative",
            display: "block",
            width: "100%"
          }}>
            <FormControl className="hasHelperText" sx={{
              width: "100%",
            }}>
              <TextField
                sx={{
                  "& .MuiInputBase-root": {
                    py: "9px",
                    "& input": {
                      textAlign: "center"
                    }
                  }
                }}
                slotProps={{
                  inputLabel: { shrink: true },
                  htmlInput: {
                    step: 1,
                    min: 1,
                    max: 100,
                    type: "number",
                    // textAlign: "center"
                  }
                }}
                // InputLabelProps={{ shrink: true }}
                id="filled-basic"
                label=" "
                variant="filled"
                value={row.quantity}
                onChange={(e) => { updateAttribute("quantity", e.target.value) }}
                error={row.errorQuantity}
              >
              </TextField>
            </FormControl>
            <IconButton sx={{
              zIndex: 100,
              position: "absolute",
              right: "10px",
              width: "20px",
              height: "20px",
              top: "50%",
              transform: "translate(0, -50%)",
              "&.MuiIconButton-root": {
                background: "transparent"
              }
            }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                updateAttribute("quantity", row.quantity + 1)
              }}
              size="small">
              <AddIcon fontSize="inherit" />
            </IconButton>
            <IconButton sx={{
              zIndex: 100,
              position: "absolute",
              left: "10px",
              width: "20px",
              height: "20px",
              top: "50%",
              transform: "translate(0, -50%)",
              "&.MuiIconButton-root": {
                background: "transparent"
              }
            }}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                updateAttribute("quantity", (row.quantity > 1 ? row.quantity - 1 : 1))
              }}
              size="small">
              <RemoveIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </Tooltip>
      </Grid2>
      {/* <Grid2 size={{ xs: 3 }}>
        <Tooltip title={t("label_unit_price_in_euro") +" " + row.price} placement="top">
          <Box className="tab-cell">
            <Typography variant="p" align="right" sx={{
              width: "100%",
            }}>
              <span>€</span>{Number(row.price * row.quantity).toLocaleString('it-IT')}
            </Typography>
          </Box>
        </Tooltip>
      </Grid2> */}
      <Grid2>
        <Box sx={{
          px: "0",
          minHeight: "59px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "flex-end"
        }}>
          <IconButton size="small"
            onClick={() => { deleteRow(index) }}
            sx={{
              flexShrink: 0,
              "&.MuiIconButton-root": {
                backgroundColor: "transparent",
              },
              svg: {
                height: "20px",
                width: "20px",
                fill: theme.palette.white.main
              }
            }}>
            <IconTrash />
          </IconButton>
        </Box>
      </Grid2>
    </>
  )
}
