import { closeSnackbar, useSnackbar } from "notistack"
import { useEffect } from "react"
import { snackbarVariants } from "../../helpers/layout"
import { useTranslation } from "react-i18next"

export default function OfflineNotifier() {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    let snackbarId
    const updateStatus = event => {
      if (navigator.onLine) {
        if (snackbarId !== undefined) {
          closeSnackbar(snackbarId)
        }
      }
      else {
        snackbarId = enqueueSnackbar(
          t('offline_notifier_snack_message'),
          {
            variant: snackbarVariants.error,
            persist: true
          }
        )
      }
    }
    window.addEventListener('online', updateStatus)
    window.addEventListener('offline', updateStatus)
    return () => {
      window.removeEventListener('online', updateStatus)
      window.removeEventListener('offline', updateStatus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar])

}
