import { Box } from "@mui/material"
import DesignGridView from "./DesignGridView"
import { useState } from "react"
import SwitchBtn from "./SwitchBtn"

export default function ProductsList(props) {
  // const theme = useTheme()
  const {
    productsList,
    handleSelectProduct
  } = props

  const [designView, setDesignView] = useState(0)

  const handleSetProduct = product => handleSelectProduct(product.id)

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        // justifyContent: "center",
        flexGrow: "2",
        overflow: "hidden",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // zIndex: theme.zIndex.appBar + 20,
        // background: theme.palette.dark.main
      }}
    >
      <SwitchBtn
        designView={designView}
        setDesignView={setDesignView}
        // className="switch-not-fixed-pos"
      />
      <DesignGridView
        designView={designView}
        designSlides={productsList}
        handleFunction={handleSetProduct}
        gridView={true}
      />
    </Box>
  )
}
