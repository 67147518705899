export const getImages = () => {
  return [
    // {
    //   name: "logo-kappa.png",
    //   url: ".imgs/logo-kappa.png",
    //   blob: "",
    //   position_standard: 1,
    //   size_x:"46.88" ,
    //   size_y: "10",
    //   angle: "0",
    //   background: false,
    //   background_color: "#FFFFFF",
    //   background_size: 0,
    //   background_angle: 1,
    //   size: "30Mb",
    //   isLocked: false,
    // }
  ]
}
