import { Box, FilledInput, FormControl, Slider, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useEffect, useState } from "react";
import ColorSelector from "./ColorSelector";
// import PaletteIcon from "@mui/icons-material/Palette";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import IconResize from "./icons/IconResize";
import Divider from "./Divider";
import PatternSelector from "./PatternSelector";
// import StarRateIcon from "@mui/icons-material/StarRate";
import ColorGradientSelector from "./ColorGradientSelector";
import ColorsListSelector from "./ColorsListSelector";
import IconOffsetX from "./icons/IconOffsetX";
import IconOffsetY from "./icons/IconOffsetY";
import { useTranslation } from "react-i18next";

export default function PanelColorPattern(props) {

  const {
    editPartPattern,
    colorsList,
    selectedPart,
    colorsInUse,
    editPartGradient,
    setEditPartGradient,
    patternSize,
    setPatternSize,
    patternAngle,
    setPatternAngle,
    patternOffsetX,
    setPatternOffsetX,
    patternOffsetY,
    setPatternOffsetY,
    handleUpdatePartColor,
    handleUpdatePartColors,
    handleUpdatePatternColor,
    handleUpdateGradientPositions,
    patternList,
    colorsAngle,
    setColorsAngle,
    colorSelected,
    setColorselected,
    handleUpdatePattern,
  } = props;

  const theme = useTheme();

  const { t } = useTranslation();

  const handleSingleColor = () => {
    selectedPart.color2 = "";
    setEditPartGradient(false);
    handleUpdatePartColor(selectedPart.color);
  }

  const handleGradientColor = () => {
    setEditPartGradient(true);
    selectedPart.color2 = selectedPart.color;
  }

  const [gradientPosition, setGradientPosition] = useState([selectedPart.colorPosition, selectedPart.color2Position]);

  const minDistanceGradient = 10;

  const handleChangeGradient = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (newValue[1] - newValue[0] < minDistanceGradient) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistanceGradient);
        setGradientPosition([clamped, clamped + minDistanceGradient]);
      } else {
        const clamped = Math.max(newValue[1], minDistanceGradient);
        setGradientPosition([clamped - minDistanceGradient, clamped]);
      }
    } else {
      setGradientPosition(newValue);
    }
  };

  useEffect(() => {
    handleUpdateGradientPositions(gradientPosition[0], gradientPosition[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradientPosition]);

  return (
    <Box
      id="edit-gradient"
      sx={{
        display: "block",
        pb: "10px",
        "&.panel-disabled .panel-content, & .panel-disabled.panel-content": {
          opacity: 0.3,
          cursor: "default",
          pointerEvents: "none",
          "& .active": {
            background: "transparent"
          }
        }
      }}
    >

      {/* PANEL HEADER*/}

      <Box
        className="panel-header"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >

        {/* NAME OF PART */}

        <Typography variant="h4" sx={{ marginRight: "auto" }}>
          {t(selectedPart.label)}
        </Typography>

        {/* BUTTON TO SWITCH BETWENN SINGLE COLOR AND GRADIENT */}
        {editPartPattern ? "" :
          <Box
            sx={{
              display: "flex",
              gap: "5px",
            }}
          >
            <ColorSelector
              className={"color-selected color-selector color-selector-s " + (editPartGradient ? "" : "active")}
              sample="#FFFFFF"
              onClick={handleSingleColor}
            ></ColorSelector>
            <ColorSelector
              className={"gradient-selected color-selector color-selector-s " + (editPartGradient ? "active" : "")}
              sample=""
              onClick={handleGradientColor}
            ></ColorSelector>
          </Box>
        }

      </Box>

      {/* SELECT PATTERN */}
      {editPartPattern ?
        <>
          <Box className="panel-content">
            <Box className="pattern-container">
              {
                patternList.map((pattern, index) => {
                  return (
                    <PatternSelector
                      key={"pattern_" + index}
                      name={pattern.label}
                      onClick={() => { handleUpdatePattern(pattern.label === "no-pattern" ? null : pattern.label, process.env.REACT_APP_FRONTEND_ASSETS + pattern.src) }}
                      className={"pattern-selector " + (pattern.label === selectedPart.pattern || (pattern.label === "no-pattern" && !selectedPart.pattern) ? "active" : "")}
                      imgsrc={process.env.REACT_APP_FRONTEND_ASSETS + pattern.icon}

                    />)
                })
              }
            </Box>
          </Box>
          <Box className="panel-divider">
            <Divider bgcolor={theme.palette.white.o015}></Divider>
          </Box>
        </>
        : ""
      }


      {/* GRADIENT SLIDER */}

      {
        !editPartPattern && editPartGradient ?
          <>
            <ColorGradientSelector
              selectedPart={selectedPart}
              gradientPosition={gradientPosition}
              handleChangeGradient={handleChangeGradient}
              setColorselected={setColorselected}
              colorSelected={colorSelected}
            />

            <Box className="panel-divider">
              <Divider bgcolor={theme.palette.white.o015}></Divider>
            </Box>
          </> : ""
      }

      {/* COLORS LIST SELECTOR */}
      {
        editPartPattern ?
          <ColorsListSelector
            disable={!selectedPart.pattern}
            colorsInUse={colorsInUse}
            colorsList={colorsList}
            color={selectedPart.patternColor}
            color2={""}
            handleUpdateColor={handleUpdatePatternColor}
            handleUpdateColors={handleUpdatePartColors}
            editGradient={false}
          />
          :
          <ColorsListSelector
            colorsInUse={colorsInUse}
            colorsList={colorsList}
            color={selectedPart.color}
            color2={selectedPart.color2}
            handleUpdateColor={handleUpdatePartColor}
            handleUpdateColors={handleUpdatePartColors}
            editGradient={editPartGradient}
          />
      }

      <>
        {/* ANGLE GRADIENT */}

        {
          !editPartPattern && editPartGradient ?
            <>
              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>
              <Box className="panel-content">
                <Box className="list-item">
                  <RotateRightIcon
                    sx={{ color: theme.palette.white.main, marginRight: "5px" }}
                    fontSize="20px"
                  />
                  <Slider
                    className="custom-slider"
                    value={colorsAngle}
                    onChange={(e, value) => { setColorsAngle(value) }}
                    sx={{ maxWidth: "calc(100% - 149px)" }}
                    max={360}
                    min={0}
                    step={0.1}
                  />
                  <FormControl variant="filled">
                    <FilledInput
                      className="MuiInput-number"
                      id="filled-adornment-deg"
                      endAdornment={"°"}
                      size="small"
                      value={colorsAngle}
                      onChange={(e) => { setColorsAngle(Number(e.target.value)) }}
                      inputProps={{
                        step: 0.1,
                        min: 0,
                        max: 360,
                        type: "number",
                        "aria-label": "deg",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
            </> : ""
        }
        {/*  PATTERN CUSTOMIZE */}
        {
          editPartPattern ?
            <>
              {/* ANGLE PATTERN */}
              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>
              <Box className={!selectedPart.pattern ? "panel-content  panel-disabled" : "panel-content"}>
                <Box className="list-item">
                  <IconResize className="mr-5"/>
                  <Slider
                    className="custom-slider"
                    value={patternSize}
                    onChange={(e, value) => { setPatternSize(value) }}
                    aria-labelledby="input-slider"
                    sx={{ maxWidth: "calc(100% - 149px)" }}
                    step={0.5}
                    min={0.5}
                    max={5}
                  />
                  <FormControl variant="filled">
                    <FilledInput
                      className="MuiInput-number"
                      id="filled-adornment-resize"
                      size="small"
                      placeholder="000.00"
                      value={patternSize}
                      onChange={(e) => { setPatternSize(Number(e.target.value)) }}
                      inputProps={{
                        step: 0.5,
                        min: 0.5,
                        max: 5,
                        type: "number",
                        "aria-label": "resize",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              {/* CUSTOMIZE ORIENTATION  PATTERN */}

              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>
              <Box className={!selectedPart.pattern ? "panel-content  panel-disabled" : "panel-content"}>
                <Box className="list-item">
                  <RotateRightIcon
                    sx={{
                      color: theme.palette.white.main,
                      marginRight: "5px",
                      fontSize: "20px"
                    }}
                  />
                  <Slider
                    className="custom-slider"
                    value={patternAngle}
                    onChange={(e, value) => { setPatternAngle(value) }}
                    sx={{ maxWidth: "calc(100% - 149px)" }}
                    max={360}
                    min={0}
                    step={5}
                  />
                  <FormControl variant="filled">
                    <FilledInput
                      className="MuiInput-number"
                      id="filled-adornment-deg"
                      endAdornment={"°"}
                      size="small"
                      value={patternAngle}
                      onChange={(e) => { setPatternAngle(Number(e.target.value)) }}
                      inputProps={{
                        step: 5,
                        min: 0,
                        max: 360,
                        type: "number",
                        "aria-label": "deg",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              {/* OFFSETX PATTERN */}
              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>
              <Box className={!selectedPart.pattern ? "panel-content  panel-disabled" : "panel-content"}>
                <Box className="list-item">
                  <IconOffsetX className="mr-5"/>
                  <Slider
                    className="custom-slider"
                    value={patternOffsetX}
                    onChange={(e, value) => { setPatternOffsetX(value) }}
                    aria-labelledby="input-slider"
                    sx={{ maxWidth: "calc(100% - 149px)" }}
                    step={0.05}
                    min={0}
                    max={1}
                  />
                  <FormControl variant="filled">
                    <FilledInput
                      className="MuiInput-number"
                      id="filled-adornment-resize"
                      size="small"
                      placeholder="000.00"
                      value={patternOffsetX}
                      onChange={(e) => { setPatternOffsetX(Number(e.target.value)) }}
                      inputProps={{
                        step: 0.05,
                        min: 0,
                        max: 1,
                        type: "number",
                        "aria-label": "resize",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>

              {/* OFFSETY PATTERN */}
              <Box className="panel-divider">
                <Divider bgcolor={theme.palette.white.o015}></Divider>
              </Box>
              <Box className={!selectedPart.pattern ? "panel-content  panel-disabled" : "panel-content"}>
                <Box className="list-item">
                  <IconOffsetY className="mr-5"/>
                  <Slider
                    className="custom-slider"
                    value={patternOffsetY}
                    onChange={(e, value) => { setPatternOffsetY(value) }}
                    aria-labelledby="input-slider"
                    sx={{ maxWidth: "calc(100% - 149px)" }}
                    step={0.05}
                    min={0}
                    max={1}
                  />
                  <FormControl variant="filled">
                    <FilledInput
                      className="MuiInput-number"
                      id="filled-adornment-resize"
                      size="small"
                      placeholder="000.00"
                      value={patternOffsetY}
                      onChange={(e) => { setPatternOffsetY(Number(e.target.value)) }}
                      inputProps={{
                        step: 0.05,
                        min: 0,
                        max: 1,
                        type: "number",
                        "aria-label": "resize",
                      }}
                    />
                  </FormControl>
                </Box>
              </Box>
            </> : ""
        }
      </>
    </Box>

  )
}
