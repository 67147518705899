export const getPatterns = () => {
  return [
    {
      label: "no-pattern",
      src: "imgs/no-pattern.png",
      icon: "imgs/no-pattern.png"
    },
    {
      label: "PATTERN_1",
      src: "viewer3d-static/svg/PATTERN 1.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_1.svg"
    },
    {
      label: "PATTERN_2",
      src: "viewer3d-static/svg/PATTERN 2.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_2.svg"
    },
    {
      label: "PATTERN_3",
      src: "viewer3d-static/svg/PATTERN 3.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_3.svg"
    },
    {
      label: "PATTERN_4",
      src: "viewer3d-static/svg/PATTERN 4.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_4.svg"
    },
    {
      label: "PATTERN_5",
      src: "viewer3d-static/svg/PATTERN 5.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_5.svg"
    },
    {
      label: "PATTERN_6",
      src: "viewer3d-static/svg/PATTERN 6.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_6.svg"
    },
    {
      label: "PATTERN_7",
      src: "viewer3d-static/svg/PATTERN 7.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_7.svg"
    },
    {
      label: "PATTERN_8",
      src: "viewer3d-static/svg/PATTERN 8.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_8.svg"
    },
    {
      label: "PATTERN_9",
      src: "viewer3d-static/svg/PATTERN 9.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_9.svg"
    },
    {
      label: "PATTERN_10",
      src: "viewer3d-static/svg/PATTERN 10.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_10.svg"
    },
    {
      label: "PATTERN_11",
      src: "viewer3d-static/svg/PATTERN 11.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_11.svg"
    },
    {
      label: "PATTERN_12",
      src: "viewer3d-static/svg/PATTERN 12.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_12.svg"
    },
    {
      label: "PATTERN_13",
      src: "viewer3d-static/svg/PATTERN 13.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_13.svg"
    },
    {
      label: "PATTERN_14",
      src: "viewer3d-static/svg/PATTERN 14.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_14.svg"
    },
    {
      label: "PATTERN_15",
      src: "viewer3d-static/svg/PATTERN 15.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_15.svg"
    },
    {
      label: "PATTERN_16",
      src: "viewer3d-static/svg/PATTERN 16.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_16.svg"
    },
    {
      label: "PATTERN_17",
      src: "viewer3d-static/svg/PATTERN 17.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_17.svg"
    },
    {
      label: "PATTERN_18",
      src: "viewer3d-static/svg/PATTERN 18.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_18.svg"
    },
    {
      label: "PATTERN_19",
      src: "viewer3d-static/svg/PATTERN 19.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_19.svg"
    },
    {
      label: "PATTERN_20",
      src: "viewer3d-static/svg/PATTERN 20.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_20.svg"
    },
    {
      label: "PATTERN_21",
      src: "viewer3d-static/svg/PATTERN 21.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_21.svg"
    },
    {
      label: "PATTERN_22",
      src: "viewer3d-static/svg/PATTERN 22.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_22.svg"
    },
    {
      label: "PATTERN_23",
      src: "viewer3d-static/svg/PATTERN 23.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_23.svg"
    },
    {
      label: "PATTERN_24",
      src: "viewer3d-static/svg/PATTERN 24.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_24.svg"
    },
    {
      label: "PATTERN_25",
      src: "viewer3d-static/svg/PATTERN 25.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_25.svg"
    },
    {
      label: "PATTERN_26",
      src: "viewer3d-static/svg/PATTERN 26.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_26.svg"
    },
    {
      label: "PATTERN_27",
      src: "viewer3d-static/svg/PATTERN 27.svg",
      icon: "viewer3d-static/svg/icon/PATTERN_27.svg"
    }//,
    // {
    //   label: "PATTERN_30",
    //   src: "viewer3d-static/svg/PATTERN 30.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_30.svg"
    // },
    // {
    //   label: "PATTERN_33",
    //   src: "viewer3d-static/svg/PATTERN 33.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_33.svg"
    // },
    // {
    //   label: "PATTERN_36",
    //   src: "viewer3d-static/svg/PATTERN 36.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_36.svg"
    // },
    // {
    //   label: "PATTERN_38",
    //   src: "viewer3d-static/svg/PATTERN 38.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_38.svg"
    // },
    // {
    //   label: "PATTERN_39",
    //   src: "viewer3d-static/svg/PATTERN 39.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_39.svg"
    // },
    // {
    //   label: "PATTERN_42",
    //   src: "viewer3d-static/svg/PATTERN 42.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_42.svg"
    // },
    // {
    //   label: "PATTERN_47",
    //   src: "viewer3d-static/svg/PATTERN 47.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_47.svg"
    // },
    // {
    //   label: "PATTERN_53",
    //   src: "viewer3d-static/svg/PATTERN 53.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_53.svg"
    // },
    // {
    //   label: "PATTERN_55",
    //   src: "viewer3d-static/svg/PATTERN 55.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_55.svg"
    // },
    // {
    //   label: "PATTERN_59",
    //   src: "viewer3d-static/svg/PATTERN 59.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_59.svg"
    // },
    // {
    //   label: "PATTERN_60",
    //   src: "viewer3d-static/svg/PATTERN 60.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_60.svg"
    // },
    // {
    //   label: "PATTERN_62",
    //   src: "viewer3d-static/svg/PATTERN 62.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_62.svg"
    // },
    // {
    //   label: "PATTERN_63",
    //   src: "viewer3d-static/svg/PATTERN 63.svg",
    //   icon: "viewer3d-static/svg/icon/PATTERN_63.svg"
    // }
  ]
}
