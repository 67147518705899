import { Box, Tooltip } from "@mui/material";

export default function ColorSelector(props) {

  const {
    className,
    onClick,
    sample,
    label
  } = props;

  return (
    <Tooltip title={label} disableInteractive>
      <Box className={className} onClick={onClick}>
        <Box className="color-inner" sx={{ backgroundColor: sample }}></Box>
      </Box>
    </Tooltip>
  );
}
