export const getColors = () => {
  return [
    { label: "Lemon", color: "#FCFF40", cmyk: { c: 5, m: 0, y: 89, k: 0 } },
    { label: "Yellow", color: "#FFE400", cmyk: { c: 0, m: 6, y: 95, k: 0 } },
    { label: "Dark Yellow", color: "#C99C00", cmyk: { c: 0, m: 26, y: 100, k: 26 } },
    { label: "Ochre", color: "#FBBA00", cmyk: { c: 0, m: 30, y: 100, k: 0 } },
    { label: "Melon", color: "#F8AC30", cmyk: { c: 0, m: 38, y: 86, k: 0 } },
    { label: "Light Orange", color: "#F39200", cmyk: { c: 0, m: 50, y: 100, k: 0 } },
    { label: "Orange", color: "#E35917", cmyk: { c: 0, m: 75, y: 96, k: 4 } },
    { label: "Dark Orange", color: "#BF340A", cmyk: { c: 0, m: 87, y: 100, k: 22 } },
    { label: "Salmon", color: "#F0856A", cmyk: { c: 0, m: 59, y: 56, k: 0 } },
    { label: "Dark Salmon", color: "#D5533C", cmyk: { c: 0, m: 76, y: 73, k: 12 } },
    { label: "Pink", color: "#FFACC2", cmyk: { c: 0, m: 47, y: 20, k: 0 } },
    { label: "Dark Pink", color: "#EE7178", cmyk: { c: 0, m: 68, y: 41, k: 0 } },
    { label: "Orange Red", color: "#E42313", cmyk: { c: 0, m: 95, y: 100, k: 0 } },
    { label: "Brillian Red", color: "#E40138", cmyk: { c: 0, m: 100, y: 72, k: 0 } },
    { label: "Red", color: "#CF0925", cmyk: { c: 0, m: 100, y: 83, k: 12 } },
    { label: "Burgundy Red", color: "#820C18", cmyk: { c: 0, m: 100, y: 75, k: 55 } },
    { label: "Red Wine", color: "#62051E", cmyk: { c: 5, m: 100, y: 50, k: 70 } },
    { label: "Light Fuchsia", color: "#FD7DAD", cmyk: { c: 3, m: 63, y: 1, k: 0 } },
    { label: "Fuchsia", color: "#FF3C79", cmyk: { c: 3, m: 89, y: 0, k: 0 } },
    { label: "Dark Fuchsia", color: "#B02B6C", cmyk: { c: 3, m: 89, y: 0, k: 30 } },
    { label: "Lilac", color: "#9E75B2", cmyk: { c: 45, m: 60, y: 0, k: 0 } },
    { label: "Dark Lilac", color: "#7B4997", cmyk: { c: 63, m: 80, y: 0, k: 0 } },
    { label: "Lavanda", color: "#9789BF", cmyk: { c: 58, m: 48, y: 0, k: 0 } },
    { label: "Dark Lavanda", color: "#655292", cmyk: { c: 77, m: 68, y: 0, k: 0 } },
    { label: "Sky", color: "#86D0F4", cmyk: { c: 49, m: 1, y: 0, k: 0 } },
    { label: "Dark Sky", color: "#50B5EB", cmyk: { c: 70, m: 0, y: 10, k: 0 } },
    { label: "Turquoise", color: "#0090D7", cmyk: { c: 85, m: 0, y: 15, k: 0 } },
    { label: "Brilliant Royal", color: "#0082E7", cmyk: { c: 90, m: 11, y: 0, k: 0 } },
    { label: "Light Royal", color: "#0052D6", cmyk: { c: 100, m: 15, y: 0, k: 0 } },
    { label: "Royal", color: "#003EAB", cmyk: { c: 100, m: 50, y: 0, k: 0 } },
    { label: "Dark Royal", color: "#0B3371", cmyk: { c: 100, m: 62, y: 0, k: 52 } },
    { label: "Light Navy", color: "#0B2659", cmyk: { c: 100, m: 78, y: 0, k: 54 } },
    { label: "Ocean Navy", color: "#132354", cmyk: { c: 100, m: 78, y: 45, k: 65 } },
    { label: "Navy", color: "#0F182C", cmyk: { c: 100, m: 85, y: 45, k: 70 } },
    { label: "Violet", color: "#574596", cmyk: { c: 79, m: 80, y: 0, k: 0 } },
    { label: "Dark Violet", color: "#472975", cmyk: { c: 100, m: 92, y: 0, k: 10 } },
    { label: "Green", color: "#04A64B", cmyk: { c: 80, m: 0, y: 90, k: 0 } },
    { label: "Dark Green", color: "#007030", cmyk: { c: 90, m: 0, y: 95, k: 40 } },
    { label: "British Green", color: "#003813", cmyk: { c: 95, m: 0, y: 95, k: 80 } },
    { label: "Forest Green", color: "#00655C", cmyk: { c: 86, m: 0, y: 64, k: 62 } },
    { label: "Dark Forest", color: "#00504D", cmyk: { c: 100, m: 0, y: 81, k: 66 } },
    { label: "Marine", color: "#9DD0B4", cmyk: { c: 44, m: 0, y: 37, k: 0 } },
    { label: "Dark Marine", color: "#58997C", cmyk: { c: 60, m: 0, y: 50, k: 27 } },
    { label: "Octanio", color: "#3CB3B6", cmyk: { c: 68, m: 0, y: 43, k: 3 } },
    { label: "Sea Green", color: "#009097", cmyk: { c: 84, m: 0, y: 56, k: 0 } },
    { label: "Dark Sea Green", color: "#006779", cmyk: { c: 100, m: 0, y: 67, k: 29 } },
    { label: "Caribbean", color: "#1688AF", cmyk: { c: 87, m: 0, y: 38, k: 0 } },
    { label: "Dark Caribbean", color: "#00668A", cmyk: { c: 100, m: 0, y: 53, k: 25 } },
    { label: "Lime", color: "#DDDE3A", cmyk: { c: 20, m: 0, y: 85, k: 0 } },
    { label: "Dark Lime", color: "#B9C526", cmyk: { c: 30, m: 0, y: 92, k: 10 } },
    { label: "Lime Green", color: "#8ABD24", cmyk: { c: 54, m: 0, y: 100, k: 0 } },
    { label: "Dark Lime Green", color: "#55841E", cmyk: { c: 60, m: 0, y: 100, k: 38 } },
    { label: "Avocado Green", color: "#C0D77A", cmyk: { c: 32, m: 0, y: 64, k: 0 } },
    { label: "Dark Avocado", color: "#758F46", cmyk: { c: 42, m: 0, y: 74, k: 40 } },
    { label: "White", color: "#FFFFFF", cmyk: { c: 0, m: 0, y: 0, k: 0 } },
    { label: "Light Silver", color: "#DADADA", cmyk: { c: 0, m: 0, y: 0, k: 20 } },
    { label: "Silver", color: "#C6C6C6", cmyk: { c: 0, m: 0, y: 0, k: 30 } },
    { label: "Light Anthracite", color: "#868C8C", cmyk: { c: 0, m: 0, y: 0, k: 60 } },
    { label: "Anthracite", color: "#464D4E", cmyk: { c: 0, m: 0, y: 3, k: 78 } },
    { label: "Black", color: "#1D1D1B", cmyk: { c: 0, m: 0, y: 0, k: 100 } },
    { label: "Army Green", color: "#597B5F", cmyk: { c: 64, m: 28, y: 62, k: 26 } },
    { label: "Dark Army", color: "#263D2E", cmyk: { c: 66, m: 28, y: 62, k: 74 } },
    { label: "Olive Green", color: "#6D672C", cmyk: { c: 65, m: 55, y: 100, k: 10 } },
    { label: "Dark Olive", color: "#566116", cmyk: { c: 70, m: 55, y: 100, k: 60 } },
    { label: "Brown", color: "#563218", cmyk: { c: 60, m: 80, y: 100, k: 45 } },
    { label: "Dark Brown", color: "#2D1801", cmyk: { c: 60, m: 80, y: 100, k: 80 } },
    { label: "Sand", color: "#DEC89F", cmyk: { c: 16, m: 21, y: 42, k: 0 } },
    { label: "Dark Sand", color: "#BDAC8A", cmyk: { c: 16, m: 21, y: 42, k: 20 } },
    { label: "Antique Gold", color: "#BE9D57", cmyk: { c: 30, m: 37, y: 73, k: 2 } },
    { label: "Dark Gold", color: "#8E743C", cmyk: { c: 35, m: 42, y: 78, k: 30 } },
    { label: "Grey", color: "#C8D2DC", cmyk: { c: 13, m: 3, y: 0, k: 17 } },
    { label: "Dark Grey", color: "#6F90A7", cmyk: { c: 42, m: 8, y: 0, k: 40 } },
    { label: "Yellow Fluo", color: "#FFFF00", cmyk: { c: 0, m: 0, y: 0, k: 0 } },
    { label: "Fuchsia Fluo", color: "#E4348B", cmyk: { c: 0, m: 0, y: 0, k: 0 } },
    { label: "Green Fluo", color: "#73E573", cmyk: { c: 0, m: 0, y: 0, k: 0 } },
    { label: "Orange Fluo", color: "#FF8500", cmyk: { c: 0, m: 0, y: 0, k: 0 } },
    { label: "Light Cyan", color: "#00AAD2", cmyk: { c: 0, m: 0, y: 0, k: 0 } },
  ]
};


export const getBrandLogoColors = () => {
  return [
    { label: "White", color: "#FFFFFF", cmyk: { c: 0, m: 0, y: 0, k: 0 } },
    { label: "Black", color: "#1D1D1B", cmyk: { c: 0, m: 0, y: 0, k: 100 } },
    { label: "Red", color: "#CF0925", cmyk: { c: 0, m: 100, y: 83, k: 12 } },
    { label: "Grey", color: "#C8D2DC", cmyk: { c: 13, m: 3, y: 0, k: 17 } },
  ]
}
