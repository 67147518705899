import { Box, IconButton, Typography, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function PanelBoxRef(props) {

  const {
    refTop,
    refBottom,
    refRight,
    refLeft,
    open,
    handleClose,
    children,
    title
  } = props;

  const theme = useTheme();

  return (
    <Box
      top={refTop ? refTop : refBottom}

      left={refLeft ? refLeft : refRight}
      sx={{
        position:"absolute",
        padding: "10px 20px",
        borderRadius: "20px",
        transform: `translate( ${refLeft ? "0": "-100%"}, ${refTop ? "0" : "-100%"})`,
        backgroundColor: theme.palette.black.o05,
        backdropFilter: theme.palette.filter.blur50,
        display: open ? "flex" : "none",
        flexDirection:"column",
        zIndex: theme.zIndex.drawer + 10,
        minWidth:"310px"
      }}>
        <Box sx={{
          display:"flex",
          gap: 2,
          alignItems:"center",
          justifyContent:"space-between",
          py: "10px"
        }}>
          <Typography variant="h4">{title}</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon fontSize="iherit"/>
          </IconButton>
        </Box>
        <Box sx={{
          flexGrow:"2"
        }}>
          {children}
        </Box>
    </Box>
  )
}
