import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import ProductDesignsList from "../components/ProductDesignsList"
import { selectProduct } from "../slices/productSlice"
import { useEffect, useState } from "react"
import PageLayout from "../components/layout/PageLayout"
import { Box, IconButton, Typography, useTheme } from "@mui/material"
import { toConfigurationPage, toHomePage } from "../slices/navigationSlice"
import { setPageTitle } from "../slices/appSlice"
import { useTranslation } from "react-i18next"
import ModalConfirmation from "../components/ModalConfirmation"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function DesignsPage() {
  const { productId } = useParams()
  const dispatch = useDispatch()
  const productState = useSelector(state => state.product)
  const dispatchSelectProduct = productId => dispatch(selectProduct(productId))
  const dispatchToConfigurationPage = designId => dispatch(toConfigurationPage(productId, designId))
  const dispatchSetPageTitle = title => dispatch(setPageTitle(title))
  const dispatchToProductsPage = () => dispatch(toHomePage())
  const { t } = useTranslation()

  const [modalResetAndGoToProductsOpen, setModalResetAndGoToProductsOpen] = useState(false);

  const theme = useTheme()

  useEffect(() => {
    dispatchSelectProduct(productId)
    dispatchSetPageTitle("page_designs_title")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productState.products])

  if (productState.productCheckFulfilled) {
    return (
      <PageLayout>
        <Box
        className="btn-back"
        sx={{
          position: "absolute",
          top: '90px',
          left: '20px',
          display: 'flex',
          flexDirection: "row",
          gap: "20px",
          alignItems: 'flex-start',
          zIndex: theme.zIndex.appBar + 100,
        }}>
          <IconButton
            onClick={() => setModalResetAndGoToProductsOpen(true)}
            sx={{
              display: 'flex',
              flexShrink: 0,
              flexGrow: 1,
            }}>
            <ArrowBackIcon />
          </IconButton>
        </Box>

        {productState.selectedProductId ?
          <ProductDesignsList
            initialSlide={productState.initialSlide}
            designsList={productState.designs}
            handleSelectDesign={dispatchToConfigurationPage}
          />
          :
          <Box sx={{
            pt: 12,
            display: "flex",
            flexGrow: 1,
            justifyContent: 'center'
          }}>
            <Typography>
              {t("label_product")+" "+productId+" "+t("label_doesnt_exist") }
            </Typography>
          </Box>
        }
        <ModalConfirmation
          isOpen={modalResetAndGoToProductsOpen}
          title={"modal_title_change_product"}
          description={"modal_description_change_product"}
          handleClose={() => { setModalResetAndGoToProductsOpen(false) }}
          handleContinue={() => { setModalResetAndGoToProductsOpen(false); dispatchToProductsPage(); }}
        ></ModalConfirmation>
      </PageLayout>
    )
  }
}
