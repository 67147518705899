import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconWidth = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 -960 960 960"
        width="20"
      >
        <path d="M288-336 144-480l144-144 51 51-57 57h396l-57-57 51-51 144 144-144 144-51-51 57-57H282l57 57-51 51Z" />
      </svg>
    </SvgIcon>
  );
};
export default IconWidth;
