export const appBarHeight = 90
export const currency = "€"

export const themeModes = {
  light: 'light',
  dark: 'dark'
}

export const snackbarVariants = {
  default: 'default',
  error: 'error',
  success: 'success',
  warning: 'warning',
  info: 'info'
}

export const defaultImage = {
  name: "",
  src: null,
  position_standard: null,
  size_x: "10",
  size_y: "10",
  size_ratio: 1,
  angle: "0",
  background: false,
  background_color: "#FFFFFF",
  background_size: 0,
  background_angle: "miter",
  isMirrorable: false,
  hasLink: null,
  isImage: true,
  isText: false,
  isLocked: false,
}

export const defaultText = {
  id: "",
  type: "free",
  text: "Text",
  position_standard: null,
  font: "Alegre Sans",
  isMirrorable: false,
  size_x: "10",
  size_y: "10",
  size_ratio: 1,
  text_align: "",
  actual_position_x: "",
  actual_position_y: "",
  angle: "0",
  text_color_active: true,
  text_color: "#1D1D1B",
  border: false,
  border_color: "#FFFFFF",
  border_weight: 1.6,
  border_angle: "miter",
  border_dotted: "line",
  background: false,
  background_color: "#FFFFFF",
  background_size: 0,
  background_angle: "miter",
  isText: true,
  isImage: false,
  isLocked: false,
}
