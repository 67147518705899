import { BrowserRouter, Route, Routes } from "react-router-dom"
import { routes } from "./Routes"
import ProductsPage from "../pages/ProductsPage"
import DesignsPage from "../pages/DesignsPage"
import ConfigurationPage from "../pages/ConfigurationPage"
import NotFoundPage from "../pages/NotFoundPage"
import NavigateTo from "../components/routing/NavigateTo"
// import UIComponents from "../pages/UIComponents"

export default function AppRouter() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <NavigateTo />
      <Routes>
        <Route path={routes.home} element={<ProductsPage />} />
        <Route path={routes.designs} element={<DesignsPage />} />
        <Route path={routes.configuration} element={<ConfigurationPage />} />
        {/* <Route path={routes.uiComponents} element={<UIComponents />} /> */}
        <Route path={routes.notFound} element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  )
}
