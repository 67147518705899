import { createSlice } from '@reduxjs/toolkit'
import { setIsLoading } from './appSlice'
import api from '../api/api'
import { createProductsMap, createProductsTree, getProductsFromMap } from '../api/apiHelper'
import { setProducts, setProductsMap, setProductsTree } from './productSlice'
import i18n from '../helpers/i18n'

const initialState = {
  initFulfilled: false,
  initError: false,
  initErrorMessage: ''
}

export const initSlice = createSlice({
  name: 'init',
  initialState,
  reducers: {
    setInitFulfilled: (state, action) => {
      state.initFulfilled = action.payload
    },
    setInitError: (state, action) => {
      state.initError = action.payload
    },
    setInitErrorMessage: (state, action) => {
      state.initErrorMessage = action.payload
    }
  }
})

export const {
  setInitFulfilled,
  setInitError,
  setInitErrorMessage
} = initSlice.actions

export const initApp = () => async (dispatch, getState) => {
  try {
    const lang = i18n.resolvedLanguage
    const response = await api.getProducts(lang)
    const productsMap = createProductsMap(response)
    dispatch(setProductsMap(productsMap))
    const products = getProductsFromMap(productsMap)
    dispatch(setProducts(products))
    const productsTree = createProductsTree(productsMap)
    dispatch(setProductsTree(productsTree))
    // const colors = await api.getColors()
    // dispatch(setColorsList(colors))
    // const patterns = await api.getPatterns()
    // dispatch(setPatternList(patterns))
  }
  catch (err) {
    dispatch(setInitError(true))
    dispatch(setInitErrorMessage(err.message))
  }
  dispatch(setInitFulfilled(true))
  dispatch(setIsLoading(false))
}

export default initSlice.reducer
