import AppRouter from "./routes/AppRouter"
import { CssBaseline } from "@mui/material"
import AppSnackbarProvider from './components/AppSnackbarProvider'
import AppThemeProvider from "./components/theme/AppThemeProvider"
import OfflineNotifier from "./components/offline/OfflineNotifier"
import AuthManager from "./components/auth/AuthManager"
import InitManager from "./components/init/InitManager"
import LoaderManager from "./components/loader/LoaderManager"
import Overlay from "./components/Overlay"
import LanguageManager from "./components/lang/LanguageManager"

export default function App() {
  return (
    <AppThemeProvider>
      <CssBaseline />
      <AppSnackbarProvider>
        <OfflineNotifier />
        <LoaderManager />
        <LanguageManager>
          <AuthManager>
            <InitManager>
              <Overlay />
              <AppRouter />
            </InitManager>
          </AuthManager>
        </LanguageManager>
      </AppSnackbarProvider>
    </AppThemeProvider>
  )
}
