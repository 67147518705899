import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "./Divider";
import ColorsListSelector from "./ColorsListSelector";
import { useDispatch, useSelector } from "react-redux";
import { setBrandLogo, setBrandLogoPosition } from "../slices/appSlice";
import { viewerInstance } from "../helpers/Viewer";
import CardPlacement from "./CardPlacement";
import ButtonRound from "./ButtonRound";
export default function PanelCustomizeBrandLogo(props) {

  const {
    brandLogo,
    handleClose,
  } = props

  const { t } = useTranslation();

  const theme = useTheme();

  const appState = useSelector(state => state.app);

  const dispatch = useDispatch();



  const updateBrandLogoColor = (value) => {
    dispatch(setBrandLogo({ ...brandLogo, color: value }));
    viewerInstance.setBrandLogoColor(value);
  }

  const updateBrandLogoPosition = (el, name) => {
    const brandLogoTemp = structuredClone(brandLogo)

    brandLogoTemp.position = { x: el.unwrapCenter.u, y: el.unwrapCenter.v }
    viewerInstance.updateItem(brandLogoTemp)
    dispatch(setBrandLogo(brandLogoTemp));
    dispatch(setBrandLogoPosition(name));
  }

  return (
    <>

      {/* INTRO INFO */}

      <Box className="panel-box">
        <Box
          className="panel-header"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ marginRight: "auto" }}>
            {t("panel_title_brand_logo")}
          </Typography>
          <IconButton aria-label="add" size="small" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="panel-content">
          <Typography variant="body2" sx={{
            color: theme.palette.white.o07,
          }}>
            {t("panel_title_text_brand_logo")}
          </Typography>
        </Box>
      </Box>

      {/* BRAND LOGO OPTIONS */}

      <Box className="panel-box">
        <Box
          className="panel-header"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ marginRight: "auto" }}>
            {t("panel_title_brand_logo_options")}
          </Typography>
        </Box>
        <Box className="panel-content container-card-placement">
          {
            Object.keys(appState.positionsList["LOGO_+A_POSITION_NAME"]).map((name, index) => {
              const el = appState.positionsList["LOGO_+A_POSITION_NAME"][name];
              return (
                <CardPlacement key={"initialPosition_" + index}
                  onClick={() => { updateBrandLogoPosition(el, name) }}
                  className={"card-placement " + (appState.brandLogoPosition === name ? " active" : "")}
                  imgsrc={process.env.REACT_APP_FRONTEND_ASSETS + "placer-icon/" + appState.positionsList["PRODUCT_TYPE"] + "_" + name + ".svg"}
                  text={t("option_position_label_" + name)}
                ></CardPlacement>
              )
            })
          }
        </Box>
        <Divider bgcolor={theme.palette.white.o015}></Divider>

        <ColorsListSelector
          disable={false}
          colorsInUse={null}
          colorsList={appState.colorsList}
          color={brandLogo?.color}
          color2={false}
          handleUpdateColor={(value) => { updateBrandLogoColor(value) }}
          handleUpdateColors={() => { }}
          editGradient={false}
        />
      </Box>
      <Box className="panel-footer">
      <ButtonRound
            id="btn-confirm"
            text={t("panel_button_label_confirm")}
            variant="contained"
            className="mx-auto btn-white"
            onClick={() => { handleClose() }}
          />
          </Box>
    </>
  )

}
