import { Box, IconButton, Typography, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputUploadFile from "./InputUploadFile";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
// import { useState } from "react";

export default function PanelUploadImage(props) {

  const {
    imageSelected,
    setImageSelected,
    handleCancel,
    errorUpload,
    setErrorUpload
  } = props;

  const theme = useTheme();

  const {t} = useTranslation();


  return (
    <Box className="panel-box">
      <Box
        className="panel-header"
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ marginRight: "auto" }}>
          {t("panel_title_upload_image")}
        </Typography>
        <IconButton aria-label="add" size="small" onClick={handleCancel}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box className="panel-content" /* onClick={() =>{console.log("CLICK")}} */>

        <InputUploadFile
          imageSelected={imageSelected}
          setImageSelected={setImageSelected}
          setErrorUpload={setErrorUpload}
        />

        {errorUpload ?<Box
          className="error-upload panel-content"
          sx={{
            borderRadius: "5px",
            marginTop: "10px",
            backgroundColor: theme.palette.messages.errorBackground,
            color: theme.palette.messages.error,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <ErrorIcon/>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                marginLeft: "5px",
                color: theme.palette.messages.error,
              }}
            >
              {t("error_file_not_compliant")}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.messages.error }}
          >
            {t("error_file_description_size")}
          </Typography>
        </Box> : ""}
      </Box>
    </Box>
  )
}
