import { Box, Button, Dialog, Divider, Stack, Typography, useTheme } from "@mui/material"
import ErrorIcon from '@mui/icons-material/Error'
import { useTranslation } from "react-i18next"

export default function ModalOrderCreationFail(props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    open,
    handleCancel,
    handleRetry
  } = props

  return (
    <Dialog
      open={open}
      sx={{
        zIndex: theme.zIndex.appBar + 300,
        background: theme.palette.black.o05,
        backdropFilter: theme.palette.filter.blur50,
        "& .MuiDialog-paper": {
          background: "rgba(51, 51, 51, 0.85)",
          padding: "10px 8px",
          borderRadius: "20px",
          width: '380px',
        }
      }}
    >
      <Box sx={{
        padding: "10px 8px"
      }}>
        <Stack alignItems='center'>
          <Box
            sx={{
              height: "70px",
              width: "70px",
              backgroundColor: theme.palette.messages.error,
              borderRadius: "50%",
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <ErrorIcon sx={{ fontSize: 36 }} />
          </Box>
          <Box mt={2}>
            <Typography
              variant='body1'
              color={theme.palette.messages.error}
              sx={{ fontWeight: 600 }}
            >
              {t('modal_order_creation_fail_title')}
            </Typography>
          </Box>
          <Box mt={2} width='88%'>
            <Typography variant='body2'>
              {t('modal_order_creation_fail_description')}
            </Typography>
          </Box>
        </Stack>
        <Box mt={2}>
          <Divider />
        </Box>
        <Box
          mt={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            className="btn-text"
            onClick={handleCancel}
            color="white"
          >
            {t('modal_order_creation_fail_button_cancel')}
          </Button>
          <Button
            className="btn-white"
            variant="contained"
            onClick={handleRetry}
          // type='submit'
          >
            {t('modal_order_creation_fail_button_retry')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
