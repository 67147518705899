import { useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useSelector } from 'react-redux';

export default function ProductsTreeView(props) {

  const {
    setFilteredProducts,
    products
  } = props;

  const theme = useTheme()
  const productState = useSelector(state => state.product)

  const handleFiltering = (idProductsToKeep) => {
    const productsToKeep = []
    // console.log(products)
    for (const product of products) {
      if (idProductsToKeep.includes(product.id)) {
        productsToKeep.push(product)
      }
    }
    setFilteredProducts(productsToKeep)
  }

  return (
    <>
      {
        productState.productsTree ?

        <Box
          className="panel-box"
          sx={{
            "& .MuiTreeItem-content": {
              padding: "10px",
              borderRadius: "10px",
              gap: "10px",
              "&.Mui-selected, &.Mui-selected.Mui-focused": {
                backgroundColor: theme.palette.white.o01,
                background: theme.palette.white.o01,
                "&:hover": {
                  backgroundColor: theme.palette.white.o01,
                  background: theme.palette.white.o01
                }
              },
              "& .MuiTreeItem-label": {
                textTransform: "lowercase",
                "&:first-letter": {
                  textTransform: "uppercase"
                }
              }
            }
          }}
        >
          <SimpleTreeView>
            {
              productState.productsTree.map(category => {
                return(<TreeItem
                key={"category"+category.id}
                itemId={category.id}
                onClick={() => handleFiltering(category.products)}
                label={category.id}>
                  {
                    category.children.map(subCategory => {
                      return(<TreeItem
                      key={"subCategory"+subCategory.id}
                      itemId={category.id+subCategory.id}
                      onClick={() => handleFiltering(subCategory.products)}
                      label={subCategory.id}>
                        {
                          subCategory.children.map(subSubCategory => {
                            return(<TreeItem
                              key={"subSubCategory"+subSubCategory.id}
                              itemId={category.id+subCategory.id+subSubCategory.id}
                              onClick={() => handleFiltering(subSubCategory.products)}
                              label={subSubCategory.id}></TreeItem>)
                          })
                        }
                      </TreeItem>)
                    })
                  }
                </TreeItem>)

              })
            }
          </SimpleTreeView>
      </Box>

          : ""
      }
    </>
  )
}
