import { SnackbarProvider } from "notistack"
import SnackbarCloseButton from "./SnackbarCloseButton"
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'


export default function AppSnackbarProvider(props) {
  const { children } = props

  return (
    <SnackbarProvider
      maxSnack={3}
      dense
      action={snackKey =>
        <SnackbarCloseButton snackKey={snackKey} />
      }
      iconVariant={{
        success: <CheckCircleOutlinedIcon fontSize='small' sx={{ mr: 1 }} />,
        error: <ErrorOutlineOutlinedIcon fontSize='small' sx={{ mr: 1 }} />,
        warning: <WarningAmberOutlinedIcon fontSize='small' sx={{ mr: 1 }} />,
        info: <InfoOutlinedIcon fontSize='small' sx={{ mr: 1 }} />
      }}
      classes={{ containerRoot: 'z-alert' }}
    >
      {children}
    </SnackbarProvider>
  )
}
