import { Box, Button, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { routes } from "../routes/Routes"
import { useEffect } from "react"
import { setPageTitle } from "../slices/appSlice"
import { useDispatch } from "react-redux"

export default function NotFoundPage() {
  const theme = useTheme()
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleClick = () => navigate(routes.home)
  const dispatchSetPageTitle = title => dispatch(setPageTitle(title))

  useEffect(() => {
    dispatchSetPageTitle(null)
  });

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100svh',
        gap: "20px",
        img: {
          borderRadius: "500px",
          maxWidth: "90%",
        }
      }}
    >
      <img src={process.env.PUBLIC_URL + "/logo512.png"} alt="adrenalina logo" />
      <Typography
        component="div"
        variant="h5"
      >
        Oops, la pagina non esiste.
      </Typography>
      <Button
        variant='outlined'
        onClick={handleClick}
        sx={{ marginTop: 4 }}
      >
        Val alla pagina iniziale
      </Button>
    </Box>

  )
}
