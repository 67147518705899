import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconSliderView = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 20"
        width="20"
      >

        <path d="M2 12V8C2 7.5875 2.14729 7.23438 2.44187 6.94063C2.73646 6.64688 3.09063 6.5 3.50438 6.5C3.91813 6.5 4.27083 6.64688 4.5625 6.94063C4.85417 7.23438 5 7.5875 5 8V12C5 12.4125 4.85271 12.7656 4.55813 13.0594C4.26354 13.3531 3.90937 13.5 3.49562 13.5C3.08187 13.5 2.72917 13.3531 2.4375 13.0594C2.14583 12.7656 2 12.4125 2 12ZM7.5 15C7.04167 15 6.67708 14.8646 6.40625 14.5938C6.13542 14.3229 6 13.9583 6 13.5V6.5C6 6.04167 6.13542 5.67708 6.40625 5.40625C6.67708 5.13542 7.04167 5 7.5 5H12.5C12.9583 5 13.3229 5.13542 13.5938 5.40625C13.8646 5.67708 14 6.04167 14 6.5V13.5C14 13.9583 13.8646 14.3229 13.5938 14.5938C13.3229 14.8646 12.9583 15 12.5 15H7.5ZM15 12V8C15 7.5875 15.1473 7.23438 15.4419 6.94063C15.7365 6.64688 16.0906 6.5 16.5044 6.5C16.9181 6.5 17.2708 6.64688 17.5625 6.94063C17.8542 7.23438 18 7.5875 18 8V12C18 12.4125 17.8527 12.7656 17.5581 13.0594C17.2635 13.3531 16.9094 13.5 16.4956 13.5C16.0819 13.5 15.7292 13.3531 15.4375 13.0594C15.1458 12.7656 15 12.4125 15 12ZM7.5 13.5H12.5V6.5H7.5V13.5Z" fill="white"/>

      </svg>
    </SvgIcon>
  );
};
export default IconSliderView;
