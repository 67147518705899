import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import ButtonSmall from "./ButtonSmall";

import { useTheme } from "@mui/material";

const AccordionMessage = (props) => {
  const {
    id,
    className,
    title,
    text,
    btnText,
    onClick
  } = props;
  const theme = useTheme();
  /*
  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none"
          },
          ".expandIconWrapper": {
            display: "none"
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block"
          }
        }}
      >
        <div className="expandIconWrapper">-</div>
        <div className="collapsIconWrapper">+</div>
      </Box>
    );
  };
  */

  return (
    <Accordion
      id={id}
      className={className}
      elevation={0}
      sx={{
        backgroundColor: theme.palette.messages.suggestionBackground,
        borderRadius: "5px!important",
        transition: "none",
        "& .MuiAccordionSummary-content .MuiTypography-root, & .MuiAccordionDetails-root > .MuiTypography-root, & .MuiSvgIcon-root":
          {
            color: theme.palette.messages.suggestion,
          },
        "&.Mui-expanded": {
          marginTop: "0px",
        },
        "::before": {
          display: "none",
        },
        ":hover": {},
        "& .MuiAccordionSummary-root": {
          px: "10px",
          minHeight: "unset",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
          minHeight: "unset",
        },
        "& .MuiAccordionSummary-content.Mui-expanded": {
          my: "12px",
        },
        "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
          transform: "rotate(135deg)",
        },
        "& .MuiAccordionDetails-root .btn-small": {
          marginTop: "10px",
        },
        "& .MuiAccordionDetails-root": {
          padding: "10px",
          paddingTop: "0px",
        },
        "&.message-error": {
          backgroundColor: theme.palette.messages.errorBackground,
        },
        "&.message-error .MuiAccordionSummary-content .MuiTypography-root, &.message-error .MuiAccordionDetails-root > .MuiTypography-root, &.message-error .MuiSvgIcon-root":
          {
            color: theme.palette.messages.error,
          },
        "&.message-alert": {
          backgroundColor: theme.palette.messages.alertBackground,
        },
        "&.message-alert .MuiAccordionSummary-content .MuiTypography-root, &.message-alert .MuiAccordionDetails-root > .MuiTypography-root, &.message-alert .MuiSvgIcon-root":
          {
            color: theme.palette.messages.alert,
          },
        "&.message-confirm": {
          backgroundColor: theme.palette.messages.confirmBackground,
        },
        "&.message-confirm .MuiAccordionSummary-content .MuiTypography-root, &.message-confirm .MuiAccordionDetails-root > .MuiTypography-root, &.message-confirm .MuiSvgIcon-root":
          {
            color: theme.palette.messages.confirm,
          },
      }}
    >
      <AccordionSummary
        expandIcon={
          <AddIcon
            sx={{
              width: "20px",
              height: "20px",
            }}
          />
        }
        /* expandIcon={<CustomExpandIcon />} */
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            lineHeight: "16px",
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body2">{text}</Typography>
        {
          btnText ?
          <ButtonSmall text={btnText} className="btn-small" onClick={onClick}></ButtonSmall>
          : ""
        }
      </AccordionDetails>
    </Accordion>
  );
};
export default AccordionMessage;
