import { useEffect, useState } from "react"
import { useTheme, Box, Tabs, Tab } from "@mui/material"
import Viewer3D from "../components/Viewer3D"
import DrawerCustomizer from "../components/DrawerCustomizer"
import DrawerText from "../components/DrawerText"
import PanelLeft from "../components/PanelLeft"
import { useTranslation } from "react-i18next"
import DrawerImages from "../components/DrawerImages"
import { viewerInstance } from "../helpers/Viewer"
import TableSizes from "../components/TableSizes"
import CustomTabPanel from "../components/CustomTabPanel"
import { appBarHeight, defaultImage, defaultText } from "../helpers/layout"
import { useDispatch } from "react-redux"
import {
  setCurrentSelectedItem, setDesignView, setEditGlobalColor,
  setEditPartColor, setEditPartGradient, setEditPartPattern, setGridView,
  setImageEdit, setImageSelected, setImagesList, setIndex, setIndexImageSelected,
  setIndexTextSelected, setIsLoading, setListProduct, setOpenTablePrice, setPreviewState,
  setProductParts, setProductExtraParts, setTextEdit, setTextSelected, setTextsList, setThemeBlur,
  setThemeMode, setBrandLogo, setEditPartClone, setBrandLogoEdit,
  handleUpdateDesignParts, setEditExtraPart,
  handleSetDesign,
  setModalOrderCreationSuccessOpened,
  setModalOrderCreationFailOpened
} from "../slices/appSlice"
import { addToCart, setLoadedConfiguration } from "../slices/productSlice"
import { toDesignsPage, toHomePage } from "../slices/navigationSlice"
import ModalOrderCreationSuccess from "./ModalOrderCreationSuccess"
import ModalOrderCreationFail from "./ModalOrderCreationFail"
import ModalOrderCreationLoad from "./ModalOrderCreationLoad"
import { getSgebSiteOrdersUrl } from "../routes/Routes"

export default function ConfigurationContent(params) {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const {
    productId,
    designId,
    appState,
    productState
  } = params

  const dispatchSetThemeMode = value => dispatch(setThemeMode(value))
  const dispatchSetThemeBlur = value => dispatch(setThemeBlur(value))
  const dispatchSetIndex = value => dispatch(setIndex(value))
  const dispatchSetDesignView = value => dispatch(setDesignView(value))
  const dispatchSetEditPartColor = value => dispatch(setEditPartColor(value))
  const dispatchSetEditPartGradient = value => dispatch(setEditPartGradient(value))
  const dispatchSetEditPartPattern = value => dispatch(setEditPartPattern(value))
  const dispatchSetEditGlobalColor = value => dispatch(setEditGlobalColor(value))
  const dispatchSetEditPartClone = value => dispatch(setEditPartClone(value))
  const dispatchSetGridView = value => dispatch(setGridView(value))
  const dispatchSetIsLoading = value => dispatch(setIsLoading(value))
  // const dispatchSetDesignSetted = value => dispatch(setDesignSetted(value))
  const dispatchSetImageSelected = value => dispatch(setImageSelected(value))
  const dispatchSetImageEdit = value => dispatch(setImageEdit(value))
  const dispatchSetBrandLogoEdit = value => dispatch(setBrandLogoEdit(value))
  const dispatchSetIndexImageSelected = value => dispatch(setIndexImageSelected(value))
  const dispatchSetTextSelected = value => dispatch(setTextSelected(value))
  const dispatchSetTextEdit = value => dispatch(setTextEdit(value))
  const dispatchSetIndexTextSelected = value => dispatch(setIndexTextSelected(value))
  const dispatchsetPreviewState = value => dispatch(setPreviewState(value))
  const dispatchSetListProduct = value => dispatch(setListProduct(value))
  const dispatchSetProductParts = value => dispatch(setProductParts(value))
  const dispatchSetProductExtraParts = value => dispatch(setProductExtraParts(value))
  // const dispatchSetProductExtraPartsInfo = value => dispatch(setProductExtraPartsInfo(value))
  const dispatchSetTextsList = value => dispatch(setTextsList(value))
  const dispatchSetImagesList = value => dispatch(setImagesList(value))
  const dispatchSetCurrentSelectedItem = value => dispatch(setCurrentSelectedItem(value))
  const dispatchSetBrandLogo = value => dispatch(setBrandLogo(value))
  const dispatchCloseTablePrices = () => dispatch(setOpenTablePrice(false))
  const dispatchAddToCart = () => dispatch(addToCart())
  const dispatchToDesignsPage = () => dispatch(toDesignsPage(productId))
  // const dispatchSetCurrentModel = value => dispatch(setCurrentModel(value))
  // const dispatchSetPositionsList = value => dispatch(setPositionsList(value))
  // const dispatchSetBrandLogoPosition = value => dispatch(setBrandLogoPosition(value))
  const dispatchHandleUpdateDesignParts = (value, reset) => dispatch(handleUpdateDesignParts(value, reset))
  const dispatchSetEditExtraPart = value => dispatch(setEditExtraPart(value))
  const dispatchHandleSetDesign = async (value, reset) => dispatch(handleSetDesign(value, reset))
  const dispatchSetLoadedConfiguration = value => dispatch(setLoadedConfiguration(value))
  // const dispatchSaveOrUpdateConfiguration = () => dispatch(saveOrUpdateConfiguration())
  const dispatchCloseModalOrderCreationSuccess = () => dispatch(setModalOrderCreationSuccessOpened(false))
  const dispatchCloseModalOrderCreationFail = () => dispatch(setModalOrderCreationFailOpened(false))
  const dispatchToProductsPage = () => dispatch(toHomePage())

  /* START TAB CUSTOM */
  const [value, setValue] = useState(1);
  const [colorsInUse, setColorsInUse] = useState([]);

  // VIEWR DESIGN
  const [openDesign, setOpenDesign] = useState(false);

  const handleChangeGridView = () => {
    dispatchSetGridView(!appState.gridView)
  }

  const handleChangeBlur = () => {
    dispatchSetThemeBlur(!appState.themeBlur);
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatchSetIndex(newValue);
    viewerInstance.unselectItem();
    dispatchSetImageEdit(false);
    dispatchSetBrandLogoEdit(false);
    // console.log("newValue", newValue);
  };


  const handleChangeThemeMode = () => {
    if (appState.themeMode === "dark") {
      dispatchSetThemeMode("light");
      if (viewerInstance) {
        viewerInstance.setViewerBackgroundColor('#E5E5E5')
      }
    } else {
      dispatchSetThemeMode("dark");
      if (viewerInstance) {
        viewerInstance.setViewerBackgroundColor('#1A1A1A')
      }
    }
  }

  const handleSetTextList = (newTextList) => {
    dispatchSetTextsList([...newTextList]);
  }

  // const handleExitCart = () => {
  //   dispatchCloseTablePrices()
  //   dispatchSaveOrUpdateConfiguration()
  // }

  // const handleSetDesign = async (design, resetDesign) => {

  //   dispatchSetIsLoading(true);
  //   setOpenDesign(false);

  //   let isModelChanged = false;

  //   if (!appState.currentModel || appState.currentModel !== design.productName) {
  //     //clear scene
  //     viewerInstance.unselectItem();
  //     viewerInstance.removeProduct();

  //     await viewerInstance.addProduct(
  //       design.productName,
  //       {
  //         modelUrl: process.env.REACT_APP_FRONTEND_ASSETS + `viewer3d-static/models/${design.productName}.glb`,
  //         design: {
  //           url: process.env.REACT_APP_FRONTEND_ASSETS + design.src
  //         }
  //       });

  //     dispatchSetCurrentModel(design.productName);
  //     dispatchSetImagesList([]);
  //     dispatchSetTextsList([]);
  //     isModelChanged = true;
  //   }

  //   const responseSetDesign = await viewerInstance.setDesign(process.env.REACT_APP_FRONTEND_ASSETS + design.src);


  //   const modelInfo = viewerInstance.getProductUserData()
  //   dispatchSetPositionsList(modelInfo);

  //   console.log("modelInfo",modelInfo)

  //   dispatchHandleUpdateDesignParts(responseSetDesign, resetDesign || isModelChanged);
  //   dispatchSetDesignSetted(design);


  //   if ((!appState.brandLogo || isModelChanged) && modelInfo && modelInfo["LOGO_+A_POSITION_NAME"]) {

  //     const blob = await (await fetch(process.env.REACT_APP_FRONTEND_ASSETS + "svg/piu-adrenalina-logo.svg")).blob();

  //     const brandLogoResponse = viewerInstance.addLogo(blob, true);

  //     const presetPostion0 = modelInfo["LOGO_+A_POSITION_NAME"][Object.keys(modelInfo["LOGO_+A_POSITION_NAME"])[0]];

  //     dispatchSetBrandLogoPosition(Object.keys(modelInfo["LOGO_+A_POSITION_NAME"])[0]);

  //     await viewerInstance.setPresetPosition(
  //       presetPostion0.unwrapCenter.u,
  //       presetPostion0.unwrapCenter.v,
  //     );

  //     await brandLogoResponse;
  //   }

  //   // TO DO: UNCOMMENT WHEN EXTRA PARTS ARE READY
  //   // if (isModelChanged && modelInfo) {
  //   //   // TO DO: READ EXTRA PARTS FROM SAVED INFO
  //   //   if( modelInfo["EXTRA_PARTS_COLORS"] ){
  //   //     dispatchSetProductExtraPartsInfo(modelInfo["EXTRA_PARTS_COLORS"]);
  //   //     let tempExtraParts = [];
  //   //     modelInfo["EXTRA_PARTS_COLORS"].forEach(extraPart => {
  //   //       if(extraPart.colors && extraPart.colors.length > 0) {
  //   //         tempExtraParts.push({
  //   //           name: extraPart.partName,
  //   //           color: extraPart.colors[0].color});
  //   //         viewerInstance.setProductExtraPartColor(extraPart.partName, extraPart.colors[0].color);
  //   //       }
  //   //     });
  //   //     dispatchSetProductExtraParts(tempExtraParts);
  //   //     console.log(tempExtraParts)
  //   //   }else {
  //   //     dispatchSetProductExtraPartsInfo([]);
  //   //     dispatchSetProductExtraParts([]);
  //   //   }
  //   // }

  //   setValue(0);
  //   dispatchSetIndex(0);
  //   dispatchSetIsLoading(false);
  // }

  const handleResetDesign = async () => {
    setValue(0);
    setOpenDesign(false);
    dispatch(setIsLoading(true));
    await dispatchHandleSetDesign(appState.designSetted, true)
    dispatch(setIsLoading(false));
  }


  const handleSetShowMode = (flag) => {
    viewerInstance.unselectItem();
    viewerInstance.showMode(flag);
    dispatchsetPreviewState(flag);
  }

  const handleOpenDesign = () => {
    dispatchSetDesignView(0);
    setValue(0);
    dispatchSetIndex(0);
    dispatchSetGridView(true);
    // setOpenDesign(true);
    dispatchToDesignsPage();
  }

  // const handleCloseDesign = () => {
  //   setOpenDesign(false);
  // }

  const handleDownload = () => {
    viewerInstance.exportDesignWithItems(true);
  }

  // const handleOpenTablePrices = () => {
  //   setOpenTablePrice(true)
  // }

  const handleConfigureNewProduct = () => {
    dispatchCloseModalOrderCreationSuccess()
    dispatchToProductsPage()
  }

  const handleRetryOrder = () => {
    dispatchCloseModalOrderCreationFail()
    dispatchAddToCart()
  }

  const handleGoToExternalCart = () => {
    window.location.href = getSgebSiteOrdersUrl() + '/cart'
  }

  useEffect(() => {
    const asyncEffect = async () => {
      const design = productState.designs.find(design => design.id === designId)
      // const design = appState.designsList[0]
      setValue(0);
      setOpenDesign(false);
      if (!productState.loadedConfiguration) {
        dispatch(setIsLoading(true));
        await dispatchHandleSetDesign(design)

        dispatch(setIsLoading(false));
      }
      dispatchSetLoadedConfiguration(false)
    }

    asyncEffect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (appState.currentSelectedItem) {
      // console.log(currentSelectedItem.type, currentSelectedItem.type === "LogoInfo", index, value);
      if (appState.currentSelectedItem.type === "TextInfo" && value !== 1) {
        setValue(1);
        dispatchSetIndex(1);
      }
      if (appState.currentSelectedItem.type === "LogoInfo" && value !== 2) {
        setValue(2);
        dispatchSetIndex(2);
      }
    }
    // eslint-disable-next-line
  }, [appState.currentSelectedItem]);


  useEffect(() => {
    setColorsInUse(appState.colorsList.filter((color) => {
      for (let i = 0; i < appState.productParts.length; i++) {
        if (appState.productParts[i].color === color.color
          || appState.productParts[i].color2 === color.color
          || (appState.productParts[i].pattern && appState.productParts[i].patternColor === color.color)) {
          return true;
        }
      }
      return false;
    }));
    // eslint-disable-next-line
  }, [appState.productParts]);
  /* END TAB CUSTOM */

  useEffect(() => {
    viewerInstance?.cancel();
  }, [value]);

  return (
    <>
      {/* <Box
        sx={{
          minHeight: `100svh`,
          position: "relative",
          background: theme.palette.dark.main
        }}
      >
        <AppBar
          isLoggedInUser={authState.isLoggedInUser}
          userData={authState.userData}
          handleLogout={dispatchOpenLogoutDialog}
          handleOpenTablePrices={handleOpenTablePrices}
          handleOpenLoginDialog={dispatchOpenLoginDialog}
        // price={appState.price}
        // currency={currency}
        // value={value}
        /> */}
      <PanelLeft
        index={appState.index}
        handleChangeGridView={handleChangeGridView}
        gridView={appState.gridView}
        blur={appState.themeBlur}
        handleChangeBlur={handleChangeBlur}
        themeMode={appState.themeMode}
        handleChangeThemeMode={handleChangeThemeMode}
        // popupSettings={popupSettings}
        // setPopupSettings={setPopupSettings}
        handleSetShowMode={handleSetShowMode}
        previewState={appState.previewState}
        handleDownload={handleDownload}
        selectDesignOpen={!appState.designSetted || openDesign}
      />

      <Box
        className="NavContainer"
        sx={{
          position: "fixed",
          zIndex: theme.zIndex.appBar + 200,
          top: 0,
          left: "auto",
          right: "0",
          width: "100%",
          pointerEvents: "none",
          height: "100%",
          display: appState.previewState ? "none" : "flex",
          flexDirection: "column",
          "& .MuiTabs-root": {
            minHeight: `${appBarHeight}px`,
            alignItems: "center",
            zIndex: 1,
            "& .MuiTabs-flexContainer": {
              justifyContent: "center",
              gap: "10px",
            },
            "& .MuiTabs-indicator": {
              display: "none",
            },
          },
        }}
      >
        {/* TABS SECTION */}
        <Tabs value={value} onChange={handleChange} aria-label="navigation">
          <Tab label={t("tab_label_design")} />
          {/* <Tab disabled={!designSetted} label={t("tab_label_customize")} /> */}
          <Tab disabled={!appState.designSetted} label={t("tab_label_texts")} />
          <Tab disabled={!appState.designSetted} label={t("tab_label_images")} />
        </Tabs>

        {/* PANNEL DESIGN */}

        {/* <CustomTabPanel value={openDesign} index={0}>
          <SwitchBtn
            designView={designView}
            className="switch-not-fixed-pos">
          </SwitchBtn>
          <SwiperDesign
            designView={designView}
            gridView={gridView}
            designSlides={designsList}
            handleFunction={handleSetDesign}
          >
          </SwiperDesign>
          <DesignGridView
            designView={designView}
            gridView={gridView}
            designSlides={designsList}
            handleFunction={handleSetDesign}
          >
          </DesignGridView>
        </CustomTabPanel> */}

        {/* PANNEL CUSTOMIZE  */}

        <CustomTabPanel value={value} index={0}>
          <DrawerCustomizer
            editPartColor={appState.editPartColor}
            setEditPartColor={dispatchSetEditPartColor}
            editPartGradient={appState.editPartGradient}
            setEditPartGradient={dispatchSetEditPartGradient}
            editPartPattern={appState.editPartPattern}
            setEditPartPattern={dispatchSetEditPartPattern}
            editGlobalColor={appState.editGlobalColor}
            setEditGlobalColor={dispatchSetEditGlobalColor}
            colorsList={appState.colorsList}
            colorsInUse={colorsInUse}
            productParts={appState.productParts}
            setProductParts={dispatchSetProductParts}
            productExtraParts={appState.productExtraParts}
            setProductExtraParts={dispatchSetProductExtraParts}
            productExtraPartsInfo={appState.productExtraPartsInfo}
            patternList={appState.patternList}
            designSetted={appState.designSetted}
            handleOpenDesign={handleOpenDesign}
            editPartClone={appState.editPartClone}
            editExtaPart={appState.editExtraPart}
            setEditExtraPart={dispatchSetEditExtraPart}
            setEditPartClone={dispatchSetEditPartClone}
            handleUpdateDesignParts={dispatchHandleUpdateDesignParts}
            handleResetDesign={handleResetDesign}
            tabValue={value}
          ></DrawerCustomizer>
        </CustomTabPanel>

        {/* PANNEL TEXTS  */}

        <CustomTabPanel value={value} index={1}>
          <DrawerText
            colorsList={appState.colorsList}
            colorsInUse={colorsInUse}
            textsList={appState.textsList}
            setTextsList={handleSetTextList}
            positionsList={appState.positionsList}
            fontList={appState.fontList}
            currentSelectedItem={appState.currentSelectedItem}
            setCurrentSelectedItem={dispatchSetCurrentSelectedItem}
            textSelected={appState.textSelected}
            setTextSelected={dispatchSetTextSelected}
            textEdit={appState.textEdit}
            setTextEdit={dispatchSetTextEdit}
            indexTextSelected={appState.indexTextSelected}
            setIndexTextSelected={dispatchSetIndexTextSelected}
            defaultText={defaultText}
          ></DrawerText>
        </CustomTabPanel>

        {/* PANNEL IMAGES  */}

        <CustomTabPanel value={value} index={2}>
          <DrawerImages
            imagesList={appState.imagesList}
            setImagesList={dispatchSetImagesList}
            colorsList={appState.colorsList}
            colorsInUse={colorsInUse}
            positionsList={appState.positionsList}
            imageSelected={appState.imageSelected}
            setImageSelected={dispatchSetImageSelected}
            imageEdit={appState.imageEdit}
            setImageEdit={dispatchSetImageEdit}
            brandLogoEdit={appState.brandLogoEdit}
            setBrandLogoEdit={dispatchSetBrandLogoEdit}
            indexImageSelected={appState.indexImageSelected}
            setIndexImageSelected={dispatchSetIndexImageSelected}
            currentSelectedItem={appState.currentSelectedItem}
            setCurrentSelectedItem={dispatchSetCurrentSelectedItem}
            defaultImage={defaultImage}
            dispatchSetBrandLogo={dispatchSetBrandLogo}
            brandLogo={appState.brandLogo}
          ></DrawerImages>
        </CustomTabPanel>
      </Box>

      {/* VIEWER */}
      <Viewer3D
        index={appState.index}
        imageSelected={appState.imageSelected}
        setImageSelected={dispatchSetImageSelected}
        indexImageSelected={appState.indexImageSelected}
        setIndexImageSelected={dispatchSetIndexImageSelected}
        imageEdit={appState.imageEdit}
        setImageEdit={dispatchSetImageEdit}
        imagesList={appState.imagesList}
        setImagesList={dispatchSetImagesList}
        textsList={appState.textsList}
        setTextsList={dispatchSetTextsList}
        currentSelectedItem={appState.currentSelectedItem}
        setCurrentSelectedItem={dispatchSetCurrentSelectedItem}
        textSelected={appState.textSelected}
        setTextSelected={dispatchSetTextSelected}
        textEdit={appState.textEdit}
        setTextEdit={dispatchSetTextEdit}
        indexTextSelected={appState.indexTextSelected}
        setIndexTextSelected={dispatchSetIndexTextSelected}
        defaultText={defaultText}
        defaultImage={defaultImage}
        isLoading={appState.isLoading}
        setIsLoading={dispatchSetIsLoading}
        dispatchSetBrandLogo={dispatchSetBrandLogo}
      />

      {appState.openTablePrice &&
        <TableSizes
          productId={productId}
          designId={designId}
          selectedDesign={productState.selectedDesign}
          listProduct={appState.listProduct}
          totalPrice={appState.totalPrice}
          setListProduct={dispatchSetListProduct}
          // handleCancel={handleExitCart}
          handleCancel={dispatchCloseTablePrices}
          handleSave={dispatchAddToCart}
        />
      }

      <ModalOrderCreationLoad
        open={appState.modalOrderCreationLoadOpened}
      />
      <ModalOrderCreationSuccess
        open={appState.modalOrderCreationSuccessOpened}
        handleGoToCart={handleGoToExternalCart}
        handleConfigureNewProduct={handleConfigureNewProduct}
      />
      <ModalOrderCreationFail
        open={appState.modalOrderCreationFailOpened}
        handleCancel={dispatchCloseModalOrderCreationFail}
        handleRetry={handleRetryOrder}
      />

    </>
  )
}
