import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material";
import { ReactComponent as IconLocketClose } from "../icons/icon-locket-close.svg";
import { ReactComponent as IconTrash } from "../icons/icon-trash.svg";
import { ReactComponent as IconClone } from "../icons/icon-clone.svg";

export default function PanelCustomImages(props) {

  const {
    imagesList,
    selectImage,
    handleDuplicate,
    handleDelete
  } = props

  const theme = useTheme();

  return (
    <List>
      { imagesList ? imagesList.map((item, index) => (
        <ListItem
          key={"list-item-" + index}
          disablePadding
          onClick={() => {selectImage(item, index) }}
          sx={{
            "& img": {
            objectFit:"contain",
            maxWidth:"100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            },
          }}
        >
          <ListItemButton sx={{
              gap: "10px",
              "& svg": {
                width: "20px",
                height: "20px",
                fill: theme.palette.text.primary
              },
              ".actions-button":{
                display:"flex",
                pointerEvents: "none",
                opacity: "0"
              },
            "&:hover": {
              ".actions-button":{
                  pointerEvents: "auto",
                  opacity: "1"
                }
              }
            }} className="file-preview">
            <Box sx={{
              position:"relative",
              borderRadius: "4px",
              overflow: "hidden",
              display:"flex",
              // width: "48px",
              // height: "48px",
              // alignItems:"center",
              justifyContent:"center",
              flexShrink: 0,
              // "&::before": {
              //   content: "''",
              //   zIndex: -1,
              //   position: "absolute",
              //   width: "100%",
              //   height: "100%",
              //   left: 0,
              //   top: 0,
              //   right: 0,
              //   bottom: 0,
              //   background: "repeating-linear-gradient(0deg, #3f4042 0, #3f4042 6px,#6f7071 6px, #6f7071 12px)",
              // },
              // "&::after": {
              //     content: "''",
              //     zIndex: -1,
              //     position: "absolute",
              //     width: "100%",
              //     height: "100%",
              //     background: "repeating-linear-gradient(90deg, #3f4042 0, #3f4042 6px,#6f7071 6px, #6f7071 12px)",
              //     left: 0,
              //     top: 0,
              //     right: 0,
              //     bottom: 0,
              //     mixBlendMode: "difference"
              //   }
            }}>
              {/* <Box sx={{
                width: "100%",
                height: "100%",
                backgroundSize: "contain",
                backgroundImage: `url("${item.url}")`
              }}/> */}
            <Box
              sx={{
                display: "flex",
                backgroundSize: "100%",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${"../../assets/imgs/Thumbnail-BG-pattern_48.png"})`,
              }}
            >
              <Box
                className="img-file-preview"
                sx={{
                  backgroundImage: `url("${item.url}")`,
                }}
              ></Box>
            </Box>
            </Box>
            <ListItemText sx={{
              wordBreak: "break-word"
            }} primary={item.name} secondary={item.id} />
            <Box className="actions-button" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <IconButton
              sx={{
                display: "flex",
                marginRight: "5px",
              }}
              className="fab-transparent"
              aria-label="add"
              size="small"
              onClick={() => {handleDuplicate(item.id)}}
            >
              <IconClone />
            </IconButton>
            <IconButton
              sx={{ display: "flex" }}
              className="fab-transparent"
              aria-label="add"
              size="small"
              onClick={() => {handleDelete(item.id)}}
            >
              <IconTrash />
            </IconButton>
            </Box>
            <Box sx={{
              padding: "2px",
              borderRadius: "5px",
              background: theme.palette.white.main,
              display: item.isLocked ? "flex" : "none",
              aspectRatio: 1,
              "& svg": {
                fill: theme.palette.black.main,
              }
            }}>
              <IconLocketClose />
            </Box>
          </ListItemButton>
        </ListItem>
      )): ""}
    </List>

  )
}
