import { Box, useTheme } from "@mui/material"
import AppBar from "../AppBar"
import { useDispatch, useSelector } from "react-redux"
import { login, logout, setLoginDialogOpened, setLogoutDialogOpened } from "../../slices/authSlice"
import LoginDialog from "../auth/LoginDialog"
import LogoutConfirmDialog from "../auth/LogoutConfirmDialog"
import { setOpenTablePrice } from "../../slices/appSlice"

export default function PageLayout(props) {
  const theme = useTheme()
  const dispatch = useDispatch()
  const authState = useSelector(state => state.auth)
  const appState = useSelector(state => state.app)
  const { children, showBuyButton } = props

  const dispatchLogin = userdata => dispatch(login(userdata))
  const dispatchLogout = () => dispatch(logout())
  const dispatchOpenLoginDialog = () => dispatch(setLoginDialogOpened(true))
  const dispatchCloseLoginDialog = () => dispatch(setLoginDialogOpened(false))
  const dispatchOpenLogoutDialog = () => dispatch(setLogoutDialogOpened(true))
  const dispatchCloseLogoutDialog = () => dispatch(setLogoutDialogOpened(false))
  const dispatchOpenTablePrices = () => dispatch(setOpenTablePrice(true))

  return (
    <Box
      sx={{
        minHeight: `100svh`,
        position: "relative",
        background: theme.palette.dark.main
      }}
    >
      <AppBar
        isLoggedInUser={authState.isLoggedInUser}
        userData={authState.userData}
        showBuyButton={showBuyButton}
        totalPrice={appState.totalPrice}
        handleLogout={dispatchOpenLogoutDialog}
        handleOpenTablePrices={dispatchOpenTablePrices}
        handleOpenLoginDialog={dispatchOpenLoginDialog}
      />
      {children}
      <LoginDialog
        open={authState.loginDialogOpened}
        handleLogin={dispatchLogin}
        handleClose={dispatchCloseLoginDialog}
      />
      <LogoutConfirmDialog
        open={authState.logoutDialogOpened}
        handleClose={dispatchCloseLogoutDialog}
        handleConfirm={dispatchLogout}
      />
    </Box>
  )
}
