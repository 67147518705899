import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconUploadCustom = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path d="m11,16V7.85l-2.6,2.6-1.4-1.45,5-5,5,5-1.4,1.45-2.6-2.6v8.15h-2Zm-5,4c-.55,0-1.02-.2-1.41-.59-.39-.39-.59-.86-.59-1.41v-3h2v3h12v-3h2v3c0,.55-.2,1.02-.59,1.41-.39.39-.86.59-1.41.59H6Z" />
      </svg>
    </SvgIcon>
  );
};
export default IconUploadCustom;
