import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconStrokeWidthThin = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 20"
        width="20"
      >
        <path d="M18,9H2v2h16V9z" />
      </svg>
    </SvgIcon>
  );
};
export default IconStrokeWidthThin;
