import { Box, List, Typography } from "@mui/material";
// import { positions } from "@mui/system";
import { useTranslation } from "react-i18next";
import { moveCameraOnPartSelection } from "../helpers/Viewer";
import PartsListItem from "./layout/PartsListItem";
import ExtraPartsListItem from "./layout/ExtraPartsListItem";
// import ScrollDownSignal from "./ScrollDownSignal";
import ScrollDownTopButton from "./ScrollDownTopButton";

export default function PanelCustomParts(props) {

  const {
    productParts,
    selectPart,
    activeClone,
    productExtraParts,
    selectExtraPart,
    isScrollable,
    isScrolledToEnd,
    scrollFunction
  } = props

  const { t } = useTranslation();

  const onClick = (item) => {
    selectPart(item);
    moveCameraOnPartSelection(item.label);
  }

  const handleActiveClone = (item) => {
    activeClone(item);
    moveCameraOnPartSelection(item.label);
  }

  return (
    <>
      <Box className="panel-box">
        <Box className="panel-header">
          <Typography variant="h4" sx={{ marginRight: "auto" }}>
            {t("panel_label_product_parts")}
          </Typography>
        </Box>
        <List>
          {productParts.map((item, index) => (
            <PartsListItem
              key={"list-item-" + index}
              item={item}
              index={index}
              onClick={onClick}
              clone={true}
              cloneFunction={handleActiveClone}
            />
          ))}
          {productExtraParts ?
            productExtraParts.map((item, index) => {
              return (<ExtraPartsListItem
                key={"list-item-extra-" + index}
                item={item}
                index={index}
                onClick={selectExtraPart}
              />)
            })
            : ""
          }
        </List>
      </Box>
      <ScrollDownTopButton
        isScrollable={isScrollable}
        isScrolledToEnd={isScrolledToEnd}
        scrollFunction={scrollFunction}
        />
    </>
  )
}
