import { Box, Button, FormControlLabel, IconButton, Menu, Radio, RadioGroup, Typography, useTheme } from "@mui/material"
import IconAccount from "@mui/icons-material/AccountCircle";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Divider from "./Divider";
import { useDispatch, useSelector } from "react-redux"
import { initApp } from "../slices/initSlice";
import { setIsLoading, setPrice, setPriceVisible } from "../slices/appSlice";
import SwitchCheck from "./SwitchCheck";

export default function UserMenu(props) {

  const {
    isLoggedInUser,
    userData,
    handleLogout,
    handleLogin,
    toCredential
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const ref = useRef();
  const open = Boolean(anchorEl);

  const { t, i18n } = useTranslation();

  const theme = useTheme();

  const appState = useSelector(state => state.app)
  const dispatch = useDispatch();

  const handleUpdatePriceVisibility = (value) => {
    dispatch(setPriceVisible(value))
  }

  const handleClick = () => {
    setAnchorEl(ref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const lngs = {
    it: "italiano",
    en: "english"
  };

  const handleChange = async (event) => {
    // console.log(event)
    i18n.changeLanguage(event.target.value);
    dispatch(setIsLoading(true))
    await dispatch(initApp())
  };
  return (
    <>
      <IconButton
        className="no-bg"
        ref={ref}
        onClick={() => { handleClick() }}
      >
        <IconAccount />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        // elevation={0}
        sx={{

          ".MuiPaper-root.MuiMenu-paper ": {
            borderRadius: "20px",
            background: theme.palette.modalBackground,
            outline: "0px transparent",
            border: "0px transparent",
            borderColor: "transparent",
            outlineColor: "transparent",
            py: "10px",
            width: "280px",
            maxWidth: "100%",
          }
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          alignItems: "center"
        }}>
          {
            isLoggedInUser ?
              <Box sx={{
                display: "flex",
                gap: "10px",
                flexDirection: "column",
                p: "10px 20px",
                alignItems: "center",
              }}>
                <Typography variant="body2" sx={{
                  color: theme.palette.white.o07
                }}>
                  {t("label_user_name")}
                </Typography>

                <Typography variant="body2" color="white">
                  {userData?.unique_name}
                </Typography>
              </Box>
              :
              <>
                <Box sx={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  p: "10px 20px",
                  alignItems: "center",
                }}>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      color: theme.palette.white.o07
                    }}>
                    {t("label_user_not_logged")}
                  </Typography>
                  <Button
                    className="btn-white"
                    onClick={() => { handleLogin() }}
                  >
                    {t("panel_login_button")}
                  </Button>
                </Box>

                {/* <Divider bgcolor={theme.palette.white.o015} />

                <Box sx={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  p: "10px 20px",
                  alignItems: "center",
                }}>
                  <Typography
                    variant="body2"
                    align="center"
                    sx={{
                      color: theme.palette.white.o07
                    }}>
                    {t("label_do_you_have_an_account")}
                  </Typography>

                  <Button
                    className="btn-text"
                    color="white"
                    onClick={()=> {toCredential();}}
                  >
                    {t("label_button_request_credentials")}
                  </Button>
                </Box> */}
              </>
          }

          {
            isLoggedInUser && false ?
            <>
              <Divider bgcolor={theme.palette.white.o015} />

              <Box sx={{
                display: "flex",
                gap: "10px",
                p: "10px 20px",
                width: "100%",
                alignItems: "center"
              }}>
               <Typography variant="body1" sx={{ marginRight: "auto" }}>
                 {t("label_price_visibility")}
               </Typography>
               <SwitchCheck
                 value={appState.priceVisible}
                 toggleFunction={() => { handleUpdatePriceVisibility(!appState.priceVisible) }}
               ></SwitchCheck>

            </Box>
            </>
              : ""
          }

          <Divider bgcolor={theme.palette.white.o015} />

          {/* LANGUAGE SELECTION */}

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            p: "10px 20px",
            width: "100%"
          }}>
            <Typography variant="body2" sx={{
              color: theme.palette.white.o07
            }}>
              {t("label_set_language")}
            </Typography>
            <RadioGroup
              value={i18n.resolvedLanguage}
              onChange={(e) => { handleChange(e) }}
              sx={{
                p: "10px 10px",
                gap: "20px",
              }}>
              {Object.keys(lngs).map((lng) => (
                <FormControlLabel
                  key={lng}
                  value={lng}
                  control={<Radio />}
                  label={lngs[lng]}
                  sx={{
                    gap: "10px",
                    textTransform: "capitalize",
                  }}
                />

              ))}
            </RadioGroup>

          </Box>
          {
            isLoggedInUser ?
              <>
                <Divider bgcolor={theme.palette.white.o015} />
                <Button
                  className="btn-white"
                  onClick={() => { handleLogout(); }}
                >
                  {t("panel_logout_button")}
                </Button>
              </>
              : ""
          }
        </Box>

      </Menu >
    </>
  )

}
