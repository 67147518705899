import { Box, CircularProgress, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function Loader(props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    isLoading
  } = props

  return (
    <Box
      sx={{
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "2",
        overflow: "hidden",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.appBar + 300,
        background: theme.palette.black.o05,
        backdropFilter: theme.palette.filter.blur50,
        alignItems: "center",
        opacity: isLoading ? 1 : 0,
        pointerEvents: isLoading ? "auto" : "none",
        transition: "0.3s all ease-out"
      }}
    >
      <Typography
        variant="h3"
        sx={{ mb: "20px" }}
      >
        {t('loader_page_text')}
      </Typography>
      <CircularProgress />
    </Box>
  )
}
