import { Box, Button, IconButton, Modal, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next";
import CloseIcon from '@mui/icons-material/Close'
import Divider from "./Divider";

export default function ModalConfirmation(props) {

  const {
    isOpen,
    handleClose,
    handleContinue,
    title,
    description
  } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        "@media print": {
          display: "none"
        }
      }}
    >
      <Box
        className="panel-box"
        sx={{
          maxWidth: "100%",
          width: "400px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderWidth: "0px",
          outline: "0px",
        }}>
        {/*  HEADER */}
        <Box sx={{
          p: "10px 12px",
          borderRadius: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
          <Typography variant="h4">{t(title)}</Typography>
          <IconButton onClick={handleClose} size="small">
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 1,
          p: "10px 12px",
          img: {
            width: "100%",
            objectFit: "cover",
            objectPosition: "center center",
            aspectRatio: 1.5,
          }
        }}>
          <Typography variant="body1">{t(description)}</Typography>
        </Box>

        <Box className="panel-content">
          <Divider bgcolor={theme.palette.white.o015}></Divider>
        </Box>

        <Box sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          p: "10px 12px",
        }}>
          <Button
            // variant="text"
            onClick={handleClose}
            color="white"
            className="btn-text"
            >{
              t("label_abort")}
          </Button>
          <Button
            variant="contained"
            onClick={handleContinue}
            className="btn-white">{
              t("label_confirm")}
          </Button>
        </Box>

      </Box>
    </Modal>
  )

}
