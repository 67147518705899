import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import BuyButton from "./BuyButton";
import { useTranslation } from "react-i18next";
// import ButtonRound from "./ButtonRound";

const CardItem = (props) => {
  const {
    id,
    imgsrc,
    className,
    name,
    onClick,
    available,
    text
  } = props;

  const theme = useTheme();

  const {t} = useTranslation();

  return (
    <Box
      id={id}
      className={className}
      onClick={available ? () => {onClick()} : {}}
      sx={{
        bgcolor: "transparent",
        p: "20px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        boxShadow: 0,
        ":hover": {
          bgcolor: theme.palette.white.o01,
          boxShadow: 0,
          cursor: available ? "pointer" : "default",
        },
        "& .card-customize-btn": {
          opacity: 0,
          visibility: "hidden",
          transition:
            "opacity 0.3s ease-in-out, visibility 0s ease-in-out 0.3s",
        },
        "&:hover .card-customize-btn": {
          opacity: 1,
          visibility: "visible",
          transition: "opacity 0.3s ease-in-out, visibility 0s ease-in-out 0s",
        },
        "& img": {
          maxWidth:"100%",
          aspectRatio: 1,
          width: "100%",
          objectFit: "contain",
          filter: available ? "none" : "saturate(0)",
        }
      }}
    >
      <Box sx={{
        position: "relative",
        display: "flex",
        alignItems:"center",
        flexDirection:"column",
        p: "50px",
        }}>
        <img src={imgsrc} alt={name} width="1024"/>
        { available ?
          <BuyButton
          text={t("tab_label_customize")}
          variant="contained"
          className="buy-button card-customize-btn mx-auto"
          onClick={onClick}
          ></BuyButton> :
          <BuyButton
          text={t("tab_label_coming_soon")}
          variant="contained"
          className="card-customize-btn mx-auto disabled"
          onClick={() =>{}}
          ></BuyButton>
        }
      </Box>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          maxWidth: "100%",
        }}
      >
        {name}
      </Typography>
      <Box
        sx={{
          textAlign: "center",
          maxWidth: "100%",
          px: "70px",
          display:"flex",
          flexDirection:"column",
          gap: "4px"
        }}
      >
         {text.split("/n").map((element, index) => {
                      return (<Typography variant="caption" key={"description_"+index}>{element}</Typography>)
                    })}
      </Box>
    </Box>
  );
};
export default CardItem;
