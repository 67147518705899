import { IconButton, AppBar as MUIAppBar, Typography, useTheme } from "@mui/material";
import logo from "../resources/adrenalina_logo.png";
import { Box } from "@mui/material";
//import { ReactComponent as Icon2D } from "../resources/icon_2D.svg";
//import ButtonRound from "./ButtonRound";
import BuyButton from "./BuyButton";
// import LoginButton from "./auth/LoginButton";
// import LoggedUserInfo from "./auth/LoggedUserInfo";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ModalConfirmation from "./ModalConfirmation";
import { useState } from "react";
import LogoutIcon from '@mui/icons-material/Logout'
import UserMenu from "./UserMenu";
import { getSgebSiteOrdersUrl } from "../routes/Routes";
// import { WindowSharp } from "@mui/icons-material";

/*
//test TextField #buy-button-price
import WrapperPrice from "./WrapperPrice";
*/

export default function AppBar(props) {
  const theme = useTheme()
  const {
    isLoggedInUser,
    userData,
    showBuyButton,
    totalPrice,
    handleLogout,
    handleOpenTablePrices,
    handleOpenLoginDialog,
    //appBarHeight,
    // price,
    // openProducts,
    // selectDesignOpen
    // price,
    //currency
  } = props;

  const appState = useSelector(state => state.app);
  const { t } = useTranslation();

  const [modalExitConfiguratorOpen, setModalExitConfiguratorOpen] = useState(false);

  /*
    //abbiamo provato a usarlo su TextField #buy-button-price
    const [text, setText] = useState('');
  */

  // const formatCurrency = (price, withSymbol = true, withDecimals = true) => {
  //   const currencyFormatter = new Intl.NumberFormat("it-IT", {
  //     style: "currency",
  //     currency: "EUR",
  //     maximumFractionDigits: withDecimals ? 2 : 0,
  //   });
  //   let formattedNumber = currencyFormatter.format(price);
  //   return withSymbol
  //     ? formattedNumber
  //     : formattedNumber.replace("€", "").trim();
  // };

  return (
    <MUIAppBar
      position="fixed"
      elevation={0}
      sx={{
        //backgroundColor: theme.palette.black.o05,
        backgroundColor: "transparent",
        //height: `calc( 100px - ${appBarHeight}px)`,
        height: "90px",
        padding: "8px 30px",
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: theme.zIndex.appBar + 100,
        pointerEvents: "none",

        /*
        svg: {
          height:"24px",
          width:"24px",
          display: "block",
          fill: "red"
        },
        */
        [theme.breakpoints.up("lg")]: {
          //esempio
        },
      }}
    >
      {/* KEEP THIS STRUCTURE AS IS TO GRAPHICAL PURPOSE */}
      <Box
        className="gradient-blur"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Box>

      <Box
        className="headerLogoContainer"
        sx={{
          width: "130px",
          "& .headerLogo": {
            maxWidth: "100%",
            objectFit: "contain",
          },
        }}
      >
        <img src={logo} alt="logo" className="headerLogo" />
      </Box>

      {appState.pageTitle ?

        <Box sx={{
          display: "flex",
        }}>

          <Typography variant="h3">

            {t(appState.pageTitle)}
          </Typography>

        </Box> : ""
      }
      {/*
      <Box className="NavContainer"
        sx={{
          display: "flex",
        }}>
        <Tabs value={value}
          TabIndicatorProps={{
            style: {opacity: 0}
          }}
          onChange={handleChange}>
          <Tab label="Design" />
          <Tab label="Personalizza" />
          <Tab label="Testi" />
          <Tab label="Immagini" />
        </Tabs>
        <ButtonRound
          text="Design"
          variant="contained"
          class="btn-rounded active"/>
        <ButtonRound
          text="Personalizza"
          variant="contained"
          class="btn-rounded"
          state=""/>
        <ButtonRound
          text="Testi"
          variant="contained"
          class="btn-rounded"
          state=""/>
        <ButtonRound
          text="Immagini"
          variant="contained"
          class="btn-rounded"
          state=""/>
      </Box>
      */}

      <Box sx={{
        display: "flex",

      }}>
        <Box sx={{
          mr: 1,
          display: "flex",
          alignItems: "center",
          pointerEvents: "auto",
          gap: "10px",
        }}>
          {Boolean(showBuyButton) &&
            <Box
              className="BuyContainer"
              sx={{
                display: "flex",
                alignItems: "center",
                p: "4px",
                paddingLeft: "20px",
                backgroundColor: theme.palette.black.o05,
                webkitBackdropFilter: theme.palette.filter.blur50,
                backdropFilter: theme.palette.filter.blur50,
                borderRadius: "50px",
                pointerEvents: "auto"
              }}
            >

              {/*
        <WrapperPrice
          price={price}
          currency={currency} />
        */}
              {/*
        <TextField
          id="buy-button-price"
          value={price}
          //fullWidth={false}
          //placeholder={price}
          variant="outlined"
          InputProps={{
              startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
              style: { width: "auto" },
              //size: placeholder.length,
            }}
          //onChange = {(e) => setText(e.target.value)}
        />
        */}
              <Typography variant="button">
              {appState.priceVisible ?
                totalPrice?.toLocaleString('it-IT', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                  style: "currency",
                  currency: "EUR",
                })
                : " "
              }
              </Typography>
              <BuyButton
                text={t('buy_button_text')}
                variant="contained"
                className="buy-button"
                onClick={handleOpenTablePrices}
              />
            </Box>
          }

          {/* {isLoggedInUser ?
            <LoggedUserInfo
              userData={userData}
              handleLogout={handleLogout}
            />
            :
            <LoginButton
              onClick={handleOpenLoginDialog}
            />
          } */}

          <UserMenu
            isLoggedInUser={isLoggedInUser}
            userData={userData}
            handleLogout={handleLogout}
            handleLogin={handleOpenLoginDialog}
            toCredential={() => { }}
          />

          {/* EXIT BUTTON */}

          <IconButton
            // size="small"
            onClick={() => { setModalExitConfiguratorOpen(true) }}
            className="no-bg"
          // variant="text"
          >
            <LogoutIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>

      <ModalConfirmation
        isOpen={modalExitConfiguratorOpen}
        title={"modal_title_exit_configurator"}
        description={"modal_description_exit_configurator"}
        handleClose={() => { setModalExitConfiguratorOpen(false) }}
        handleContinue={() => {
          window.location.href = getSgebSiteOrdersUrl()
          setModalExitConfiguratorOpen(false)
        }}
      />
    </MUIAppBar>
  );
}
