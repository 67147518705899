import { Box, Typography, useTheme } from "@mui/material";
import PaletteIcon from "@mui/icons-material/Palette";
import StarRateIcon from "@mui/icons-material/StarRate";
import ColorSelector from "./ColorSelector";
import { useTranslation } from "react-i18next";

export default function ColorsListSelector(props) {

  const {
    colorsInUse,
    colorsList,
    color,
    color2,
    handleUpdateColor,
    handleUpdateColors,
    editGradient,
    disable
  } = props

  const theme = useTheme();

  const {t} = useTranslation();

  return (
    <>
      {/* COLORS SELECTORS */}
      <Box
        className="panel-content"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          opacity: disable ? 0.5 : 1,
          pointerEvents: disable ? "none" : "auto"
        }}
      >
        {/* COLORS IN USE */}
        {
          colorsInUse ?
        <Box className="color-sample-container">
          <StarRateIcon
            sx={{ margin: "5px",width: "25px", height: "25px"}}
          ></StarRateIcon>
          {
          colorsInUse.map((item, index) => (
            <ColorSelector
              label={item.label}
              key={"color_in_use_" + index}
              className={"color-selector color-selector-m " + (
                (
                  color === item.color ||
                  color2 === item.color
                ) ? "active" : "")}
              sample={item.color}
              onClick={() => {
                if (!editGradient) {
                  handleUpdateColor(item.color);
                } else {
                  handleUpdateColors(item.color);
                }
              }}
            ></ColorSelector>
          ))
          }
        </Box>
        : ""}
        {/* COLORS LIST */}
        <Box
          className="color-sample-container"
          sx={{
            pt: "0px",
          }}
        >
          <PaletteIcon sx={{ margin: "5px",width: "25px", height: "25px" }}></PaletteIcon>
          {colorsList.map((item, index) => (
            <ColorSelector
              label={item.label}
              key={"color_selector_" + index}
              className={"color-selector color-selector-m " + (
                (
                  color === item.color ||
                  color2 === item.color
                ) ? "active" : "")}
              sample={item.color}
              onClick={() => {
                if (!editGradient) {
                  handleUpdateColor(item.color);
                } else {
                  handleUpdateColors(item.color);
                }
              }}
            ></ColorSelector>
          ))}
        </Box>
      </Box>

      <Box
        className="panel-content"
        sx={{
          display: "flex",
          gap: "4px",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.white.o07,
          }}
        >
          {t("panel_label_selected_color")}
        </Typography>
        <Typography variant="body2">
          {colorsList.filter((col) => {
            return col.color === color || col.color === color2
          }).map((el, index) => {
            return (
              <span key={index}>{(index !== 0 ? ", " : "") + el.label}</span>
            )
          })}
        </Typography>
      </Box>
    </>
  )
}
