import Button from "@mui/material/Button";
import { Typography, useTheme } from "@mui/material";

const ButtonSmall = (props) => {
  const {
    id,
    icon,
    className,
    onClick
  } = props;
  const theme = useTheme();
  return (
    <Button
      disableRipple
      id={id}
      startIcon={icon}
      className={className}
      onClick={onClick}
      sx={{
        bgcolor: theme.palette.white.o015,
        color: theme.palette.black.main,
        py: "7px",
        paddingLeft: "15px",
        paddingRight: "15px",
        borderRadius: "35px",
        textTransform: "unset",
        boxShadow: 0,
        ":hover": {
          bgcolor: theme.palette.white.o025,
          boxShadow: 0,
        },
        "&.btn-small-icon": {
          py: "5px",
          paddingLeft: "10px",
          svg: {
            height:"20px"
          }
        },
        "& .MuiButton-startIcon": {
          marginRight: "5px",
        },
      }}
    >
      <Typography variant="body2">{props.text}</Typography>
    </Button>
  );
};
export default ButtonSmall;
