import { Box, Divider, Typography } from "@mui/material"
import PanelBoxRef from "./PanelBoxRef"
// import SwitchCustom from "./SwitchCustom"
import SwitchCheck from "./SwitchCheck"
import { useTranslation } from "react-i18next"


export default function OptionPanel(props) {

  const {
    refLeft,
    refBottom,
    optionMenuOpen,
    handleCloseOptionMenu,
    // themeMode,
    // handleChangeThemeMode,
    // blur,
    // handleChangeBlur,
    gridView,
    handleChangeGridView,
  } = props


  const { t } = useTranslation();

  return(
    //  {/* PANEL OPTIONS */}
     <PanelBoxRef
     refLeft={refLeft}
     refBottom={refBottom}
     title={t("scene_option_menu_title")}
     open={optionMenuOpen}
     handleClose={handleCloseOptionMenu}
   >
     <Box sx={{
       display:"flex",
       flexDirection:"column",
       py: "10px",
       gap: "10px",
       "& .option-list-item": {
         display:"flex",
         flexDirection:"row",
         alignItems:"center",
         justifyContent:"space-between"
       }
     }}>

       {/* Theme color */}

       {/* <Box className="option-list-item">
         <Typography variant="body1">{t("option_label_interface_theme")}</Typography>
         <SwitchCustom
           value={themeMode}
           toggleFunction={handleChangeThemeMode}
         />
       </Box> */}

       {/* <Divider></Divider> */}

       {/* Theme Blur */}

       {/* <Box className="option-list-item">
         <Typography variant="body1">{t("option_label_interface_blur")}</Typography>
         <SwitchCheck
           value={blur}
           toggleFunction={handleChangeBlur}
         />
       </Box> */}

       <Divider></Divider>

       {/* Grid visualization */}

       <Box className="option-list-item">
         <Typography variant="body1">{t("option_label_interface_grid")}</Typography>
         <SwitchCheck
           value={gridView}
           toggleFunction={handleChangeGridView}
         />
       </Box>

     </Box>
   </PanelBoxRef>
  );
}
