import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { getCustomTheme } from "../../helpers/ThemeHelper"

export default function AppThemeProvider(props) {
  const { children } = props
  const appState = useSelector(state => state.app)
  const { themeMode, themeBlur } = appState

  const theme = useMemo(
    () => responsiveFontSizes(createTheme(getCustomTheme(themeMode, themeBlur))),
    [themeMode, themeBlur]
  )

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}
