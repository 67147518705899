import { createSlice } from '@reduxjs/toolkit'
import { getColors, getBrandLogoColors } from '../api/data/colors'
import { getPatterns } from '../api/data/patterns'
// import { getDesigns } from '../api/data/designs'
// import { getProducts } from '../api/data/products'
import { getProductParts } from '../api/data/productParts'
import { getTexts } from '../api/data/texts'
import { getImages } from '../api/data/images'
import { getPositions } from '../api/data/positions'
import { getFonts } from '../api/data/fonts'
import { themeModes } from '../helpers/layout'
import { viewerInstance } from '../helpers/Viewer'

const initialState = {
  themeMode: themeModes.dark,
  themeBlur: true,
  index: 0,
  price: 151.0,
  designView: 0,
  widthMask: 86,
  transformMask: 0,
  editPartColor: false,
  editPartGradient: false,
  editPartPattern: false,
  editGlobalColor: false,
  editPartClone: false,
  editExtraPart: false,
  gridView: false,
  isLoading: true,
  designSetted: false,
  wallpaperMode: false,
  imagesMode: false,
  imageSelected: null,
  imageEdit: false,
  brandLogoEdit: false,
  indexImageSelected: null,
  textSelected: null,
  textEdit: false,
  indexTextSelected: null,
  previewState: false,
  listProduct: [],
  totalPrice: 0,
  colorsList: getColors(),
  colorsListBrandLogo: getBrandLogoColors(),
  patternList: getPatterns(),
  designsList: [], // getDesigns(),
  productsList: [], // getProducts(),
  productParts: getProductParts(),
  productExtraParts: [],
  productExtraPartsInfo: [],
  textsList: getTexts(),
  imagesList: getImages(),
  positionsList: getPositions(),
  brandLogoPosition: null,
  brandLogo: null,
  brandLogoColorDefault: null,
  fontList: getFonts(),
  currentSelectedItem: null,
  openProducts: true,
  openTablePrice: false,
  currentModel: null,
  pageTitle: null,
  modalOrderCreationLoadOpened: false,
  modalOrderCreationSuccessOpened: false,
  modalOrderCreationFailOpened: false,
  priceVisible: true,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setThemeMode: (state, action) => {
      state.themeMode = action.payload
    },
    setThemeBlur: (state, action) => {
      state.themeBlur = action.payload
    },
    setIndex: (state, action) => {
      state.index = action.payload
    },
    setPrice: (state, action) => {
      state.price = action.payload
    },
    setDesignView: (state, action) => {
      state.designView = action.payload
    },
    setWidthMask: (state, action) => {
      state.widthMask = action.payload
    },
    setTransformMask: (state, action) => {
      state.transformMask = action.payload
    },
    setEditPartColor: (state, action) => {
      state.editPartColor = action.payload
    },
    setEditPartGradient: (state, action) => {
      state.editPartGradient = action.payload
    },
    setEditPartPattern: (state, action) => {
      state.editPartPattern = action.payload
    },
    setEditGlobalColor: (state, action) => {
      state.editGlobalColor = action.payload
    },
    setEditPartClone: (state, action) => {
      state.editPartClone = action.payload
    },
    setEditExtraPart: (state, action) => {
      state.editExtraPart = action.payload
    },
    setGridView: (state, action) => {
      state.gridView = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setDesignSetted: (state, action) => {
      state.designSetted = action.payload
    },
    setWallpaperMode: (state, action) => {
      state.wallpaperMode = action.payload
    },
    setImagesMode: (state, action) => {
      state.imagesMode = action.payload
    },
    setImageSelected: (state, action) => {
      state.imageSelected = action.payload
    },
    setImageEdit: (state, action) => {
      state.imageEdit = action.payload
    },
    setBrandLogoEdit: (state, action) => {
      state.brandLogoEdit = action.payload
    },
    setIndexImageSelected: (state, action) => {
      state.indexImageSelected = action.payload
    },
    setTextSelected: (state, action) => {
      state.textSelected = action.payload
    },
    setTextEdit: (state, action) => {
      state.textEdit = action.payload
    },
    setIndexTextSelected: (state, action) => {
      state.indexTextSelected = action.payload
    },
    setPreviewState: (state, action) => {
      state.previewState = action.payload
    },
    setListProduct: (state, action) => {
      state.listProduct = action.payload
    },
    setTotalPrice: (state, action) => {
      state.totalPrice = action.payload
    },
    setColorsList: (state, action) => {
      state.colorsList = action.payload
    },
    setPatternList: (state, action) => {
      state.patternList = action.payload
    },
    setDesignsList: (state, action) => {
      state.designsList = action.payload
    },
    setProductsList: (state, action) => {
      state.productsList = action.payload
    },
    setProductParts: (state, action) => {
      state.productParts = action.payload
    },
    setProductExtraParts: (state, action) => {
      state.productExtraParts = action.payload
    },
    setProductExtraPartsInfo: (state, action) => {
      state.productExtraPartsInfo = action.payload
    },
    setTextsList: (state, action) => {
      state.textsList = action.payload
    },
    setImagesList: (state, action) => {
      state.imagesList = action.payload
    },
    setPositionsList: (state, action) => {
      state.positionsList = action.payload
    },
    setFontList: (state, action) => {
      state.fontList = action.payload
    },
    setCurrentSelectedItem: (state, action) => {
      state.currentSelectedItem = action.payload
    },
    setOpenProducts: (state, action) => {
      state.openProducts = action.payload
    },
    setOpenTablePrice: (state, action) => {
      state.openTablePrice = action.payload
    },
    setBrandLogo: (state, action) => {
      state.brandLogo = action.payload
    },
    setBrandLogoPosition: (state, action) => {
      state.brandLogoPosition = action.payload
    },
    setBrandLogoColorDefault: (state, action) => {
      state.brandLogoColorDefault = action.payload
    },
    setCurrentModel: (state, action) => {
      state.currentModel = action.payload
    },
    setPageTitle: (state, action) => {
      state.pageTitle = action.payload
    },
    setModalOrderCreationLoadOpened: (state, action) => {
      state.modalOrderCreationLoadOpened = action.payload
    },
    setModalOrderCreationSuccessOpened: (state, action) => {
      state.modalOrderCreationSuccessOpened = action.payload
    },
    setModalOrderCreationFailOpened: (state, action) => {
      state.modalOrderCreationFailOpened = action.payload
    },
    setPriceVisible: (state, action) => {
      state.priceVisible = action.payload
    }
  }
})

export const {
  setThemeMode,
  setThemeBlur,
  setIndex,
  setPrice,
  setDesignView,
  setWidthMask,
  setTransformMask,
  setEditPartColor,
  setEditPartGradient,
  setEditPartPattern,
  setEditGlobalColor,
  setEditPartClone,
  setEditExtraPart,
  setGridView,
  setIsLoading,
  setDesignSetted,
  setWallpaperMode,
  setImagesMode,
  setImageSelected,
  setImageEdit,
  setBrandLogoEdit,
  setIndexImageSelected,
  setTextSelected,
  setTextEdit,
  setIndexTextSelected,
  setPreviewState,
  setListProduct,
  setTotalPrice,
  setColorsList,
  setPatternList,
  setDesignsList,
  setProductsList,
  setProductParts,
  setProductExtraParts,
  setProductExtraPartsInfo,
  setTextsList,
  setImagesList,
  setPositionsList,
  setFontList,
  setCurrentSelectedItem,
  setOpenProducts,
  setOpenTablePrice,
  setBrandLogo,
  setBrandLogoPosition,
  setBrandLogoColorDefault,
  setCurrentModel,
  setPageTitle,
  setModalOrderCreationLoadOpened,
  setModalOrderCreationSuccessOpened,
  setModalOrderCreationFailOpened,
  setPriceVisible
} = appSlice.actions

export const handleUpdateDesignParts = (designParts, resetDesign) => (dispatch, getState) => {
  const partsList = [];

  const appState = getState().app;

  designParts.forEach(part => {
    partsList.push({
      id: part.id,
      label: part.label,
      color: typeof part.fill === "string" ? part.fill.toUpperCase() : part.fill.startColor.toUpperCase(),
      colorPosition: 0,
      color2: typeof part.fill === "string" ? "" : part.fill.stopColor.toUpperCase(),
      color2Position: 100,
      colorsAngle: typeof part.fill === "string" ? 0 : part.fill.rotation,
      supportPattern: part.supportPattern,
      pattern: part.pattern,
      patternUrl: part.pattern ? part.pattern.url : "",
      patternAngle: part.pattern ? part.pattern.angle : 0,
      patternSize: part.pattern ? part.pattern.scale : 1,
      patternOffsetX: part.pattern ? part.pattern.offsetX : 0,
      patternOffsetY: part.pattern ? part.pattern.offsetY : 0,
      patternColor: part.pattern ? part.pattern.fill : "#1D1D1B",
    });
  });
  // console.log(partsList);
  // console.log(partsList);
  // console.log(designParts);
  // console.log(appState.productParts);

  if (appState.productParts.length > 0 && !resetDesign) {

    appState.productParts.forEach((element, index) => {
      if (index <= partsList.length) {
        if (partsList[index] && partsList[index].id === element.id) {
          if (element.color2 !== "") {
            viewerInstance.setDesignGradient(
              element.id,
              {
                startColor: element.color,
                startOffset: element.colorPosition,
                stopColor: element.color2,
                stopOffset: element.color2Position,
                rotation: element.colorsAngle
              })
          }
          else {
            viewerInstance.setDesignColor(element.id, element.color);
          }
          if (element.pattern && partsList[index].supportPattern) {
            viewerInstance.setDesignPattern(
              element.id,
              {
                url: element.patternUrl,
                fill: element.patternColor,
                scale: element.patternSize,
                angle: element.patternAngle,
                offsetX: element.patternOffsetX,
                offsetY: element.patternOffsetY,
              })
          } else {
            partsList.pattern = undefined;
          }
          const oldData = { supportPattern: partsList[index].supportPattern, label: partsList[index].label }
          partsList[index] = { ...element, ...oldData }
          // console.log(partsList[index])
        }
      }
    });
  }
  else {
    partsList.forEach((element) => {
      // console.log(element)
      if (element.color2 !== "") {
        viewerInstance.setDesignGradient(
          element.id,
          {
            startColor: element.color,
            startOffset: element.colorPosition,
            stopColor: element.color2,
            stopOffset: element.color2Position,
            rotation: element.colorsAngle
          })
      } else {
        viewerInstance.setDesignColor(element.id, element.color);
      }

      if (element.pattern) {
        viewerInstance.setDesignPattern(
          element.id,
          {
            url: element.patternUrl,
            fill: element.patternColor,
            scale: element.patternSize,
            angle: element.patternAngle,
            offsetX: element.patternOffsetX,
            offsetY: element.patternOffsetY,
          })
      }
    });
  }
  dispatch(setProductParts([...partsList]));
}

export const handleSetDesign = (design, resetDesign, skipLogo) => async (dispatch, getState) => {
  // dispatch(setIsLoading(true));
  const appState = getState().app;

  let isModelChanged = false;

  if (!appState.currentModel || appState.currentModel !== design.productName || skipLogo) {
    //clear scene
    viewerInstance.unselectItem();
    viewerInstance.removeProduct();

    await viewerInstance.addProduct(
      design.productName,
      {
        modelUrl: process.env.REACT_APP_FRONTEND_ASSETS + `viewer3d-static/models/${design.productName}.glb`,
        design: {
          url: process.env.REACT_APP_FRONTEND_ASSETS + design.src
        }
      });

    dispatch(setCurrentModel(design.productName));
    dispatch(setImagesList([]));
    dispatch(setTextsList([]));
    isModelChanged = true;
  }

  const responseSetDesign = await viewerInstance.setDesign(process.env.REACT_APP_FRONTEND_ASSETS + design.src);


  const modelInfo = viewerInstance.getProductUserData()
  dispatch(setPositionsList(modelInfo));

  // console.log("modelInfo",modelInfo)

  dispatch(handleUpdateDesignParts(responseSetDesign, resetDesign || isModelChanged));
  dispatch(setDesignSetted(design));

  const defaultLogoColor = viewerInstance.getDefaultLogoColor()
  dispatch(setBrandLogoColorDefault(defaultLogoColor));

  if (!skipLogo && ((!appState.brandLogo || isModelChanged) && modelInfo && modelInfo["LOGO_+A_POSITION_NAME"] && modelInfo["LOGO_+A_FORMAT"])) {

    if (modelInfo["LOGO_+A_FORMAT"]?.length > 1) {
      //TODO: HANDLE MULTIPLE LOGO FORMATS
    }

    const format = modelInfo["LOGO_+A_FORMAT"][0] === "short" ? "svg/piu-adrenalina-logo.svg" :
      modelInfo["LOGO_+A_FORMAT"][0] === "long" ? "svg/piu-adrenalina-logo-esteso.svg" :
        "svg/piu-adrenalina-logo.svg";

    const blob = await (await fetch(process.env.REACT_APP_FRONTEND_ASSETS + format)).blob();

    const brandLogoResponse = viewerInstance.addLogo(blob, true);

    const presetPostion0 = modelInfo["LOGO_+A_POSITION_NAME"][Object.keys(modelInfo["LOGO_+A_POSITION_NAME"])[0]];

    //HERE WE SET THE LOGO SOURCE
    dispatch(setBrandLogo({
      url: process.env.REACT_APP_FRONTEND_ASSETS + format
    }));

    dispatch(setBrandLogoPosition(Object.keys(modelInfo["LOGO_+A_POSITION_NAME"])[0]));

    // IT'S HARDCODED LIKE HELL
    if (modelInfo["LOGO_+A_FORMAT"][0] === "long") {
      await viewerInstance.setPresetPosition(
        presetPostion0.unwrapCenter.u,
        presetPostion0.unwrapCenter.v,
        120
      );
    } else {
      await viewerInstance.setPresetPosition(
        presetPostion0.unwrapCenter.u,
        presetPostion0.unwrapCenter.v,
      );
    }

    await brandLogoResponse;
  } else {
    // IF RESET DESIGN IS TRUE, WE RESET THE LOGO POSITION AND COLOR
    if ((!skipLogo && resetDesign) && modelInfo && modelInfo["LOGO_+A_POSITION_NAME"] && modelInfo["LOGO_+A_FORMAT"]) {
      const brandLogoTemp = { ...appState.brandLogo };

      const presetPostion0 = modelInfo["LOGO_+A_POSITION_NAME"][Object.keys(modelInfo["LOGO_+A_POSITION_NAME"])[0]];

      brandLogoTemp.position = {
        x: presetPostion0.unwrapCenter.u,
        y: presetPostion0.unwrapCenter.v
      };

      dispatch(setBrandLogo({ ...brandLogoTemp, color: defaultLogoColor }));

      dispatch(setBrandLogoPosition(Object.keys(modelInfo["LOGO_+A_POSITION_NAME"])[0]));
      viewerInstance.updateItem(structuredClone(brandLogoTemp))
      viewerInstance.setBrandLogoColor(defaultLogoColor);
    }
  }
  // TO DO: UNCOMMENT WHEN EXTRA PARTS ARE READY
  if (isModelChanged && modelInfo) {
    // TO DO: READ EXTRA PARTS FROM SAVED INFO
    if (modelInfo["EXTRA_PARTS_COLORS"]) {
      dispatch(setProductExtraPartsInfo(modelInfo["EXTRA_PARTS_COLORS"]));
      let tempExtraParts = [];
      modelInfo["EXTRA_PARTS_COLORS"].forEach(extraPart => {
        if (extraPart.colors && extraPart.colors.length > 0) {
          tempExtraParts.push({
            name: extraPart.partName,
            color: extraPart.defaultColor ? extraPart.defaultColor.color : extraPart.colors[0].color
          });
          viewerInstance.setProductExtraPartColor(extraPart.partName, extraPart.defaultColor ? extraPart.defaultColor.color : extraPart.colors[0].color);
        }
      });
      dispatch(setProductExtraParts(tempExtraParts));
      // console.log(tempExtraParts)
    } else {
      dispatch(setProductExtraPartsInfo([]));
      dispatch(setProductExtraParts([]));
    }
  }

  dispatch(setIndex(0));
  // dispatch(setIsLoading(false));
}

export default appSlice.reducer
