import { Button, ButtonGroup, useTheme } from "@mui/material";
// import ListIcon from '@mui/icons-material/List';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';
import CheckIcon from '@mui/icons-material/Check';
import IconSliderView from "./icons/IconSliderView";

export default function SwitchButtonButton(props) {

  const {
    listActive,
    toggleFunction,
  } = props;

  const theme = useTheme();

  return (
    <ButtonGroup sx={{
      border: `1px solid ${theme.palette.white.o01}`,
      borderRadius: "20px",
      "& .MuiButtonBase-root.MuiButton-contained": {
        p: "8px 18px !important",
        "&.active": {
          background: theme.palette.white.o01,
        }
      },
      "& .MuiButtonGroup-firstButton, .MuiButtonGroup-middleButton": {
        borderTopRightRadius: "0px !important",
        borderBottomRightRadius: "0px !important",
      },
      "& .MuiButtonGroup-lastButton, & .MuiButtonGroup-middleButton": {
        borderTopLeftRadius: "0px !important",
        borderBottomLeftRadius: "0px !important",
        marginLeft: "-1px",
      },
      "& .MuiButton-icon.MuiButton-startIcon": {
        marginRight: "0px",
      }
    }}
      disableElevation
    >
      <Button
        variant={"contained"}
        className={listActive ? "active" : ""}
        startIcon={listActive ? <CheckIcon /> : ""}
        onClick={() => {
          toggleFunction();
        }}
      >
        {/* <FormatListBulletedIcon fontSize="20px" /> */}
        <IconSliderView />
      </Button>
      <Button
        variant={"contained"}
        className={listActive ? "" : "active"}
        startIcon={listActive ? "" : <CheckIcon />}
        onClick={() => {
          toggleFunction();
        }}
      >
        <GridViewIcon fontSize="20px" />
      </Button>
    </ButtonGroup >
  )
}
