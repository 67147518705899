import { Box, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemButton, ListItemText, Typography, useTheme } from "@mui/material"
import CloseDialogButton from "./layout/CloseDialogButton"
import { useEffect } from "react"
import { DateTime } from "luxon"
import DeleteIcon from '@mui/icons-material/Delete'
import { useTranslation } from "react-i18next"

export default function ConfiguredProductsDialog(props) {
  const {
    open,
    configuredProducts,
    handleGetConfiguredProducts,
    handleLoadItem,
    handleDeleteItem,
    handleClose
  } = props

  useEffect(() => {
    if (open) {
      handleGetConfiguredProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const theme = useTheme();

  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      sx={{
        background: theme.palette.black.o05,
        "& .MuiDialog-paper": {
          background: theme.palette.dark.light08,
          backdropFilter: theme.palette.filter.blur50,
          padding: "10px 8px",
          borderRadius: "20px",
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        p: "10px 12px"
      }}>
        <DialogTitle sx={{
          mr: "auto",
          p: 0,
        }}>
          {t("label_configured_products")}
        </DialogTitle>
        <CloseDialogButton
          size="small"
          handleClose={handleClose}
        />
      </Box>
      <DialogContent sx={{
        display: 'flex',
        p: "10px 12px",
        flexDirection: 'column',
        minWidth: '384px',
        // maxWidth: '384px',
      }}>
        {configuredProducts.length === 0 &&
          <Typography>
            {t("label_no_configured_product")}
          </Typography>
        }
        <List>
          {
            configuredProducts.map((configuredProduct, i) => {
              const shortId = configuredProduct.id.substring(32, 36)
              const productId = configuredProduct.product_id
              const designId = configuredProduct.design_id
              const updatedAt = DateTime.fromISO(configuredProduct.updated_at).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
              const handleLoad = () => handleLoadItem(configuredProduct.id)
              const handleDelete = e => {
                e.stopPropagation()
                handleDeleteItem(configuredProduct.id)
              }
              return (
                <ListItem
                  key={'list-item-' + i}
                  disablePadding
                  onClick={handleLoad}
                >
                  <ListItemButton>
                    <ListItemText>
                      {configuredProduct.id}
                    </ListItemText>
                    <ListItemText>
                      {shortId}
                    </ListItemText>
                    <ListItemText>
                      {productId}/{designId}
                    </ListItemText>
                    <ListItemText>
                      {updatedAt}
                    </ListItemText>
                    <IconButton
                      size="small"
                      onClick={handleDelete}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </ListItemButton>
                </ListItem>
              )
            })
          }
        </List>
      </DialogContent>
    </Dialog>
  )
}
