import axios, { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'
import { getErrorMessage } from '../helpers/error'
import { snackbarVariants } from '../helpers/layout'
import { getLocalStorageAccessToken, getLocalStorageAnonymousId } from '../helpers/auth'
import { store } from '../store/store'
import { refreshTokenOnAxios401Error } from '../slices/authSlice'

axios.interceptors.response.use(
  response => {
    return response
  },
  async error => {
    const requestUrl = error.config?.url
    const is401error = error instanceof AxiosError
      && error.response?.status === 401
    if (!is401error || requestUrl === 'refresh-token')
      enqueueSnackbar(getErrorMessage(error), { variant: snackbarVariants.error })
    return Promise.reject(error)
  }
)

const createRequestBackend = params => {
  const requestParams = {
    ...params,
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      'Accept': 'application/json',
      ...params.headers,
    },
    // withCredentials: false
  }
  return requestParams
}

const createAuthRequestBackend = params => {
  const requestParams = createRequestBackend(params)
  requestParams.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
  return requestParams
}

const createOptionalAuthRequestBackend = params => {
  const requestParams = createRequestBackend(params)
  const accessToken = getLocalStorageAccessToken()
  if (accessToken)
    requestParams.headers.Authorization = 'Bearer ' + accessToken
  return requestParams
}

const createAnonymousOrAuthRequestBackend = params => {
  const requestParams = createRequestBackend(params)
  const accessToken = getLocalStorageAccessToken()
  requestParams.headers.Authorization = 'Bearer ' + (accessToken ? accessToken : getLocalStorageAnonymousId())
  return requestParams
}

const login = async loginData => {
  const params = createRequestBackend({
    url: 'login',
    method: 'POST',
    data: {
      username: loginData.username,
      password: loginData.password
    }
  })
  const res = await axios.request(params)
  return res.data
}

const logout = async () => {
  const params = createAuthRequestBackend({
    url: 'logout',
    method: 'POST'
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const refreshToken = async refreshToken => {
  const params = createRequestBackend({
    url: 'refresh-token',
    method: 'POST',
    data: {
      refreshToken
    }
  })
  const res = await axios.request(params)
  return res.data
}

const createUpdateAnonymousOwner = async id => {
  const params = createRequestBackend({
    url: 'anonymous-owner',
    method: 'POST',
    data: {
      ownerId: id
    }
  })
  const res = await axios.request(params)
  return res.data
}

const getProducts = async lang => {
  const params = createOptionalAuthRequestBackend({
    url: 'products',
    method: 'GET',
    params: {
      lang
    }
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const addToCart = async requestData => {
  const params = createAuthRequestBackend({
    url: 'add-to-cart',
    method: 'POST',
    data: requestData
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const getColors = async () => {
  const params = createRequestBackend({
    url: 'colors',
    method: 'GET'
  })
  const res = await axios.request(params)
  return res.data
}

const getPatterns = async () => {
  const params = createRequestBackend({
    url: 'patterns',
    method: 'GET'
  })
  const res = await axios.request(params)
  return res.data
}

const createConfiguredProduct = async data => {
  const params = createAnonymousOrAuthRequestBackend({
    url: 'configured-product',
    method: 'POST',
    data
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const getConfiguredProducts = async () => {
  const params = createAnonymousOrAuthRequestBackend({
    url: 'configured-product',
    method: 'GET'
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const getConfiguredProduct = async id => {
  const params = createAnonymousOrAuthRequestBackend({
    url: `configured-product/${id}`,
    method: 'GET'
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const updateConfiguredProduct = async (id, data) => {
  const params = createAnonymousOrAuthRequestBackend({
    url: `configured-product/${id}`,
    method: 'PUT',
    data
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const deleteConfiguredProduct = async id => {
  const params = createAnonymousOrAuthRequestBackend({
    url: `configured-product/${id}`,
    method: 'DELETE'
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const createUploadedResource = async requestData => {
  const params = createAnonymousOrAuthRequestBackend({
    url: `uploaded-resource`,
    method: 'POST',
    headers: {
      'content-type': 'multipart/form-data'
    }
  })
  const formData = new FormData()
  formData.append('image', requestData.image)
  formData.append('configuredProductId', requestData.configuredProductId)
  params.data = formData
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const getUploadedResource = async id => {
  const params = createAnonymousOrAuthRequestBackend({
    url: `uploaded-resource/${id}`,
    method: 'GET',
    responseType: 'blob'
  })
  let res
  try {
    res = await axios.request(params)
  }
  catch (err) {
    await store.dispatch(refreshTokenOnAxios401Error(err))
    params.headers.Authorization = 'Bearer ' + getLocalStorageAccessToken()
    res = await axios.request(params)
  }
  return res.data
}

const api = {
  login,
  logout,
  refreshToken,
  createUpdateAnonymousOwner,
  getProducts,
  addToCart,
  getColors,
  getPatterns,
  createConfiguredProduct,
  getConfiguredProducts,
  getConfiguredProduct,
  updateConfiguredProduct,
  deleteConfiguredProduct,
  createUploadedResource,
  getUploadedResource
}

export default api
