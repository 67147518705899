import { Box, Slider, useTheme } from "@mui/material";


export default function ColorGradientSelector(props) {

  const {
    selectedPart,
    gradientPosition,
    handleChangeGradient,
    setColorselected,
    colorSelected
  } = props;

  const theme = useTheme();

  return(
    <Box className="panel-content">
      <Box sx={{
        padding: "2px 12px",
        background: `linear-gradient(90deg,${selectedPart.color} ${gradientPosition[0]}%, ${selectedPart.color2} ${gradientPosition[1]}%)`,
        outline: `1px solid ${theme.palette.white.o05}`,
        borderRadius:"30px",
        height: "24px",
      }}>
        <Slider
          getAriaLabel={() => 'Minimum distance shift'}
          value={gradientPosition}
          onChange={handleChangeGradient}
          valueLabelDisplay="auto"
          disableSwap
          onPointerDown={(e) =>{
            if(e.target.dataset.index){
              //console.log(e.target.dataset.index);
              if(e.target.dataset.index === "0"){
                setColorselected(1)
              }
              if(e.target.dataset.index === "1"){
                setColorselected(2)
              }
            }
          }}
          sx={{
            "&": {
              height: "20px",
              borderRadius: "30px",
              opacity: 1,
              boxSizing: "border-box",
              background: "transparent",
              padding:"0px"
            },
            "& .MuiSlider-rail": {
              boxSizing: "border-box",
              maxWidth: "100%",
              opacity: 1,
              background: "transparent",
              outline: "0px"
            },
            "& .MuiSlider-thumb": {
              borderRadius: "30px",
              height: "16px",
              width: "16px",
              background: "transparent",
              "&::before, &::after, &:hover, &.Mui-focusVisible, &.Mui-active": {
                boxShadow: 'unset',
              },
              "&[data-index='0']": {
                outline: colorSelected === 1 ? `2px solid ${theme.palette.white.main}` : `1px solid ${theme.palette.white.main}` ,
                outlineOffset: colorSelected === 1 ? "-1px" : "-1px",
                background: selectedPart.color,
                boxShadow: colorSelected === 1 ? `0px 0px 0px 2px ${theme.palette.black.main}` : `0px 0px 0px 1px ${theme.palette.black.main}`
              },
              "&[data-index='1']": {
                outline: colorSelected === 2 ? `2px solid ${theme.palette.white.main}` : `1px solid ${theme.palette.white.main}` ,
                outlineOffset: colorSelected === 2 ? "-1px" : "-1px",
                background: selectedPart.color2,
                boxShadow: colorSelected === 2 ? `0px 0px 0px 2px ${theme.palette.black.main}` : `0px 0px 0px 1px ${theme.palette.black.main}`
              }
            },
            "& .MuiSlider-track": {
              background: "transparent",
              border: "0px"
            }
          }}
        />
      </Box>
    </Box>
  )
}
