import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconOffsetY = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 21"
        width="20"
      >
        <path d="M5 12.166V2.16602H15V12.166H5ZM6.66667 10.4993H13.3333V3.83268H6.66667V10.4993ZM13.3333 15.4993V13.8327H15V15.4993H13.3333ZM5 15.4993V13.8327H6.66667V15.4993H5ZM13.3333 18.8327V17.166H15V18.8327H13.3333ZM9.16667 18.8327V17.166H10.8333V18.8327H9.16667ZM5 18.8327V17.166H6.66667V18.8327H5Z" fill="currentColor"/>
      </svg>
    </SvgIcon>
  );
};
export default IconOffsetY;
