import { Box, CircularProgress, circularProgressClasses, Dialog, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function ModalOrderCreationLoad(props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    open
  } = props

  return (
    <Dialog
      open={open}
      sx={{
        zIndex: theme.zIndex.appBar + 300,
        background: theme.palette.black.o05,
        backdropFilter: theme.palette.filter.blur50,
        "& .MuiDialog-paper": {
          background: "rgba(51, 51, 51, 0.85)",
          padding: "10px 8px",
          borderRadius: "20px",
          width: '350px',
        }
      }}
    >
      <Box sx={{
        padding: "10px 8px"
      }}>
        <Box
          display='flex'
          justifyContent='center'
        >
          <Box sx={{
            position: 'relative'
          }}>
            <CircularProgress
              variant="determinate"
              sx={(theme) => ({
                color: theme.palette.grey.main
              })}
              size={80}
              thickness={4}
              value={100}
            />
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={(theme) => ({
                color: theme.palette.main,
                animationDuration: '550ms',
                position: 'absolute',
                left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              })}
              size={80}
              thickness={4}
            />
          </Box>
        </Box>
        <Typography
          mt={2}
          variant='body2'
          textAlign='center'
        >
          {t('modal_order_creation_load_description')}
        </Typography>
      </Box>
    </Dialog>
  )
}
