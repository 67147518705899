import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, useTheme } from "@mui/material";
import { ReactComponent as IconNumber} from "../icons/icon-text-number.svg";
import { ReactComponent as IconText} from "../icons/icon-text-text.svg";
import { ReactComponent as IconInitial} from "../icons/icon-text-initial.svg";
import { ReactComponent as IconName} from "../icons/icon-text-name.svg";
import { ReactComponent as IconLocketClose } from "../icons/icon-locket-close.svg";
import { ReactComponent as IconTrash } from "../icons/icon-trash.svg";
import { ReactComponent as IconClone } from "../icons/icon-clone.svg";

export default function PanelCustomTexts(props) {

  const {
    textsList,
    selectText,
    handleDuplicate,
    handleDelete
  } = props

  const theme = useTheme();
  return (
    <List>
      { textsList ? textsList.map((item, index) => (
        <ListItem
          key={"list-item-" + index}
          disablePadding
          onClick={() => {selectText(item, index) }}
          sx={{
            "& svg": {
              width: "20px",
              height: "20px",
              fill: theme.palette.text.primary
            }
          }}
        >
          <ListItemButton sx={{
            gap: "10px",
            ".actions-button":{
                display:"flex",
                pointerEvents: "none",
                opacity: "0"
              },
            "&:hover": {
              ".actions-button":{
                  pointerEvents: "auto",
                  opacity: "1"
                }
              }
            }}>
            {
              item.type === "free" ?
              <IconText /> :
              item.type === "name" ?
              <IconName /> :
              item.type === "number" ?
              <IconNumber /> :
              item.type === "initials" ?
              <IconInitial /> : ""
            }
            <ListItemText primary={item.text +" "+ item.id} />
            {/* <ListItemText primary={item.text} /> */}
            <Box className="actions-button" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}>
            <IconButton
              sx={{
                display: "flex",
                marginRight: "5px",
              }}
              className="fab-transparent"
              aria-label="add"
              size="small"
              onClick={() => {handleDuplicate(item.id)}}
            >
              <IconClone />
            </IconButton>
            <IconButton
              sx={{ display: "flex" }}
              className="fab-transparent"
              aria-label="add"
              size="small"
              onClick={() => {handleDelete(item.id)}}
            >
              <IconTrash />
            </IconButton>
            </Box>
            <Box sx={{
              padding: "2px",
              borderRadius: "5px",
              background: theme.palette.white.main,
              display: item.isLocked ? "flex" : "none",
              aspectRatio: 1,
              "& svg": {
                fill: theme.palette.black.main,
              }
            }}>
              <IconLocketClose />
            </Box>
          </ListItemButton>
        </ListItem>
      )): ""}
    </List>

  )
}
