import { Box } from "@mui/material";

export default function Divider(props) {

  return (
    <Box
        className="divider"
        sx={{ backgroundColor: props.bgcolor }}>
    </Box>
  )
}
