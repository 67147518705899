import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconBackgroundOffsetThick = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 20"
        width="20"
      >
        <path d="M13,7v6H7V7H13z M13,3H5C3.9,3,3,3.9,3,5v10c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2H13z" />
      </svg>
    </SvgIcon>
  );
};
export default IconBackgroundOffsetThick;
