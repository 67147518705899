import { Box } from "@mui/material";

export default function CustomTabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="tabpanelz"
      sx={{
        display: value !== index ? "none" : "flex",
        flexDirection: "column",
        justifyContent: "center",
        flexGrow: "2",
        overflow: "hidden",
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
