import { Box, Typography } from "@mui/material"

export default function ErrorPage(props) {
  const { message } = props

  return (
    <Box
      textAlign='center'
    >
      <Typography mt={10}>
        Impossibile inizializzare l'applicazione
      </Typography>
      <Typography mt={1}>
        {message}
      </Typography>
    </Box>
  )
}
