import { Box, Typography, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
// import CachedIcon from "@mui/icons-material/Cached";
import IconDeleteCustom from "../components/icons/IconDeleteCustom.jsx";

const FilePreview = (props) => {
  const {
    id,
    className,
    name,
    url,
    // blob,
    fileDimension,
    labelDimension,
    // handleRotate,
    handleDelete
  } = props;

  const theme = useTheme();

  return (
    <Box
      id={id}
      className={`file-preview ${className}`}
      sx={{
        bgcolor: "transparent",
        p: "0px",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        boxShadow: 0,
        "&.error": {
          bgcolor: theme.palette.messages.errorBackground,
          p: "10px",
        },
        "&.confirm": {
          bgcolor: theme.palette.messages.confirmBackground,
          p: "10px",
        },
      }}
    >
      <Box
        className="img-png-container"
        sx={{
          backgroundImage: `url(${"../../assets/imgs/Thumbnail-BG-pattern_48.png"})`,
        }}
      >
        <Box
          className="img-file-preview"
          sx={{
            backgroundImage: `url("${url}")`,
          }}
        ></Box>
      </Box>
      <Box
        className="text-file-preview"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0,
          marginRight: "auto",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontWeight: 600,
            color: theme.palette.white.main,
            wordBreak: "break-word"
          }}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.white.o07,
          }}
        >
          {labelDimension}&nbsp;•&nbsp;{fileDimension}
        </Typography>
      </Box>
      <Box
        className="actions-file-preview"
        sx={{ display: "flex", gap: "10px" }}
      >
        {/* <IconButton aria-label="cached" size="small" onClick={handleRotate}> */}
          {/* <CachedIcon fontSize="small" /> */}
        {/* </IconButton> */}
        <IconButton aria-label="delete" size="small" onClick={handleDelete}>
          <IconDeleteCustom fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};
export default FilePreview;
