import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const IconDeleteCustom = (props) => {
  const { className } = props;
  return (
    <SvgIcon className={className} sx={{ width: "20px", height: "20px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        height="20"
        viewBox="0 0 20 21"
        width="20"
      >
        <path d="m6.5,17.5c-.41,0-.77-.15-1.06-.44-.29-.29-.44-.65-.44-1.06V6h-1v-1.5h4v-1h4v1h4v1.5h-1v9.99c0,.42-.15.78-.44,1.07-.29.29-.65.44-1.06.44h-7Zm1.5-3h1.5v-7h-1.5v7Zm2.5,0h1.5v-7h-1.5v7Z" />
      </svg>
    </SvgIcon>
  );
};
export default IconDeleteCustom;
