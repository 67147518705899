import CloseIcon from "@mui/icons-material/Close";
import PanelUploadImage from "./PanelUploadImage";
import ButtonRound from "./ButtonRound";
import { Box, FilledInput, FormControl, IconButton, InputAdornment, Slider, Tooltip, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import ColorSelector from "./ColorSelector";
// import StarRateIcon from "@mui/icons-material/StarRate";
// import PaletteIcon from "@mui/icons-material/Palette";
import Divider from "./Divider";
import CardPlacement from "./CardPlacement";
import SwitchCheck from "./SwitchCheck";
import AccordionMessage from "./AccordionMessage";
// import InputUploadFile from "./InputUploadFile";
import FilePreview from "./FilePreview";
// import AddIcon from "@mui/icons-material/Add";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import HeightIcon from "@mui/icons-material/Height";
import IconWidth from "./icons/IconWidth";
import IconNull from "./icons/IconNull";
import IconStrokeWidthThin from "./icons/IconStrokeWidthThin";
import IconStrokeWidthMedium from "./icons/IconStrokeWidthMedium";
import IconStrokeWidthThick from "./icons/IconStrokeWidthThick";
import IconStrokeJoinMiter from "./icons/IconStrokeJoinMiter";
import IconStrokeJoinRound from "./icons/IconStrokeJoinRound";
import IconStrokeJoinBevel from "./icons/IconStrokeJoinBevel";
// import { ReactComponent as IconImagesmode } from "../icons/icon-imagesmode.svg";
// import { ReactComponent as IconWallpaper } from "../icons/icon-wallpaper.svg";
import { ReactComponent as IconClone } from "../icons/icon-clone.svg";
import { ReactComponent as IconLocketOpen } from "../icons/icon-locket-open.svg";
import { ReactComponent as IconLocketClose } from "../icons/icon-locket-close.svg";
import { ReactComponent as IconTrash } from "../icons/icon-trash.svg";
import { ReactComponent as IconLayers } from "../icons/icon-bring-up.svg";
import RotateRightIcon from "@mui/icons-material/RotateRight";
// import LockIcon from "@mui/icons-material/Lock";
// import ErrorIcon from "@mui/icons-material/Error";
import { filesize } from "filesize";
import ColorsListSelector from "./ColorsListSelector";
import { viewerInstance } from "../helpers/Viewer"
// import { debounce } from "lodash"


let timer;

export default function PanelCustomImage(props) {

  const {
    imageSelected,
    setImageSelected,
    handleCancel,
    handleSave,
    handleDelete,
    positionsList,
    colorsList,
    colorsInUse,
    currentSelectedItem,
    handleMirror,
    handleUnlink,
    handleUpdateImageSelected,
    imagesList,
    handleDuplicate,
    handleBringOnTop,
    handleCenterItem,
    setWaitingForPreset,
    setPresetSide
  } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  const [initialPosition, setInitialPosition] = useState(imageSelected.position_standard === null ? true : false)

  const [imageEditStatus, setImageEditStatus] = useState(imageSelected.position_standard !== null ? 0 : null);

  const [errorUpload, setErrorUpload] = useState(false);

  // const [sizeX, setSizeX] = useState(imageSelected.size_x);
  // const [sizeY, setSizeY] = useState(imageSelected.size_y);

  // useEffect(() => {
  //   setSizeX(imageSelected.size_x);
  //   setSizeY(imageSelected.size_y);
  // }, [imageSelected]);

  const handleUpdateAttributeOnImage = (attribute, value) => {
    const tempImageSelected = { ...imageSelected };
    let timeToWaitMillisecond = 250;
    if (tempImageSelected.hasOwnProperty(attribute)) {
      // CHANGE SIZE WITH SIZE_RATIO
      if (attribute === "size_x" || attribute === "size_y") {
        let size_x = tempImageSelected.size_x;
        let size_y = tempImageSelected.size_y;
        timeToWaitMillisecond = 1000;
        if (attribute === "size_x") {
          size_x = value;
          size_y = parseFloat(value) / tempImageSelected.size_ratio;
        }
        if (attribute === "size_y") {
          size_y = value;
          size_x = parseFloat(value) * tempImageSelected.size_ratio;
        }

        tempImageSelected["size_x"] = size_x.toString();
        tempImageSelected["size_y"] = size_y.toString();
      } else {

        tempImageSelected[attribute] = value;
      }
    }


    if (currentSelectedItem && currentSelectedItem.name === tempImageSelected.id) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        handleUpdateList({ ...tempImageSelected }, { ...currentSelectedItem });
      },
        timeToWaitMillisecond);
    } else {
      setImageSelected(tempImageSelected);
    }
    // console.log("NOT-DEBOUNCE", attribute, value)
    setImageSelected(tempImageSelected);
  }

  const handleUpdateList = (tempImageSelected,) => {
    // console.log("DEBOUNCE")
    const updateItem = {
      ...currentSelectedItem, ...{
        rotation: tempImageSelected.angle,
        size: { width: parseFloat(tempImageSelected["size_x"]), height: parseFloat(tempImageSelected["size_y"]) },
        isLocked: tempImageSelected.isLocked,
        backgroundColor: tempImageSelected.background ? tempImageSelected.background_color : null,
        backgroundBorderWidth: tempImageSelected.background_size,
        backgroundBorderJoin: tempImageSelected.background_angle,
      }
    };

    const tempItem = JSON.stringify(updateItem);
    const itemToUpdate = JSON.parse(tempItem);

    const responseUpdate = viewerInstance.updateItem(itemToUpdate);

    // console.log("responseUpdate", tempImageSelected, responseUpdate);
    handleUpdateImageSelected({ ...tempImageSelected }, responseUpdate, [...imagesList])
  }


  // TO REFACTOR
  const handleDeleteImage = () => {
    const tempImageSelected = { ...imageSelected }
    const emptyImage = {
      id: "",
      name: "",
      url: "",
      blob: null,
      size: filesize(0),
      position_standard: null,
      isLocked: false,
    }
    setImageSelected({ ...tempImageSelected, ...emptyImage })
  }

  const handleSetImageSelected = async (props) => {
    setImageSelected(props);
    await handleAddLogo(props)
  }

  const handleSelectInitialPosition = async (el, name) => {
    handleUpdateAttributeOnImage("position_standard", name);
    // await handleAddLogo({...imageSelected})
    setInitialPosition(false);
    setImageEditStatus(0);

    viewerInstance.setPresetPosition(
      el.unwrapCenter.u,
      el.unwrapCenter.v,
      el.height * 10 //cm to mm
    );

    setWaitingForPreset(true);
    setPresetSide(el.side);
  }

  const handleAddLogo = async (props) => {
    if (props.url) {
      const blob = await (await fetch(props.url)).blob();
      let response = await viewerInstance.addLogo(blob);
      if (response) {
        // console.log("addLogo response", response)
        const id = {
          id: response.name,
          position_standard: 0
        };
        setInitialPosition(false);
        setImageEditStatus(0);
        handleUpdateImageSelected({ ...props, ...id }, response, [...imagesList]);
      }
    }
  }

  return (
    <Box sx={{
      position: "relative"
    }}>
      <Tooltip title={!imageSelected.isLocked ? "" : "The item is locked, unlock to interact"} followCursor>
        <>
          {
            !imageSelected.url || errorUpload ?
              // SECTION TU UPLOAD IMAGE IF IMAGE IS NOT SETTED
              <PanelUploadImage
                imageSelected={imageSelected}
                setImageSelected={handleSetImageSelected}
                handleCancel={handleCancel}
                errorUpload={errorUpload}
                setErrorUpload={setErrorUpload}
              />
              :
              // SECTION TU PLACE THE IMAGE IF IMAGE IS SETTED
              <>
                <Box
                  id="text-placement-and-style"
                  sx={{
                    "&.panel-disabled .panel-content, & .panel-disabled.panel-content": {
                      opacity: 0.3,
                      cursor: "default",
                      pointerEvents: "none",
                      "& .active": {
                        background: "transparent"
                      }
                    }
                  }
                  }
                  className={imageSelected.isLocked ? "panel-disabled" : ""}
                >
                  {/*TEXT PLACEMENT*/}
                  <Box
                    id="text-placement"
                  // sx={{ display: imageEditBackground ? "block" : "none" }}
                  >
                    <Box className="panel-box">
                      <Box
                        className="panel-header"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          ".fab-transparent": {
                            "svg": {
                              width: "20px",
                              height: "20px",
                              fill: theme.palette.text.primary
                            }
                          }
                        }}
                      >
                        <Typography variant="h4" sx={{ marginRight: "auto" }}>
                          {t("panel_title_upload_image")}
                        </Typography>

                        <IconButton
                          aria-label="add"
                          size="small"
                          onClick={handleCancel}
                          sx={{ display: initialPosition ? "flex" : "none" }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>

                        <IconButton
                          sx={{
                            display: initialPosition ? "none" : "flex",
                            marginRight: "5px",
                          }}
                          className="fab-transparent"
                          aria-label="add"
                          size="small"
                          onClick={() => { handleBringOnTop() }}
                        >
                          <IconLayers />
                        </IconButton>

                        <IconButton
                          sx={{
                            display: initialPosition ? "none" : "flex",
                            marginRight: "5px",
                          }}
                          className="fab-transparent"
                          aria-label="add"
                          size="small"
                          onClick={() => { handleUpdateAttributeOnImage("isLocked", !imageSelected.isLocked) }}
                        >
                          {imageSelected.isLocked ? <IconLocketClose /> : <IconLocketOpen />}
                        </IconButton>
                        <IconButton
                          sx={{
                            display: initialPosition ? "none" : "flex",
                            marginRight: "5px",
                          }}
                          className="fab-transparent"
                          aria-label="add"
                          size="small"
                          onClick={handleDuplicate}
                        >
                          <IconClone />
                        </IconButton>
                        <IconButton
                          sx={{ display: initialPosition ? "none" : "flex" }}
                          className="fab-transparent"
                          aria-label="add"
                          size="small"
                          onClick={handleDelete}
                        >
                          <IconTrash />
                        </IconButton>
                      </Box>

                      <Box className="panel-content">
                        <FilePreview
                          id="file-preview-1"
                          className="confirm"
                          name={imageSelected.name}
                          url={imageSelected.url}
                          // blob={imageSelected.blob}
                          fileDimension={imageSelected.size}
                          labelDimension={t("label_size")}
                          handleRotate={handleDeleteImage}
                          handleDelete={handleDelete}
                        ></FilePreview>
                      </Box>

                      {imageSelected.isMirrorable ?
                        <>
                          <Box className="panel-content">
                            <Box className="list-item">
                              <Box sx={{ marginRight: "auto" }}>
                                <Typography variant="body1">
                                  {t("label_mirroring_title")}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={{ color: theme.palette.white.o07 }}
                                >
                                  {t("label_mirroring_description")}
                                </Typography>
                              </Box>
                              <SwitchCheck toggleFunction={handleMirror} value={imageSelected.hasLink ? true : false}></SwitchCheck>
                            </Box>
                          </Box>
                          {imageSelected.hasLink ?
                            <Box className="panel-content">
                              <AccordionMessage
                                className=""
                                title={t("info_title_image_connected")}
                                text={t("info_description_image_connected")}
                                btnText={t("info_btn_image_connected")}
                                onClick={handleUnlink}
                              />
                            </Box> : ""}
                        </> : ""}

                    </Box>



                    {/* INITIAL POSITION */}

                    <Box
                      className="panel-box"
                      sx={{ display: initialPosition ? "block" : "none", marginTop: "10px", offseY: "auto" }}
                    >
                      <Box
                        className="panel-header"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h4" sx={{ marginRight: "auto" }}>
                          {t("panel_title_positioning")}
                        </Typography>
                      </Box>
                      <Box className="panel-content">
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.white.o07,
                          }}
                        >
                          {t("panel_description_positioning_image")}
                        </Typography>
                      </Box>
                      <Box className="panel-content container-card-placement">
                        {
                          Object.keys(positionsList["USER_LOGO/TEXT_POSITION_NAME"]).map((name, index) => {
                            const el = positionsList["USER_LOGO/TEXT_POSITION_NAME"][name];
                            return (
                              <CardPlacement key={"initialPosition_" + index}
                                onClick={() => { handleSelectInitialPosition(el) }}
                                className="card-placement"
                                imgsrc={process.env.REACT_APP_FRONTEND_ASSETS + "placer-icon/" + positionsList["PRODUCT_TYPE"] + "_" + name + ".svg"}
                                text={t("option_position_label_" + name)}
                              ></CardPlacement>
                            )
                          })

                        }
                      </Box>
                    </Box>
                  </Box>


                  {/*IMAGE STYLE*/}

                  <Box
                    id="text-style"
                    sx={{ display: imageEditStatus !== null ? "block" : "none", marginTop: "10px" }}
                  >
                    {/* <Box className="panel-actions">
                    <IconButton
                      //className="parts-icons active"
                      className={`parts-icons ${imageEditStatus === 0 ? "active" : ""}`}
                      aria-label="wallpaper"
                      size="large"
                      onClick={() => {
                        setImageEditStatus(0);
                      }}
                    >
                      <IconWallpaper width="32" height="32" />
                    </IconButton>
                    <IconButton
                      //className="parts-icons"
                      className={`parts-icons ${imageEditStatus === 1 ? "active" : ""}`}
                      aria-label="imagesmode"
                      size="large"
                      onClick={() => {
                        setImageEditStatus(1);
                      }}
                    >
                      <IconImagesmode width="32" height="32" />
                    </IconButton>
                  </Box> */}

                    <Box
                      className="panel-box"
                    >
                      <Box className="panel-content">
                        <Box className="list-item" sx={{ gap: "10px" }}>
                          <Typography variant="body1" sx={{ marginRight: "auto" }}>
                            {t("panel_title_image_dimensions")}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <IconWidth></IconWidth>
                            <FormControl variant="filled">

                              {/* Scrive solo numeri e "." Vedi useEffect usato qui sopra */}

                              <FilledInput
                                className="MuiInput-number"
                                id="filled-adornment-mm"
                                size="small"
                                placeholder="000.00"
                                value={imageSelected.size_x}
                                // value={sizeX}
                                onChange={(e) => {
                                  // setSizeX(e.target.value)
                                  handleUpdateAttributeOnImage("size_x", e.target.value)
                                }}
                                endAdornment={
                                  <InputAdornment position="end">mm</InputAdornment>
                                }
                                //aria-describedby="filled-mm-helper-text"
                                inputProps={{
                                  step: 1,
                                  min: 1,
                                  max: 600,
                                  type: "number",
                                  "aria-label": "mm",
                                }}
                              />
                            </FormControl>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "2px",
                            }}
                          >
                            <HeightIcon
                              sx={{ width: "20px", height: "20px" }}
                            ></HeightIcon>
                            <FormControl variant="filled">
                              {/* Scrive solo numeri e "." Vedi useEffect usato qui sopra */}
                              <FilledInput
                                className="MuiInput-number"
                                id="filled-adornment-mm"
                                size="small"
                                placeholder="000.00"
                                value={imageSelected.size_y}
                                // value={sizeY}
                                onChange={(e) => {
                                  // setSizeY(e.target.value)
                                  handleUpdateAttributeOnImage("size_y", e.target.value)
                                }}
                                endAdornment={
                                  <InputAdornment position="end">mm</InputAdornment>
                                }
                                //aria-describedby="filled-mm-helper-text"
                                inputProps={{
                                  step: 1,
                                  min: 1,
                                  max: 600,
                                  type: "number",
                                  "aria-label": "mm",
                                }}
                              />
                            </FormControl>
                          </Box>
                        </Box>
                      </Box>
                      <Box className="panel-divider">
                        <Divider bgcolor={theme.palette.white.o015}></Divider>
                      </Box>
                      <Box className="panel-content">
                        <Box className="list-item">
                          <RotateRightIcon
                            sx={{ color: theme.palette.white.main, marginRight: "5px" }}
                          />
                          <Slider
                            className="custom-slider"
                            value={Number(imageSelected.angle)}
                            onChange={(e) => {
                              handleUpdateAttributeOnImage("angle", e.target.value);
                            }}
                            aria-labelledby="input-slider"
                            sx={{ maxWidth: "calc(100% - 149px)" }}
                            step={1}
                            min={0}
                            max={360}
                          />
                          <FormControl variant="filled">
                            <FilledInput
                              className="MuiInput-number"
                              id="filled-adornment-deg"
                              size="small"
                              placeholder="000.00"
                              value={Number(imageSelected.angle)}
                              onChange={(e) => {
                                handleUpdateAttributeOnImage("angle", e.target.value);
                              }}
                              inputProps={{
                                step: 1,
                                min: 0,
                                max: 360,
                                type: "number",
                                "aria-label": "deg",
                              }}
                            />
                          </FormControl>
                        </Box>
                      </Box>

                      <Box className="panel-divider">
                        <Divider bgcolor={theme.palette.white.o015}></Divider>
                      </Box>

                      <Box className="panel-content">
                        <Box className="list-item">
                          <Typography variant="body1" sx={{ marginRight: "auto" }}>
                            {t("panel_title_image_align_object")}
                          </Typography>
                          <IconButton
                            className="fab-small"
                            aria-label="align-object"
                            size="small"
                            onClick={handleCenterItem}
                          >
                            <AlignHorizontalCenterIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      </Box>

                      <Box className="panel-divider">
                        <Divider bgcolor={theme.palette.white.o015}></Divider>
                      </Box>

                      <Box className="panel-content">
                        <Box className="list-item">
                          <Typography variant="body1" sx={{ marginRight: "auto" }}>
                            {t("panel_title_image_background")}
                          </Typography>
                          <SwitchCheck
                            value={imageSelected.background}
                            toggleFunction={() => { handleUpdateAttributeOnImage("background", !imageSelected.background) }}
                          ></SwitchCheck>
                        </Box>
                      </Box>

                      <ColorsListSelector
                        disable={!imageSelected.background}
                        colorsInUse={colorsInUse}
                        colorsList={colorsList}
                        color={imageSelected.background_color}
                        color2={false}
                        handleUpdateColor={(value) => { handleUpdateAttributeOnImage("background_color", value) }}
                        handleUpdateColors={() => { }}
                        editGradient={false}
                      />

                      <Box className="panel-divider">
                        <Divider bgcolor={theme.palette.white.o015}></Divider>
                      </Box>

                      {/* IMAGE BACKGROUND - SIZE */}

                      <Box className={"panel-content " + (imageSelected.background !== false ? "" : "panel-disabled")}>
                        <Box className="list-item">
                          <Typography variant="body1" sx={{ marginRight: "auto" }}>
                            {t("panel_title_image_background_size")}
                          </Typography>
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            <IconButton
                              className={"fab-small " + (imageSelected.background_size === 0 ? "active" : "")}
                              onClick={() => { handleUpdateAttributeOnImage("background_size", 0) }}
                              aria-label="null"
                              size="small"
                            >
                              <IconNull fontSize="small" />
                            </IconButton>
                            <IconButton
                              className={"fab-small " + (imageSelected.background_size === 3 ? "active" : "")}
                              onClick={() => { handleUpdateAttributeOnImage("background_size", 3) }}
                              aria-label="stroke-width-thin"
                              size="small"
                            >
                              <IconStrokeWidthThin fontSize="small" />
                            </IconButton>
                            <IconButton
                              className={"fab-small " + (imageSelected.background_size === 5 ? "active" : "")}
                              onClick={() => { handleUpdateAttributeOnImage("background_size", 5) }}
                              aria-label="stroke-width-medium"
                              size="small"
                            >
                              <IconStrokeWidthMedium fontSize="small" />
                            </IconButton>
                            <IconButton
                              className={"fab-small " + (imageSelected.background_size === 8 ? "active" : "")}
                              onClick={() => { handleUpdateAttributeOnImage("background_size", 8) }}
                              aria-label="stroke-width-thick"
                              size="small"
                            >
                              <IconStrokeWidthThick fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>

                      <Box className="panel-divider">
                        <Divider bgcolor={theme.palette.white.o015}></Divider>
                      </Box>

                      {/* IMAGE BACKGROUND - ANGLE */}

                      <Box className={"panel-content " + (imageSelected.background !== false ? "" : "panel-disabled")}>
                        <Box className="list-item">
                          <Typography variant="body1" sx={{ marginRight: "auto" }}>
                            {t("panel_title_image_angle_shape")}
                          </Typography>
                          <Box sx={{ display: "flex", gap: "5px" }}>
                            <IconButton
                              className={"fab-small " + (imageSelected.background_angle === "miter" ? "active" : "")}
                              onClick={() => { handleUpdateAttributeOnImage("background_angle", "miter") }}
                              aria-label="stroke-join-miter"
                              size="small"
                            >
                              <IconStrokeJoinMiter fontSize="small" />
                            </IconButton>
                            <IconButton
                              className={"fab-small " + (imageSelected.background_angle === "round" ? "active" : "")}
                              onClick={() => { handleUpdateAttributeOnImage("background_angle", "round") }}
                              aria-label="stroke-join-round"
                              size="small"
                            >
                              <IconStrokeJoinRound fontSize="small" />
                            </IconButton>
                            <IconButton
                              className={"fab-small " + (imageSelected.background_angle === "bevel" ? "active" : "")}
                              onClick={() => { handleUpdateAttributeOnImage("background_angle", "bevel") }}
                              aria-label="stroke-join-bevel"
                              size="small"
                            >
                              <IconStrokeJoinBevel fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    </Box>


                  </Box>

                </Box>

              </>
          }

          <Box className="panel-footer">
            {!initialPosition ? "" : <ButtonRound
              id="btn-cancel"
              text={t("panel_button_label_cancel")}
              variant="contained"
              className={" " + (initialPosition ? "mx-auto" : "")}
              sx={{
                marginRight: initialPosition ? "auto" : "unset",
                marginLeft: initialPosition ? "auto" : "unset"
              }}
              onClick={() => { handleCancel(); }}
            />}
            {initialPosition ? "" : <ButtonRound
              id="btn-confirm"
              text={t("panel_button_label_confirm")}
              variant="contained"
              className="mx-auto btn-white"
              onClick={() => { handleSave(); }}
            />}
          </Box>
        </>
      </Tooltip>
    </Box>
  )

}
