import { Box, Button, IconButton, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import {
  deleteConfiguredProduct,
  getConfiguredProducts, loadConfiguredProduct, saveOrUpdateConfiguration, selectDesign, setConfigurationDialogOpened,
  setDesignCheckFulfilled
} from "../slices/productSlice"
import PageLayout from "../components/layout/PageLayout"
import ConfigurationContent from "../components/ConfigurationContent"
import { toHomePage } from "../slices/navigationSlice"
import ConfiguredProductsDialog from "../components/ConfiguredProductsDialog"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModalConfirmation from "../components/ModalConfirmation"
import { setPageTitle } from "../slices/appSlice"
import { useTranslation } from "react-i18next"

export default function ConfigurationPage() {
  const { productId, designId } = useParams()
  const dispatch = useDispatch()
  const productState = useSelector(state => state.product)
  const appState = useSelector(state => state.app)
  const dispatchSelectDesign = (productId, designId) => dispatch(selectDesign(productId, designId))
  const dispatchToProductsPage = () => dispatch(toHomePage())
  const dispatchSaveOrUpdateConfiguration = () => dispatch(saveOrUpdateConfiguration())
  const dispatchOpenConfigurationDialog = () => dispatch(setConfigurationDialogOpened(true))
  const dispatchCloseConfigurationDialog = () => dispatch(setConfigurationDialogOpened(false))
  const dispatchGetConfiguredProducts = () => dispatch(getConfiguredProducts())
  const dispatchLoadConfiguredProduct = id => dispatch(loadConfiguredProduct(id))
  const dispatchDeleteConfiguredProduct = id => dispatch(deleteConfiguredProduct(id))
  const dispatchSetPageTitle = title => dispatch(setPageTitle(title))
  const { t } = useTranslation();

  useEffect(() => {
    dispatchSelectDesign(productId, designId)
    dispatchSetPageTitle(null)
    return () => dispatch(setDesignCheckFulfilled(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    dispatchSelectDesign(productId, designId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productState.products])

  const [modalResetAndGoToProductsOpen, setModalResetAndGoToProductsOpen] = useState(false);

  if (productState.designCheckFulfilled) {
    return (
      <PageLayout
        showBuyButton={true}
      >
        <Box sx={{
          position: "absolute",
          top: '90px',
          left: '20px',
          display: 'flex',
          flexDirection: "row",
          gap: "20px",
          alignItems: 'flex-start',
        }}>
          <IconButton
            onClick={() => setModalResetAndGoToProductsOpen(true)}
            sx={{
              display: 'flex',
              flexShrink: 0,
              flexGrow: 1,
            }}>
            <ArrowBackIcon />
          </IconButton>
          <Box>
            <Typography sx={{
              fontSize: '60px',
              fontWeight: 'bold',
              lineHeight: '40px',
              mb: "10px"
            }}>{appState.designSetted?.productName}</Typography>
            <Typography variant="body2" color="grey">{productState.selectedDesign?.productDescription /*appState.designSetted?.productDescription*/}</Typography>
          </Box>
        </Box>
        {/* <Button
          sx={{
            position: "absolute",
            top: '90px',
            left: '20px',
            width: '160px',
            height: '40px',
            borderRadius: '50px',
            zIndex: theme => theme.zIndex.appBar + 100
          }}
          onClick={dispatchToProductsPage}
        >
          Prodotti
        </Button>
        <Button
          sx={{
            position: "absolute",
            top: '140px',
            left: '20px',
            width: '160px',
            height: '40px',
            borderRadius: '50px',
            zIndex: theme => theme.zIndex.appBar + 100
          }}
          onClick={dispatchToDesignsPage}
        >
          Designs
        </Button> */}
        {process.env.REACT_APP_SHOW_SAVE_LOAD_BUTTONS === 'true' &&
          <>
            <Button
              variant='contained'
              sx={{
                position: "absolute",
                top: '190px',
                left: '20px',
                width: '160px',
                height: '40px',
                borderRadius: '50px',
                zIndex: theme => theme.zIndex.appBar + 100
              }}
              onClick={dispatchSaveOrUpdateConfiguration}
            >
              Salva
            </Button>
            <Button
              variant='contained'
              sx={{
                position: "absolute",
                top: '240px',
                left: '20px',
                width: '160px',
                height: '40px',
                borderRadius: '50px',
                zIndex: theme => theme.zIndex.appBar + 100
              }}
              onClick={dispatchOpenConfigurationDialog}
            >
              Carica
            </Button>
          </>
        }
        {productState.selectedDesign ?
          <ConfigurationContent
            productId={productId}
            designId={designId}
            appState={appState}
            productState={productState}
          />
          :
          <Box sx={{
            pt: 12,
            display: "flex",
            flexGrow: 1,
            justifyContent: 'center'
          }}>
            <Typography>
              {t("label_the_product") + " " + productId + "/" + designId + " " + t("label_doesnt_exist")}
            </Typography>
          </Box>
        }
        <ConfiguredProductsDialog
          open={productState.configurationDialogOpened}
          configuredProducts={productState.configuredProducts}
          handleLoadItem={dispatchLoadConfiguredProduct}
          handleDeleteItem={dispatchDeleteConfiguredProduct}
          handleGetConfiguredProducts={dispatchGetConfiguredProducts}
          handleClose={dispatchCloseConfigurationDialog}
        />

        <ModalConfirmation
          isOpen={modalResetAndGoToProductsOpen}
          title={"modal_title_change_product"}
          description={"modal_description_change_product"}
          handleClose={() => { setModalResetAndGoToProductsOpen(false) }}
          handleContinue={() => { setModalResetAndGoToProductsOpen(false); dispatchToProductsPage(); }}
        ></ModalConfirmation>
      </PageLayout>
    )
  }
}
