import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export default function SwitchCheck(props) {

  const {
    className,
    toggleFunction,
    value
  } = props;

  const theme = useTheme();


  return (
    <Switch
      checked={value}
      className={className}
      onChange={() => {if(toggleFunction) {toggleFunction();} }}
      sx={{
          //padding: 8,
          "& .MuiSwitch-track": {
            backgroundColor: theme.palette.white.o02,
            zIndex: "100",
            pointerEvents:"none",
            "&:before, &:after": {
              content: '""',
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              width: 16,
              height: 16,
              backgroundSize: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              transition: theme.transitions.create(["opacity", "left", "right"], {
                duration: "0.3s",
              }),
            },
            "&:before": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.black.main
              )}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>')`,
              left: 9,
              right: 35,
              opacity: 1,
            },
            "&:after": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.white.main
              )}" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>')`,
              left: 9,
              right: 35,
              opacity: 0,
            },
          },
          "& .Mui-checked + .MuiSwitch-track": {
            backgroundColor: theme.palette.white.o02,
            "&:before": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.white.main
              )}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>')`,
              right: 9,
              left: 35,
              opacity: 0,
            },
            "&:after": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.black.main
              )}" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>')`,
              right: 9,
              left: 35,
              opacity: 1,
            },
          },
          "&.switch-dark .MuiSwitch-track": {
            "&:before": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.white.main
              )}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>')`,
              left: 9,
              opacity: 1,
            },
            "&:after": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.white.main
              )}" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>')`,
              right: 9,
              opacity: 0,
            },
          },
          "&.switch-dark .Mui-checked + .MuiSwitch-track": {
            "&:before": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.white.main
              )}" d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>')`,
              opacity: 0,
            },
            "&:after": {
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.white.main
              )}" d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>')`,
              opacity: 1,
            },
          },
          "& .MuiSwitch-thumb": {
            boxShadow: "none",
            width: 28,
            height: 28,
            margin: 0,
            background: theme.palette.white.main,
          },
          "& .Mui-checked + .MuiSwitch-thumb": {
            backgroundColor: theme.palette.white.main,
          },
          "&.switch-dark .MuiSwitch-thumb": {
            background: theme.palette.black.main,
          },
          "&.switch-dark .Mui-checked .MuiSwitch-thumb": {
            backgroundColor: theme.palette.black.main,
          },
      }}
    ></Switch>

  );
}

