import { IconButton } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'

export default function CloseDialogButton(props) {
  const {
    handleClose,
    size,
  } = props

  return (
    <IconButton
      size={size}
      onClick={handleClose}
      sx={{
        display: 'flex',
      }}
    >
      <CloseIcon fontSize="inherit"/>
    </IconButton>
  )
}
