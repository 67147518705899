import { Box, Typography, useTheme } from "@mui/material";
import CardItem from "../components/CardItem";
// import { useEffect, useState } from "react";
import { appBarHeight } from "../helpers/layout";
import { useTranslation } from "react-i18next";

export default function DesignGridView(props) {
  const {
    designView,
    gridView,
    designSlides,
    handleFunction
  } = props;

  const theme = useTheme()

  const { t } = useTranslation();

  // const compareByTitle = (a, b) => {
  //   if (a.title.toUpperCase() < b.title.toUpperCase()) {
  //     return -1;
  //   }
  //   if (a.title.toUpperCase() > b.title.toUpperCase()) {
  //     return 1;
  //   }
  // }

  return (

    <Box sx={{
      // display: "block",
      display: gridView ? "block" : "none",
      overflowY: "auto",
      // mt: "30px",
      pointerEvents: "auto",
      pt: `calc(30px + ${appBarHeight + "px"})`,
      pb: "80px",
      "&::-webkit-scrollbar": {
        width: "0.3em",
        borderRadius: "10px"
      },
      "&::-webkit-scrollbar-track": {
        // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgrey",
        borderRadius: "10px",
        // outline: "1px solid slategrey",
      },
    }}>
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        padding: "30px",
        paddingTop: "0px",
        maxWidth: "100%",
        [theme.breakpoints.up("md")]: {
          "& .card-item": {
            maxWidth: "50%",
          }
        },
        [theme.breakpoints.up("lg")]: {
          "& .card-item": {
            maxWidth: "33%",
          }
        },
        [theme.breakpoints.up("xl")]: {
          "& .card-item": {
            maxWidth: "25%",
          }
        }

      }}>
        {designSlides.length === 0 &&
          <Box sx={{
            mt: 10,
            display: "flex",
            flexGrow: 1,
            justifyContent: 'center'
          }}>
            <Typography>
              {t("label_no_products")}
            </Typography>
          </Box>
        }
        {designSlides.map((el, index) => {
          return (
            <CardItem
              key={"card_item_" + index}
              className="card-item"
              imgsrc={
                designView === 0 ?
                  process.env.REACT_APP_FRONTEND_ASSETS + el.imgSrcFront
                  : process.env.REACT_APP_FRONTEND_ASSETS + el.imgSrcBack
              }
              name={el.title}
              text={el.description}
              available={el.available}
              onClick={() => handleFunction(el)}
            />
          )
        })
        }
      </Box>
    </Box>
  );
}
