import { useDispatch, useSelector } from "react-redux"
import ProductsList from "../components/ProductsList"
import PageLayout from "../components/layout/PageLayout"
import { toDesignsPage } from "../slices/navigationSlice"
import { Box, Grid2, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { setPageTitle, setTotalPrice } from "../slices/appSlice"
import ProductsTreeView from "../components/ProductsTreeView"
import { appBarHeight } from "../helpers/layout"

export default function ProductsPage() {
  const dispatch = useDispatch()
  const productState = useSelector(state => state.product)
  const dispatchToProductDesignPage = productId => dispatch(toDesignsPage(productId))
  const dispatchSetTotalPrice = price => dispatch(setTotalPrice(price))
  const dispatchSetPageTitle = title => dispatch(setPageTitle(title))

  const [filteredProducts, setFilteredProducts] = useState(productState.products);

  useEffect(() => {
    dispatchSetTotalPrice(0)
    dispatchSetPageTitle("page_products_title")
  },);

  useEffect(() => {
    setFilteredProducts(productState.products)
  }, [productState.products]);

  return (
    <PageLayout>
      <Grid2 container>
        <Grid2
          size={{ xs: 3, xl: 2 }}
          sx={{
            pt: `calc(30px + ${appBarHeight + "px"})`,
            pl: "40px"
          }}>
          <ProductsTreeView
            setFilteredProducts={setFilteredProducts}
            products={productState.products}
          />
        </Grid2>

        <Grid2
          size={{ xs: 9, xl: 10 }}
          sx={{
            position: "relative",
            minHeight: "100svh",
          }}>

          <ProductsList
            productsList={filteredProducts}
            handleSelectProduct={dispatchToProductDesignPage}
          />
        </Grid2>
      </Grid2>
      <Box
        sx={{
          position: 'absolute',
          bottom: 2,
          right: 4,
        }}
      >
        <Typography sx={{ fontSize: '8px' }}>
          {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </PageLayout>
  )
}
