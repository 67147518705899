// Import Swiper React components
import * as React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Typography, Box, useTheme } from "@mui/material";
//import SwitchBtn from "./SwitchBtn";
import ButtonRound from "./ButtonRound";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  EffectCoverflow,
} from "swiper/modules";
// import CardItem from "./CardItem";
import { useTranslation } from "react-i18next";

export default function SwiperDesign(props) {
  const theme = useTheme();

  const {
    initialSlide,
    designView,
    gridView,
    designSlides,
    handleFunction
  } = props;

  const { t } = useTranslation();

  //const { designView, setDesignView } = props;
  //console.log("designView swiper", designView);
  //const { designView, setDesignView } = context;

  /* START TAB CUSTOM */
  //const [value, setValue] = React.useState(0);

  /* const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log("newValue", newValue);
  };
  */
  /* END TAB CUSTOM */
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: "2",
      // pt: "40px",
      pointerEvents: "auto",
      maxHeight: "100%",
    }}>
      {/* <SwitchBtn designView={designView}></SwitchBtn> */}
      {/* SWIPER */}
      <Box sx={{
        // display: "block",
        display: gridView ? "none" : "block",
        maxWidth: "100%",
        mb: "auto",
        flexGrow: "2",
      }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
            //background: theme.palette.black.o05,
            position: "realtive",
            pointerEvents: "all",
            // paddingTop: "40px",
            flexGrow: "2",
            // my:"auto",
            height: "100%",
            pb: "30px",
            "& .swiper ": {
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              // alignItems: "center",
              marginTop: "28px",
              userSelect: "none",
              flexGrow: "2"
            },
            "& .swiper-wrapper": {
              height: "auto",
              marginY: "auto",
              "& .swiper-slide": {
                position: "realtive",
                filter: theme.palette.filter.blur15,
                "& .swiper-text": {
                  position: "absolute",
                  top: 0,
                  left: 0,
                  marginTop: "170px",
                  width: "285px",
                  opacity: 0,
                  transition: "0.3s opacity ease-in",
                  userSelect: "auto",
                },
                "& .swiper-image": {
                  display: "block",
                  margin: "0 auto",
                  maxWidth: "100%",
                  width: "100%",
                  aspectRatio: 1,
                  objectFit: "contain",
                },
                "&.swiper-slide-active": {

                  filter: theme.palette.filter.blur0,
                  "& .swiper-text": {
                    opacity: 1,
                  },
                },
              },
            },
            "& .swiper-wrapper, & .swiper-button-prev": {
              order: 1,
            },
            "& .swiper-button-prev:after ": {
              backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'%3E%3Cpath d='m3.38,7.25l4.18,4.19-1.06,1.06L.5,6.5,6.5.5l1.06,1.06L3.37,5.75h9.13v1.5s-9.12,0-9.12,0Z' fill='${theme.palette.white.per23}'/%3E%3C/svg%3E")`,
            },
            "& .swiper-button-next:after ": {
              backgroundImage: `url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13'%3E%3Cpath d='m9.62,5.75L5.44,1.56l1.06-1.06,6,6-6,6-1.06-1.06,4.19-4.19H.5v-1.5h9.12Z' fill='${theme.palette.white.per23}'/%3E%3C/svg%3E")`,
            },
            "& .swiper-button-prev:after, & .swiper-button-next:after ": {
              content: "''",
              width: "13px",
              height: "13px",
              display: "block",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            },
            "& .swiper-button-next": {
              order: 3,
            },
            "& .swiper, & .swiper-wrapper, & .swiper-slide": {
              //maxHeight: `calc(100svh - calc(${appBarHeight}px + 100px ))`,
            },
            "& .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic, & .swiper-pagination.swiper-pagination-horizontal.swiper-pagination-bullets,  & .swiper-button-prev, & .swiper-button-next":
            {
              mt: "auto",
              position: "relative",
              top: "unset",
              bottom: "unset",
              left: "unset",
              right: "unset",
              transform: "unset",
              display: "flex",
              width: "auto",
              alignSelf: "center"
            },
            "& .swiper-button-prev, & .swiper-button-next": {
              position: "relative",
              mt: "auto",
              color: theme.palette.white.main,
              width: "30px",
              height: "30px",
              padding: "5px",
              borderRadius: "100%",
              mb: "9px",
              background: theme.palette.white.o01,
              "&:hover": {
                background: theme.palette.white.o02,
              },
            },
            "& .swiper-pagination": {
              background: theme.palette.black.o05,
              borderRadius: "50px",
              padding: "18px 20px",
              marginLeft: "20px",
              marginRight: "20px",
              width: "auto",
              bottom: 0,
              order: 2,
              "& .swiper-pagination-bullet ": {
                //background: theme.palette.white.o02,
                background: theme.palette.white.main,
                opacity: 0.2,
                /*
              "&.swiper-pagination-bullet-active-prev, &.swiper-pagination-bullet-active-next":{
                transform: "scale(0.7)",
              },
              */
                "&.swiper-pagination-bullet-active": {
                  background: theme.palette.white.main,
                  opacity: 1,
                  width: "10px",
                  height: "10px",
                },
              },
            },
          }}
        >
          <Swiper
            initialSlide={initialSlide}
            spaceBetween={17}
            slidesPerView={1.53}
            //slidesPerView={1.8}
            navigation={true}
            loop={true}
            pagination={{
              clickable: true,
              //dynamicBullets: true,
              // dynamicMainBullets: 1,
              //dynamicMainBullets: 3
            }}
            mousewheel={true}
            keyboard={true}
            modules={[
              Navigation,
              Pagination,
              Mousewheel,
              Keyboard,
              EffectCoverflow,
            ]}
            onSwiper={(swiper) => {
              // console.log(swiper)
            }}
            centeredSlides={true}
            effect={"coverflow"}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 500,
              modifier: 1,
              slideShadows: false,
            }}
          >
            {designSlides.map((item, index) => (
              <SwiperSlide key={index}>
                <Box className="swiper-text">
                  <Typography
                    variant="h3"
                    sx={{
                      color: theme.palette.white.main,
                      marginBottom: "20px",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      color: theme.palette.white.o07,
                      marginBottom: "40px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "4px"
                    }}
                  >
                    {item.description.split("/n").map((element, index) => {
                      return (<Typography variant="body1" key={"description_" + index}>{element}</Typography>)
                    })}
                  </Box>
                  <ButtonRound
                    id={"btn-design-" + index}
                    text={t("tab_label_customize")}
                    variant="contained"
                    onClick={() => { handleFunction(item) }}
                  ></ButtonRound>
                </Box>
                <img
                  alt="preview of content"
                  className="swiper-image"
                  width="1024"
                  src={
                    designView === 0 ?
                      process.env.REACT_APP_FRONTEND_ASSETS + item.imgSrcFront
                      : process.env.REACT_APP_FRONTEND_ASSETS + item.imgSrcBack
                  }
                ></img>
              </SwiperSlide>
            ))}
          </Swiper>

          {/* BACKGROUND EFFECT */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              pointerEvents: "none",
              zIndex: -1
            }}
          >
            <Box
              sx={{
                height: "785px",
                width: "768px",
                transform: "rotate(-90deg)",
                borderRadius: "100%",
                background:
                  "conic-gradient(from 180deg at 50% 50%, rgba(255, 0, 0, 0.50) 0deg, rgba(0, 142, 244, 0.24) 180deg, rgba(255, 0, 0, 0.50) 360deg)",
                filter: theme.palette.filter.blur150,
                opacity: 0.5,
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
