import { createSlice } from '@reduxjs/toolkit'
import { existsDesign, existsProduct, getProductDesignsFromMap } from '../api/apiHelper'
import { setIsLoading, setListProduct, setOpenTablePrice, handleUpdateDesignParts, handleSetDesign, setBrandLogo, setImagesList, setBrandLogoPosition, setTextsList, setModalOrderCreationLoadOpened, setModalOrderCreationSuccessOpened, setModalOrderCreationFailOpened, setProductExtraParts } from './appSlice'
import { setLoginDialogOpened } from './authSlice'
import api from '../api/api'
import { enqueueSnackbar } from 'notistack'
import { viewerInstance } from '../helpers/Viewer'
import { snackbarVariants } from '../helpers/layout'
import _ from 'lodash'
import { toConfigurationPage } from "../slices/navigationSlice"
import i18n from '../helpers/i18n'

const initialState = {
  productsMap: null,
  productsTree: [],
  products: [],
  designs: [],
  selectedProductId: null,
  initialSlide: 0,
  selectedDesign: null,
  productCheckFulfilled: false,
  designCheckFulfilled: false,
  configuredProductId: null,
  configurationDialogOpened: false,
  configuredProducts: [],
  loadedFromLink: false,
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductsMap: (state, action) => {
      state.productsMap = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setProductsTree: (state, action) => {
      state.productsTree = action.payload
    },
    setDesigns: (state, action) => {
      state.designs = action.payload
    },
    setSelectedProductId: (state, action) => {
      state.selectedProductId = action.payload
    },
    setInitialSlide: (state, action) => {
      state.initialSlide = action.payload
    },
    setSelectedDesign: (state, action) => {
      state.selectedDesign = action.payload
    },
    setProductCheckFulfilled: (state, action) => {
      state.productCheckFulfilled = action.payload
    },
    setDesignCheckFulfilled: (state, action) => {
      state.designCheckFulfilled = action.payload
    },
    setConfiguredProductId: (state, action) => {
      state.configuredProductId = action.payload
    },
    setConfigurationDialogOpened: (state, action) => {
      state.configurationDialogOpened = action.payload
    },
    setConfiguredProducts: (state, action) => {
      state.configuredProducts = action.payload
    },
    setLoadedConfiguration: (state, action) => {
      state.loadedConfiguration = action.payload
    }
  }
})

export const {
  setProductsMap,
  setProducts,
  setProductsTree,
  setDesigns,
  setSelectedProductId,
  setInitialSlide,
  setSelectedDesign,
  setProductCheckFulfilled,
  setDesignCheckFulfilled,
  setConfiguredProductId,
  setConfigurationDialogOpened,
  setConfiguredProducts,
  setLoadedConfiguration
} = productSlice.actions


export const loadConfiguredProduct = id => async (dispatch, getState) => {
  // TO AVOID DOUBLE SELECTE DESIGN WHEN ENTERING THE CONFIGURATION PAGE
  dispatch(setLoadedConfiguration(true))
  dispatch(setIsLoading(true))
  dispatch(setConfigurationDialogOpened(false))
  try {

    const configuredProduct = await api.getConfiguredProduct(id)

    dispatch(setConfiguredProductId(id))

    // console.log('configuredProduct ' + id, configuredProduct)

    if (configuredProduct.data) {
      dispatch(setListProduct(configuredProduct.data.listProduct))
      dispatch(toConfigurationPage(configuredProduct.product_id, configuredProduct.design_id))
      dispatch(selectProduct(configuredProduct.product_id))
      dispatch(selectDesign(configuredProduct.product_id, configuredProduct.design_id))

      const product = getState().product;
      const app = getState().app;
      await dispatch(handleSetDesign(product.selectedDesign, true, true));
      // const app = getState().app;

      if (configuredProduct.data.designParts) {
        dispatch(handleUpdateDesignParts(configuredProduct.data.designParts, true));
      }

      if (configuredProduct.data.getProductConfiguration?.extra) {
        const extra = configuredProduct.data.getProductConfiguration.extra;
        const tempProductExtraParts = structuredClone(app.productExtraParts);
        for (const extraPart of extra) {
          const foundExtraPart = tempProductExtraParts.find(el => el.name === extraPart.name);
          if (foundExtraPart) {
            foundExtraPart.color = extraPart.color;
          }
        }
        dispatch(setProductExtraParts(tempProductExtraParts));
      }

      const configurationProductTemp = structuredClone(configuredProduct.data.getProductConfiguration);
      const imagesListTemp = structuredClone(configuredProduct.data.imagesList);
      const filteredLogo = configurationProductTemp.paper.itemStates.filter(el => el.itemInfo.isBrandLogo);

      if (filteredLogo.length > 0) {
        const imageState = filteredLogo[0];
        const index = configurationProductTemp.paper.itemStates.indexOf(imageState);
        const response = await api.getUploadedResource(configuredProduct.data.brandLogo.idResouce);
        configurationProductTemp.paper.itemStates[index].src = response;
        const brandLogoTemp = configuredProduct.data.brandLogo;
        brandLogoTemp.url = URL.createObjectURL(response);
        brandLogoTemp.idResouce = false;
        dispatch(setBrandLogo(brandLogoTemp))
        dispatch(setBrandLogoPosition(configuredProduct.data.brandLogoPosition));
      }

      // TEXTS LIST
      dispatch(setTextsList(configuredProduct.data.textsList));

      // TO DO REMOVE THIS CODE
      for (let i = 0; i < configurationProductTemp.paper.itemStates.length; i++) {
        if (configurationProductTemp.paper.itemStates[i].itemInfo.type === "TextInfo") {
          configurationProductTemp.paper.itemStates[i].src = configurationProductTemp.paper.itemStates[i].itemInfo.text;
        }
      }

      // IMAGES LIST
      if (configuredProduct.data.imagesList?.length > 0) {

        for (let i = 0; i < imagesListTemp.length; i++) {
          const image = imagesListTemp[i];
          const imageTemp = structuredClone(image);
          imageTemp.idResouce = false;
          const filtered = configurationProductTemp.paper.itemStates.filter(el => el.itemInfo.name === image.id);
          if (filtered.length > 0) {
            const imageState = filtered[0];
            const index = configurationProductTemp.paper.itemStates.indexOf(imageState);
            const response = await api.getUploadedResource(image.idResouce);
            configurationProductTemp.paper.itemStates[index].src = response;
            imageTemp.url = URL.createObjectURL(response);
          }
          imagesListTemp[i] = imageTemp;
        }

        dispatch(setImagesList(imagesListTemp));
      }
      // console.log("configurationProductTemp", configurationProductTemp)
      await viewerInstance.setProductConfiguration(configurationProductTemp);
    }
  }
  catch (err) {
    console.log(err)
    // throw err
    // dispatch(toHomePage())
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const deleteConfiguredProduct = id => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  try {
    await api.deleteConfiguredProduct(id)
    const configuredProducts = await api.getConfiguredProducts()
    dispatch(setConfiguredProducts(configuredProducts))
    enqueueSnackbar(`configurazione ${id} eliminata`)
  }
  catch (err) { }
  dispatch(setIsLoading(false))
}

export const getConfiguredProducts = () => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  try {
    const configuredProducts = await api.getConfiguredProducts()
    dispatch(setConfiguredProducts(configuredProducts))
  }
  catch (err) {

  }
  dispatch(setIsLoading(false))
}

export const saveOrUpdateConfiguration = () => async (dispatch, getState) => {
  const { configuredProductId } = getState().product
  // dispatch(setIsLoading(true))
  let configuredProduct
  if (!configuredProductId)
    configuredProduct = await dispatch(saveConfiguration())
  else
    configuredProduct = await dispatch(updateConfiguration(configuredProductId))
  // dispatch(setIsLoading(false))
  return configuredProduct?.id
}

export const updateConfiguration = id => async (dispatch, getState) => {
  const { selectedProductId, selectedDesign } = getState().product
  const { textsList, imagesList, brandLogo, brandLogoPosition, listProduct } = getState().app
  const designParts = viewerInstance.getDesignParts()
  const getProductConfiguration = await viewerInstance.getProductConfiguration()
  const tempBrandLogo = structuredClone(brandLogo);
  const tempImagesList = structuredClone(imagesList);
  if (id && (imagesList.length > 0 || brandLogo)) {
    if (!brandLogo.idResouce) {
      const idResourcesLogo = await api.createUploadedResource({
        image: await (await fetch(brandLogo.url)).blob(),
        configuredProductId: id
      });
      tempBrandLogo.idResouce = idResourcesLogo.id;
      tempBrandLogo.fileNameUploaded = idResourcesLogo.filename;
      dispatch(setBrandLogo(tempBrandLogo));
    }
    const tempItemBrandLogo = getProductConfiguration.paper.itemStates.find(el => el.isBrandLogo === true);
    if (tempItemBrandLogo) {
      tempItemBrandLogo.resourceId = tempBrandLogo.idResouce;
      tempItemBrandLogo.fileNameUploaded = tempBrandLogo.fileNameUploaded;
    }
    const imagesPromises = [];
    tempImagesList.forEach(async (image, index) => {
      if (!tempImagesList[index].idResouce) {
        imagesPromises.push(new Promise(async (resolve, reject) => {
          try {
            const blob = await (await fetch(image.url)).blob();
            const idImage = await api.createUploadedResource({
              image: blob,
              configuredProductId: id,
            });
            tempImagesList[index].idResouce = idImage.id;
            tempImagesList[index].fileNameUploaded = idImage.filename;
            const tempItem = getProductConfiguration.paper.itemStates.find(el => el.itemInfo.name === image.id);
            if (tempItem) {
              tempItem.resourceId = idImage.id
              tempItem.fileNameUploaded = idImage.filename;
            }
            resolve();
          }
          catch (err) {
            reject(err)
          }
        }));
      }
      else {
        const tempItem = getProductConfiguration.paper.itemStates.find(el => el.itemInfo.name === image.id);
        if (tempItem) {
          tempItem.resourceId = tempImagesList[index].idResouce
          tempItem.fileNameUploaded = tempImagesList[index].fileNameUploaded;
        }
      }
    });
    await Promise.all(imagesPromises);
    dispatch(setImagesList(tempImagesList));
  }
  const data = {
    product_id: selectedProductId,
    design_id: selectedDesign.id,
    data: JSON.stringify({
      designParts,
      getProductConfiguration,
      textsList: structuredClone(textsList),
      imagesList: structuredClone(tempImagesList),
      brandLogo: structuredClone(tempBrandLogo),
      brandLogoPosition: brandLogoPosition,
      listProduct: structuredClone(listProduct)
    })
  }
  const configuredProduct = await api.updateConfiguredProduct(id, data)
  // enqueueSnackbar(`configurazione ${configuredProduct.id} aggiornata`)
  return configuredProduct
}

export const saveConfiguration = () => async (dispatch, getState) => {
  const { selectedProductId, selectedDesign } = getState().product
  const { textsList, imagesList, brandLogo, brandLogoPosition, listProduct } = getState().app
  // dispatch(setIsLoading(true))
  let configuredProduct
  const designParts = viewerInstance.getDesignParts()
  const getProductConfiguration = await viewerInstance.getProductConfiguration()
  const data = {
    product_id: selectedProductId,
    design_id: selectedDesign.id,
    data: JSON.stringify({
      designParts,
      getProductConfiguration,
      textsList: structuredClone(textsList),
      imagesList: structuredClone(imagesList),
      brandLogo: structuredClone(brandLogo),
      brandLogoPosition: brandLogoPosition,
      listProduct: structuredClone(listProduct)
    })
  }
  configuredProduct = await api.createConfiguredProduct(data)
  // enqueueSnackbar(`configurazione ${configuredProduct.id} salvata`)
  dispatch(setConfiguredProductId(configuredProduct.id))
  if (configuredProduct.id && (imagesList.length > 0 || brandLogo)) {
    const idResourcesLogo = await api.createUploadedResource({
      image: await (await fetch(brandLogo.url)).blob(),
      configuredProductId: configuredProduct.id
    });
    const tempBrandLogo = structuredClone(brandLogo);
    tempBrandLogo.idResouce = idResourcesLogo.id;
    tempBrandLogo.fileNameUploaded = idResourcesLogo.filename;
    dispatch(setBrandLogo(tempBrandLogo));
    const tempImagesList = structuredClone(imagesList);
    const imagesPromises = [];
    tempImagesList.forEach(async (image, index) => {
      if (!tempImagesList[index].idResouce) {
        imagesPromises.push(new Promise(async (resolve, reject) => {
          try {
            const blob = await (await fetch(image.url)).blob();
            const idImage = await api.createUploadedResource({
              image: blob,
              configuredProductId: configuredProduct.id
            });
            tempImagesList[index].idResouce = idImage.id;
            tempImagesList[index].fileNameUploaded = idImage.filename;
            resolve();
          }
          catch (err) {
            reject(err)
          }
        }));
      }
    });
    await Promise.all(imagesPromises);
    dispatch(setImagesList(tempImagesList));
    await dispatch(updateConfiguration(configuredProduct.id))
  }
  // dispatch(setIsLoading(false))
  return configuredProduct
}

export const addToCart = () => async (dispatch, getState) => {
  const state = getState()
  if (state.auth.isLoggedInUser) {
    const listProductCopy = _.cloneDeep(state.app.listProduct)
    if (listProductCopy.length > 0) {
      let validData = true
      const selectedDesign = state.product.selectedDesign
      const availableSizes = selectedDesign.sizes.map(size => size.variantName)
      for (const product of listProductCopy) {
        const { size, quantity, minQuantity } = product
        product.errorSize = (size === '') || !availableSizes.includes(size)
        product.errorQuantity = (quantity === '') || quantity < minQuantity
        const { errorSize, errorQuantity } = product
        if (errorSize || errorQuantity)
          validData = false
      }
      dispatch(setListProduct(listProductCopy))
      if (validData) {
        dispatch(setModalOrderCreationLoadOpened(true))
        // dispatch(setIsLoading(true))
        try {
          const configuredProductId = await dispatch(saveOrUpdateConfiguration())
          // console.log('configuredProductId', configuredProductId)
          const svg = await viewerInstance.exportDesignWithItems(false)
          // console.log('svg', svg)
          await api.createUploadedResource({
            image: new File([svg], '_customer-project.svg', { type: svg.type }),
            configuredProductId
          })
          if (process.env.REACT_APP_GENERATE_VIEW_ON_CART === 'true') {
            const blobs = await viewerInstance.takeScreenshot(false)
            // console.log('blobs', blobs)
            let i = 1
            for (const blob of blobs) {
              const filename = `_preview_${i}.jpg`
              const file = new File([blob], filename, { type: blob.type })
              const requestData = {
                image: file,
                configuredProductId
              }
              await api.createUploadedResource(requestData)
              i++
            }
          }
          let addToCartItems = []
          for (const tableRow of state.app.listProduct) {
            const foundSize = selectedDesign.sizes.find(size => size.variantName === tableRow.size)
            const foundItem = addToCartItems.find(item => item.variantId === foundSize.variantId)
            if (!foundItem) {
              const cartItem = {
                parentProductId: selectedDesign.productId,
                variantId: foundSize.variantId,
                quantyPrice: tableRow.quantity
              }
              addToCartItems.push(cartItem)
            }
            else {
              foundItem.quantyPrice += tableRow.quantity
            }
          }
          addToCartItems.sort((a, b) => {
            if (a.variantDisplayOrder < b.variantDisplayOrder)
              return -1
            else if (a.variantDisplayOrder > b.variantDisplayOrder)
              return 1
            return 0
          })
          addToCartItems = addToCartItems.map(item => {
            const { variantDisplayOrder, ...otherProps} = item
            return { ...otherProps }
          })
          const addToCartRequest = {
            projectRef: configuredProductId,
            items: addToCartItems
          }
          await api.addToCart(addToCartRequest)
          dispatch(setOpenTablePrice(false))
          // dispatch(setListProduct([]))
          // enqueueSnackbar(`Il prodotto configurato ${configuredProductId} è stato aggiunto al carrello`)
          dispatch(setModalOrderCreationSuccessOpened(true))
        }
        catch (err) {
          // console.log(err)
          dispatch(setModalOrderCreationFailOpened(true))
        }
        dispatch(setModalOrderCreationLoadOpened(false))
        // dispatch(setIsLoading(false))
      }
      else {
        enqueueSnackbar(i18n.t('cart_invalid_data_snack_message'), { variant: snackbarVariants.error })
      }
    }
    else {
      enqueueSnackbar(i18n.t('cart_empty_table_snack_message'), { variant: snackbarVariants.error })
    }
  }
  else {
    dispatch(setLoginDialogOpened(true))
  }
}

export const selectDesign = (productId, designId) => (dispatch, getState) => {
  const { productsMap } = getState().product
  if (existsDesign(productId, designId, productsMap)) {
    const productDesigns = dispatch(selectProduct(productId))
    const slideIndex = productDesigns.map(design => design.id).indexOf(designId)
    dispatch(setInitialSlide(slideIndex))
    const design = productDesigns.find(productDesign => productDesign.id === designId)
    dispatch(setSelectedDesign(design))
  }
  else {
    dispatch(setSelectedDesign(null))
  }
  dispatch(setDesignCheckFulfilled(true))
}

export const selectProduct = productId => (dispatch, getState) => {
  const { productsMap } = getState().product
  let productDesigns = []
  if (existsProduct(productId, productsMap)) {
    productDesigns = getProductDesignsFromMap(productId, productsMap)
    dispatch(setDesigns(productDesigns))
    dispatch(setInitialSlide(0))
    dispatch(setSelectedProductId(productId))
  }
  else {
    dispatch(setSelectedProductId(null))
  }
  dispatch(setProductCheckFulfilled(true))
  return productDesigns
}


export default productSlice.reducer
