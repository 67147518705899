import {
  Box, Button, Dialog, DialogContent, DialogTitle, FormControl, Grid2, IconButton,
  InputAdornment, InputLabel, OutlinedInput, TextField,
  Typography,
  useTheme
} from "@mui/material"
import { useState } from "react"
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CloseDialogButton from "../layout/CloseDialogButton"
import Divider from "../Divider"
import { useTranslation } from "react-i18next"

export default function LoginDialog(props) {
  const theme = useTheme()
  const {
    open,
    handleClose,
    handleLogin
  } = props

  const { t } = useTranslation()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleClickLogin = () => {
    if (username && password) {
      handleLogin({ username, password })
    }
  }

  const handleChangeUsername = event => {
    setUsername(event.target.value)
  }

  const handleChangePassword = event => {
    setPassword(event.target.value)
  }

  const handleClickShowPassword = () => {
    setShowPassword(show => !show)
  }

  const handleFormSubmit = event => {
    event.preventDefault()
  }

  return (
    <Dialog
      open={open}
      sx={{
        background: theme.palette.black.o05,
        "& .MuiDialog-paper": {
          background: theme.palette.dark.light08,
          backdropFilter: theme.palette.filter.blur50,
          padding: "10px 8px",
          borderRadius: "20px",
        }
      }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        p: "10px 12px"
      }}>

        <DialogTitle sx={{
          mr: "auto",
          p: 0,
        }}>
          {t("panel_login_title")}
        </DialogTitle>
        <CloseDialogButton
          size="small"
          handleClose={handleClose}
        />
      </Box>
      <DialogContent sx={{
        display: 'flex',
        alignItems: 'center',
        p: "10px 12px",
        flexDirection: 'column',
        minWidth: '220px',
        maxWidth: '384px',
      }}>
        <Typography variant="body1" align="center" sx={{p: "24px"}}>
          {t("panel_login_description")}
        </Typography>
        <Box
          sx={{
            mt: "10px",
          }}
        >
          <form onSubmit={handleFormSubmit}>
            <Grid2 container spacing={3} sx={{ p: "10px 0px"}}>
              <Grid2 size={{ xs: 12 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t("panel_login_email")}
                  value={username}
                  onChange={handleChangeUsername}
                  slotProps={{ inputLabel: { shrink: true } }}
                  // InputLabelProps={{ shrink: true }}
                />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <InputLabel
                    shrink
                    htmlFor="login-password"
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="login-password"
                    variant="outlined"
                    fullWidth
                    notched
                    label={t("panel_login_password")}
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={handleChangePassword}
                    endAdornment={
                      <InputAdornment position="end" sx={{ mr: 1 }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid2>
            </Grid2>
            <Box sx={{
              p: "10px 0px"
            }}>
              <Divider bgcolor={theme.palette.white.o015}></Divider>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              pt: "10px"
            }}>
              <Button
                className="btn-text"
                onClick={handleClose}
                color="white"
              >
                {t("panel_login_abort")}
              </Button>
              <Button
                className="btn-white"
                variant="contained"
                onClick={handleClickLogin}
                type='submit'
              >
                {t("panel_login_button")}
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
