
export const existsDesign = (productId, designId, productsMap) => {
  let foundDesign = false
  const product = productsMap[productId]
  if (product) {
    const design = product.graphics[designId]
    if (design)
      foundDesign = true
  }
  return foundDesign
}

export const existsProduct = (productId, productsMap) => {
  return Boolean(productsMap[productId])
}

export const getProductDesignsFromMap = (productId, productsMap) => {
  const product = productsMap[productId]
  let productDesigns = []
  if (product) {
    const productName = product.parentProductName
    const mappedGraphics = Object.values(product.graphics)
    for (const mappedGraphic of mappedGraphics) {
      const graphicName = mappedGraphic.graphic
      const productDesign = {
        id: graphicName,
        productId: product.parentProductId,
        productName: productName,
        productDescription: product.parentProductDescription,
        title: `${productName} - ${graphicName}`,
        description: product.parentProductDescription,
        imgSrcFront: `imgs/${productName}_${graphicName}_Fronte.png`,
        imgSrcBack: `imgs/${productName}_${graphicName}_Retro.png`,
        src: `viewer3d-static/svg/${productName} ${graphicName} - TG L.svg`,
        available: true,
        sizes: Object.values(mappedGraphic.sizes),
        prices: mappedGraphic.prices,
        graphicsOrder: mappedGraphic.graphicsOrder
      }
      productDesign.sizes.sort((a, b) => {
        if (a.variantDisplayOrder < b.variantDisplayOrder)
          return -1
        else if (a.variantDisplayOrder > b.variantDisplayOrder)
          return 1
        return 0
      })
      productDesigns.push(productDesign)
    }
    productDesigns.sort((a, b) => {
      if (a.graphicsOrder > b.graphicsOrder)
        return 1
      else if (a.graphicsOrder < b.graphicsOrder)
        return -1
      return 0
    })

    const essen = productDesigns.find(productDesign => productDesign.id === 'ESSEN')
    if (essen) {
      const notEssen = productDesigns.filter(productDesign => productDesign.id !== 'ESSEN')
      productDesigns = [essen].concat(notEssen)
    }
    else {
      const gand = productDesigns.find(productDesign => productDesign.id === 'GAND')
      const notGand = productDesigns.filter(productDesign => productDesign.id !== 'GAND')
      productDesigns = [gand].concat(notGand)
    }
  }
  return productDesigns
}

export const getProductsFromMap = productsMap => {
  const mappedProducts = Object.values(productsMap)
  const products = []
  for (const mappedProduct of mappedProducts) {
    const productName = mappedProduct.parentProductName
    const gandProducts = ['AD208', 'AD402']
    const graphic = gandProducts.includes(productName) ? 'GAND' : 'ESSEN'
    const product = {
      id: productName,
      title: productName,
      description: mappedProduct.parentProductDescription,
      imgSrcFront: `imgs/${productName}_${graphic}_Fronte.png`,
      imgSrcBack: `imgs/${productName}_${graphic}_Retro.png`,
      available: true,
      parentProductDisplayOrder: mappedProduct.parentProductDisplayOrder,
    }
    products.push(product)
  }
  products.sort((a, b) => {
    if (a.parentProductDisplayOrder > b.parentProductDisplayOrder)
      return 1
    else if (a.parentProductDisplayOrder < b.parentProductDisplayOrder)
      return -1
    return 0
  })
  return products
}

export const createProductsMap = items => {
  const productsMap = {}
  for (const item of items) {
    const productName = item.parentProductName
    const product = productsMap[productName]
    if (!product) {
      productsMap[productName] = createProduct(item)
    }
    else {
      for (const graphicItem of item.graphics) {
        const graphic = graphicItem.graphics
        product.graphics[graphic] = createGraphic(graphicItem, item)
      }
    }
  }
  return productsMap
}

export const createProductsTree = items => {
  const productsTree = []

  for (const item in items) {
    // FOR EACH ITEM
    // console.log("item", item, items[item].categories)
    //FOR EACH CATEGORIES COLLECTION OF firstLevelCategory-secondLevelCategory-thirdLevelCategory

    for (const category in items[item].categories) {
      // console.log("category",items[item].categories[category])

      let firstLevelCategory = productsTree.find(product => product.id === items[item].categories[category]["firstLevelCategory"])
      let secondLevelCategory = undefined

      if (firstLevelCategory) {
        firstLevelCategory.products.push(item)
      } else {
        productsTree.push({
          id: items[item].categories[category]["firstLevelCategory"],
          label: items[item].categories[category]["firstLevelCategory"],
          products: [item],
          children: [],
          order: items[item].categories[category]["firstLevelCategoryDisplayOrder"]
        })
        firstLevelCategory = productsTree.find(product => product.id === items[item].categories[category]["firstLevelCategory"])
      }

      if (firstLevelCategory) {
        secondLevelCategory = firstLevelCategory.children.find(product => product.id === items[item].categories[category]["secondLevelCategory"])
        if (secondLevelCategory) {
          secondLevelCategory.products.push(item)
        } else {
          firstLevelCategory.children.push({
            id: items[item].categories[category]["secondLevelCategory"],
            label: items[item].categories[category]["secondLevelCategory"],
            products: [item],
            children: [],
            order: items[item].categories[category]["secondLevelCategoryDisplayOrder"]
          })
          secondLevelCategory = firstLevelCategory.children.find(product => product.id === items[item].categories[category]["secondLevelCategory"])
        }
      }

      if (secondLevelCategory) {
        let thirdLevelCategory = secondLevelCategory.children.find(product => product.id === items[item].categories[category]["thirdLevelCategory"])
        if (thirdLevelCategory) {
          thirdLevelCategory.products.push(item)
        } else {
          secondLevelCategory.children.push({
            id: items[item].categories[category]["thirdLevelCategory"],
            label: items[item].categories[category]["thirdLevelCategory"],
            products: [item],
            children: [],
            order: items[item].categories[category]["thirdLevelCategoryDisplayOrder"]
          })
          thirdLevelCategory = secondLevelCategory.children.find(product => product.id === items[item].categories[category]["thirdLevelCategory"])
        }
      }
    }
  }

  const compareByOrder = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  const visitAndSortChildren = (node) => {
    node.children.sort(compareByOrder)
    for (const child of node.children) {
      visitAndSortChildren(child)
    }
  }

  visitAndSortChildren(productsTree[0])

  return (productsTree)
}

export const createProduct = item => {
  const graphics = {}
  for (const graphicItem of item.graphics) {
    const graphic = graphicItem.graphics
    graphics[graphic] = createGraphic(graphicItem, item)
  }
  return {
    parentProductId: item.parentProductId,
    parentProductName: item.parentProductName,
    parentProductDescription: item.parentProductDescription,
    parentProductDisplayOrder: item.parentProductDisplayOrder,
    categories: item.categories,
    graphics
  }
}

export const createGraphic = (graphic, item) => {
  const sizes = {}
  for (const variant of item.variants)
    sizes[variant.variantName] = createSize(variant, item)
  const prices = []
  for (const price of item.prices)
    prices.push([price.quantyPrice, price.price])
  prices.sort((a, b) => {
    if (a[0] < b[0])
      return 1
    else if (a[0] > b[0])
      return -1
    return 0
  })
  return {
    graphic: graphic.graphics,
    sizes,
    prices,
    graphicsOrder: graphic.graphicsOrder,
  }
}

export const createSize = (variant, item) => {
  return {
    variantName: variant.variantName,
    variantId: variant.variantId,
    variantDisplayOrder: variant.variantDisplayOrder
  }
}
