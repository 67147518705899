import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next";
import Divider from "../Divider";
import { Box } from "@mui/system";

export default function LogoutConfirmDialog(props) {
  const {
    open,
    handleClose,
    handleConfirm
  } = props

  const theme = useTheme();

  const { t } =  useTranslation()

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        background: theme.palette.black.o05,
        "& .MuiDialog-paper": {
          background: theme.palette.dark.light08,
          backdropFilter: theme.palette.filter.blur50,
          padding: "10px 8px",
          borderRadius: "20px",
        }
      }}
    >
      <DialogTitle sx={{
        p: "10px 12px",
      }}>
        {t("panel_logout_title")}
      </DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        p: "10px 12px",
        flexDirection: 'column',
        minWidth: '220px',
        maxWidth: '384px',
      }}>
        <DialogContentText>
          {t("panel_logout_description")}
        </DialogContentText>
      </DialogContent>
      <Box sx={{
        p: "10px 12px",
      }}>

        <Divider bgcolor={theme.palette.dark.light08} />
      </Box>
      <DialogActions sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <Button
          onClick={handleClose}
          className="btn-text"
          color="white"
        >
          {t("panel_login_abort")}
          </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          className="btn-white"
          autoFocus>
          {t("panel_logout_button")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
