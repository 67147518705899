import { Input, IconButton, Box, useTheme, Typography, CircularProgress } from "@mui/material";
import IconUploadCustom from "./icons/IconUploadCustom";
import ButtonRound from "./ButtonRound";
import { useEffect, useRef, useState } from "react";
import { filesize } from "filesize";
import { useTranslation } from "react-i18next";

const InputUploadFile = (props) => {
  const {
    imageSelected,
    setImageSelected,
    setErrorUpload
  } = props;

  const theme = useTheme();

  const ref = useRef();

  const [isUpdatingImage, setIsUpdatingImage] = useState(false);

  const { t } = useTranslation();

  const [imageValue, setImageValue] = useState({
    id: "",
    name: "",
    url: "",
    // blob: null,
    size: filesize(0)
  });

  const updateImage = async (e) => {
    if (e.files && e.files.length > 0) {
      const selectedFile = e.files[0];
      // const blob = new Blob([await selectedFile.arrayBuffer()], {
      //   type: selectedFile.type,
      // });
      // console.log('handleUpdate', selectedFile)
      // console.log(Number(selectedFile.size))
      // console.log(selectedFile.type)
      if (
        Number(selectedFile.size) < 10000000 && (
          selectedFile.type === "image/png" ||
          selectedFile.type === "image/jpg" ||
          selectedFile.type === "image/jpeg" ||
          selectedFile.type === "image/svg" ||
          selectedFile.type === "image/svg+xml"
        )) {
        setImageValue({
          id: "",
          name: selectedFile.name,
          url: URL.createObjectURL(selectedFile),
          type: selectedFile.type,
          // blob: blob,
          size: filesize(selectedFile.size)
        });
        setErrorUpload(false)
      } else {
        setErrorUpload(true)
      }
      setIsUpdatingImage(false);
    }
  };

  useEffect(() => {
    const element = ref.current;

    if (element) {
      element.addEventListener('cancel', (e) => { setIsUpdatingImage(false); });

      return () => {
        element.removeEventListener('cancel', (e) => { setIsUpdatingImage(false); });
      };
    }
  }, []);

  useEffect(() => {
    const tempImageSelected = { ...imageSelected }
    setImageSelected({ ...tempImageSelected, ...imageValue })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageValue]);

  return (
    <Box className="container-upload-file">
      <Box
        className="upload-file"
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onDrop={(e) => {
          e.preventDefault();
          setIsUpdatingImage(true);
          updateImage(e.dataTransfer);
        }}
        sx={{
          borderRadius: "10px",
          //border: `1px dashed ${theme.palette.white.o02}`,
          padding: "20px 50px",
          transition: "all 0.3s ease",
          textAlign: "center",
          position: "relative",
          "& .MuiTypography-root:not(.MuiTypography-button)": {
            margin: "10px auto",
          },
          "&:hover": {
            backgroundColor: theme.palette.white.o02,
            //border: `1px solid ${theme.palette.white.o02}`,
            transition: "all 0.3s ease",
          },
          "& .MuiButtonBase-root.MuiIconButton-root, & .MuiButtonBase-root.MuiIconButton-root:hover":
          {
            padding: "0px",
            backgroundColor: "transparent",
            cursor: "auto",
          },
          "& .loading": {
            position: "absolute",
            borderRadius: "10px",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1000",
            background: theme.palette.black.o05,
            backdropFilter: theme.palette.filter.blur15,
            flexDirection: "column",
            pointerEvents: isUpdatingImage ? "auto" : "none",
            opacity: isUpdatingImage ? 1 : 0,
            transition: "opacity 0.3s ease-out"
          }
        }}
      >
        <Box className="loading">
          <Typography variant="body2">Loading Image</Typography>
          <CircularProgress />
        </Box>
        <IconButton>
          <IconUploadCustom />
        </IconButton>
        <Typography variant="body2">
          {t("panel_upload_file_description")}
        </Typography>
        <Box className="support-notes" sx={{ marginTop: "10px" }}>
        <Typography
          variant="caption"
          sx={{
            display: "block",
            textAlign: "center",
            color: theme.palette.white.o07,
          }}
        >
          {t("panel_upload_file_support_notes")}
        </Typography>
      </Box>
        <ButtonRound
          text={t("label_button_upload_img")}
          variant="contained"
          className=""
          onClick={() => {
            ref.current.querySelector("input").click();
          }}
        />
        <Input
          ref={ref}
          type="file"
          onChange={(e) => {
            updateImage(e.target);
          }}
          onClick={() => {
            setIsUpdatingImage(true);
          }}
          componentsProps={{
            input: {
              accept: ".png, .jpeg, .jpg, .svg, .jfif",
            },
          }}
          sx={{
            display: "none",
          }}
        ></Input>
        <Box className="dashed-vector">
          <svg viewBox="0 0 402 162" preserveAspectRatio="none">
            <path
              vectorEffect="non-scaling-stroke"
              d="M10,0.5h382c5.2,0,9.5,4.3,9.5,9.5v142c0,5.2-4.3,9.5-9.5,9.5H10c-5.2,0-9.5-4.3-9.5-9.5V10
              C0.5,4.8,4.8,0.5,10,0.5z"
            />
          </svg>
        </Box>
      </Box>

    </Box>
  );
};
export default InputUploadFile;
