import { IconButton, ListItem, ListItemButton, ListItemText, Tooltip, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import ColorizeIcon from '@mui/icons-material/Colorize';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from "react-i18next";
import { ReactComponent as IconClone } from "../../icons/icon-clone.svg";

export default function PartsListItem(props) {

  const {
    item,
    // index,
    onClick,
    clone,
    cloneFunction,
    copy,
    copyFunction,
    patternWarning,
    notClickable
  } = props

  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <ListItem
      disablePadding
      onClick={() => { onClick(item) }}
    >
      <ListItemButton sx={{
        gap: "10px",
        pointerEvents: notClickable ? "none" : "auto",
        "&:hover .icon-invisible": {
            opacity: 1,
        }
      }}>
        <Box
          sx={{
            width: "48px",
            aspectRatio: 1,
            borderRadius: "4px",
            outline: "1px solid rgba(255,255,255,0.15)",
            outlineOffset: "-0.5px",
            background: item.color2 !== "" ? `linear-gradient(${item.colorsAngle}deg, ${item.color}, ${item.color2})` : item.color,
            overflow: "hidden",
            position: "relative"
          }}
        >
          <Box
            sx={{
              opacity: item.pattern ? 1 : 0,
              height: "150%",
              width: "150%",
              position: "absolute",
              transformOrigin: "center",
              zIndex: 1,
              // top: 0,
              // left: 0,
              top: "50%",
              left: "50%",
              transform: `translate(-50%, -50%) rotate(${item.patternAngle}deg)`,
              // scale: "0.25",
              backgroundSize: "contain",
              // fill: item.patternColor,
              background: item.patternColor,
              maskImage: item.pattern ?
                `url("${item.patternUrl}")`
                : "none",
              maskSize: `${100 * item.patternSize}%`,
              maskRepeat: "repeat",
              maskOrigin: "center",
            }}>
          </Box>
        </Box>
        <ListItemText primary={t(item.label)} />
        {clone ?

          <IconButton sx={{
            opacity: 0,
            transition: "opacity 0.3s ease-in",
            "&.MuiIconButton-root.MuiIconButton-root": {
              backgroundColor: "rgba(255,255,255,0)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0.1)"
              }
            },
          }}
            className="icon-invisible"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              cloneFunction(item);
            }}
          >
            <ColorizeIcon />
          </IconButton> : ""
        }

        {copy ?

          <IconButton sx={{
            opacity: 0,
            transition: "opacity 0.3s ease-in",
            display: "flex",
            "&.MuiIconButton-root.MuiIconButton-root": {
              backgroundColor: "rgba(255,255,255,0)",
              "&:hover": {
                backgroundColor: "rgba(255,255,255,0)",
              }
            },
            svg: {
              width: "20px",
              fill: "currentcolor"
            }
          }}
            className="icon-invisible"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              copyFunction(item);
            }}
          >
            <IconClone />
          </IconButton> : ""
        }

        {patternWarning ?
          <Tooltip title={t("error_message_pattern")} placement="left" arrow>
            <ErrorIcon sx={{ color: theme.palette.messages.alert }} />
          </Tooltip>
          : ""
        }
      </ListItemButton>
    </ListItem>
  )

}
