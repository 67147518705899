import { useEffect, useState } from "react";
import {
  Drawer, Box, Typography, IconButton, useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PanelCustomImages from "./PanelCustomImages";
import PanelCustomizeImage from "./PanelCustomizeImage";
import { useTranslation } from "react-i18next";
import { viewerInstance } from "../helpers/Viewer";
import { appBarHeight } from "../helpers/layout";
import BrandLogoListItem from "./BrandLogoListItem";
import PanelCustomizeBrandLogo from "./PanelCustomizeBrandLogo";
import { ReactComponent as IconTrash } from "../icons/icon-trash.svg";
import ModalConfirmation from "./ModalConfirmation";

export default function DrawerImages(props) {
  const {
    imagesList,
    setImagesList,
    colorsList,
    colorsInUse,
    positionsList,
    imageSelected,
    setImageSelected,
    imageEdit,
    setImageEdit,
    brandLogoEdit,
    setBrandLogoEdit,
    indexImageSelected,
    setIndexImageSelected,
    currentSelectedItem,
    setCurrentSelectedItem,
    defaultImage,
    brandLogo,
    // dispatchSetBrandLogo
  } = props;

  const theme = useTheme()

  const { t } = useTranslation();

  // const [value, setValue] = useState(0);

  const [colorsInUseImage, setColorsInUseImage] = useState(colorsInUse);

  const [waitingForPreset, setWaitingForPreset] = useState(false);
  const [presetSide, setPresetSide] = useState("front");

  const [modalDeleteDesignOpen, setModalDeleteDesignOpen] = useState(false);

  const handleImageSelection = (item, index) => {
    setImageSelected({ ...item });
    setIndexImageSelected(index);
    setImageEdit(true);
    viewerInstance.selectItemName(item.id);
  }

  const handleOpenUpload = () => {

    setImageSelected({ ...defaultImage });
    // const tempImagesList = [...imagesList];
    // let length = tempImagesList.push({ ...defaultImage });
    // setImagesList([...tempImagesList]);
    setIndexImageSelected(null);
    setImageEdit(true);
  }

  const handleImageEditCancel = () => {
    if (indexImageSelected === null) {
      viewerInstance.deleteItem();
    } else {
      if (imageSelected.id === "") {
        const tempImagesList = [...imagesList];
        if (indexImageSelected !== null) {
          tempImagesList.splice(indexImageSelected, 1);
        }
        setImagesList([...tempImagesList]);
      }
    }

    setImageSelected(null);
    setIndexImageSelected(null);
    setImageEdit(false)
    viewerInstance.cancel();
    viewerInstance.unselectItem();
    setCurrentSelectedItem(null);
  }

  const handleImageEditConfirm = () => {
    // console.log(imageSelected, [...imagesList]);
    if (imageSelected) {
      const tempImagesList = [...imagesList];
      if (indexImageSelected !== null && imageSelected.id !== "") {
        tempImagesList[indexImageSelected] = { ...imageSelected };
      } else {
        if (imageSelected.id === "") {
          if (indexImageSelected !== null) {
            tempImagesList.splice(indexImageSelected, 1);
          }
        } else {
          if (tempImagesList.filter((image) => image.id === imageSelected.id).length === 0) {
            tempImagesList.push({ ...imageSelected });
          }
        }
      }
      setImagesList([...tempImagesList]);
    }

    viewerInstance.cancel();
    viewerInstance.unselectItem()
    handleUnselect();
  }

  const handleUnselect = () => {
    setImageEdit(false)
    setImageSelected(null);
    setIndexImageSelected(null);
  }

  const handleImageDuplicate = () => {
    const tempImagesList = [...imagesList];
    // console.log(indexImageSelected, tempImagesList)

    if (indexImageSelected !== null) {
      tempImagesList[indexImageSelected] = { ...imageSelected };
    } else {
      tempImagesList.push({ ...imageSelected });
    }

    const clonedResponse = viewerInstance.cloneItem(imageSelected.id);

    const newIndex = tempImagesList.push({ ...imageSelected, ...{ id: clonedResponse.name, hasLink: null } });
    // console.log(tempImagesList);
    handleImageSelection({ ...imageSelected, ...{ id: clonedResponse.name, hasLink: null } }, newIndex - 1)
    setImagesList([...tempImagesList]);
    // handleTextEditCancel();
  }

  const handleImageDeleteFromList = (id) => {
    const tempImagesList = [...imagesList];

    const filtered = tempImagesList.filter((e) => e.id === id);
    if (filtered.length > 0) {
      const index = tempImagesList.indexOf(filtered[0]);
      tempImagesList.splice(index, 1);
      setImagesList([...tempImagesList]);
      viewerInstance.deleteItem(id);
      handleImageEditCancel();
    }
    // setCurrentSelectedItem(null);
  }

  const handleImageDuplicateFromList = (id) => {
    const tempImagesList = [...imagesList];
    // console.log(indexImageSelected, tempImagesList)

    const filtered = tempImagesList.filter((e) => e.id === id);

    const clonedResponse = viewerInstance.cloneItem(id);

    const newIndex = tempImagesList.push({ ...filtered[0], ...{ id: clonedResponse.name, hasLink: null } });
    // console.log(tempImagesList);
    handleImageSelection({ ...filtered[0], ...{ id: clonedResponse.name, hasLink: null } }, newIndex - 1)
    setImagesList([...tempImagesList]);
    // handleTextEditCancel();
  }

  const handleImageDelete = () => {
    const tempImagesList = [...imagesList];
    if (indexImageSelected !== null) {
      tempImagesList.splice(indexImageSelected, 1);
    }
    setImagesList([...tempImagesList]);
    viewerInstance.deleteItem();
    handleImageEditCancel();
    // setCurrentSelectedItem(null);
  }

  const handleMirror = () => {
    if (imageSelected && imageSelected.id && imageSelected.id !== "") {
      const tempImageSelected = { ...imageSelected };
      const tempImagesList = [...imagesList];

      if (imageSelected.hasLink) {
        viewerInstance.unmirrorItem(imageSelected.id);
        viewerInstance.deleteItem(tempImageSelected.hasLink);
        tempImageSelected.hasLink = null;
        handleUpdateImageSelected({ ...tempImageSelected }, currentSelectedItem, [...tempImagesList]);
        // console.log(unmirrorResponse);
        viewerInstance.selectItemName(tempImageSelected.id);
      } else {
        const mirrorResponse = viewerInstance.mirrorItem(imageSelected.id);
        tempImagesList.push({
          ...tempImageSelected, ...{
            id: mirrorResponse.name,
            size_x: mirrorResponse.size.width.toFixed(2).toString(),
            size_y: mirrorResponse.size.height.toFixed(2).toString(),
            angle: mirrorResponse.rotation,
            hasLink: tempImageSelected.id,
          }
        });
        tempImageSelected.hasLink = mirrorResponse.name;
        // console.log("handleMirror setImagesList:",tempImageSelected, [...tempImagesList]);
        handleUpdateImageSelected({ ...tempImageSelected }, currentSelectedItem, [...tempImagesList]);
      }
    }
  }

  const handleUnlink = () => {
    // console.log("handleUnlink")
    if (imageSelected && imageSelected.id !== "") {
      const tempImageSelected = { ...imageSelected };
      const tempImagesList = [...imagesList];

      if (imageSelected.hasLink) {
        const unmirrorResponse = viewerInstance.unmirrorItem(imageSelected.id);
        if (unmirrorResponse) {

          const filtered = tempImagesList.filter((e) => e.id === tempImageSelected.hasLink);

          if (filtered.length > 0) {
            const index = tempImagesList.indexOf(filtered[0]);
            if (index >= 0) {
              let tempImageFromList = { ...tempImagesList[index] };
              tempImageFromList.hasLink = null;
              tempImagesList[index] = tempImageFromList;
            }
          }

          tempImageSelected.hasLink = null;
          setImageSelected({ ...tempImageSelected });

          const filteredCurrent = tempImagesList.filter((e) => e.id === tempImageSelected.id);

          if (filteredCurrent.length > 0) {
            const index = tempImagesList.indexOf(filteredCurrent[0]);
            if (index >= 0) {
              tempImagesList[index] = { ...tempImageSelected };
            }
          }

          setImagesList([...tempImagesList]);
        }
      }
    }
  }

  const handleUpdateImageSelected = (tempImageSelected, itemInfo, tempImagesList) => {
    // const tempImageSelected = { ...imageSelected };
    if (tempImageSelected.id === itemInfo.name) {
      // console.log(itemInfo.isMirrorable);
      const imageSelectedUpdated = {
        ...tempImageSelected, ...{
          angle: itemInfo.rotation >= 0 ? itemInfo.rotation % 360 : 360 + (itemInfo.rotation % 360),
          size_x: itemInfo.size.width.toFixed(0).toString(),
          size_y: itemInfo.size.height.toFixed(0).toString(),
          isMirrorable: itemInfo.isMirrorable,
          isLocked: itemInfo.isLocked,
          background: itemInfo.backgroundColor ? true : false,
          background_size: itemInfo.backgroundBorderWidth,
          background_angle: itemInfo.backgroundBorderJoin,
          size_ratio: (itemInfo.size.width / itemInfo.size.height),
        }
      };

      setImageSelected({ ...imageSelectedUpdated });

      //UPDATE IMAGES LIST - IF HASLINK

      if (imageSelectedUpdated.hasLink) {
        // console.log("ENTRO", imageSelectedUpdated.hasLink, tempImagesList)
        const filteredHasLink = tempImagesList.filter((e) => e.id === imageSelectedUpdated.hasLink);
        // console.log("filteredHasLink", filteredHasLink)
        if (filteredHasLink.length > 0) {
          const indexHasLink = tempImagesList.indexOf(filteredHasLink[0]);
          if (indexHasLink >= 0) {
            const infoToUpdate = viewerInstance.getItemInfo(imageSelectedUpdated.hasLink);
            if (infoToUpdate) {
              const tempLink = {
                ...tempImagesList[indexHasLink], ...{
                  angle: infoToUpdate.rotation >= 0 ? infoToUpdate.rotation % 360 : 360 + (infoToUpdate.rotation % 360),
                  size_x: infoToUpdate.size.width.toFixed(0).toString(),
                  size_y: infoToUpdate.size.height.toFixed(0).toString(),
                  isMirrorable: infoToUpdate.isMirrorable,
                  isLocked: infoToUpdate.isLocked,
                  background: infoToUpdate.backgroundColor ? true : false,
                  background_size: infoToUpdate.backgroundBorderWidth,
                  background_angle: infoToUpdate.backgroundBorderJoin,
                  size_ratio: (infoToUpdate.size.width / infoToUpdate.size.height),
                }
              }
              tempImagesList[indexHasLink] = { ...tempLink };
            }
          }
        }
      }

      // const tempImagesList = [...imagesList];

      const filtered = tempImagesList.filter((e) => e.id === itemInfo.name);
      if (filtered.length > 0) {
        const index = tempImagesList.indexOf(filtered[0]);
        if (index >= 0) {
          setIndexImageSelected(index);
          tempImagesList[index] = { ...imageSelectedUpdated };
        } else {
          setIndexImageSelected(tempImagesList.push({ ...imageSelectedUpdated }) - 1);
        }
      } else {
        setIndexImageSelected(tempImagesList.push({ ...imageSelectedUpdated }) - 1);
      }

      setImagesList([...tempImagesList])
      // console.log("UPDATEIMAGESELECTED", {...imageSelectedUpdated}, [...tempImagesList], indexImageSelected);
    }
  }

  const handleBringOnTop = () => {
    viewerInstance.bringCurrentItemOnTop();
  }

  const handleCenterItem = () => {
    viewerInstance.centerItem();
  }

  useEffect(() => {
    // console.log("ITEMS LIST CHANGES", [...imagesList]);
  }, [imagesList]);

  useEffect(() => {
    // console.log("currentSelectedItem changed", currentSelectedItem, imageSelected);
    // Check item currentItem is empty and imageSelected is not we have to unset imageSelected
    if (
      (currentSelectedItem === null && imageSelected) ||
      (currentSelectedItem && currentSelectedItem.type === "TextInfo") ||
      (
        currentSelectedItem &&
        imageSelected &&
        currentSelectedItem.type === "LogoInfo" &&
        imageSelected.id !== "" &&
        imageSelected.id !== currentSelectedItem.name &&
        (imageSelected.hasLink === null ||
          imageSelected.hasLink !== currentSelectedItem.name))
    ) {
      handleUnselect()
    }

    if (currentSelectedItem && currentSelectedItem.isLogo && imageSelected && currentSelectedItem.name === imageSelected.id) {
      handleUpdateImageSelected({ ...imageSelected }, { ...currentSelectedItem }, [...imagesList]);
    }

    // UPDATE CAMERA ON PRESET LOADING
    if (waitingForPreset) {
      setWaitingForPreset(false);
      setTimeout(() => {
        switch (presetSide) {
          case "front":
            viewerInstance.setCameraToFront();
            break;
          case "back":
            viewerInstance.setCameraToBack();
            break;
          case "left":
            viewerInstance.setCameraToLeft();
            break;
          case "right":
            viewerInstance.setCameraToRight();
            break;
          default:
            break;
        }
      }, (0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedItem]);

  useEffect(() => {
    const filtered = colorsList.filter((color) => {

      for (let i = 0; i < imagesList.length; i++) {
        if (imagesList[i].background_color === color.color && !colorsInUse.includes(color)) {
          return true;
        }
      }
      return false;
    });

    const tempColorInUseImage = [...colorsInUse, ...filtered];
    setColorsInUseImage(tempColorInUseImage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorsInUse, imagesList]);

  const handleDeleteAll = () => {
    setImagesList([]);
    imagesList.forEach((image) => {
      viewerInstance.deleteItem(image.id);
    });

  }

  return (
    <>
      <Drawer
        className="panel-right"
        sx={{
          //width: 442,
          "& .MuiDrawer-paper": {
            width: 442,
            maxHeight: `calc(100vh - ${appBarHeight + 30}px)`,
            gap: "10px",
            borderRadius: "20px"
          },
        }}
        variant="permanent"
        anchor="right"
        elevation={0}
      >

        {/* IMAGES LIST */}
        <Box id="texts">
          <Box id="text-init" sx={{ display: imageEdit || brandLogoEdit ? "none" : "block" }}>
            <Box className="panel-box">
              <Box
                className="panel-header"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4" sx={{ marginRight: "auto" }}>
                  {t("panel_title_entered_images")}
                </Typography>

                <IconButton
                  aria-label="delete-all"
                  size="small"
                  onClick={() => { setModalDeleteDesignOpen(true) }}
                  sx={{
                    display: imagesList.length === 0 ? "none" : "flex",
                    mr: "5px",
                    svg: {
                      width: "20px",
                      height: "20px",
                      fill: "currentcolor"
                    }
                  }}>
                  <IconTrash />
                </IconButton>

                <IconButton aria-label="add" size="small" onClick={handleOpenUpload}>
                  <AddIcon fontSize="inherit" />
                </IconButton>
              </Box>

              <Box className="panel-content">
                {
                  brandLogo ?
                    <BrandLogoListItem
                      brandLogo={brandLogo}
                      handleOpenBrandLogoCustomizer={() => { setBrandLogoEdit(true); }}
                      small={true}
                    />
                    : ""
                }
                {
                  imagesList.length === 0 ?
                    <Typography
                      variant="body2"
                      sx={{
                        mt: "10px",
                        color: theme.palette.white.o07,
                      }}
                    >
                      {t("panel_description_entered_images")}
                    </Typography>
                    :
                    <PanelCustomImages
                      imagesList={imagesList}
                      selectImage={handleImageSelection}
                      handleDuplicate={handleImageDuplicateFromList}
                      handleDelete={handleImageDeleteFromList}
                    />
                }
              </Box>
            </Box>
          </Box>
        </Box>

        {
          imageEdit ?
            <PanelCustomizeImage
              imageSelected={imageSelected}
              imagesList={imagesList}
              setImageSelected={setImageSelected}
              handleCancel={handleImageEditCancel}
              handleDelete={handleImageDelete}
              handleSave={handleImageEditConfirm}
              colorsList={colorsList}
              colorsInUse={colorsInUseImage}
              positionsList={positionsList}
              currentSelectedItem={currentSelectedItem}
              handleMirror={handleMirror}
              handleUnlink={handleUnlink}
              handleUpdateImageSelected={handleUpdateImageSelected}
              handleDuplicate={handleImageDuplicate}
              handleBringOnTop={handleBringOnTop}
              handleCenterItem={handleCenterItem}
              setWaitingForPreset={setWaitingForPreset}
              setPresetSide={setPresetSide}
            />
            : ""
        }

        {
          brandLogoEdit ?
            <PanelCustomizeBrandLogo
              handleClose={() => { setBrandLogoEdit(false); }}
              brandLogo={brandLogo}
            />
            : ""
        }
      </Drawer>

      <ModalConfirmation
        isOpen={modalDeleteDesignOpen}
        title={"modal_title_reset_images"}
        description={"modal_description_reset_images"}
        handleClose={() => { setModalDeleteDesignOpen(false) }}
        handleContinue={() => { handleDeleteAll(); setModalDeleteDesignOpen(false) }}
      />
    </>
  );
}
