import { getColors } from "./ColorHelper";
var Color = getColors;
//console.log('Color', Color);
export const getCustomTheme = (themeMode, blur) => (
  {
    palette: {
      mode: themeMode,
      primary: {
        main: '#C8102E',
        hover: '#A00D25',
        blur: '#21030850',
        blurHover: '#55061350',
        solidRed: '#C8102E80',
      },
      ...(blur === true ?
        {
          filter: {
            blur0: "blur(0px)",
            blur15: "blur(15px)",
            blur50: "blur(50px)",
            blur150: "blur(150px)"
          }
        }
        :
        {
          filter: {
            blur0: "blur(0px)",
            blur15: "blur(0px)",
            blur50: "blur(0px)",
            blur150: "blur(0px)"
          }
        }
      ),
      grey: {
        main: Color.grey,
        o05: Color.grey + '80',
        per23: Color.grey23,
        text: '#777777',
      },
      link: {
        main: '#333333',
      },
      modalBackground: {
        main: "#333333D9"
      },
      error: {
        main: '#48211E26'
      },
      messages: {
        main: '#FC66FF33',
        error: '#FF8680',
        errorBackground: '#48211E99',
        errorBackground015: '#48211E26',
        suggestion: '#64B2FF',
        suggestionBackground: '#20344899',
        alert: '#F5D431',
        alertBackground: '#48401799',
        confirm: '#57C973',
        confirmBackground: '#1E3F2699',
      },
      white: {
        main: Color.white,
        o01: Color.white + '1A',
        o015: Color.white + '26',
        o02: Color.white + '33',
        o025: Color.white + '40',
        o05: Color.white + '80',
        o07: Color.white + 'B3',
        per23: Color.white23,
      },
      black: {
        main: Color.black,
        o02: Color.black + '33',
        o05: Color.black + '80',
        o07: Color.black + 'B3',
        per23: Color.black23,
      },
      ...(themeMode === "light" ?
        {
          text: {
            primary: "#000000"
          },
          // white: {
          //   main: Color.black,
          //   o01: Color.black + '1A',
          //   o015: Color.black + '26',
          //   o02: Color.black + '33',
          //   o025: Color.black + '40',
          //   o05: Color.black + '80',
          //   o07: Color.black + 'B3',
          //   per23: Color.black23,
          // },
          // black: {
          //   main: Color.white,
          //   o02: Color.white + '33',
          //   o05: Color.white + '80',
          //   per23: Color.white23,
          // },
          dark: {
            main: "#E5E5E5",
            light: "#959595",
            light08: "#959595CC",
          },
        }
        :
        {
          text: {
            primary: "#FFFFFF"
          },
          // white: {
          //   main: Color.white,
          //   o01: Color.white + '1A',
          //   o015: Color.white + '26',
          //   o02: Color.white + '33',
          //   o025: Color.white + '40',
          //   o05: Color.white + '80',
          //   o07: Color.white + 'B3',
          //   per23: Color.white23,
          // },
          // black: {
          //   main: Color.black,
          //   o02: Color.black + '33',
          //   o05: Color.black + '80',
          //   per23: Color.black23,
          // },
          dark: {
            main: "#1A1A1A",
            light: "#525252",
            light08: "#525252CC",
          },
        }
      ),
    },
    typography: {
      fontFamily: 'Work Sans',
      h1: {
        fontSize: 103,
        lineHeight: 1,
        fontWeight: '600',
      },
      h2: {
        fontSize: 48,
        lineHeight: 1,
        fontWeight: '700',
      },
      h3: {
        fontSize: 32,
        lineHeight: 1.2,
        fontWeight: '600',
      },
      h4: {
        fontSize: 20,
        lineHeight: 1.2,
        fontWeight: '600',
      },
      h5: {
        fontSize: 16,
        lineHeight: 1.2,
        fontWeight: '600',
      },
      body1: {
        fontSize: 16,
        lineHeight: 1.2,
        fontWeight: '400',
      },
      body2: {
        fontSize: 14,
        lineHeight: 1.2,
        fontWeight: '400',
      },
      button: {
        fontSize: 14,
        lineHeight: 1.2,
        fontWeight: '600',
      },
      caption: {
        fontSize: 12,
        lineHeight: 1.2,
        fontWeight: '400',
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            ...(themeMode === "light" ?
              {
                color: "#000000"
              }
              :
              {
                color: "#FFFFFF"
              }
            ),
          }
        }
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: ({ theme }) => ({
            "&.btn-white":{
              borderRadius: '50px',
              textTransform: "uppercase",
              height: '40px',
              padding: "8px 24px",
              backgroundColor: theme.palette.white.main,
              color: theme.palette.black.main,
              "&:hover": {
                backgroundColor: theme.palette.white.o07,
              }
            },
            "&.btn-text": {
              background: "transparent",
              textTransform: "lowercase",
              height: '40px',
              borderRadius: '50px',
              padding: "8px 24px",
              display: "inline-block",
              "&::first-letter": {
                textTransform: "uppercase",
              }
            },
            "&.MuiButtonBase-root": {
              // borderRadius: '50px',
              boxShadow: 'unset',
              ':hover': {
                boxShadow: 'unset',
              }
            },
            "&.MuiButtonBase-root.MuiButton-contained": {
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '24px',
              paddingRight: '24px',
              height: '40px',
            },
            "&.MuiButtonBase-root.MuiTab-root": {
              borderRadius: '50px',
              boxShadow: 'unset',
              paddingTop: '8px',
              paddingBottom: '8px',
              paddingLeft: '24px',
              paddingRight: '24px',
              pointerEvents: 'auto',
              backgroundColor: theme.palette.black.o05,
              color: theme.palette.white.main,
              webkitBackdropFilter: theme.palette.filter.blur50,
              backdropFilter: theme.palette.filter.blur50,
              opacity: "1",
              ':hover': {
                backgroundColor: theme.palette.grey.o05,
                boxShadow: 'unset',
                cursor: 'pointer',
              },
              '&.active': {
                backgroundColor: theme.palette.white.main,
                boxShadow: 'unset',
              },
              '&.active span': {
                color: theme.palette.black.main,
              },
              '&.Mui-selected': {
                backgroundColor: theme.palette.white.main,
                color: theme.palette.black.main,
                boxShadow: 'unset',
              },
              '&.Mui-disabled': {
                opacity: "0.5",
                cursor: "default",
                backgroundColor: theme.palette.black.o05,

              }
            },
            "&.MuiButtonBase-root.no-bg": {
              backgroundColor: "transparent",
            },
            '&.MuiIconButton-root': {
              backgroundColor: theme.palette.white.o01,
            },
            '&.MuiIconButton-root:hover': {
              backgroundColor: theme.palette.white.o02,
            },
            '&.MuiIconButton-root svg': {
              color: theme.palette.white.main,
            },
            '&.MuiIconButton-root.fab-transparent': {
              backgroundColor: 'transparent',
            },
            '&.MuiIconButton-root.fab-transparent:hover': {
              backgroundColor: theme.palette.white.o01,
            },
            '&.MuiIconButton-root.fab-transparent.active': {
              backgroundColor: theme.palette.white.main,
            },
            '&.MuiIconButton-root.fab-transparent.active svg': {
              color: theme.palette.black.main,
            },
            '&.MuiIconButton-root.fab-small': {
              backgroundColor: 'transparent',
              width: '24px',
              height: '24px',
              borderRadius: '5px',
            },
            '&.MuiIconButton-root.fab-small:hover': {
              backgroundColor: theme.palette.white.o01,
            },
            '&.MuiIconButton-root.fab-small.active': {
              backgroundColor: theme.palette.white.main,
              ".panel-disabled &": {
                backgroundColor: "transparent",
              }
            },
            '&.MuiIconButton-root.fab-small.active svg': {
              color: theme.palette.black.main,
              ".panel-disabled &": {
                color: theme.palette.white.main,
              }
            },
            '&.MuiIconButton-root.parts-icons': {
              aspectRatio: 1,
              alignSelf: "flex-end",
              backgroundColor: theme.palette.black.o05,
              padding: '8px',
              webkitBackdropFilter: theme.palette.filter.blur50,
              backdropFilter: theme.palette.filter.blur50,
            },
            '&.MuiIconButton-root.parts-icons:hover': {
              backgroundColor: theme.palette.grey.o05,
            },
            '&.MuiIconButton-root.parts-icons.active': {
              backgroundColor: theme.palette.white.main,
            },
            '&.MuiIconButton-root.parts-icons svg path': {
              fill: theme.palette.white.main,
            },
            '&.MuiIconButton-root.parts-icons.active svg path': {
              fill: theme.palette.black.main,
            },
          })
        }
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip : ({ theme }) => ({
            backgroundColor: theme.palette.dark.main,
            color: theme.palette.white.main,
            fontSize: '14px',
            borderRadius: '5px',
            padding: '10px',
            // backdropFilter: theme.palette.filter.blur50,
          }),
          arrow: ({ theme }) => ({
            color: theme.palette.dark.main,
            fontSize: '14px',
            // backdropFilter: theme.palette.filter.blur50,
          }),
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& .MuiPaper-root": {
              borderRadius: "20px",
              "&::-webkit-scrollbar": {
                width: "0.3em",
                borderRadius: "10px"
              },
              "&::-webkit-scrollbar-track": {
                // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "darkgrey",
                borderRadius: "10px",
                // outline: "1px solid slategrey",
              },
            },
            "&.panel-right": {
              "&.MuiPaper-root": {
                justifyContent: "safe center",
                top: "90px",
                borderLeft: "0px",
                right: "30px",
                backgroundColor: "transparent",
                "&>*": {
                  pointerEvents: "auto",
                }
              },
              "& .panel-box": {
                padding: "10px 8px",
                borderRadius: "20px",
                backgroundColor: theme.palette.black.o05,
                backdropFilter: theme.palette.filter.blur50,
                "&::-webkit-scrollbar": {
                  width: "0.3em",
                  borderRadius: "10px"
                },
                "&::-webkit-scrollbar-track": {
                  // boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "darkgrey",
                  borderRadius: "10px",
                  // outline: "1px solid slategrey",
                },
              },
              "& .MuiList-root": {
                padding: "0px",
                maxHeight: "calc(100% - 44px)",
                overflowY: "auto",
              },
              "& .MuiList-root .color-sample": {
                marginLeft: "10px",
              }
            }
          })
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: ({ theme }) => ({
            '&.MuiInputLabel-filled': {
              borderRadius: '5px 5px 0px 0px',
              fontSize: '14px',
              lineHeight: '1.2',
              color: theme.palette.white.o07,
              position: 'relative',
              maxWidth: '100%',
              transform: 'none',
              top: 'unset',
              left: 'unset',
              padding: '10px 12px 0px 12px',
            },
            '&.MuiInputLabel-filled.Mui-focused': {
              color: theme.palette.white.o07,
            },
            '&.MuiInputLabel-filled[data-shrink="false"] + .MuiFilledInput-root input::-webkit-input-placeholder': {
              opacity: '1 !important',
              color: theme.palette.white.o07,
            },
            '&.MuiInputLabel-filled[data-shrink="false"] + .MuiFilledInput-root input::-moz-placeholder': {
              opacity: '1 !important',
              color: theme.palette.white.o07,
            },
            '&.MuiInputLabel-filled[data-shrink="false"] + .MuiFilledInput-root input:-ms-input-placeholder': {
              opacity: '1 !important',
              color: theme.palette.white.o07,
            },
            '&.MuiInputLabel-filled[data-shrink="false"] + .MuiFilledInput-root input:-moz-placeholder': {
              opacity: '1 !important',
              color: theme.palette.white.o07,
            },
          }),
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: ({ theme }) => ({
            '& .MuiTextField-root': {
              borderRadius: '5px',
            },
            '& .MuiTextField-root .MuiFilledInput-root, & .MuiTextField-root .MuiInputLabel-filled, & .MuiFilledInput-root, & .MuiInputLabel-filled, &.FormControlSelect .MuiFilledInput-root.Mui-focused': {
              backgroundColor: theme.palette.white.o01,
            },
            '& .MuiTextField-root:hover .MuiFilledInput-root::before, &.FormControlSelect:hover .MuiFilledInput-root::before, & .MuiInput-number:hover::before': {
              borderBottom: 0,
              outline: `1px solid ${theme.palette.white.o02}`,
              outlineOffset: '-1px',
            },
            '&.FormControlSelect .MuiFilledInput-root:hover': {
              borderBottom: 0,
              backgroundColor: theme.palette.white.o01,
            },
            '& .MuiTextField-root .MuiFilledInput-root.Mui-focused::before, &.FormControlSelect .MuiFilledInput-root.Mui-focused::before': {
              borderBottom: 0,
              outline: `1px solid ${theme.palette.white.main}`,
              outlineOffset: '-1px',
            },
            '& .MuiTextField-root .MuiFilledInput-root.Mui-error::before, &.FormControlSelect .MuiFilledInput-root.Mui-error::before': {
              borderBottom: 0,
              outline: `1px solid ${theme.palette.messages.error}`,
              outlineOffset: '-1px',
              borderRadius: '5px',
            },
            '&.hasHelperText .MuiTextField-root .MuiFilledInput-root.Mui-error::before': {
              borderBottom: 0,
              outline: `1px solid ${theme.palette.messages.error}`,
              outlineOffset: '-1px',
              borderRadius: '5px 5px 0px 0px',
            },
            '& .MuiTextField-root .MuiFilledInput-root.Mui-error, & .MuiTextField-root .MuiInputLabel-filled.Mui-error, &.FormControlSelect .MuiFilledInput-root.Mui-error, &.FormControlSelect .MuiInputLabel-filled.Mui-error': {
              backgroundColor: theme.palette.messages.errorBackground015,
            },
            '&.FormControlSelect.hasHelperText .MuiInputLabel-filled.Mui-error + .MuiFilledInput-root::before': {
              borderRadius: '5px 5px 0px 0px',
            },
            '& .MuiTextField-root .MuiFilledInput-root.Mui-error': {
              color: theme.palette.white.main,
              borderRadius: '0px',
            },
            '& .MuiTextField-root .MuiInputLabel-filled.Mui-error': {
              color: theme.palette.white.o07,
            },
            '& .MuiInput-number.MuiFilledInput-root:not(.Mui-disabled, .Mui-error)::before': {
              borderBottom: 0,
            },
          })
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: ({ theme }) => ({
            //borderRadius: '5px',
            //position: 'static',
            '& input': {
              colorScheme: "dark",
            },
            '&.MuiFilledInput-root': {
              borderRadius: '0px 0px 5px 5px',
              position: 'static',
            },
            '&.MuiFilledInput-root input, & .MuiSelect-select': {
              backgroundColor: 'transparent',
              height: 'auto',
              padding: '2px 12px 10px 12px',
              fontSize: '16px',
              lineHeight: '1.2',
              color: theme.palette.white.main,
            },
            '&.MuiFilledInput-root:focus, &.MuiFilledInput-root .MuiSelect-filled:focus': {
              backgroundColor: 'transparent',
            },
            '&.MuiFilledInput-root:focus-visible': {
              outline: '0px',
              backgroundColor: 'transparent',
            },
            '&.MuiFilledInput-root::before': {
              content: '""',
              borderBottom: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '5px',
            },
            '&.MuiFilledInput-root::after': {
              display: 'none',
            },
            /*
            '&.MuiOutlinedInput-root': {
              borderRadius: '5px',
              position: 'static',
            },
            '& .MuiInputBase-input': {
              height: 'auto',
              padding: '2px 12px 10px 12px',
              fontSize: '16px',
              lineHeight: '1.2',
              color: theme.palette.white.main,
            },
            '&::placeholder': {
              //textOverflow: 'ellipsis !important',
              color: theme.palette.white.o07,
              opacity: 1,
            },
            */
            '&.MuiFilledInput-root fieldset': {
              top: '0px',
            },
            '&.MuiFilledInput-root fieldset legend': {
              display: 'none',
            },
            '&.MuiFilledInput-root.MuiInputBase-inputAdornedEnd': {
              paddingRight: '48px',
            },
            '&.MuiFilledInput-root .MuiInputAdornment-filled': {
              position: 'absolute',
              top: '13px',
              right: '12px',
            },
            '&.MuiFilledInput-root input:autofill': {
              boxShadow: 'unset',
            },
            '& .MuiSelect-select': {
              minHeight: 'unset',
            },
            '&.MuiInput-number': {
              fontSize: '14px',
              lineHeight: 1.2,
              fontWeight: 600,
              alignSelf: 'flex-start',
              position: 'relative',
            },
            '&.MuiInput-number.MuiInputBase-sizeSmall': {
              width: '100px',
              padding: '0px 10px',
              display: 'flex',
              justifyContent: 'center',
              background: theme.palette.white.o01,
              borderRadius: '5px',
              gap: '0px',
            },
            '&.MuiInput-number.MuiInputBase-sizeSmall .MuiInputBase-inputSizeSmall': {
              width: 'auto',
              height: 'auto',
              padding: '5px 0px',
              textAlign: 'center',
              background: 'transparent',
              borderRadius: '5px',
              fontSize: '14px',
            },
            '&.MuiInput-number.MuiInputBase-sizeSmall .MuiInputAdornment-root': {
              marginLeft: '4px',
              height: 'auto',
              padding: '5px 0px',
              maxHeight: 'unset',
              position: 'static',
              top: 'unset',
              right: 'unset',
            },
            '&.MuiInput-number.MuiInputBase-sizeSmall .MuiInputAdornment-root p': {
              fontSize: '14px',
            },
          })
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: ({ theme }) => ({
            margin: 0,
            padding: '2px 12px 4px 12px',
            fontSize: '14px',
            color: theme.palette.white.o07,
            borderRadius: '0px 0px 5px 5px',
            '&.Mui-error': {
              color: theme.palette.black.main,
              backgroundColor: theme.palette.messages.error,
              outline: `1px solid ${theme.palette.messages.error}`,
              outlineOffset: '-1px',
            },
          })
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            //background: 'transparent',
            '&.Mui-selected, &:hover, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible': {
              backgroundColor: theme.palette.white.o01,
            }
          })
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            //background: 'transparent',
            '&.MuiMenu-paper': {
              outline: `1px solid ${theme.palette.white.main}`,
              outlineOffset: '-1px',
              //borderTopLeftRadius: '0px',
              //borderTopRightRadius: '0px',
            }
          })
        }
      },
      MuiSlider: {
        styleOverrides: {
          root: ({ theme }) => ({
            "&.custom-slider": {
              color: theme.palette.white.main,
              '& .MuiSlider-rail': {
                opacity: 0.2,
              },
              '& .MuiSlider-track': {
                border: 0,
              },
              '& .MuiSlider-thumb, & .MuiSlider-thumb:hover, & .MuiSlider-thumb.Mui-focusVisible': {
                width: '15px',
                height: '15px',
              },
              '& .MuiSlider-thumb:hover, & .MuiSlider-thumb.Mui-focusVisible, & .MuiSlider-thumb.Mui-active': {
                boxShadow: 'unset',
              },
              '& .MuiSlider-thumb::before': {
                boxShadow: 'unset',
              }
            }
          })
        }
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            width: 60,
            height: 32,
            padding: 0,
            '& .MuiSwitch-switchBase': {
              padding: 0,
              margin: '2px 0px',
              transitionDuration: '300ms',
              transform: 'translate(0px,0px)',
              left: '3px',
              '&.Mui-checked': {
                transform: 'translate(26px,0px)',
                color: '#fff',
                margin: '2px 0px',
                '& .MuiSwitch-thumb': {
                  //position: "relative",
                  backgroundColor: theme.palette.white.main,
                  width: 28,
                  height: 28,
                  margin: 0,
                },
                '& + .MuiSwitch-track': {
                  backgroundColor: theme.palette.white.o02,
                  opacity: 1,
                  border: 0,
                  zIndex: 1,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                  opacity: 0.5,
                },
              },
              /*
              '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
              },
              '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey.o05,
              },
              */
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.7,
              },
            },
            '& .MuiSwitch-thumb': {
              width: 28,
              height: 28,
              boxSizing: 'border-box',
              background: theme.palette.black.main,
            },
            '& .MuiSwitch-track': {
              borderRadius: 32,
              backgroundColor: theme.palette.white.o02,
              opacity: 1,
              transition: theme.transitions.create(['background-color'], {
                duration: 500,
              }),
            },
            '&.switch-dark .MuiSwitch-track, &.switch-dark .Mui-checked + .MuiSwitch-track': {
              backgroundColor: theme.palette.black.o02,
            },
          })
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: 0,
            color: theme.palette.white.o07,
            '&:hover': {
              color: theme.palette.white.main,
            },
            '&.Mui-checked, .MuiCheckbox-indeterminate': {
              color: theme.palette.white.main,
            },
          })
        }
      },
      MuiRadio: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: 0,
            color: theme.palette.white.o07,
            '&:hover': {
              color: theme.palette.white.main,
            },
            '&.Mui-checked, .MuiCheckbox-indeterminate': {
              color: theme.palette.white.main,
            },
          })
        }
      },
    },
  }
)
