import { useEffect, useRef, useState } from "react";
import { Drawer, Box, Typography, useTheme, IconButton, Button } from "@mui/material";
import PanelCustomParts from "./PanelCustomParts";
import { useTranslation } from "react-i18next";
import PanelGlobalColor from "./PanelGlobalColor";
import PanelCustomizePart from "./PanelCustomizePart";
import { viewerInstance } from "../helpers/Viewer";
import EditIcon from '@mui/icons-material/Edit';
import { appBarHeight } from "../helpers/layout";
import _ from "lodash";
import PanelClonePart from "./PanelClonePart";
import IconResetDesign from "./icons/IconResetDesign";
import ModalConfirmation from "./ModalConfirmation";
import PanelCustomizeExtraPart from "./PanelCustomizeExtraPart";
let timer;

export default function DrawerCustomizer(props) {

  const {
    editPartColor,
    setEditPartColor,
    editPartGradient,
    setEditPartGradient,
    editPartPattern,
    setEditPartPattern,
    editGlobalColor,
    setEditGlobalColor,
    editPartClone,
    setEditPartClone,
    editExtaPart,
    setEditExtraPart,
    colorsList,
    colorsInUse,
    productParts,
    setProductParts,
    patternList,
    designSetted,
    handleOpenDesign,
    handleResetDesign,
    productExtraParts,
    setProductExtraParts,
    productExtraPartsInfo,
    tabValue
  } = props;

  // 0: Part List 1: Part Color 2: Part Pattern 3: Global Color
  const [status, setStatus] = useState(0);

  const { t } = useTranslation();

  const theme = useTheme();

  useEffect(() => {
    //console.log("CHANGED", productParts)
  }, [productParts])

  const [cloneDestination, setCloneDestination] = useState(null);

  const ref = useRef();

  const [modalResetDesignOpen, setModalResetDesignOpen] = useState(false);


  const [isScrollable, setIsScrollable] = useState(false);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

  useEffect(() => {
    switch (status) {
      case 0:
        setEditPartColor(false);
        setEditPartPattern(false);
        setEditGlobalColor(false);
        setEditPartClone(false);
        setSelectedPart(null);
        setEditExtraPart(false);
        break;

      case 1:
        setEditPartColor(true);
        setEditPartPattern(false);
        setEditGlobalColor(false);
        setEditPartClone(false);
        setEditExtraPart(false);
        break;

      case 2:
        setEditPartColor(false);
        setEditPartPattern(true);
        setEditGlobalColor(false);
        setEditPartClone(false);
        setEditExtraPart(false);
        break;

      case 3:
        setEditPartColor(false);
        setEditPartPattern(false);
        setEditGlobalColor(true);
        setEditPartClone(false);
        setEditExtraPart(false);
        break;

      case 4:
        setEditPartColor(false);
        setEditPartPattern(false);
        setEditGlobalColor(false);
        setEditPartClone(true);
        setEditExtraPart(false);
        break;

      case 5:
        setEditPartColor(false);
        setEditPartPattern(false);
        setEditGlobalColor(false);
        setEditPartClone(false);
        setEditExtraPart(true);
        break;

      default:
        setEditPartColor(false);
        setEditPartPattern(false);
        setEditGlobalColor(false);
        setEditPartClone(false);
        setEditExtraPart(false);
        setCloneDestination(null);
        break;
    }

    ref.current?.children[0]?.scrollTo({ top: 0, left: 0, behavior: "instant" });
    // ADDED TIMOUT TO LET THE COMPONENT RENDER BEFORE CHECKING SCROLL POSITION
    timer = setTimeout(() => {
      ref.current?.children[0]?.dispatchEvent(new Event("scroll"))
    }, 100);
    // eslint-disable-next-line
  }, [status]);

  const [globalColorSelected, setGlobalColorSelected] = useState("");

  const [selectedPart, setSelectedPart] = useState(null);

  const handleSaveChangeGlobalColor = (newColor) => {
    //console.log("HANDLE SAVE GLOBAL")
    const temp = _.cloneDeep(productParts)
    // const temp = [...productParts];
    temp.forEach(element => {
      let update = false;
      if (element.color === globalColorSelected) {
        element.color = newColor;
        update = true;
      }
      if (element.color2 === globalColorSelected) {
        element.color2 = newColor;
        update = true;
      }
      if (element.pattern && element.patternColor === globalColorSelected) {
        element.patternColor = newColor;
        update = true;
      }
      if (update) {
        // console.log(element);
        // handleUpdatePart({...element});
        updatePart({ ...element });
      }
    });

    setProductParts([...temp]);
    setStatus(0);
  }

  const handleChangeGlobalColor = (newColor) => {
    // console.log(newColor);
    const tempz = [...productParts];

    //console.log(globalColorSelected, newColor, tempz);
    tempz.forEach(element => {
      const el = { ...element }
      let update = false;
      if (el.color === globalColorSelected) {
        el.color = newColor;
        update = true;
      }
      if (el.color2 === globalColorSelected) {
        el.color2 = newColor;
        update = true;
      }
      if (el.pattern && el.patternColor === globalColorSelected) {
        el.patternColor = newColor;
        update = true;
      }
      if (update) {
        //console.log(el);
        // handleUpdatePart({...el});
        updatePart({ ...el });
      }
    });

  }

  const setDesign = () => {

    //console.log(productParts)
    productParts.forEach(element => {

      updatePart({ ...element });

    });

    setProductParts([...productParts]);
    setStatus(0);
  }


  const handleSaveCustomizePart = (newParts) => {
    const newStatus = [...productParts];
    for (let i = 0; i < newStatus.length; i++) {
      // console.log(newStatus[i], newParts, newStatus[i].id === newParts.id);
      if (newStatus[i].id === newParts.id) {
        // console.log(newStatus[i], newParts);
        newStatus[i] = { ...newParts }
        break;
      }
    }
    setProductParts([...newStatus]);
    setStatus(0);
  }

  const handleEditColor = () => {
    setStatus(1);
    // if(selectedPart) {
    //   selectedPart.pattern = "no-pattern"
    // }
  }

  const handleEditPattern = () => {
    setStatus(2);
  }

  const handleUpdatePart = (part) => {
    //console.log("UPDATE PART", part)
    handleUpdate({ ...part });
    setSelectedPart({ ...part });
  }

  const handleSaveCustomizeExtraPart = () => {
    setStatus(0);
  }

  const handleUpdateExtraPart = (part) => {
    viewerInstance.setProductExtraPartColor(part.name, part.color)
    let tempProductExtraParts = structuredClone(productExtraParts);
    let index = tempProductExtraParts.findIndex((item) => item.name === part.name);
    if (index !== -1) {
      tempProductExtraParts[index].color = part.color;
    }
    setProductExtraParts(tempProductExtraParts);
    setSelectedPart(structuredClone(part));
  }

  const handleUpdate = (part) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      updatePart(part)
    }, 250);
  }

  const updatePart = (part) => {
    if (part.color2 === "") {
      //console.log("setDesignColor", part.id, part.color);
      viewerInstance.setDesignColor(part.id, part.color)
    } else {
      viewerInstance.setDesignGradient(
        part.id,
        {
          startColor: part.color,
          startOffset: part.colorPosition,
          stopColor: part.color2,
          stopOffset: part.color2Position,
          rotation: part.colorsAngle,
        }
      )
    }
    if (part.pattern) {
      viewerInstance.setDesignPattern(
        part.id,
        {
          url: part.patternUrl,
          fill: part.patternColor,
          scale: part.patternSize,
          angle: part.patternAngle,
          offsetX: part.patternOffsetX,
          offsetY: part.patternOffsetY
        });
      // console.log(part.id,
      //   {
      //     url: part.patternUrl,
      //     fill: part.patternColor,
      //     scale: part.patternSize,
      //     angle: part.patternAngle,
      //     offsetX: part.patternOffsetX,
      //     offsetY: part.patternOffsetY
      //   })
    } else {
      viewerInstance.removeDesignPattern(part.id)
    }
  }

  const handleActiveClone = (part) => {
    setCloneDestination({ ...part });
    setStatus(4);
  }

  useEffect(() => {

    if ((editPartColor || editPartPattern) && selectedPart) {
      handleSaveCustomizePart(selectedPart);
    }
    if (editExtaPart && selectedPart) {
      handleSaveCustomizeExtraPart(selectedPart);
    }
    if (editGlobalColor) {
      setDesign();
    }
    setStatus(0);
    // eslint-disable-next-line
  }, [tabValue]);

  useEffect(() => {
    const container = ref.current?.children[0];

    const checkScrollable = () => {
      if (container) {
        // ADDED TIMOUT TO LET THE COMPONENT RENDER BEFORE CHECKING SCROLL
        timer = setTimeout(() => {
          const canScroll = container.scrollHeight > container.clientHeight;
          setIsScrollable(canScroll);
          handleScroll()
        }, 100);
      }
    }

    const handleScroll = () => {

      if (container) {
        const scrolledToBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight;
        setIsScrolledToEnd(scrolledToBottom);
      }
    };

    if (container) {
      container.addEventListener("scroll", handleScroll);
      checkScrollable();

      return () => {
        container.removeEventListener("scroll", handleScroll);
      }
    };

    // eslint-disable-next-line
  }, [productParts, status]);

  const scrollFunction = () => {
    if (isScrolledToEnd) {
      ref.current?.children[0]?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }else {
      ref.current?.children[0]?.scrollTo({ top: ref.current?.children[0]?.scrollHeight, left: 0, behavior: "smooth" });
    }

    // ADDED TIMOUT TO LET THE COMPONENT RENDER BEFORE CHECKING SCROLL POSITION
    timer = setTimeout(() => {
      ref.current?.children[0]?.dispatchEvent(new Event("scroll"))
    }, 100);
  }

  return (
    <>
      <Drawer
        className="panel-right"
        sx={{
          //width: 442,
          "& .MuiDrawer-paper": {
            width: 442,
            maxHeight: `calc(100vh - ${appBarHeight + 30}px)`,
            gap: "10px",
          },
        }}
        variant="permanent"
        anchor="right"
        elevation={0}
        ref={ref}
      >

        {/*  DESIGN EDIT AND PREVIEW */}
        {!(editPartColor ||
          editPartPattern ||
          editGlobalColor ||
          editPartClone ||
          editExtaPart) && designSetted ?
          <Box
            className="panel-box"
            sx={{
              marginBottom: "10px",
              img: {
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain"
              }
            }}
          >
            <Box className="panel-header" sx={{
              display: "flex",
              gap: "10px",
              py: "10px",
              alignItems: "center"
            }}>
              <Typography variant="h4" sx={{ marginRight: "auto" }}>
                {designSetted.title}
              </Typography>

              <IconButton size="small" sx={{
                svg: {
                  width: "20px",
                  height: "20px"
                }
              }}
                onClick={() => { setModalResetDesignOpen(true) }}
              >
                <IconResetDesign />
              </IconButton>

              <Button
                variant="contained"
                onClick={handleOpenDesign}
                size="small"
                sx={{
                  fontWeight: "300",
                  gap: "5px",
                  padding: "5px !important",
                  display: "flex",
                  height: "auto !important",
                  background: theme.palette.white.o01,
                  borderRadius: "5px",
                  span: {
                    display: "inline-block",
                    padding: "0px 5px",
                    fontSize: "16px",
                    textTransform: "lowercase",
                    "&:first-letter": {
                      textTransform: "uppercase",
                    }
                  },
                  "&:hover": {
                    background: theme.palette.white.o02,
                  }
                }}
              >
                <span>
                  {designSetted.id}
                </span>
                <EditIcon fontSize="small" />
              </Button>
            </Box>
            <Box className="panel-content">
              <Typography variant="body2" sx={{
                color: theme.palette.white.o07,
              }}>
                {t("panel_description_design")}
              </Typography>
            </Box>
          </Box>
          : ""}
        {/*COLORS IN USE AND INIT PARTS*/}
        <Box
          id="init-parts"
          sx={{
            display:
              editPartColor ||
                editPartPattern ||
                editGlobalColor ||
                editPartClone ||
                editExtaPart
                ? "none"
                : "block",
          }}
        >
          <Box
            className="panel-box"
            sx={{
              marginBottom: "10px",
            }}
          >
            <Box className="panel-header">
              <Typography variant="h4" sx={{ marginRight: "auto" }}>
                {t("panel_label_colors_in_use")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  pt: "5px",
                  color: theme.palette.white.o07,
                }}
              >
                {t("panel_description_colors_in_use")}
              </Typography>
            </Box>
            <Box className="panel-content">
              <Box className="color-sample-container" sx={{ gap: "5px" }}>
                {colorsInUse.map((backgroundColor, index) => (
                  <Box
                    key={"color-sample-" + index}
                    className="color-selector color-selector-m "
                    onClick={() => { setGlobalColorSelected(backgroundColor.color); setStatus(3); }}
                  >
                    <Box
                      className={"color-inner"}
                      sx={{ backgroundColor: backgroundColor.color }}
                    ></Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          {/* PANEL CUSTOM PARTS */}

          <PanelCustomParts
            isScrollable={isScrollable}
            isScrolledToEnd={isScrolledToEnd}
            productParts={productParts}
            selectPart={(part) => {
              setSelectedPart(part)
              setStatus(1)
            }}
            activeClone={handleActiveClone}
            cloneDestination={cloneDestination}
            productExtraParts={productExtraParts}
            selectExtraPart={(part) => {
              setSelectedPart(part)
              setStatus(5)
            }}
            scrollFunction={() => {scrollFunction()}}
          >
          </PanelCustomParts>
        </Box>

        {/*EDIT GLOBAL COLOR*/}
        {editGlobalColor &&
          <PanelGlobalColor
            colorsList={colorsList}
            globalColorSelected={globalColorSelected}
            handleClose={setDesign}
            handleChangeGlobalColor={handleChangeGlobalColor}
            handleSave={handleSaveChangeGlobalColor}
          />
        }

        {/*CONFIG SINGOLA PARTE*/}
        {(editPartColor || editPartPattern) && selectedPart ?
          <PanelCustomizePart
            editPartColor={editPartColor}
            editPartGradient={editPartGradient}
            setEditPartGradient={setEditPartGradient}
            editPartPattern={editPartPattern}
            handleEditColor={handleEditColor}
            handleEditPattern={handleEditPattern}
            handleClose={() => { setStatus(0) }}
            handleSave={handleSaveCustomizePart}
            selectedPart={selectedPart}
            colorsList={colorsList}
            colorsInUse={colorsInUse}
            patternList={patternList}
            handleUpdatePart={handleUpdatePart}
          />
          : ""

        }

        {/* CONFIG EXTRA PART */}
        {
          editExtaPart && selectedPart ?
            <PanelCustomizeExtraPart
              colorsList={productExtraPartsInfo.filter(el => el.partName === selectedPart.name)[0].colors}
              selectedPart={selectedPart}
              handleUpdatePart={handleUpdateExtraPart}
              handleSave={handleSaveCustomizeExtraPart}
            ></PanelCustomizeExtraPart>
            : ""
        }

        {/* CLONE PART */}
        {editPartClone && cloneDestination ?
          <PanelClonePart
            productParts={productParts}
            handleClose={() => { setStatus(0) }}
            cloneDestination={cloneDestination}
            setCloneDestination={setCloneDestination}
            updatePart={updatePart}
            isScrollable={isScrollable}
            isScrolledToEnd={isScrolledToEnd}
          />
          : ""}

      </Drawer>

      <ModalConfirmation
        isOpen={modalResetDesignOpen}
        title={"modal_title_reset_design"}
        description={"modal_description_reset_design"}
        handleClose={() => { setModalResetDesignOpen(false) }}
        handleContinue={() => { handleResetDesign(); setModalResetDesignOpen(false) }}
      />

    </>
  );
}
