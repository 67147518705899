import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import IconScrollDown from "./icons/IconScrollDown";
// import { transform } from "lodash";

export default function ScrollDownTopButton(props) {

  const {
    isScrollable,
    isScrolledToEnd,
    scrollFunction
  } = props

  const { t } = useTranslation();

  // const theme = useTheme();

  return (
    <Box sx={{
      display: isScrollable ? "flex" : "none",
      pointerEvents: "auto",
      position: "sticky",
      bottom: "10px",
      zIndex: 100,
      marginTop: "20px",
      width: "100%",
      justifyContent: "center",
    }}>
    <Button
      variant="contained"
      className="btn-white"
      sx={{
        svg: {

          transform: isScrollable && !isScrolledToEnd ? "rotate(0deg)" : "rotate(180deg)",
        }
      }}
      onClick={() => {scrollFunction()}}
      startIcon={
          <IconScrollDown />
      }
    >
      {
        isScrollable && !isScrolledToEnd ?
          t("label_scroll_down")

          :

          t("label_scroll_top")
      }
    </Button>
    </Box>
  )
}
