import { Box, Typography, useTheme } from "@mui/material";



export default function Overlay(props) {

  const theme = useTheme();

  return(
  <Box
    sx={{
      background: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100svh',
      gap: "20px",
      position:"absolute",
      zIndex: theme.zIndex.appBar + 1000,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      img: {
        borderRadius: "500px",
        maxWidth: "90%",
      },
      [theme.breakpoints.up("lg")]:{
        display: 'none',
      }
    }}
  >
    <img src={process.env.PUBLIC_URL + "/logo512.png"} alt="adrenalina logo"/>
    <Typography
      component="div"
      variant="h5"
      align="center"
    >
      L'applicazione non supporta schermi di queste dimensioni
    </Typography>
  </Box>)
}
