import { Box, IconButton, Typography } from "@mui/material";
import { ReactComponent as IconColors } from "../icons/icon-colors.svg";
// import { ReactComponent as IconPattern } from "../icons/icon-pattern.svg";
import ButtonRound from "./ButtonRound";
import { useTranslation } from "react-i18next";
// import ButtonSmall from "./ButtonSmall";
// import Divider from "./Divider";
// import AddIcon from "@mui/icons-material/Add";
// import PanelColorPattern from "./PanelColorPattern";
// import ColorSelector from "./ColorSelector";
import ColorsListSelector from "./ColorsListSelector";

export default function PanelCustomizeExtraPart(props) {

  const {
    editPartColor,
    // editPartGradient,
    // setEditPartGradient,
    // editPartPattern,
    handleEditColor,
    // handleEditPattern,
    // handleClose,
    handleSave,
    selectedPart,
    colorsList,
    // colorsInUse,
    // patternList,
    handleUpdatePart
  } = props;

  const { t } = useTranslation();

  // const theme = useTheme();

  const colorSelected = 1
  // const [colorSelected, setColorselected] = useState(1);


  const handleUpdatePartColor = (color) => {
    if (colorSelected) {
      const temp = { ...selectedPart };
      temp.color = color;
      handleUpdatePart({ ...temp });
    }
  }

  return (
    <>
      {/* SWITCH COLORS E PATTERNS */}
      <Box>
        <Box className="panel-dx">
          <Box className="panel-actions">
            <IconButton
              className={"parts-icons " + (editPartColor ? "active" : "")}
              aria-label="colors"
              size="large"
              onClick={handleEditColor}
            >
              <IconColors width="32" height="32" />
            </IconButton>
          </Box>
          <Box className="" sx={{ p: "0px 8px 8px 8px" }}>
            <Box className="panel-header">
              <Typography variant="h4" sx={{ marginRight: "auto", textTransform: "capitalize" }}>
                {t(selectedPart.name)}
              </Typography>
            </Box>
            <Box className="panel-content">

            </Box>
            <ColorsListSelector
                disable={false}
                colorsList={colorsList}
                color={selectedPart.color}
                color2={false}
                handleUpdateColor={(value) => { handleUpdatePartColor(value) }}
                handleUpdateColors={() => { }}
                editGradient={false}
              />
          </Box>
        </Box>
        <Box className="panel-footer">
          {/* <ButtonRound
          id="btn-cancel"
          text={t("panel_button_label_cancel")}
          variant="contained"
          className="btn-red"
          onClick={handleClose}
        /> */}
          <ButtonRound
            id="btn-confirm"
            text={t("panel_button_label_confirm")}
            variant="contained"
            className="mx-auto btn-white"
            onClick={() => { handleSave({ ...selectedPart }) }}
          />
        </Box>
      </Box>
    </>
  )
}
