import { Box, IconButton, useTheme } from "@mui/material";
import { ReactComponent as IconColors } from "../icons/icon-colors.svg";
import { ReactComponent as IconPattern } from "../icons/icon-pattern.svg";
import ButtonRound from "./ButtonRound";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ButtonSmall from "./ButtonSmall";
import Divider from "./Divider";
import AddIcon from "@mui/icons-material/Add";
import PanelColorPattern from "./PanelColorPattern";

export default function PanelCustomizePart(props) {

  const {
    editPartColor,
    editPartGradient,
    setEditPartGradient,
    editPartPattern,
    handleEditColor,
    handleEditPattern,
    // handleClose,
    handleSave,
    selectedPart,
    colorsList,
    colorsInUse,
    patternList,
    handleUpdatePart
  } = props;

  const { t } = useTranslation();

  const theme = useTheme();

  const [colorSelected, setColorselected] = useState(1);

  useEffect(() => {
    if (selectedPart) {
      if (selectedPart.color2 !== "") {
        setEditPartGradient(true)
      } else {
        setEditPartGradient(false)
      }
    }
    // eslint-disable-next-line
  }, [selectedPart]);

  const handleUpdateColorsAngle = (angle) => {
    const temp = { ...selectedPart };
    temp.colorsAngle = angle;
    handleUpdatePart({ ...temp });
  }

  const handleUpdateGradientPositions = (position1, position2) => {
    const temp = { ...selectedPart };
    temp.colorPosition = position1;
    temp.color2Position = position2;
    handleUpdatePart({ ...temp });
  }

  const handleSetPatternSize = (size) => {
    const temp = { ...selectedPart };
    if (size > 0.5) {
      temp.patternSize = size;
    } else {
      temp.patternSize = 0.5;
    }
    handleUpdatePart({ ...temp });
  }
  const handleSetPatternAngle = (angle) => {
    const temp = { ...selectedPart };
    temp.patternAngle = angle;
    handleUpdatePart({ ...temp });
  }
  const handleSetPatternOffsetX = (offsetX) => {
    const temp = { ...selectedPart };
    temp.patternOffsetX = offsetX;
    handleUpdatePart({ ...temp });
  }
  const handleSetPatternOffsetY = (offsetY) => {
    const temp = { ...selectedPart };
    temp.patternOffsetY = offsetY;
    handleUpdatePart({ ...temp });
  }

  const handleUpdatePartColor = (color) => {
    const temp = { ...selectedPart };
    temp.color = color;
    handleUpdatePart({ ...temp });
  }

  const handleUpdatePatternColor = (color) => {
    const temp = { ...selectedPart };
    temp.patternColor = color;
    handleUpdatePart({ ...temp });
  }

  const handleUpdatePattern = (patternLabel, patternUrl) => {
    const temp = { ...selectedPart };
    temp.pattern = patternLabel;
    temp.patternUrl = patternUrl;
    handleUpdatePart({ ...temp });
  }

  const handleUpdatePartColors = (color) => {
    if (colorSelected === 1) {
      const temp = { ...selectedPart };
      temp.color = color;
      handleUpdatePart({ ...temp });
    }
    if (colorSelected === 2) {
      const temp = { ...selectedPart };
      temp.color2 = color;
      handleUpdatePart({ ...temp });
    }
  }

  return (
    <>
      <Box className="panel-box" sx={{
        display: "none",
        padding: "10px 8px",
        borderRadius: "20px",
        backgroundColor: "rgba(0,0,0,0.5)",
        backdropFilter: "blur(50px)",
        width: "200px",
        height: "200px",
        // position: "absolute",
        // top: "20px",
        // left: "20px",
        overflow: "hidden",
        zIndex: "-1",
        mx: "auto",
        flexShrink: 0,
        mb: "20px"
      }}>
        <Box sx={{
          opacity: selectedPart.pattern ? 1 : 0,
          height: "150%",
          width: "150%",
          position: "absolute",
          transformOrigin: "center",
          zIndex: 1,
          // top: 0,
          // left: 0,
          top: "50%",
          left: "50%",
          transform: `translate(-50%, -50%) rotate(${selectedPart.patternAngle}deg)`,
          // scale: "0.25",
          background: selectedPart.patternColor,
          maskImage: selectedPart.pattern ?
            `url("${selectedPart.patternUrl}")`
            : "none",
          maskSize: `${100 * selectedPart.patternSize}%`
        }}>
        </Box>
        <Box sx={{
          position: "absolute",
          width: "200%",
          height: "200%",
          top: 0,
          left: 0,
          transform: "translate(-25%, -25%)",
          backgroundRepeat: "repeat",
          backgroundSize: `${selectedPart.patternSize}%`,
          background: `linear-gradient(${selectedPart.colorsAngle}deg,${selectedPart.color} ${selectedPart.colorPosition}%, ${selectedPart.color2 !== "" ? selectedPart.color2 : selectedPart.color} ${selectedPart.color2Position}%)`,
        }}>

        </Box>
      </Box>
      {/* SWITCH COLORS E PATTERNS */}
      <Box>
        <Box className="panel-dx">
          <Box className="panel-actions">
            <IconButton
              className={"parts-icons " + (editPartColor ? "active" : "")}
              aria-label="colors"
              size="large"
              onClick={handleEditColor}
            >
              <IconColors width="32" height="32" />
            </IconButton>
            {selectedPart.supportPattern ?
              <IconButton
                className={"parts-icons " + (editPartColor ? "" : "active")}
                aria-label="pattern"
                size="large"
                onClick={handleEditPattern}
              >
                <IconPattern width="32" height="32" />
              </IconButton>
              : ""}
          </Box>
          <Box className="" sx={{ p: "0px 8px 8px 8px"}}>
            <>
              <PanelColorPattern
                editPartPattern={editPartPattern}
                colorsList={colorsList}
                selectedPart={selectedPart}
                colorsInUse={colorsInUse}
                editPartGradient={editPartGradient}
                setEditPartGradient={setEditPartGradient}
                patternSize={selectedPart.patternSize}
                setPatternSize={handleSetPatternSize}
                patternAngle={selectedPart.patternAngle}
                setPatternAngle={handleSetPatternAngle}
                patternOffsetX={selectedPart.patternOffsetX}
                setPatternOffsetX={handleSetPatternOffsetX}
                patternOffsetY={selectedPart.patternOffsetY}
                setPatternOffsetY={handleSetPatternOffsetY}
                handleUpdatePartColor={handleUpdatePartColor}
                handleUpdatePartColors={handleUpdatePartColors}
                handleUpdatePatternColor={handleUpdatePatternColor}
                patternList={patternList}
                colorsAngle={selectedPart.colorsAngle}
                setColorsAngle={handleUpdateColorsAngle}
                colorSelected={colorSelected}
                setColorselected={setColorselected}
                handleUpdatePattern={handleUpdatePattern}
                handleUpdateGradientPositions={handleUpdateGradientPositions}
              />
              {!editPartPattern && !selectedPart.pattern && selectedPart.supportPattern ?
                <>
                  <Box className="panel-divider">
                    <Divider bgcolor={theme.palette.white.o015}></Divider>
                  </Box>
                  <Box
                    className="panel-content"
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    <ButtonSmall
                      id="btn-add-pattern"
                      text={t("label_add_pattern")}
                      className="btn-small btn-small-icon"
                      icon={<AddIcon style={{ color: theme.palette.white.main }} />}
                      onClick={handleEditPattern}
                    ></ButtonSmall>
                  </Box>
                </>
                : ""}
            </>

          </Box>
        </Box>
        <Box className="panel-footer">
          {/* <ButtonRound
          id="btn-cancel"
          text={t("panel_button_label_cancel")}
          variant="contained"
          className="btn-red"
          onClick={handleClose}
        /> */}
          <ButtonRound
            id="btn-confirm"
            text={t("panel_button_label_confirm")}
            variant="contained"
            className="mx-auto btn-white"
            onClick={() => { handleSave({ ...selectedPart }) }}
          />
        </Box>
      </Box>
    </>
  )
}
